// @flow
import React, { useState } from 'react';
import { useNotify } from 'react-admin';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Layout as AppLayout, Footer, Container } from 'components';
import { Input } from '@material-ui/core';
import type { ComponentType } from 'react';
import { isValidEmail, isNumber } from 'helpers';

import cognito from '../../helpers/apis/cognito';

const Layout: ComponentType<*> = styled(AppLayout)`
  min-width: 320px;
  max-width: 440px;
  margin: 0 auto;
`;

const Form: ComponentType<*> = styled.form`
  display: flex;
  flex-direction: column;
  background-color: var(--backgroundPaper);
  border-radius: 2px;
  border-top: 5px solid #082f49;
  margin-top: calc(var(--spacing) * 10);
  padding: calc(var(--spacing) * 5);
  && {
    > div {
      margin: 20px 0;
    }
  }
`;

const UserNew = () => {
  const notify = useNotify();

  const passwordIsValid = () => {
    const passwordValid =
      newUserPayload.password.length >= 6 &&
      newUserPayload.password === newUserPayload.confirm_password &&
      newUserPayload.password.trim() !== '' &&
      newUserPayload.confirm_password.trim() !== '';
    if (!passwordValid) {
      notify('Password should be at least 6 characters and should match');
      return false;
    }
    return true;
  };

  const emailIsValid = () => {
    const emailValid =
      newUserPayload.email === newUserPayload.confirm_email &&
      isValidEmail(newUserPayload.email) &&
      isValidEmail(newUserPayload.confirm_email);
    if (!emailValid) {
      notify('Please provide a valid email and make sure emails match');
      return false;
    }
    return true;
  };

  const validateField = field => {
    const fieldValid = newUserPayload[field].trim() !== '';
    if (!fieldValid) {
      notify(`${field} can't be empty`);
      return false;
    }
    return true;
  };

  const formIsValid = () =>
    passwordIsValid() &&
    emailIsValid() &&
    validateField('registration_code') &&
    validateField('first_name') &&
    validateField('last_name');

  const [loading, setLoading] = useState(false);

  const handleCancel = e => {
    window.location.href = `/#login`;
  };
  const [newUserPayload, setNewUserPayload] = useState({
    registration_code: '',
    email: '',
    confirm_email: '',
    first_name: '',
    last_name: '',
    phone_number: '',
    phone_number_ext: '',
    cell_number: '',
    password: '',
    confirm_password: ''
  });
  const submit = e => {
    e.preventDefault();
    if (formIsValid()) {
      setLoading(true);
      cognito
        .registerUser(newUserPayload)
        .then(response => {
          console.log('response=', response);
          setLoading(false);
          notify(response.data.description);
          window.location.href = `/#login?email=${newUserPayload.email}`;
        })
        .catch(err => {
          console.log('error=', err);
          setLoading(false);
          notify(err.response.data.description);
        });
    }
  };

  const onChange = e => {
    setNewUserPayload({ ...newUserPayload, [e.target.name]: e.target.value });
  };

  const isSubmitDisabled =
    newUserPayload.registration_code.trim() === '' ||
    newUserPayload.first_name.trim() === '' ||
    newUserPayload.last_name.trim() === '' ||
    newUserPayload.email.trim() === '' ||
    newUserPayload.confirm_email.trim() === '' ||
    newUserPayload.password.trim() === '' ||
    newUserPayload.confirm_password.trim() === '';

  const headerStyle = { color: '#082f49', 'text-align': 'left' };

  return (
    <Layout sidebar={null} background="paper" centered footer={<Footer />}>
      <Form onSubmit={submit}>
        <h2 style={headerStyle}>Create Account</h2>
        <Input placeholder="Registration Code *" name="registration_code" onChange={onChange} />

        <h3 style={headerStyle}>Personal Info</h3>
        <Input fullWidth placeholder="First name *" onChange={onChange} name="first_name" />
        <Input fullWidth placeholder="Last name *" onChange={onChange} name="last_name" />

        <Container.Grid sm={12} xs={12}>
          <Container.Grid sm={8} xs={8}>
            <Input
              fullWidth
              placeholder="Phone number"
              onChange={onChange}
              onKeyPress={e => {
                if (!isNumber(e.key)) {
                  e.preventDefault();
                }
              }}
              inputProps={{
                maxlength: 15
              }}
              name="phone_number"
            />
          </Container.Grid>
          <Container.Grid pl={1} sm={4} xs={4}>
            <Input
              fullWidth
              placeholder="Ext"
              style={{ display: 'inline-block' }}
              onChange={onChange}
              name="phone_number_ext"
              type="text"
              onKeyPress={e => {
                if (!isNumber(e.key)) {
                  e.preventDefault();
                }
              }}
              inputProps={{
                maxlength: 5
              }}
            />
          </Container.Grid>
        </Container.Grid>

        <Input
          fullWidth
          onKeyPress={e => {
            if (!isNumber(e.key)) {
              e.preventDefault();
            }
          }}
          inputProps={{
            maxlength: 15
          }}
          placeholder="Cell number"
          onChange={onChange}
          name="cell_number"
        />

        <h3 style={headerStyle}>Account</h3>
        <Input fullWidth type="email" placeholder="Email *" onChange={onChange} name="email" inputProps={{ maxlength: 199 }} />
        <Input fullWidth placeholder="Confirm Email *" onChange={onChange} name="confirm_email" inputProps={{ maxlength: 199 }} />
        <Input fullWidth type="password" placeholder="Password *" onChange={onChange} name="password" />
        <Input fullWidth type="password" placeholder="Verify Password *" onChange={onChange} name="confirm_password" />

        <Container.Grid container justifyContent="center" sm={12} xs={12}>
          <Container.Grid item pr={1}>
            <Button
              disabled={loading || isSubmitDisabled}
              style={{
                backgroundColor: `${isSubmitDisabled ? '#ccc' : '#082f49'}`,
                color: '#fff',
                cursor: `${isSubmitDisabled ? 'not-allowed' : 'pointer'}`,
                'pointer-events': 'auto'
              }}
              type="submit"
              variant="contained"
            >
              &nbsp;&nbsp;Submit
              {loading && <CircularProgress size={18} thickness={2} />}
            </Button>
          </Container.Grid>
          <Container.Grid item pl={1}>
            <Button onClick={handleCancel} style={{ backgroundColor: '#082f49' }} variant="contained" color="primary">
              &nbsp;&nbsp;Cancel
            </Button>
          </Container.Grid>
        </Container.Grid>
      </Form>
    </Layout>
  );
};

export default UserNew;
