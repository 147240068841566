// @flow

const dashboard = {
  active: true,
  label: 'Dashboard',
  onChangeUpdate: false,
  parentIsList: true,
  isList: false,
  idRequired: true,
  show: false,
  component: 'clients/ClientsEditDashboard'
};

export default dashboard;
