// @flow

const storageType: Function = (type: boolean = false): typeof sessionStorage | typeof localStorage => localStorage;

export const setStorage: Function = (key: string, data: string | number | boolean, toSession?: boolean): void => {
  storageType(toSession).setItem(key, data);
};

export const getStorage: Function = (key: string, fromSession?: boolean): string | null | typeof undefined => {
  return storageType(fromSession).getItem(key);
};

export const clearStorage: Function = (): void => {
  localStorage.clear();
};
