// @flow
import React, { useState, type ComponentType } from 'react';
import { isValidEmail } from 'helpers'
import styled, { css } from 'styled-components';
import { DialogContent, DialogTitle, DialogContentText, Dialog as MuiDialog } from '@material-ui/core';
import { Container, TextField } from 'components';
import { ButtonCancel, ButtonSubmit as ButtonSubmitRed } from './ComponentTypes';
import { LazyApp } from '../../apps';
import { ButtonSubmit as ButtonSubmitGreen } from '../modal/ComponentTypes';

type DialogTypes = {
  title: string,
  message: string,
  setOpen: Function,
  onSubmit: Function,
  open: boolean,
  maxWidth: string,
  buttonSubmit: string,
  email?: boolean,
  deleteMessage?: boolean,
  component?: string | null,
  variant?: string,
  onlyOk?: boolean
};

const DialogMui = styled(({ ...props }) => <MuiDialog {...props} />)`
  &.Variant-standard {
    && {
      & h2 {
        font-size: 24px;
        font-weight: 500;
      }
    }
  }

  &.Variant-deleteMessage {
    && {
      text-align: center;
      & h2 {
        font-size: 24px;
        font-weight: 500;
      }
    }
  }

  && {
    ${({submitButtonPosition}) => {
      return submitButtonPosition === "top" && css`
        .dialog-buttons {
          order: 0;
          margin-bottom: 0;
        }
        .dialog-content {
          order: 1;
        }
      `
    }}
  }


`;

const Dialog = ({
  title,
  message,
  setOpen,
  onSubmit,
  open = false,
  maxWidth = 'sm',
  buttonSubmit,
  email,
  component,
  variant = 'standard' || 'deleteMessage',
  deleteMessage,
  onlyOk,
  children,
  submitButtonPosition = "bottom"
}: DialogTypes) => {
  const [emailValue, setEmail] = useState(null);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const submit = () => {
    onSubmit(emailValue);
  };
  const handleEmailChange = e => {
    setEmail(e.target.value);

    if(!isValidEmail(e.target.value)) setInvalidEmail(true);
    else setInvalidEmail(false);
  };
  return (
    <div>
      <DialogMui
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={maxWidth}
        fullWidth
        classes={{ root: `Variant-${variant}` }}
        submitButtonPosition={submitButtonPosition}
      >
        <DialogTitle>{title}</DialogTitle>

        <DialogContent className={"dialog-content"}>
          <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
          {email && (
            <TextField
              autoFocus
              margin="dense"
              id="name"
              value={emailValue}
              label="Email Address"
              type="email"
              fullWidth
              onChange={handleEmailChange}
              error={invalidEmail}
              helperText={invalidEmail && 'Please enter a valid email address'}
            />
          )}
          {component !== null && <LazyApp component={component} />}
          {children}
        </DialogContent>
        {variant === 'deleteMessage' ? (
          <Container.Grid direction="column" alignItems="center" justify="center" mb={3}>
            <ButtonSubmitRed onClick={onSubmit} color="primary" autoFocus>
              {buttonSubmit}
            </ButtonSubmitRed>
            <ButtonCancel onClick={handleClose} color="primary">
              Cancel
            </ButtonCancel>
          </Container.Grid>
        ) : (
          <Container.Grid alignItems="flex-end" justify="flex-end" mb={3} pr={3} className={"dialog-buttons"}>
            {!onlyOk && (
              <ButtonCancel mr={3} onClick={handleClose} color="primary">
                Cancel
              </ButtonCancel>
            )}
            {
              buttonSubmit && (
                <ButtonSubmitGreen onClick={submit} color="primary" autoFocus disabled={email && invalidEmail}>
                  {buttonSubmit}
                </ButtonSubmitGreen>
              )
            }

          </Container.Grid>
        )}
      </DialogMui>
    </div>
  );
};

Dialog.defaultProps = {
  email: false,
  deleteMessage: true,
  component: null,
  variant: 'deleteMessage'
};

export default Dialog;
