/* eslint-disable react-hooks/exhaustive-deps */
// @flow
import React, {
  type Element,
  type Context,
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
  useReducer
} from 'react';
import { useSelector } from 'react-redux';
import { dataProvider, hasKey, useApp, isObjectEmpty } from 'helpers';
import { useConfig, useTableData, useTableProps, useTableRowSelected, useModalConfig } from 'hooks';
import { useNotify } from 'react-admin';

const TableListContext: Context<{
  dispatch: Object,
  filter: string | boolean,
  settings: Object,
  record: Object | Array<*>,
  order: 'asc' | 'desc',
  selected: Array<*>,
  orderBy: string,
  page: number,
  emptyRows: number,
  rowsPerPage: number,
  open: boolean,
  password: string,
  app: string,
  tab: string,
  item: string | number | boolean,
  paramKeyForModal: string,
  toolbar: any,
  tableActions: Object | null,
  disabeldActions: Array<string>,
  total: number | null,
  isFetching: boolean,
  isPreviousData: boolean,
  isLoading: boolean,
  status: string,
  keyCheckToHideIcon?: string,
  openDialog?: boolean
}> = createContext({
  dispatch: {},
  filter: false,
  settings: {},
  record: null,
  order: 'asc',
  selected: [],
  orderBy: '',
  paramKeyForModal: '',
  keyCheckToHideIcon: '',
  page: 0,
  emptyRows: 0,
  rowsPerPage: 25,
  open: false,
  password: '',
  app: '',
  tab: '',
  item: 0,
  toolbar: null,
  tableActions: null,
  disabeldActions: [],
  total: 0,
  isFetching: false,
  isPreviousData: false,
  isLoading: false,
  status: ''
});

export type TableListProviderTypes = {
  tableSettings?: Object,
  paramKeyForModal?: string,
  keyCheckToHideIcon?: string,
  data?: {
    data: Array<*> | Object,
    total: number,
    status: string,
    dispatch: Function,
    isFetching: boolean,
    isPreviousData: boolean,
    isLoading: boolean
  } | null,
  source?: string,
  id?: string | boolean,
  onSearch?: Function,
  cache?: boolean,
  children?: any,
  toolbar?: any,
  tableActions?: Object,
  disabeldActions?: Array,
  newItemSource?: any,
  refresh?: boolean,
  setApp?: string,
  transform?: Function,
  tableParams?: Object,
  tableOptions?: Object,
  resetTable?: string,
  modalTitle?: string,
  noFilter?: boolean,
  localSelection?: boolean,
  disableTableAction?: boolean,
  additionalRefetch?: Function,
  paramIdVal?: string
};

export const TableListProvider = ({
  tableSettings,
  paramKeyForModal,
  keyCheckToHideIcon,
  data,
  source: tableTab,
  id,
  onSearch,
  cache = true,
  children,
  toolbar,
  tableActions,
  disabeldActions,
  newItemSource,
  refresh = false,
  setApp: tableApp,
  transform = e => e,
  tableParams = {},
  tableOptions = {},
  resetTable,
  modalTitle,
  noFilter,
  localSelection,
  disableTableAction,
  additionalRefetch,
  paramIdVal
}: TableListProviderTypes): Element<*> => {
  const state = useSelector(rxState => rxState.bsn);
  const { dispatch: dispatchApp } = useApp();
  const { app, tab, item, settings: settingsProps } = useTableProps({
    tableApp,
    tableTab,
    tableItem: id,
    tableSettings
  });
  let settings = settingsProps;
  const { getFilterFlat, isSelectedAll } = useTableRowSelected();
  const selectFilters = getFilterFlat();
  const { pagination } = useConfig('system', 'table');
  const initialState = {
    open: false,
    searchTerm: '',
    page: 0,
    params:
      settings && hasKey(settings, 'params')
        ? settings.params
        : {
            pagination: {
              page: 0,
              perPage: 25
            }
          }
  };
  const [reduce, setState] = useReducer(reducer, initialState);
  const [openDialog, setOpenDialog] = useState(false);
  const notify = useNotify();

  const [order, setOrder] = useState(hasKey(settings, 'order') ? settings.order : 'asc');
  const [orderBy, setOrderBy] = useState(hasKey(settings, 'orderBy') ? settings.orderBy : 'name');
  const [selected, setSelected] = useState([]);
  const [unselected, setUnselected] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(pagination.rowsPerPage);
  const [filter, setFilter] = useState(false);
  const [password, setPassword] = useState('none');
  const [, setTotal] = useState(null);
  const [newRefresh, setRefresh] = useState(refresh);

  const UseTableData = () => useTableData({ app, tab, item, transform, params: getParams(), options: tableOptions });
  const { data: record, page: pageUtd, prePage: perPageUtd, total, status, dispatch: dispatchUtd, isFetching, isPreviousData, isLoading } = (d => {
    if (d && hasKey(d, 'data') && d.data !== null) return d;
    return UseTableData();
  })(data);

  const { setOpen: setOpenModal, open: openModal } = useModalConfig(app, tab);

  if (record) {
    const [rowTable] = hasKey(record, "data") ? record.data : record;

    const settingsAccessLevelHidden =
      rowTable &&
      settings.cells.map(cl => {
        if (cl.id === 'access_level') {
          const [lDirSync, lBill, lMktg, lBuy, lTax] = Object.keys(rowTable.access_level);
          const [vMktg, vBuy, vBill, vTax, vDirSync] = cl.subLabel;
          const accessLevelLabels = {
            [lMktg]: vMktg,
            [lBuy]: vBuy,
            [lBill]: vBill,
            [lTax]: vTax,
            [lDirSync]: vDirSync
          };

          return {
            ...cl,
            subLabel: Object.keys(accessLevelLabels)
              .filter(al => typeof rowTable.access_level[al] === 'boolean')
              .map(al => accessLevelLabels[al])
          };
        }
        return cl;
      });

    const rowKeys = rowTable && Object.keys(rowTable);
    const keysHidden = rowTable && rowKeys.filter(row => rowTable[row] === 'hidden');
    if (settings) {
      const newCells = rowTable
        ? settingsAccessLevelHidden.filter(row => !keysHidden.includes(row.id))
        : settings.cells;
      settings = { ...settings, cells: newCells };
    }
  }

  useEffect(() => {
    if (refresh)  setRefresh(true);
    return () => {
      setTimeout(() => {
        setRefresh(false);
      }, 300);
    };
  }, [refresh]);

  useEffect(() => {
    if (newRefresh) dispatchUtd.refetch()
  }, [newRefresh]);

  useEffect(() => {
    if (resetTable) {
      setOrder(hasKey(settings, 'order') ? settings.order : 'asc');
      setOrderBy(hasKey(settings, 'orderBy') ? settings.orderBy : 'name');
      setPage(0);
      setRowsPerPage(25);
      dispatchUtd.setPrePage(25);
      setSelected([]);
      dispatchApp.set('system', 'tableRowSelected', []);
      dispatchApp.set('system', 'tableRowSelectedList', []);
      dispatchApp.set('system', 'tableRowUnselected', []);
      dispatchApp.set('system', 'tableRowSelectedCount', 0);
    }
  }, [resetTable]);

  useEffect(() => {
    const isFilter = selectFilters !== '' || reduce.searchTerm !== '';
    if (!isLoading) search(isFilter);
  }, [selectFilters, reduce.searchTerm, orderBy, order, isLoading]);

  useEffect(() => {
    setSelected([]);
    if (selectFilters) {
      setPage(0);
      dispatchApp.set('system', 'tableRowSelected', []);
      dispatchApp.set('system', 'tableRowSelectedList', []);
      dispatchApp.set('system', 'tableRowUnselected', []);
      dispatchApp.set('system', 'tableRowSelectedCount', 0);
    }
  }, [selectFilters]);

  useEffect(() => {
    if (pageUtd || perPageUtd) {
      setPage(pageUtd);
      setRowsPerPage(perPageUtd);
    }
  }, [pageUtd, perPageUtd]);

  const dispatch = {};

  dispatch.handleRequestSort = useCallback(
    (event, property) => {
      const isDesc = orderBy === property && order === 'desc';
      const newOrder = isDesc ? 'asc' : 'desc';
      const newParams = {
        ...reduce.params,
        sort: {
          field: property,
          order: newOrder
        }
      };
      setOrder(newOrder);
      setOrderBy(property);
      setParams(newParams);

      if (dispatchUtd.setOrder) dispatchUtd.setOrder(newOrder);
      if (dispatchUtd.setOrderBy) dispatchUtd.setOrderBy(property);
    },
    [order, orderBy, reduce.params]
  );

  dispatch.handleSelectAllClick = useCallback(
    event => {
      if (event.target.checked) {
        let newSelecteds = record.map(n => n.id);
        setSelected(newSelecteds);
        newSelecteds = settings?.selectAll ? newSelecteds : ['all'];
        if (!localSelection) {
          dispatchApp.set('system', 'tableRowSelected', newSelecteds);
          dispatchApp.set('system', 'tableRowSelectedList', record);
        }
        return;
      }
      setSelected([]);
      setUnselected([]);
      if (!localSelection) {
        dispatchApp.set('system', 'tableRowSelected', []);
        dispatchApp.set('system', 'tableRowSelectedList', []);
        dispatchApp.set('system', 'tableRowUnselected', []);
      }
    },
    [record]
  );

  dispatch.handleClick = useCallback(
    (event, name, row) => {
      if (state.system.tableRowSelected[0] === 'all') {
        const newUnselected = getSelected(name, 'tableRowUnselected');
        setUnselected(newUnselected);
        if (!localSelection) dispatchApp.set('system', 'tableRowUnselected', newUnselected);
      } else {
        const newSelected = getSelected(name, 'tableRowSelected');
        setSelected(newSelected);
        if (!localSelection) {
          dispatchApp.set('system', 'tableRowSelected', newSelected);
          let oldList = [...state.system.tableRowSelectedList];
          let newList = event.target.checked ? oldList.concat([row]) : oldList.filter(el => el.id !== row.id);
          dispatchApp.set('system', 'tableRowSelectedList', newList);
        }
      }
    },
    [dispatchApp, selected]
  );

  function getSelected(name: string, type: 'tableRowSelected' | 'tableRowUnselected' = 'tableRowSelected') {
    let newSelected = localSelection ? [...selected] : state.system[type];
    const newName = String(name);
    const selectIndex = newSelected.indexOf(newName);
    if (selectIndex > 0 || selectIndex === 0) return newSelected.filter(s => s !== newName);
    if (selectIndex === -1) return [...newSelected, ...Array(newName)];
    return selectIndex;
  }

  dispatch.handleRadio = useCallback(
    (event, name) => {
      setSelected([name]);
      dispatchApp.set('system', 'tableRowSelected', [name]);
    },
    [dispatchApp]
  );

  dispatch.handleChangeRowsPerPage = useCallback(event => {
    dispatchUtd.setPrePage(+event.target.value);
    setRowsPerPage(+event.target.value);
    setPage(0);
  }, []);

  dispatch.setNewPage = (e, p) => {
    dispatchUtd.setPage(p);
    setPage(p);
  };

  dispatch.setOpen = e => {
    if (!e) dispatchUtd.refetch();
    setState({ type: 'SETOPEN', payload: e });
  };

  dispatch.setOpenDialog = useCallback(e => setOpenDialog(e));

  dispatch.setFilter = useCallback(e => setFilter(e), []);

  dispatch.setPassword = useCallback(e => setPassword(e), []);

  dispatch.setTotal = useCallback(e => setTotal(e), []);

  dispatch.setRefresh = setRefresh;

  dispatch.isSelected = useCallback(
    name => {
      if (isSelectedAll) return unselected.indexOf(name) === -1;
      return selected.indexOf(name) !== -1;
    },
    [selected, unselected]
  );

  dispatch.search = useCallback((term: string) => {
    setState({ type: 'SETSEARCH', payload: term });
  }, []);

  function search(reset = true) {
    const newParams = getParams(reset);
    if (!data) dispatchUtd.setFilter(newParams);
    const type = reset ? 'SETSEARCHPARAMS' : 'SETPARAMS';
    setState({ type, payload: newParams });
  }

  function getParams(reset) {
    return {
      ...reduce.params,
      _filter: noFilter ? '' : getFielterFields(),
      sort: {
        field: orderBy,
        order
      },
      pagination: {
        page: reset ? 0 : reduce?.params?.pagination?.page || 0,
        perPage: rowsPerPage
      }
    };
  }

  function getFielterFields() {
    if (!settings?.searchFields && selectFilters === '' && isObjectEmpty(tableParams)) return '';
    const fields =
      reduce.searchTerm.length > 0 && settings?.searchFields.map(f => `${f}:${reduce.searchTerm}`).join(',');
    const filterFields = [];
    if (fields) filterFields.push(fields);
    if (selectFilters) filterFields.push(selectFilters);
    Object.keys(tableParams).forEach(e => filterFields.push(tableParams[e]));
    return filterFields.join(',');
  }
  dispatch.delete = useCallback(() => {
    const rowList = state.system.tableRowSelected;
    dataProvider
      .delete(app, `${String(tab)}/${String(id)}`, null, { id: rowList })
      .then(res => {
        setPage(0);
        dispatchUtd.setPage(0);
        setSelected([]);
        dispatchApp.set('system', 'tableRowSelected', []);
        dispatchApp.set('system', 'tableRowSelectedList', []);
        // notify(`${rowList.length} Other Policy/Policies have been deleted`, 'warning');
        notify(
          res.data?.status
            ? `${res.data.status}`
            : `${rowList.length} ${rowList.length === 1 ? 'item' : 'items'} have been deleted`,
          'warning'
        );
      })
      .catch(error =>
        notify(
          error.response?.data?.message ? `${error.response.data.message}` : `${error.response.data.description}`,
          'warning'
        )
      )
      .finally(() => dispatchUtd.refetch());
  }, [app, tab]);

  dispatch.resetPhishingItem = useCallback(
    selected => {
      const rowList = selected || state.system.tableRowSelected;
      dataProvider
        .post(app, `phishingResetCampaign/${id}`, null, { ids: rowList })
        .then(res =>
          notify(
            `Campaign has been reset successfully for ${rowList.length} ${rowList.length === 1 ? 'User' : 'Users'}`,
            'warning'
          )
        )
        .catch(error => notify(`${error.response.data.code}: ${error.response.data.description}`, 'warning'))
        .finally(() => dispatchUtd.refetch());
    },
    [app, tab]
  );

  dispatch.endCampaign = useCallback(() => {
    const rowList = state.system.tableRowSelected;
    dataProvider
      .post(app, `phishingReportsCampaign/${id}`, null, { type: 'endCampaign', ids: rowList })
      .then(res => notify(`${rowList.length} ${rowList.length === 1 ? 'item' : 'items'} was ended`, 'warning'))
      .catch(error => {
        notify(`It was not possible end campaign due to: ${error.response.data.description}`);
      })
      .finally(() => dispatchUtd.refetch());
  }, [app, tab]);

  dispatch.setSelected = (selection: Array<*>) => {
    setSelected(selection);
    if (!localSelection) {
      dispatchApp.set('system', 'tableRowSelected', selection);
      dispatchApp.set('system', 'tableRowSelectedList', selection);
    }
  };

  function setPage(p) {
    setState({ type: 'SETPAGE', payload: p });
  }

  function setParams(params) {
    setState({ type: 'SETPARAMS', payload: params });
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, record.length - reduce.page * rowsPerPage);
  return (
    <TableListContext.Provider
      value={{
        app,
        tab,
        item,
        dispatch,
        filter,
        settings,
        record,
        order,
        selected,
        orderBy,
        page: reduce.page,
        emptyRows,
        rowsPerPage,
        open: reduce.open,
        openDialog,
        password,
        toolbar,
        tableActions,
        disabeldActions,
        total,
        status,
        paramKeyForModal,
        keyCheckToHideIcon,
        isFetching,
        isPreviousData,
        isLoading,
        setOpenModal,
        openModal,
        modalTitle,
        disableTableAction,
        additionalRefetch,
        paramIdVal
      }}
    >
      {children}
    </TableListContext.Provider>
  );
};

function reducer(previousState, { type, payload }) {
  switch (type) {
    case 'SETOPEN': {
      return { ...previousState, open: payload };
    }

    case 'SETSEARCH': {
      return { ...previousState, searchTerm: payload };
    }

    case 'SETPAGE': {
      return { ...previousState, page: payload };
    }

    case 'SETPARAMS': {
      return { ...previousState, params: payload };
    }

    case 'SETSEARCHPARAMS': {
      const newState = previousState;
      newState.params = payload;
      newState.page = 0;
      return newState;
    }

    default: {
      return { ...previousState, ...payload };
    }
  }
}

TableListProvider.defaultProps = {
  data: null,
  source: null,
  id: null,
  tableSettings: null,
  onSearch: e => e,
  cache: true,
  children: null,
  toolbar: null,
  tableActions: null,
  disabeldActions: [],
  newItemSource: null,
  refresh: false,
  paramKeyForModal: '',
  keyCheckToHideIcon: '',
  setApp: null,
  transform: e => e,
  tableParams: {},
  tableOptions: {},
  resetTable: null,
  modalTitle: '',
  noFilter: false,
  localSelection: false,
  disableTableAction: false,
  additionalRefetch: null,
  paramIdVal: ''
};

export const useTableList = () => useContext(TableListContext);
