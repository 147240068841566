// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const NotComplete = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 24 24" role="img" aria-hidden="false">
      <path d="M9.21 1.73L5.03 10.08L0.33 19.47C0.11 19.93 0 20.41 0 20.87C0 22.51 1.32 24 3.12 24H12H20.88C22.69 24 24 22.51 24 20.87C24 20.41 23.89 19.93 23.66 19.47L18.96 10.08L14.78 1.73C13.64 -0.580001 10.36 -0.580001 9.21 1.73ZM13 2.62L17.18 10.97L21.88 20.36C21.96 20.52 22 20.69 22 20.86C22 21.4 21.57 21.98 20.88 21.98H12H3.12C2.43 22 2 21.41 2 20.87C2 20.7 2.04 20.53 2.12 20.37L6.82 10.98L11 2.62C11.28 2.06 11.79 2 12 2C12.21 2 12.72 2.06 13 2.62Z" fill="#535353"/>
      <path d="M11.06 13.92L10.79 9.83C10.74 9.03 10.71 8.46 10.71 8.11C10.71 7.64 10.83 7.27 11.08 7C11.33 6.74 11.65 6.6 12.06 6.6C12.55 6.6 12.88 6.77 13.04 7.11C13.21 7.45 13.29 7.94 13.29 8.58C13.29 8.96 13.27 9.34 13.23 9.73L12.86 13.94C12.82 14.44 12.73 14.83 12.6 15.09C12.47 15.36 12.25 15.49 11.95 15.49C11.64 15.49 11.43 15.36 11.31 15.1C11.2 14.85 11.11 14.45 11.06 13.92ZM12.01 19.55C11.66 19.55 11.36 19.44 11.1 19.21C10.84 18.99 10.71 18.67 10.71 18.27C10.71 17.92 10.83 17.62 11.08 17.37C11.33 17.12 11.63 17 11.99 17C12.35 17 12.66 17.12 12.91 17.37C13.16 17.62 13.29 17.92 13.29 18.27C13.29 18.67 13.16 18.98 12.91 19.21C12.65 19.43 12.35 19.55 12.01 19.55Z" fill="#535353"/>
  </SvgIconStyled>
);

export default NotComplete;
