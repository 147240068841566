// @flow
import { type ComponentType } from 'react';
import styled from 'styled-components';
import { MenuItemLink as MuiMenuItemLink } from 'react-admin';

export const ButtonCancel: ComponentType<*> = styled(MuiMenuItemLink)`
  && {
    text-transform: capitalize;
    color: var(--colorDefault);
    background: transparent;
    text-decoration: underline;
    box-shadow: none;
    margin-right: 20px;
    font-size: var(--fontSize);
    & span {
      color: var(--colorDefault);
    }
    &:hover {
      color: var(--colorDefault);
      background: transparent;
      text-decoration: underline;
      box-shadow: none;
    }
  }
`;
