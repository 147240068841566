// @flow
import React from 'react';
import { Avatar, Typography, makeStyles, Box } from '@material-ui/core';
import ReactTimeAgo from 'react-time-ago';

const useStyles = makeStyles(theme => ({
  name: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '19px',
    letterSpacing: '0px',
    textAlign: 'left'
  },
  timestamp: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '14px',
    letterSpacing: '0em',
    textAlign: 'left'
  },
  timeAgo: {
    display: 'inline-block',
    '&::first-letter': {
      textTransform: 'uppercase'
    }
  },
  avatar: {
    width: '50px',
    height: '50px',
    [theme.breakpoints.down('sm')]: {
      width: '40px',
      height: '40px'
    },
  }
}));

const UserAvatar = ({ user, timestamp }) => {
  const classes = useStyles();

  return (
    <>
      <Box>
        <Avatar style={{ backgroundColor: user.partner_base_color }}>
          {`${user.first_name.charAt(0)}${user.last_name.charAt(0)}`}
        </Avatar>
      </Box>
      <Box pl={{ xs: 1.2, md: 2 }} flexGrow={1}>
        <Box>
          <Typography className={classes.name} variant="h4" gutterBottom>
            {`${user.first_name} ${user.last_name}`}
          </Typography>
        </Box>
        <Box>
          <Typography className={classes.timestamp} variant="caption" display="block" gutterBottom>
            {`${user.client_name} ${timestamp && ` / `}`}
            {timestamp && (
              <ReactTimeAgo
                className={classes.timeAgo}
                timeStyle="round-minute"
                date={timestamp.replace(' ', 'T') + 'Z'}
                locale="en-US"
              />
            )}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default UserAvatar;
