// @flow
import React, { memo, type Element } from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';

const Foot = styled.footer`
  padding: calc(var(--spacing) * 2) 0;
  text-align: center;
  color: var(--colorDefault);
`;

type Props = Object;

const Footer = (props: Props): Element<*> => {
  const domainsCopyrights = { 
    hipaa: "HIPAA Secure Now! Copyright",
    pii: "PII Protect Copyright"  ,
    phi:"PHI Protect Copyright"
  };
  const hostname = window.location.hostname;
  const currentDomain = hostname.includes("hipaasecurenow.com") ? "hipaa": hostname.includes("phi-protect.com") ? "phi":"pii";
  const copyright = domainsCopyrights[currentDomain];
  return (
    <Foot {...props}>
      <Typography variant="caption">{copyright} &#169; {new Date().getFullYear()}</Typography>
    </Foot>
  );
};

export default memo<Props>(Footer);
