// @flow
import React, { memo, type Element } from 'react';
import { Link } from 'react-router-dom';
import { hasKey } from 'helpers';
import { TabsHeader, TabsFixed, TabButton } from './ComponentTypes';
import { useTabs } from './TabsContext';
import TabsTab from './TabsTab';
import { Hidden } from '@material-ui/core';

type TabsTypes = Object;

const TabsTabs = (): Element<*> => {
  const { tabCurrent, layout, tabs, dispatch, access, hideTabs } = useTabs();

  const isComingSoon = hasKey(layout[tabCurrent], 'soon') && layout[tabCurrent].soon;

  return (
    <>
      {!hideTabs && (
        <Hidden smDown>
          <TabsFixed>
            <TabsHeader variant="scrollable" scrollButtons="desktop" value={tabCurrent} onChange={dispatch.onChangeTab}>
              {tabs.map(
                tid =>
                  access[tid] && (
                    <Link
                      to={({ pathname }) => {
                        const [, pathApp, , item] = pathname.split('/');
                        return item ? `/${pathApp}/${tid}/${item}` : `/${pathApp}/${tid}`;
                      }}
                      key={tid}
                      tabId={tid}
                      value={tid}
                      label={layout[tid].label}
                      component={TabButton}
                    />
                  )
              )}
            </TabsHeader>
          </TabsFixed>
        </Hidden>
      )}
      {isComingSoon ? <h1>{layout[tabCurrent].label} coming soon!</h1> : <TabsTab />}
    </>
  );
};

export default memo<TabsTypes>(TabsTabs);
