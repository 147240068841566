// @flow
import React, { cloneElement, type Element, useState, useEffect, type ComponentType, useMemo } from 'react';
import styled from 'styled-components';
import {
  Filter,
  // BooleanField,
  CreateButton,
  NumberField,
  FunctionField,
  TopToolbar,
  SearchInput,
  BooleanInput,
  useRefresh,
  SelectInput,
  sanitizeListRestProps,
  useNotify,
} from 'react-admin';
import {
  Button,
  IconButton,
  Toolbar,
  Tooltip,
  CircularProgress,
  Select,
  MenuItem
} from '@material-ui/core';
import Clipboard from 'simple-react-clipboard';

import {
  ArrowBackIos as ArrowBackIosIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
  FileCopyOutlined as FileCopyOutlinedIcon
} from '@material-ui/icons';
import { List, ChartHalfCircle, LazyIcon, Typography, Dialog } from 'components';
import { dataProvider, useApp, dataPost, dataFetch } from 'helpers';
import { useLocation, useId } from 'hooks';
import ClientsCreate from './ClientsCreate';
import ClientsEdit from './ClientsEdit';
import reporting from '../../helpers/apis/reporting';
import useHasInternet from '../../hooks/useHasInternet';
import { Pagination } from '@material-ui/lab';

type ClientsListProps = Object;

const Chart = styled(ChartHalfCircle)`
  && {
    width: 5rem;
    height: 3rem;
    display: table-caption;

    text {
      font-size: 2.5rem;
      transform: translateX(-9px);
    }

    path {
      stroke-width: calc(var(--spacing) * 1.4);
    }

    .chart-halfcircle-foregournd {
      stroke: var(
        ${({ chart: { chartData } }) => {
          if (chartData[0].name < 500) return '--colorSystemDanger';
          if (chartData[0].name < 630) return '--colorSystemWarning';
          return '--colorSystemSuccess';
        }}
      );
    }
  }
`;

const HeadTopbar: ComponentType<*> = styled(TopToolbar)`
  && {
    padding: 0;

    & button,
    a {
      text-transform: capitalize;
      background-color: var(--colorBaseBase);
      color: var(--colorCommonWhite);
      padding: calc(var(--spacing) * 0.5) calc(var(--spacing) * 2);
      margin: 0 calc(var(--spacing) * 2);
      font-weight: var(--fontWeightRegular);
    }
  }
`;

const FilterSearchInput: ComponentType<*> = styled(SearchInput)`
  && {
    margin: 0;
    & > div {
      display: flex;
      flex-direction: row-reverse;
      border-radius: var(--borderRadius);
      width: calc(var(--spacing) * 45);
      background-color: var(--backgroundDefault);
      padding: calc(var(--spacing) * 0.2) 0;
      &:before,
      &:after {
        display: none;
      }

      & svg {
        font-size: calc(var(--fontSize) * 1.5);
      }
    }
  }
`;

const FilterSelectInput: ComponentType<*> = styled(SelectInput)`
  && {
    margin: 0;
    & > div {
      display: flex;
      border-radius: var(--borderRadius);
      width: calc(var(--spacing) * 45);
      background-color: var(--backgroundDefault);
      &:before,
      &:after {
        display: none;
      }

      & svg {
        font-size: calc(var(--fontSize) * 1.5);
        margin-top: calc(var(--spacing) * 0.4);
      }
    }
  }
`;

const CompanyName = styled.span`
  display: block;
`;

const ProductType = styled.span`
  display: block;
  font-size: 1.2rem;
`;

const NoResults = styled.h2`
  text-align: center;
  color: var(--colorDefault);
  padding: 30px;
`;

const PaginationToolbar: ComponentType<*> = styled(Toolbar)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const PaginationButton: ComponentType<*> = styled(Button)`
  & > span {
    text-transform: capitalize;
    font-size: var(--fontSize);
    display: flex;
    align-items: center;
  }
`;

const PaginationLink: ComponentType<*> = styled(Typography.p)`
  cursor: pointer;
`;

const ArrowIconForward: ComponentType<*> = styled(ArrowForwardIosIcon)`
  margin-left: calc(var(--spacing) * 1.3);
  font-size: calc(var(--fontSize) * 1.3);
`;

const ArrowIconBack: ComponentType<*> = styled(ArrowBackIosIcon)`
  margin-right: calc(var(--spacing) * 1.3);
  font-size: calc(var(--fontSize) * 1.3);
`;

const NumberRight: ComponentType<*> = styled(NumberField)`
  display: inline-table;
  width: 50%;
  text-align: right;
`;

const ClientListActions = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  filterValues,
  permanentFilter,
  hasCreate, // you can hide CreateButton if hasCreate = false
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  ...rest
}: Object) => (
  <HeadTopbar className={className} {...sanitizeListRestProps(rest)}>
    {filters &&
      cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button'
      })}
    <CreateButton label="New Client" basePath={basePath} />
  </HeadTopbar>
);

const ClientListFilter = props => {
  const { dispatch } = useApp();
  const partnerId = useId({ key: 'partnerId' });
  const [productsType, setProductsType] = useState(null);
  const hasInternet = useHasInternet()

  useEffect(() => {
    dispatch.set('clients', 'information', null);
  }, [dispatch]);

  useEffect(() => {
    if (productsType === null) {
      dataProvider.getList('clients', `productsType/${partnerId}`, {}).then(res => {
        hasInternet(() => {
          setProductsType(res.data)
        })
      });
    }
  }, [productsType]);

  return (
    <Filter {...props}>
      <FilterSearchInput source="name" alwaysOn />
      {productsType && <FilterSelectInput source="product_type" label="Product" choices={productsType} />}
      <BooleanInput label="Active" source="active" defaultValue />
    </Filter>
  );
};

const StyledPagination = ({ page, perPage, total, setPage, setPerPage, loaded }: ClientsListProps) => {
  const nbPages = Math.ceil(total / perPage) || 1;
  const start = page * perPage - (perPage - 1);
  const end = Math.min(start + perPage - 1, total);

  const rowsPerPageMemo = useMemo(
    () =>
      <Select value={perPage || 25} onChange={({target: {value}}) => setPerPage(value)}>
        <MenuItem value={25}>25</MenuItem>
        <MenuItem value={50}>50</MenuItem>
        <MenuItem value={100}>100</MenuItem>
        <MenuItem value={200}>200</MenuItem>
      </Select>,
    [perPage]
  );

  return (
    <>
      {loaded && total === '0' && <NoResults>No results.</NoResults>}

      <PaginationToolbar>

        <Typography.p fontSize={12} mr={5} ml={5}>
          {start} - {end} of {total} Items
        </Typography.p>

        {rowsPerPageMemo}

        <Typography.p fontSize={12} mr={2} ml={2}>
          Items per page
        </Typography.p>

        <Pagination
          count={nbPages}
          page={page}
          onChange={(event, paginationPage) => setPage(paginationPage)}
        />

      </PaginationToolbar>
    </>
  );
};

const ClientList = (props: ClientsListProps): Element<*> => {
  // const { dispatch } = useApp();
  const refresh = useRefresh();
  const notify = useNotify();
  const [openDialog, setOpen] = useState(false);
  const [idOnOpen, setIdOnOpen] = useState(null);
  const [loading, setLoading] = useState(false);
  const [idOnPrint, setIdOnPrint] = useState(null);

  const actionClient = (type, id) => {
    const app = 'clients';
    const tab = 'actions';

    dataPost({ app, tab, item: id, params: {}, data: { type } }).then(res => {
      refresh();
      notify(res.data.message);
    });
  };
  const setOpenDialog = (val, id) => {
    setOpen(val);
    setIdOnOpen(id);
  };

  const actionDeleteClient = () => {
    const app = 'clients';
    const tab = 'actions';

    setOpenDialog(false);

    dataProvider
      .delete(app, tab, idOnOpen)
      .then(res => {
        refresh();
        notify(res.data.message);
      })
      .catch(err => notify(`${err.response.data.description}`, 'error'))
      .finally(() => {
        setOpenDialog(false, null);
      });
  };

  const printClientReport = client_id => {
    setLoading(true);
    setIdOnPrint(client_id);
    reporting
      .getReport({
        report_key: 'ClientUberReport',
        recipe: 'xlsx',
        parameters: { client_id },
        delivery: 'url'
      })
      .then(res => window.open(res.data.url))
      .catch(error => notify(`${error.response}`, error))
      .finally(() => setLoading(false));
  };

  return (
    <>
      <List
        {...props}
        sort={{ field: 'name', order: 'ASC' }}
        actions={<ClientListActions basePath={props.basePath} />}
        edit={<ClientsEdit />}
        create={<ClientsCreate />}
        bulkActions={false}
        bulkActionButtons={false}
        filters={<ClientListFilter />}
        pagination={<StyledPagination />}
      >
        <FunctionField
          label="Name"
          sortBy="name"
          sortByOrder="ASC"
          render={({ name, product_type, is_visible_client_id, clientid }) => (
            <>
              <CompanyName>{name}</CompanyName>
              <ProductType>
                Product: {product_type}
                {is_visible_client_id && (
                  <>
                    &nbsp;&nbsp; | Client ID: {clientid}&nbsp;&nbsp;
                    <Clipboard
                      text={clientid}
                      render={({ copy }) => (
                        <IconButton
                          aria-label="Copy to clipboard"
                          onClick={e => {
                            e.stopPropagation();
                            copy();
                            notify(`Client ID: ${clientid} has been copied to the clipboard`, 'info');
                          }}
                        >
                          <FileCopyOutlinedIcon />
                        </IconButton>
                      )}
                    />
                  </>
                )}
              </ProductType>
            </>
          )}
        />
        <FunctionField
          label="Branding"
          textAlign="center"
          sortBy="branding"
          sortByOrder="DESC"
          render={({ branding }) => branding && <LazyIcon component="Shield" size={1.6} />}
        />
        <FunctionField
          label="Consulting"
          textAlign="center"
          sortBy="ra_consulting"
          sortByOrder="DESC"
          render={({ ra_consulting }) => ra_consulting && <LazyIcon component="Consulting" size={1.6} />}
        />
        {/* <BooleanField source="branding" label="Branding" /> */}
        {/* <BooleanField source="ra_consulting" label="Consulting" /> */}
        <FunctionField
          label="Insurance"
          textAlign="center"
          sortBy="insurance"
          sortByOrder="DESC"
          render={({ insurance_code }) =>
            insurance_code !== '' && (
              <a href={insurance_code} target="_blank" onClick={e => e.stopPropagation()} rel="noopener noreferrer">
                <LazyIcon component="Insurance" size={1.6} />
              </a>
            )
          }
        />
        <FunctionField
          label="RA"
          sortBy="ra_complete"
          sortByOrder="DESC"
          render={({ ra_complete }) => ra_complete && <span style={{ whiteSpace: 'nowrap' }}>{ra_complete}</span>}
        />
        <NumberRight source="users" label="Users" textAlign="center" sortByOrder="DESC" />
        <NumberRight source="data_breaches" label="Breaches" textAlign="center" sortByOrder="DESC" />
        {/* <FunctionField
          label="Breaches"
          textAlign="right"
          sortBy="data_breaches"
          sortByOrder="DESC"
          render={({ data_breaches }) => {
            const db = data_breaches.split('/');
            return typeof db[1] !== 'undefined' ? (
              <>
                <CompanyName>{db[0]}</CompanyName>
                <ProductType>({Math.round((db[0] / db[1]) * 100)}%)</ProductType>
              </>
            ) : (
              db[0]
            );
          }}
        /> */}
        <FunctionField
          label="ESS"
          textAlign="center"
          sortBy="average_ess"
          sortByOrder="DESC"
          render={({ average_ess }) =>
            average_ess && (
              <Chart
                title="Average ESS"
                chart={{
                  chartData: [{ name: average_ess, value: (average_ess * 100) / 800 }]
                }}
                labels
                showOf={false}
                showLegends={false}
                showTitle={false}
                classses={{ root: 'chart-root' }}
              />
            )
          }
        />
        {/* <DateField source="last_breach" label="Last Breach" /> */}
        {/* <NumberField source="average_micro_quizz" label="Ave Micro Quiz" />
        <NumberField source="phishing_fail_rate" label="Phishing Fail Rate" /> */}
        {/* <BooleanField source="active" label="Active" /> */}
        <FunctionField
          label="Active"
          textAlign="center"
          sortBy="active"
          sortByOrder="DESC"
          render={({ active, product_type, id }) =>
            product_type === 'Unlimited Cybersecurity Training' ? (
              <>
                {active ? (
                  <IconButton
                    aria-label="Deactivate Client"
                    onClick={e => {
                      e.stopPropagation();
                      actionClient('deactivate', id);
                    }}
                  >
                    <LazyIcon component="Enable" color="colorSystemSuccess" strokeColor="colorSystemSuccess" />
                  </IconButton>
                ) : (
                  <>
                    <IconButton
                      aria-label="Deactivate Client"
                      onClick={e => {
                        e.stopPropagation();
                        actionClient('activate', id);
                      }}
                    >
                      <LazyIcon component="Disable" color="colorSystemDanger" />
                    </IconButton>
                    <IconButton
                      aria-label="Deactivate Client"
                      onClick={e => {
                        e.stopPropagation();
                        setOpenDialog(true, id);
                      }}
                    >
                      <LazyIcon component="Delete" color="colorSystemDanger" />
                    </IconButton>
                  </>
                )}
              </>
            ) : (
              <>
                {active ? (
                  <LazyIcon component="Enable" color="colorSystemSuccess" strokeColor="colorSystemSuccess" />
                ) : (
                  <LazyIcon component="Disable" color="colorSystemDanger" />
                )}
              </>
            )
          }
        />
        <FunctionField
          label="New UI"
          textAlign="center"
          sortBy="new_ui"
          sortByOrder="DESC"
          render={({ new_ui }) =>
            new_ui ? (
              <LazyIcon
                component="Enable"
                color="colorSystemSuccess"
                strokeColor="colorSystemSuccess"
                ml={1.1}
                mr={1.1}
              />
            ) : (
              <LazyIcon component="Close" color="colorSystemDanger" ml={1.1} mr={1.1} />
            )
          }
        />
        <FunctionField
          label="Client Report"
          textAlign="center"
          sortBy="users"
          sortByOrder="DESC"
          render={({ id, users }) =>
            users > 0 &&
            (loading && id === idOnPrint ? (
              <CircularProgress size={18} thickness={2} />
            ) : (
              <Tooltip title="Download Client Report">
                <IconButton
                  aria-label="Download Client Report"
                  disabled={loading}
                  onClick={e => {
                    e.stopPropagation();
                    printClientReport(id);
                  }}
                >
                  <LazyIcon component="SpreadSheet" color="buttonPrimaryBackground" size={1.4} />
                </IconButton>
              </Tooltip>
            ))
          }
        />
      </List>
      <Dialog
        title="Delete"
        message="This client will be deleted and cannot be recovered. Are you sure about that?"
        buttonSubmit="Yes, I want to delete"
        open={openDialog || false}
        setOpen={setOpenDialog}
        maxWidth="xs"
        onSubmit={() => {
          actionDeleteClient();
        }}
      />
    </>
  );
};

export default ClientList;
