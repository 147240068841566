/* eslint-disable no-console */
// @flow
import { BSN_SET_USER } from 'conf';
import { getStorage, setStorage, clearStorage } from './storage';
import { setSession, setExpirationTime, checkSession } from './session';
import dataProvider from './dataProvider';
import store from './store';
import { isPublicUrl, setVariables } from './utils';

const setGlobalVars = user => {
  window.clientType = user.client_type;
  window.userFirstName = user.first_name;
  window.userLastName = user.last_name;
  window.userEmail = user.email;
  window.clientName = user.client_name;
  window.productName = user.product_name
  window.partnerName = user.partner_name;
  window.productName = user.product_name;
  window.userRole = user.user_role;
  window.lastLogin = user.last_login;
  window.productType = user.product_name;
};

let timer = 0;

const setData = ({ AuthenticationResult, user, colors, access, userpool_parameters }) => {
  const {
    id,
    partner_id: partnerId,
    client_id: clientId,
    client_name: clientName,
    first_name: firstName,
    last_name: lastName,
    group_id: groupId,
    email,
    product_name: productName,
    federated:federated
  } = user;
  const { IdToken, RefreshToken, AccessToken, ExpiresIn } = AuthenticationResult;
  const sessionObj: {
    userId: string,
    partnerId: string,
    clientId: string,
    clientName: string,
    userName: string,
    idToken: string,
    groupId: string,
    refreshToken?: string,
    email?: string,
    accessToken?: string,
    productName?: string, 
    federated? : boolean
  } = {
    userId: id,
    partnerId,
    clientId,
    clientName,
    groupId,
    userName: `${firstName} ${lastName}`,
    idToken: IdToken,
    email,
    productName,
    federated
  };
  if (RefreshToken) sessionObj.refreshToken = RefreshToken;
  if (AccessToken) sessionObj.accessToken = AccessToken;
  if (ExpiresIn) setExpirationTime(ExpiresIn);
  if (AccessToken !== localStorage.getItem('accessToken')) setStorage('tokenUpdated', 'true', true);
  sessionObj.userpoolClientID = userpool_parameters.client_id;
  sessionObj.userpoolUrl = userpool_parameters.url;
  setSession(sessionObj);
  setGlobalVars(user);
  localStorage.setItem('favorite', typeof user.favorite !== 'undefined' ? user.favorite : 'myDashboard');
  localStorage.setItem('name', `${user.first_name} ${user.last_name}`);
  localStorage.setItem('ess', user.ess);
  sessionStorage.setItem('currentTab', 'true');

  clearTimeout(timer);
  timer = setTimeout(() => {
    checkSession();
  }, (ExpiresIn - 60) * 1000);
 
  store('bsn').dispatch({
    type: BSN_SET_USER,
    payload: {
      profile: user,
      access,
      theme: colors
    }
  });

  setVariables(colors);
};

const authProvider = {
  login: (loginPayload: any) => {
    // store('bsn').dispatch({
    //   type: BSN_SET_USER,
    //   payload: {
    //     profile: null,
    //     access: null,
    //     theme: null,
    //     fofo: false
    //   }
    // });
    return dataProvider
      .post('user', 'login', null, loginPayload)
      .then(({ status, statusText, data }) => {
        if (status < 200 || status >= 300) throw new Error(statusText);
        return data;
      })
      .then(data => {
        setData(data);
      })
      .catch(error => {
        return Promise.reject({
          redirectTo:
            '#/login' +
            (new URLSearchParams(window.location.search).get('microtraining_id')
              ? '?microtraining_id=' + new URLSearchParams(window.location.search).get('microtraining_id')
              : ''),
          message: error.response.data.description
        });
      });
  },
  logout: () => {
    clearStorage();
    return Promise.resolve();
  },
  checkAuth: () => {
    if (isPublicUrl(window.location.hash.split('/')[1].split('?')[0])) 
    return Promise.resolve();
    const id_token = getStorage('idToken', true);
    const refresh_token = getStorage('refreshToken', true);
    const access_token = getStorage('accessToken', true);
    console.log(`%cCHECK AUTH`, 'font-size: medium; color: #808000');
    // eslint-disable-next-line prefer-promise-reject-errors
    if (window.location.hash === '#/login') return Promise.resolve();
    if (!refresh_token || !id_token) return Promise.reject({ redirectTo: '/login' });
    return dataProvider
      .post('user', 'authorize', null, { id_token, refresh_token, access_token })
      .then(({ status, statusText, data }) => {
        if (status < 200 || status >= 300) throw new Error(statusText);
        return data;
      })
      .then(data => {
        setData(data);
      })
      .catch(error => {
        const status = error?.response?.status;
        if (status === 500) {
          window.location.href = '/#/login';
          clearStorage();
        }
      });
  },
  checkError: ({ status }: { status: number }) => {
    if (status === 401 || status === 403) {
      clearStorage();
      return Promise.reject();
    }
    return Promise.resolve();
  },
  getPermissions: (params: any) => Promise.resolve(),
  userAuthorize: () => {
    const id_token = getStorage('idToken', true);
    const refresh_token = getStorage('refreshToken', true);
    const access_token = getStorage('accessToken', true);
    // eslint-disable-next-line prefer-promise-reject-errors
    return dataProvider
      .post('user', 'authorize', null, { id_token, refresh_token, access_token })
      .then(({ status, statusText, data }) => {
        if (status < 200 || status >= 300) throw new Error(statusText);
        return data;
      })
      .catch(error => {
        console.log(error);
        return Promise.reject();
      });
  }
};

export default authProvider;

