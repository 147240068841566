// @flow

import errorHandlerReducer from './errorHandlerReducer';
import bsnReducer from './bsnReducer';
import newsfeedReducer from './newsfeedReducer';

export default {
  errorHandler: errorHandlerReducer,
  bsn: bsnReducer,
  newsfeed: newsfeedReducer
};

export { default as errorHandlerReducer } from './errorHandlerReducer';
export { default as bsnReducer } from './bsnReducer';
