import React, { type ComponentType } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { Container } from 'components';
import womanImg from './woman.svg';

const ImgWrap: ComponentType<*> = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  ${props => props.theme.breakpoints.down("sm")} {
    justify-content: center;
    margin-bottom: 20px;
  }
`;

const Img: ComponentType<*> = styled.img`
  width: 100%;
  max-width: 530px;
  height: auto;
  margin: 0 auto;
  ${props => props.theme.breakpoints.down("sm")} {
    max-width: 400px;
    width: 80%;
  }
`;

const TextContent: ComponentType<*> = styled.div`
  max-width: 450px;
  ${props => props.theme.breakpoints.down("sm")} {
    margin: 0 auto 15px;
    text-align: center;
  }
`;

const Title: ComponentType<*> = styled.p`
  font-size: 156px;
  line-height: 1.1;
  font-weight: bold;
  margin: 100px 0 0;
  color: var(--colorBaseLight1);
  margin-bottom: 16px;
  ${props => props.theme.breakpoints.down("md")} {
    margin: 50px 0 0;
    font-size: 120px;
  }
  ${props => props.theme.breakpoints.down("sm")} {
    margin: 0 0 10px;
    font-size: 90px;
  }
`;

const SubTitle: ComponentType<*> = styled.p`
  font-size: 36px;
  font-weight: bold;
  margin: 0;
  color: var(--colorBaseLight1);
  ${props => props.theme.breakpoints.down("md")} {
    font-size: 28px;
  }
  ${props => props.theme.breakpoints.down("sm")} {
    font-size: 24px;
  }
`;

const Paragraph: ComponentType<*> = styled.p`
  font-size: 24px;
  font-weight: 600;
  margin: 10px 0;
  color: var(--colorBaseLight1);
  opacity: .5;
  ${props => props.theme.breakpoints.down("md")} {
    font-size: 20px;
  }
  ${props => props.theme.breakpoints.down("sm")} {
    font-size: 16px;
  }
`;

const LinkStyled: ComponentType<*> = styled(Link)`
  text-decoration: underline;
  color: var(--colorBox);
  font-size: 18px;
  ${props => props.theme.breakpoints.down("sm")} {
    padding: 5px;
    font-size: 16px;
  }
`;

function PageError({ title, subtitile, description, linkText, linkTo }) {
  return (
    <Box mt={{ xs: 0, md: 2 }}>
      <Container.Paper p={2} radius={1}>
        <Container.Grid container>
          <Container.Grid item md={6} xs={12}>
            <ImgWrap>
              <Img src={womanImg} alt="Woman" />
            </ImgWrap>
          </Container.Grid>
          <Container.Grid item md={6} xs={12} style={{margin: 'auto'}}>
            <TextContent>
              <Title>{title}</Title>
              <SubTitle>{subtitile}</SubTitle>
              <Paragraph>{description}</Paragraph>
              <LinkStyled to={linkTo}>{linkText}</LinkStyled>
            </TextContent>
          </Container.Grid>
        </Container.Grid>
      </Container.Paper>
    </Box>
  )
}

export default PageError;
