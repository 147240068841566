import styled from "styled-components";
import { Link } from "react-router-dom"

export const PageUnavailableContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 16px;
  background-color: white;
  height: 100vh;
  box-sizing: border-box;

  @media(max-height: 576px) {
    justify-content: flex-start;
    height: 100%;
  }

  .page-unavailable {
    width: 268px;
    height: 268px;
  }

  h3 {
    font-size: 24px;
    margin: 40px 0 14px;
    color: var(--colorBaseDark3);
    text-align: center;
  }

  p {
    font-size: 16px;
    color: #3F414A;
    text-align: center;
    margin: 0 0 25px;
  }
`

export const AvailableLinksItem = styled(Link)`
  color: var(--colorSystemInfo);
  margin: 15px 0;
  font-weight: 500;
  font-size: 14px;
`

export const LogoutButton = styled(Link)`
  && {
    color: var(--colorBaseLight3);
    background: var(--colorBaseBase);
    max-width: 243px;
    width: 100%;
    font-size: 16px;
    padding: 18px 0;
    text-transform: inherit;
    margin-top: 13px;
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
  }
`
