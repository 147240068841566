// @flow

import React, { memo } from 'react';
import styled from 'styled-components';
import { LoadingStyled } from '../../types';

const Loading = styled.div`
  width: 100%;
  position: relative;
  height: 215px;
  display: table-row;

  > h2 {
    padding: 80px 20px 20px;
    display: table-cell;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    text-align: center;
  }

  > div {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
`;

type TableListNoResultstypes = {
  hasRecords: boolean,
  fetched: boolean
};

const TableListNoResults = ({ hasRecords, fetched }: TableListNoResultstypes) => {
  return hasRecords ? <Loading>{fetched ? <h2>No results.</h2> : <LoadingStyled />}</Loading> : null;
};

export default memo<TableListNoResultstypes>(TableListNoResults);
