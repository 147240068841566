// @flow
import React, { type Element } from 'react';
import { TrainingProvider } from './training/TrainingContext';
import Training from './training/Training';

const PageTrainings = (): Element<*> => (
  <TrainingProvider>
    <Training />
  </TrainingProvider>
);

export default PageTrainings;
