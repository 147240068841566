// @flow
import React, { type Element, type ComponentType, useState } from 'react';
import Parser from 'html-react-parser';
import styled from 'styled-components';
import { IconButton, Popover } from '@material-ui/core';
import Typography from '../types/Typography';
import { LazyIcon } from '../icons';
import InfoIcon from '../icons/Info';
import { ContainerHeaderBox } from './ComponentTypes';
import { useDashboard } from './DashboardContext';
import { useBreakpoint }  from 'hooks';

type DashboardBoxTitleTypes = {
  label: string,
  description?: string | null,
  fontSize?: number,
  icon?: boolean | null,
  iconSize?: number | null,
  iconDescription?: string | null,
  pt?: number | null,
  pl?: number | null,
  pr?: number | null,
  justify?: string | null,
  mbbox?: boolean | null,
  fullWidth?: boolean | null,
  labelColor?: string | null,
  hideTitle?: boolean | null
};

const PopoverContent: ComponentType<*> = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ fullWidth }) => (fullWidth ? 'initial' : 'calc(var(--spacing) * 50)')};
  padding: calc(var(--spacing) * 2.5);
  white-space: pre-wrap;

  ${props => props.theme.breakpoints.down("sm")} {
    box-sizing: border-box;
    width: 100%;
    max-width: ${({ fullWidth }) => (fullWidth ? 'initial' : 'calc(var(--spacing) * 50)')};
  }
`;

const PopoverContentTitle: ComponentType<*> = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: calc(var(--spacing) * 0.5);
`;

const ContainerIcon: ComponentType<*> = styled.div`
  margin-left: calc(var(--spacing) * 5);
`;

const DashboardBoxTitle = ({
  label,
  description,
  fontSize = 13,
  icon = false,
  iconSize = 0,
  iconDescription,
  pt = 0,
  pl = 0,
  pr = 0,
  justify,
  mbbox,
  fullWidth,
  labelColor,
  hideTitle,
}: DashboardBoxTitleTypes): Element<*> => {
  const { dispatch } = useDashboard();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover-dashboard' : undefined;

  const mobileView = useBreakpoint("sm")

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
    dispatch.setBox({
      open: true,
      box: label
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
    dispatch.setBox({
      open: false,
      box: ''
    });
  };

  return (
    <div onClick={e => e.stopPropagation()}>
      <ContainerHeaderBox mbbox={mbbox} pt={pt} pl={pl} pr={pr} justify={justify}>
        {!hideTitle && (
          <Typography.h3 fontSize={fontSize} color={labelColor}>
            {label}
          </Typography.h3>
        )}
        {icon && (
          <ContainerIcon>
            {iconDescription && iconDescription}
            <IconButton aria-describedby={id} onClick={handleClick}>
              <InfoIcon size={iconSize} />
            </IconButton>
          </ContainerIcon>
        )}
      </ContainerHeaderBox>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        PaperProps={{
          style: {
            maxWidth: mobileView ? 'calc(100% - 32px)' : 'calc(100% - (var(--layoutSidebarWidth) + var(--spacing) * 4.5))'
          }
        }}
      >
        <PopoverContent fullWidth={fullWidth} >
          <PopoverContentTitle>
            <Typography.h6 fontSize={14} mt={0.1}>
              {label}
            </Typography.h6>
            <IconButton onClick={handleClose}>
              <LazyIcon component="Close" color="colorDefault" />
            </IconButton>
          </PopoverContentTitle>
          <Typography.p mt={0.1} mb={0.1} style={{webkitTextSizeAdjust: "100%"}}>
            {Parser(`${description}`)}
          </Typography.p>
        </PopoverContent>
      </Popover>
    </div>
  );
};

DashboardBoxTitle.defaultProps = {
  icon: false,
  iconSize: 0,
  fontSize: 13,
  description: null,
  iconDescription: null,
  pt: 0,
  pl: 0,
  pr: 0,
  justify: 'space-between',
  mbbox: false,
  fullWidth: false,
  labelColor: 'var(--colorDefault)',
  hideTitle: false
};

export default DashboardBoxTitle;
