import apiClient from '../apiClient/apiClient';
import { getStorage } from '../storage';



const env = process.env.REACT_APP_BUILD_ENV;
const base = process.env.REACT_APP_BASE_URL;
const apiGWID = process.env.REACT_APP_BASE_URL_4;
const baseURL = `https://${apiGWID}.${base}/${env}/`;

const cognito = {};

cognito.checkFederatedLogin = (userEmail) => {
    const requestOptions = {
        url:`${baseURL}identity_provider`,
        params: {user_email:userEmail}
    }
    return apiClient.get(requestOptions);
}


cognito.resetUserPassword = (password,token) => {
    const requestOptions = {
        url:`${baseURL}user/reset_password`,
        data: {password,token}
    }
    return apiClient.post(requestOptions);
}

cognito.sendForgotPasswordEmail =(email) => {
    const requestOptions = {
        url:`${baseURL}user/forgot_password`,
        data: {email}
    }
    return apiClient.post(requestOptions);

}

cognito.registerUser =(newUserPayload) => {
    const requestOptions = {
        url:`${baseURL}user/register`,
        data: newUserPayload
    }
    return apiClient.post(requestOptions);

}

cognito.logout =() => {
    const access_token = getStorage('accessToken', true);
    const requestOptions = {
        url:`${baseURL}user/logout`,
        data: {access_token}
    }
    return apiClient.post(requestOptions);

}

cognito.authorize = () => {
    const id_token = getStorage('idToken', true);
    const refresh_token = getStorage('refreshToken', true);
    const access_token = getStorage('accessToken', true);
    const requestOptions = {
        url:`${baseURL}user/authorize`,
        data: { id_token, refresh_token, access_token }
    }
    return apiClient.post(requestOptions);
}



export default cognito;
