import GA4React from 'ga-4-react';
import { createBrowserHistory } from 'history';

const trackingUtils = {};

trackingUtils.initialize = () => {
  const trackingId = process.env.REACT_APP_TRACK_ID;
  const ga4react = new GA4React(trackingId);
  ga4react.initialize().then(
    ga4 => {
      const history = createBrowserHistory();
      history.listen(location => {
        ga4.pageview(location.pathname);
        ga4.gtag('event', 'pageview', location.pathname);
      });
    },
    err => {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  );
};

export default trackingUtils;
