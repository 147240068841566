/* eslint-disable no-underscore-dangle */
// @flow

import { isObjectEmpty, getIdByResource, hasKey } from './utils';
import { getStorage } from './storage';
import dataApiUrl from './dataApiUrl';

type getParamsTypes = {
  pagination?: {
    page: number,
    perPage: number
  },
  sort?: {
    field: string,
    order: string
  },
  filter?: Object,
  _sort?: string,
  _start?: number,
  _end?: number,
  _order?: string,
  _filter?: string
};

type dataOptionTypes = {
  app: string,
  tab: string | false,
  item?: string | false,
  method: string,
  params?: Object,
  useDefaultParams?: boolean
};

const exeptions = {
  clients: []
};

function dataOption({ app, tab, item: itemProps, method, params = {} }: dataOptionTypes): Object {
  const { apiUrl } = dataApiUrl(app, tab);
  const item = hasKey(exeptions, app) && exeptions[app].includes(tab) ? getIdByResource(app, tab) : itemProps;
  const url = item ? `${apiUrl}/${item}` : apiUrl;
  if (!url) return {};
  const newParams = getParams(params);

  return {
    url,
    method,
    headers: {
      Authorization: getStorage('idToken', true),
      'Content-Type': 'application/json'
    },
    params: newParams
  };
}

export function getParams({ pagination, sort, filter, ...params }: getParamsTypes) {
  const p = params;
  if (pagination) {
    p._start = pagination.page === 0 ? pagination.page * pagination.perPage : pagination.page * pagination.perPage + 1;
    p._end =
      pagination.page === 0
        ? (pagination.page + 1) * pagination.perPage
        : (pagination.page + 1) * pagination.perPage + 1;
  }
  if (sort) {
    p._sort = params?._sort || sort.field;
    p._order = sort?.order.toUpperCase() || 'ASC';
  }
  if (filter && !isObjectEmpty(filter)) {
    p._filter = JSON.stringify(filter)
      .replace(/[{}]/g, '')
      .replace(/[""]/g, '');
  }
  return p;
}

export default dataOption;
