// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const UserOutline = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 20.1 21.014" role="img" aria-hidden="false">
    <path fill="none" d="M0 0h20.1v21.014H0z" />
    <path
      d="M43.943,35.227a8.478,8.478,0,0,0,1.545-4.788,5.939,5.939,0,1,0-11.877,0,8.476,8.476,0,0,0,1.545,4.788c-3.315,1.047-5.657,3.155-5.657,5.262v3.2a1.829,1.829,0,0,0,1.827,1.827H47.773A1.829,1.829,0,0,0,49.6,43.686v-3.2C49.6,38.382,47.258,36.274,43.943,35.227Zm-4.393-8.9a4.116,4.116,0,0,1,4.111,4.111c0,2.818-2,5.482-4.111,5.482s-4.111-2.664-4.111-5.482A4.116,4.116,0,0,1,39.55,26.327Zm8.223,17.359H31.327v-3.2c0-1.238,2.139-3.021,5.267-3.762a4.788,4.788,0,0,0,5.912,0c3.128.741,5.267,2.524,5.267,3.762Z"
      transform="translate(-29.5 -24.5)"
      fill="#535353"
    />
  </SvgIconStyled>
);

export default UserOutline;
