import {
  SET_FEEDS,
  CLEAR_FEEDS,
  ADD_FEED,
  UPDATE_FEED_ID,
  DELETE_FEED,
  SET_COMMENTS,
  CLEAR_COMMENTS,
  ADD_COMMENT,
  UPDATE_COMMENT_ID,
  DELETE_COMMENT,
  SET_NOTIFICATIONS,
  MARK_OPENED,
  ADD_NOTIFICATION,
  UPDATE_NOTIFICATIONS,
  NEWSFEED_LICENSE,
  NEWSFEED_LICENSE_AGREED
} from 'conf/constants';

const initialState = {
  feeds: null,
  comments: {},
  notifications: [],
  reloadNotifications: false,
  license: null
};

const newsfeedReducer = (state = initialState, action) => {
  switch (action.type) {
    //general

    case NEWSFEED_LICENSE: {
      return {
        ...state,
        license: action.payload
      };
    }

    case NEWSFEED_LICENSE_AGREED: {
      return {
        ...state,
        license: { ...state.license, newsfeed_license_agreed: 1 }
      };
    }

    //feeds

    case SET_FEEDS:
      return {
        ...state,
        feeds: action.payload
      };

    case CLEAR_FEEDS:
      return {
        ...state,
        feeds: null
      };

    case ADD_FEED: {
      const newFeed = action.payload;
      let newFeeds = [...state.feeds];

      if (newFeeds) newFeeds.unshift(newFeed);
      else newFeeds = [newFeed];

      return {
        ...state,
        feeds: newFeeds
      };
    }

    case UPDATE_FEED_ID: {
      const { oldId, newId } = action;
      const newFeeds = [...state.feeds];

      const feed = newFeeds.find(obj => obj.id === oldId);
      if (feed) {
        feed.id = newId;
        delete feed.disableActions;
      }

      return {
        ...state,
        feeds: newFeeds
      };
    }

    case DELETE_FEED: {
      const feedId = action.payload;
      let newFeeds = [...state.feeds];
      newFeeds = newFeeds.filter(obj => obj.id !== feedId);

      return {
        ...state,
        feeds: newFeeds
      };
    }

    //comments

    case SET_COMMENTS: {
      const { postId, postComments } = action;
      const newComments = { ...state.comments };

      if (postComments.length) newComments[postId] = postComments;
      else delete newComments[postId];

      if (newComments[postId]) {
        let ids = [];
        newComments[postId] = newComments[postId].filter(item => {
          if (!ids.includes(item.id)) {
            ids.push(item.id);
            return item;
          }
        });
      }

      return {
        ...state,
        comments: newComments
      };
    }

    case CLEAR_COMMENTS: {
      const postId = action.payload;
      let newComments = { ...state.comments };

      if (postId) delete newComments[postId];
      else newComments = {};

      return {
        ...state,
        comments: newComments
      };
    }

    case ADD_COMMENT: {
      const { postId, newComment } = action;
      const newComments = { ...state.comments };

      if (newComments[postId]) newComments[postId].unshift(newComment);
      else newComments[postId] = [newComment];

      return {
        ...state,
        comments: newComments
      };
    }

    case UPDATE_COMMENT_ID: {
      const { postId, oldId, newId } = action;
      const newComments = { ...state.comments };

      const comment = newComments[postId].find(obj => obj.id === oldId);
      comment.id = newId;
      delete comment.disableActions;

      return {
        ...state,
        comments: newComments
      };
    }

    case DELETE_COMMENT: {
      const { postId, commentId } = action;
      let newComments = { ...state.comments };
      newComments[postId] = newComments[postId].filter(obj => obj.id !== commentId);

      return {
        ...state,
        comments: newComments
      };
    }

    //notifications

    case SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload
      };

    case MARK_OPENED: {
      const { notificationId } = action;
      let newNotifications = [ ...state.notifications ];
      if (newNotifications.length) {
        let idx = newNotifications.findIndex( s => s.notification_id == +notificationId );
        newNotifications[idx].opened = 1;
      }

      return {
        ...state,
        notifications: newNotifications
      };
    }

    case ADD_NOTIFICATION: {
      const notifications = state.notifications;
      return {
        ...state,
        notifications: notifications ? [action.payload, ...notifications] : notifications
      };
    }

    case UPDATE_NOTIFICATIONS: {
      return {
        ...state,
        reloadNotifications: action.payload
      };
    }
  
    default:
      return state;
  }
};

export default newsfeedReducer;
