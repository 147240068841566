// @flow
import React, { type Element, useState } from 'react';
import { Container, TextField, DragZone } from 'components';
import { strClean } from 'helpers';

type PagePolicyInfoModalTypes = {
  record: Object,
  id: string,
  dispatch: {
    fileChanged: Function
  }
};

const PagePolicyInfoModal = ({ record, id, dispatch }: PagePolicyInfoModalTypes): Element<*> => {
  const [data, setData] = useState(record);

  const onChange = ({ target}) => {
    setData({ ...data, [target.name]: target.value });

    if (target.required) {
      if (!target.value.trim()) target.setCustomValidity('Please fill out this field.');
      else target.setCustomValidity('');
    }
  };

  const onFileChange = file => {
    setData({ ...data, file });
    dispatch.fileChanged(file);
  };

  return (
    <>
      <Container.Grid mb={2}>
        <TextField
          fullWidth
          source={data.name}
          value={strClean(data.name)}
          id="name"
          label="Name"
          name="name"
          onChange={onChange}
          ml={3}
          inputProps={{ maxLength: 200 }}
          required
        />
        <TextField
          fullWidth
          source={data.description}
          value={strClean(data.description)}
          id="description"
          label="Description of Policy"
          name="description"
          multiline
          rows={3}
          onChange={onChange}
          ml={3}
          required
        />
        <TextField
          fullWidth
          source={data.details}
          value={strClean(data.details)}
          id="details"
          label="Details of Policy"
          name="details"
          multiline
          rows={8}
          onChange={onChange}
          ml={3}
          required
        />
      </Container.Grid>
      <DragZone
        title="Upload a file"
        fileType=".doc, .docx, .pdf, .txt, .dotx, .csv, .xlsx, .xls"
        size={12}
        record={data}
        dispatch={onFileChange}
        type="file"
        disabledClick
      />
    </>
  );
};

export default PagePolicyInfoModal;
