// @flow
import colorScheme from './theme/colorScheme';
import type { myDashboardTypes, myCompanyTypes, partnerProfileType, clientsTypes, tablesType } from './definitions';
import tables from './state/tables';

export type systemType = {
  systemHasData: boolean,
  initPosition: number,
  item: string | null,
  hasSidebar: boolean,
  isApp: boolean,
  location: string,
  locationPrevious: string | null,
  hasError: boolean,
  errorMessage: string,
  errorInfo: string,
  tabPrevious: string | null,
  tabCurrent: string | null,
  hasDarkMode: boolean
};

export type tabType = {
  active: boolean,
  component: string,
  isList?: boolean,
  label: string,
  onChangeUpdate: boolean,
  noQuery?: boolean
};

export type tabsType = {
  myDashboard: {
    dashboard: tabType,
    securityTraining: tabType,
    darkWeb: tabType,
    microTrainings: tabType,
    newsletters: tabType,
    securityPolicies: tabType
  },
  myCompany: {
    dashboard: tabType,
    complianceDashboard: tabType,
    training: tabType,
    policies: tabType,
    documents: tabType,
    sra: tabType,
    darkWebMonitoring: tabType,
    track: tabType,
    employees: tabType,
    directorySync: tabType
  },
  clients: Object
};

export type initialStateType = {
  myDashboard: myDashboardTypes,
  myCompany: myCompanyTypes,
  clients: clientsTypes,
  partnerProfile: partnerProfileType,
  system: systemType,
  tabs: tabsType,
  tables: tablesType
};

const colors = JSON.parse(localStorage.getItem('colorScheme') || JSON.stringify(colorScheme.winter));
const infoIconDescriptionDWM = `<b>Accounts:</b> Displays the number of accounts available in the credentials dump on a particular data breach.
<b>Score: </b> The “Score” is a numerical index from 1 to 100 that denotes how confident our research staff is that a particular data leak comes from credible sources.
<b>100-80: </b> Eighty or above. A source is presented with this score only when our security research staff feels the leaked data is valid, comes from a credible data source and is thought to be overall legitimate. To earn a score of 80 or above a data leak must be confirmed by attributed press reports, or through a trusted 3rd party, and the quality of the leaked data must be high. Data should contain a wealth of sensitive data besides just account credentials.
<b>80-60: </b> Eighty to sixty. This score usually indicates that a source is possibly legitimate but overall validity of the dumped data is hard to verify. However, we still believe that sources in this range still pose a significant threat. What we look for in this score range is quality data and the possibility of the said breach being valid.
<b>60-40: </b> Sixty to forty. Sources in this bracket are usually somewhat legitimate data leaks but have signs of being duplicates of slightly older leaks. Hackers or hacker groups will often duplicate leaks from other actors and publish them as their own. These leaks are not necessarily fake per se, but they do contain entire (or partial) copies of older leaks. The leaks in this category also may not have breach target associated with them.
<b>40-20: </b> Forty to twenty. Sources with this score range usually do either do not have valid account credentials or are missing something to make them as sensitive (dangerous) as other data leaks with higher scores. We often see dumps of account credentials with just email addresses and plaintext passwords with no contextual data. Those types of leaks would also fall into this category.
<b>20-0: </b> Twenty to zero. Sources in this range are the least dangerous leaks we came across. These data leaks are usually dumps of non-sensitive data and don't appear to be truly harmful to the target from which the data originated. Most likely these leaks do not contain any passwords or any form of credentials.
`;
const leaderboard = {
  type: 'leaderboard',
  sort: { field: 'rank', order: 'ASC' },

  cells: [
    { id: 'rank', label: 'Rank', align: 'left' },
    { id: 'username', label: 'Name', align: 'left' },
    { id: 'ess', label: 'Score', align: 'left' }
  ]
};

const ranking = {
  type: 'ranking',
  sort: { field: 'rank', order: 'ASC' },

  cells: [
    { id: 'rank', label: 'Rank', align: 'left' },
    { id: 'username', label: 'Name', align: 'left' },
    { id: 'ess', label: 'Score', align: 'left' }
  ]
};

export const initialState: initialStateType = {
  myDashboard: {
    dashboard: null,
    leaderboard: null,
    leaderboardOverall: null,
    securityTraining: null,
    microTrainings: null,
    microTrainingsQuiz: null,
    microTrainingsStats: null,
    darkWeb: null,
    darkWebList: null,
    newsletters: null,
    securityPolicies: null,
    securityPolicy: null,
    otherPolicies: null,
    otherPolicy: null
  },
  myCompany: {
    dashboard: null,
    complianceDashboard: null,
    individualEssRanking: null,
    training: null,
    trainingFilter: null,
    microTrainings: null,
    microTrainingUsers: null,
    policies: null,
    otherPolicies: null,
    documents: null,
    documentsInfo: null,
    documentsServiceProvider: null,
    documentsTemplates: null,
    otherDocuments: null,
    pushNotification: null,
    sra: { id: 1 }, // TODO: Change to null later
    darkWebMonitoring: null,
    darkWebMonitoringBreaches: null,
    track: null,
    employees: null,
    directorySync: null,
    workPlan: null,
    workPlanFinding: null,
    workPlanFindingInfo: null,
    sraDocuments: null,
    sraDocumentsInfo: null,
    cyberInsurance: null
  },
  clients: {
    list: null,
    dashboard: null,
    complianceDashboard: null,
    access: null,
    clientUpdatedAccess: null,
    directorySync: null,
    information: null,
    notification: null,
    products: null,
    user: null,
    users: null,
    usersList: null,
    addUser: null,
    tags: null,
    darkWebMonitoring: null,
    trainingReports: null,
    trainingAnnual: null,
    trainingTrainings: null,
    trainingTrainingsUsers: null,
    trainingUsers: null,
    trainingUsersTrainings: null,
    phishingReports: null,
    phishingReportsCampaign: null,
    phishingReportsRecipients: null,
    policies: null,
    policyInfo: null,
    otherPolicies: null,
    otherPolicyInfo: null,
    documents: null,
    documentsInfo: null,
    documentsServiceProvider: null,
    documentsTemplates: null,
    disasterRecovery: null,
    otherDocuments: null,
    track: null,
    trackSecurityIncidents: null,
    trackSecurityIncidentsInfo: null,
    trackServerRoomAccess: null,
    trackServerRoomAccessInfo: null,
    phishingCampaignScenariosList: null,
    phishingCampaignRecipientsList: null,
    autophish: null,
    autoPhishCampaigns: null,
    scenarioPreview: '',
    workPlan: null,
    workPlanFinding: null,
    workPlanFindingInfo: null,
    sraDocuments: null,
    sraDocumentsInfo: null
  },
  newsfeed: {
    dashboard: null
  },
  phishing: {
    list: null,
    overview: null,
    campaign: null,
    recipients: null
  },
  partnerProfile: {
    customProfile: null,
    colorScheme: colors,
    colorSchemeCustom: colors.name === 'custom' ? colors : colorScheme.custom,
    information: null,
    onboarding: null,
    pushNotification: null,
    phishing: null,
    darkWebMonitoring: null,
    billing: null,
    psaSystem: null,
    whiteLabel: null,
    payment: null,
    taxExempt: null,
    integrations: null
  },
  user: {
    profile: null,
    theme: null,
    access: null,
    contactUs: null
  },
  system: {
    systemHasData: false,
    initPosition: 0,
    item: null,
    hasSidebar: false,
    isApp: false,
    loading: false,
    loadingTab: false,
    loadingItem: false,
    location: '',
    locationPrevious: null,
    hasError: false,
    errorMessage: '',
    errorInfo: '',
    tabPrevious: null,
    tabCurrent: null,
    hasDarkMode: false,
    tableRowSelected: [],
    tableRowSelectedList: [],
    tableRowUnselected: [],
    tableRowSelectedCount: 0,
    tableRowModal: false,
    tableFilter: {},
    disabledNext: true,
    activeTabType: ''
  },
  tabs: {
    myDashboard: {
      dashboard: {
        active: false,
        label: 'Dashboard',
        onChangeUpdate: false,
        isList: false,
        component: 'dashboard/DashboardDashboard'
      },
      securityTraining: {
        active: false,
        noQuery: true,
        label: 'Training',
        onChangeUpdate: false,
        isList: true,
        component: 'dashboard/DashboardSecurityTraining'
      },
      microTrainings: {
        active: false,
        label: 'Micro Training',
        onChangeUpdate: false,
        isList: true,
        component: 'dashboard/DashboardMicroTrainings'
      },
      darkWeb: {
        active: false,
        label: 'Dark Web',
        onChangeUpdate: false,
        isList: false,
        component: 'dashboard/DashboardDarkWeb'
      },
      newsletters: {
        soon: false,
        active: false,
        label: 'Newsletters',
        noQuery: true,
        onChangeUpdate: false,
        isList: true,
        component: 'dashboard/DashboardNewsletter'
      },
      securityPolicies: {
        soon: false,
        active: false,
        noQuery: true,
        label: 'Policies',
        onChangeUpdate: false,
        isList: true,
        component: 'dashboard/DashboardSecurityPolicies'
      }
    },
    myCompany: {
      dashboard: {
        active: false,
        label: 'Dashboard',
        onChangeUpdate: false,
        isList: false,
        component: 'company/CompanyDashboard'
      },
      complianceDashboard: {
        active: false,
        label: 'Dashboard',
        onChangeUpdate: false,
        isList: false,
        component: 'shared/complianceDashboard/ComplianceDashboard'
      },
      pushNotifications: {
        noQuery: true,
        active: false,
        label: 'Push Notifications',
        onChangeUpdate: false,
        isList: false,
        idRequired: false,
        component: 'myCompany/CompanyPushNotifications'
      },
      employees: {
        active: false,
        label: 'Employees',
        onChangeUpdate: true,
        isList: false,
        idRequired: true,
        component: 'shared/users/ClientsUsers'
      },
      training: {
        active: false,
        label: 'Training Reports',
        onChangeUpdate: false,
        noQuery: true,
        component: 'company/CompanyTraining'
      },
      directorySync: {
        active: false,
        label: 'Directory Sync',
        onChangeUpdate: false,
        isList: false,
        noQuery: true,
        component: 'shared/directorySync/DirectorySync'
      },
      policies: {
        active: false,
        label: 'Policies',
        onChangeUpdate: false,
        isList: true,
        component: 'company/CompanyPolicies'
      },
      documents: {
        active: false,
        label: 'Documents',
        onChangeUpdate: false,
        isList: true,
        component: 'company/CompanyDocuments'
      },
      sra: {
        active: false,
        label: 'SRA',
        onChangeUpdate: false,
        noQuery: true,
        component: 'company/CompanySRA'
      },
      sraDocuments: {
        active: false,
        label: 'SRA Report',
        onChangeUpdate: false,
        showSaveButton: false,
        isList: false,
        idRequired: true,
        component: 'company/CompanyWorkPlan'
      },
      darkWebMonitoring: {
        active: false,
        label: 'Dark Web',
        isList: true,
        onChangeUpdate: false,
        component: 'shared/darkWebMonitoring/DarkWebMonitoring'
      },
      phishingReports: {
        active: false,
        label: 'Phishing',
        idRequired: true,
        noQuery: true,
        onChangeUpdate: false,
        component: 'shared/phishingReports/PhishingReports'
      },
      track: {
        active: false,
        label: 'Track',
        noQuery: true,
        idRequired: true,
        onChangeUpdate: false,
        component: 'shared/track/Track',
        tableSource: 'trackSecurityIncidents'
      },
      cyberInsurance: {
        active: false,
        label: 'Cyber Insurance',
        idRequired: true,
        onChangeUpdate: false,
        component: 'company/CompanyCyberInsurance'
      }
    },
    clients: {
      create: {},
      dashboard: {
        active: false,
        label: 'Dashboard',
        onChangeUpdate: false,
        parentIsList: true,
        isList: false,
        idRequired: true,
        component: 'clients/ClientsEditDashboard'
      },
      complianceDashboard: {
        active: false,
        label: 'Dashboard',
        onChangeUpdate: false,
        isList: false,
        component: 'shared/complianceDashboard/ComplianceDashboard'
      },
      information: {
        active: false,
        label: 'Information',
        onChangeUpdate: false,
        showSaveButton: false,
        isList: false,
        idRequired: true,
        component: 'clients/ClientsEditInformation'
      },
      notification: {
        active: false,
        label: 'Notification',
        isList: false,
        idRequired: true,
        noQuery: true,
        component: 'clients/ClientsEditNotification'
      },
      products: {
        active: false,
        label: 'Products',
        onChangeUpdate: false,
        isList: false,
        idRequired: true,
        component: 'clients/ClientsEditProducts'
      },
      access: {
        active: false,
        label: 'Access',
        isList: true,
        idRequired: true,
        component: 'clients/ClientsEditAccess'
      },
      directorySync: {
        active: false,
        label: 'Directory Sync',
        onChangeUpdate: false,
        isList: false,
        idRequired: true,
        component: 'shared/directorySync/DirectorySync'
      },
      users: {
        active: false,
        label: 'Users',
        onChangeUpdate: true,
        isList: false,
        idRequired: true,
        component: 'shared/users/ClientsUsers'
      },
      policies: {
        active: false,
        label: 'Policies',
        onChangeUpdate: false,
        isList: true,
        component: 'clients/ClientsPolicies'
      },
      documents: {
        active: false,
        label: 'Documents',
        onChangeUpdate: false,
        isList: true,
        component: 'clients/ClientsEditDocuments'
      },
      sra: {
        active: false,
        label: 'SRA',
        onChangeUpdate: false,
        noQuery: true,
        component: 'clients/ClientsEditSRA'
      },
      sraDocuments: {
        active: false,
        label: 'SRA Report',
        noQuery: true,
        onChangeUpdate: false,
        showSaveButton: false,
        isList: false,
        idRequired: true,
        component: 'company/CompanyWorkPlan'
      },
      darkWebMonitoring: {
        active: false,
        label: 'Dark Web',
        isList: true,
        onChangeUpdate: false,
        idRequired: true,
        component: 'shared/darkWebMonitoring/DarkWebMonitoring'
      },
      trainingReports: {
        active: false,
        label: 'Training Reports',
        idRequired: true,
        noQuery: true,
        onChangeUpdate: false,
        component: 'clients/ClientsEditTrainingReport'
      },
      phishingReports: {
        active: false,
        label: 'Phishing',
        idRequired: true,
        noQuery: true,
        onChangeUpdate: false,
        component: 'shared/phishingReports/PhishingReports'
      },
      track: {
        active: false,
        label: 'Track',
        noQuery: true,
        idRequired: true,
        onChangeUpdate: false,
        component: 'shared/track/Track',
        tableSource: 'trackSecurityIncidents'
      }
    },
    newsfeed: {
      dashboard: {
        active: false,
        label: 'Dashboard',
        noQuery: true,
        isList: false,
        component: 'newsfeed/NewsfeedDashboard',
        hideTabs: true
      }
    },
    phishing: {
      overview: {
        active: false,
        label: 'Overview',
        onChangeUpdate: false,
        isList: true,
        component: 'phishing/PhishingEditOverview'
      },
      campaign: {
        active: false,
        label: 'New Campaign',
        onChangeUpdate: false,
        isList: false,
        component: 'phishing/PhishingEditCampaign'
      },
      reports: {
        active: false,
        label: 'Reports',
        onChangeUpdate: false,
        isList: true,
        component: 'phishing/PhishingEditReports'
      }
    },
    partnerProfile: {
      information: {
        active: false,
        label: 'Information',
        onChangeUpdate: false,
        isList: false,
        component: 'partner/PartnerInformation'
      },
      customProfile: {
        active: false,
        label: 'Custom Profile',
        onChangeUpdate: false,
        isList: false,
        component: 'partner/PartnerCustomProfile'
      },
      psaConfiguration: {
        noQuery: true,
        active: false,
        label: 'TMS Configuration',
        onChangeUpdate: false,
        isList: false,
        idRequired: false,
        component: 'partner/PartnerPsaConfiguration'
      },
      pushNotifications: {
        noQuery: true,
        active: false,
        label: 'Push Notifications',
        onChangeUpdate: false,
        isList: false,
        idRequired: false,
        component: 'partner/PartnerPushNotifications'
      },
      billing: {
        active: false,
        label: 'Billing Report',
        onChangeUpdate: false,
        isList: false,
        idRequired: true,
        component: 'partner/PartnerBillingReport'
      },
      payment: {
        // noQuery: true,
        active: false,
        label: 'Payment Information',
        onChangeUpdate: false,
        isList: false,
        idRequired: false,
        component: 'partner/PartnerPayment'
      },
      taxExempt: {
        active: false,
        label: 'Tax Exempt',
        onChangeUpdate: false,
        isList: false,
        component: 'partner/PartnerTaxExempt'
      },
      integrations: {
        active: false,
        noQuery: true,
        label: 'Integrations',
        onChangeUpdate: false,
        isList: false,
        component: 'partner/PartnerIntegrations'
      }
      // notifications: {
      //   active: false,
      //   label: 'Notifications',
      //   onChangeUpdate: false,
      //   isList: false,
      //   noQuery: true,
      //   component: 'partner/PartnerNotifications'
      // },
      // whiteLabel: {
      //   active: false,
      //   label: 'White Label',
      //   onChangeUpdate: true,
      //   isList: false,
      //   component: 'partner/PartnerWhiteLabel'
      // },
    },
    user: {
      profile: {
        active: false,
        label: 'My Profile',
        onChangeUpdate: true,
        noQuery: true,
        isList: false,
        component: 'user/UserProfile'
      },
      contactUs: {
        active: false,
        label: 'Contact Us',
        onChangeUpdate: true,
        noQuery: true,
        isList: false,
        component: 'user/UserContactUs'
      }
    }
  },
  tables: {
    myDashboard: {
      dashboard: leaderboard,
      leaderboard: ranking,
      leaderboardOverall: ranking,
      securityTraining: {
        type: 'securityTraining',
        cells: [
          { id: 'code', label: 'Course Code', align: 'left' },
          { id: 'name', label: 'Course Name', align: 'left' },
          { id: 'score', label: 'Score', align: 'left' },
          { id: 'date_completed', label: 'Date Completed', align: 'left' },
          {
            id: 'certificate_link',
            label: 'Print Certificate',
            align: 'left',
            action: {
              icon: 'Print',
              label: '',
              tooltip: 'Print Training Certificate',
              modal: {
                app: 'myDashboard',
                tab: 'trainingCertificate'
              }
            }
          }
        ]
      },
      microTrainings: {
        type: 'microTrainings',
        orderBy: 'published_date',
        order: 'desc',
        cells: [
          {
            id: 'micro_training',
            label: 'Micro Training',
            align: 'left'
          },
          { id: 'date_taken', label: 'Date Taken', align: 'left' },
          { id: 'score', label: 'Score', align: 'left' },
          { id: 'watch', label: 'Watch', align: 'right', ghostId: 'score' }
        ]
      },
      darkWebList: {
        type: 'darkweblist',
        orderBy: 'service',
        order: 'asc',

        cells: [
          { id: 'service', label: 'Service', align: 'left' },
          { id: 'date', label: 'Date', align: 'left' },
          { id: 'records', label: 'Records', align: 'left' },
          {
            id: 'score',
            label: 'Score',
            align: 'left',
            infoIcon: true,
            infoIconDescription: infoIconDescriptionDWM
          },
          {
            id: 'compromised_data',
            label: 'Compromised Data',
            align: 'left',
            hideOrderBy: true
          }
        ]
      },
      newsletters: {
        type: 'newsletters',
        orderBy: 'published_date',
        order: 'desc',
        row: {
          click: true,
          action: 'newWindow',
          field: 'link'
        },

        cells: [
          { id: 'name', label: 'Name', align: 'left' },
          { id: 'published_date', label: 'Published', align: 'left' }
        ]
      },
      securityPolicies: {
        type: 'securityPolicies',
        orderBy: 'policy_id',
        order: 'asc',
        noQuery: true,
        row: {
          click: true,
          action: 'modal',
          modal: {
            app: 'myDashboard',
            tab: 'securityPolicy'
          }
        },

        cells: [
          { id: 'policy_id', label: 'Policy', align: 'left' },
          { id: 'name', label: 'Name', align: 'left' },
          {
            id: 'description',
            label: 'Description',
            align: 'left',
            removeHtml: true,
            hideOrderBy: true
          },
          {
            id: 'attachment_link',
            label: 'Download',
            labelAlign: 'center',
            align: 'center',
            button: true,
            val: 'attachment_link',
            renderDowloadIcon: true,
            hideOrderBy: true
          },
          {
            id: 'acknowledgement',
            label: 'Ackno',
            labelAlign: 'center',
            align: 'center',
            renderCheckIcon: true,
            hideOrderBy: true
          }
        ]
      },
      otherPolicies: {
        type: 'otherPolicies',
        noQuery: true,
        row: {
          click: true,
          action: 'modal',
          modal: {
            app: 'myDashboard',
            tab: 'otherPolicy'
          }
        },

        cells: [
          { id: 'name', label: 'Name', align: 'left' },
          {
            id: 'description',
            label: 'Description',
            align: 'left',
            removeHtml: true,
            hideOrderBy: true
          },
          {
            id: 'attachment_link',
            label: 'Download',
            labelAlign: 'center',
            align: 'center',
            button: true,
            val: 'attachment_link',
            renderDowloadIcon: true,
            hideOrderBy: true
          },
          {
            id: 'acknowledgement',
            label: 'Ackno',
            labelAlign: 'center',
            align: 'center',
            renderCheckIcon: true,
            hideOrderBy: true
          }
        ]
      }
    },
    myCompany: {
      ...tables.myCompany,
      dashboard: leaderboard,
      individualEssRanking: ranking,
      training: {
        type: 'training',

        cells: [
          { id: 'name', label: 'Name', align: 'left' },
          { id: 'score', label: 'Score', align: 'left' },
          { id: 'date_completed', label: 'Date Completed', align: 'left' },
          {
            id: 'certificate',
            label: 'Certificate',
            align: 'left',
            button: true,
            buttonLabel: 'View'
          }
        ]
      },
      microTrainings: {
        type: 'microTrainings',
        row: {
          click: true,
          action: 'modal',
          modal: {
            app: 'myCompany',
            tab: 'microTrainingUsers'
          }
        },

        cells: [
          { id: 'name', label: 'Name', align: 'left' },
          { id: 'published', label: 'Published', align: 'left' }
        ]
      },
      microTrainingUsers: {
        type: 'microTrainingUsers',
        selecting: true,
        actions: [
          {
            icon: 'Mail',
            title: 'Mail',
            method: '() => {}'
          },
          {
            icon: 'Lock',
            title: 'Lock',
            method: '() => {}'
          },
          {
            icon: 'Delete',
            title: 'Delete',
            method: '() => {}'
          }
        ],

        cells: [
          { id: 'name', label: 'Name', align: 'left' },
          { id: 'score', label: 'Score', align: 'left' },
          { id: 'date_completed', label: 'Date Completed', align: 'left' }
        ]
      },
      darkWebMonitoring: {
        type: 'darkwebmonitoring',
        search: true,
        searchFields: ['account', 'site_breached'],
        searchLabel: 'Search',
        actions: [
          // {
          //   icon: 'Export',
          //   title: 'Export CSV',
          //   function: e => e,
          //   activateOnSelect: false
          // }
        ],

        cells: [
          { id: 'account', label: 'Account', align: 'left' },
          { id: 'site_breached', label: 'Site Breached', align: 'left' },
          { id: 'import_date', label: 'Breach Date', align: 'left' },
          {
            id: 'confidence_score',
            label: 'Confidence Score',
            align: 'left',
            infoIcon: true,
            infoIconDescription: infoIconDescriptionDWM
          },
          {
            id: 'password',
            label: 'Password',
            align: 'left',
            showing: true,
            showingValue: ['redacted', 'partial', 'none'],
            defaultShowingValue: 'partial'
          }
        ]
      }
    },
    phishing: {
      overview: {
        type: 'overview',
        selecting: true,
        folding: 'PhishingOverviewFolding',
        foldingSource: 'campaign',
        actions: [
          {
            icon: 'Mail',
            title: 'Mail',
            method: '() => {}'
          },
          {
            icon: 'Lock',
            title: 'Lock',
            method: '() => {}'
          },
          {
            icon: 'Delete',
            title: 'Delete',
            method: '() => {}'
          }
        ],

        cells: [
          {
            id: 'name',
            label: 'Campaign Name',
            align: 'left',

            filter: {
              type: 'text',
              placeholder: 'e.g: 3,10-15,>10',
              help: 'Input Methods: Range (x-y), Grater then ....'
            }
          },
          { id: 'difficulty', label: 'Difficulty' },
          { id: 'last_run_date', label: 'Last Run Date' },
          { id: 'phishing_fail_rate', label: 'PFR%' },
          { id: 'status', label: 'Status' }
        ]
      },
      campaign: {
        type: 'campaign',
        selecting: true,
        actions: [
          {
            icon: 'Mail',
            title: 'Mail',
            method: '() => {}'
          },
          {
            icon: 'Lock',
            title: 'Lock',
            method: '() => {}'
          },
          {
            icon: 'Delete',
            title: 'Delete',
            method: '() => {}'
          }
        ],

        cells: [
          { id: 'name', label: 'Recipient Name' },
          { id: 'email', label: 'Email' },
          {
            id: 'position',
            label: 'Position',
            editing: [true, 'multiple'],
            editingValue: ['Employee', 'Manager', 'Partner Admin']
          },
          { id: 'tag', label: 'Tag', editing: [true, 'multiple'], editingValue: ['HR', 'Finance', 'Legal'] },
          { id: 'ess', label: 'ESS', editing: [true, 'multiple'], editingValue: ['Low', 'Good', 'Excellent'] }
        ]
      },
      recipients: {
        type: 'recipients',
        selecting: true,
        actions: [
          {
            icon: 'Mail',
            title: 'Mail',
            method: '() => {}'
          },
          {
            icon: 'Lock',
            title: 'Lock',
            method: '() => {}'
          },
          {
            icon: 'Delete',
            title: 'Delete',
            method: '() => {}'
          }
        ],
        cells: [
          { id: 'name', label: 'Recipient Name' },
          { id: 'email', label: 'Email' },
          { id: 'position', label: 'Position' },
          { id: 'ess', label: 'ESS' },
          { id: 'status', label: 'Status' }
        ]
      }
    },
    clients: {
      ...tables.clients,
      dashboard: leaderboard,
      darkWebMonitoring: {
        type: 'clientsDarkWebMonitoring',
        search: true,
        searchFields: ['account', 'site_breached'],
        searchLabel: 'Search',
        actions: [
          // {
          //   icon: 'Export',
          //   title: 'Export CSV',
          //   function: e => e,
          //   activateOnSelect: false
          // }
        ],

        cells: [
          { id: 'account', label: 'Account', align: 'left' },
          { id: 'site_breached', label: 'Site Breached', align: 'left' },
          { id: 'import_date', label: 'Breach Date', align: 'left' },
          {
            id: 'confidence_score',
            label: 'Confidence Score',
            align: 'left',
            infoIcon: true,
            infoIconDescription: infoIconDescriptionDWM
          },
          {
            id: 'password',
            label: 'Password',
            align: 'left',
            showing: true,
            showingValue: ['redacted', 'partial', 'none'],
            defaultShowingValue: 'partial'
          }
        ]
      },
      trainingAnnual: {
        type: 'clientsTrainingAnnual',
        search: true,
        searchFields: ['name'],
        searchLabel: 'Search',

        cells: [
          { id: 'name', label: 'Name', align: 'left' },
          { id: 'score', label: 'Score', align: 'left' },
          { id: 'date', label: 'Date Completed', align: 'left' },
          {
            id: 'view',
            label: 'View',
            align: 'left',
            action: {
              icon: 'Print',
              label: '',
              tooltip: 'Print Training Certificate',
              modal: {
                app: 'clients',
                tab: 'clientsTrainingCertificateViewModal'
              }
            }
          }
        ]
      },
      trainingTrainings: {
        type: 'trainingMicroTrainings',
        search: true,
        searchFields: ['name'],
        searchLabel: 'Search',
        orderBy: 'published',
        order: 'desc',

        cells: [
          { id: 'name', label: 'Name', align: 'left' },
          { id: 'published', label: 'Published', align: 'left' },
          {
            id: 'view',
            label: 'View',
            align: 'left',
            action: {
              icon: 'Eye',
              label: '',
              tooltip: 'View Micro Training Report',
              modal: {
                app: 'clients',
                tab: 'trainingTrainings'
              }
            }
          }
        ]
      },
      trainingTrainingsUsers: {
        type: 'trainingsTrainingsUsers',
        deepSource: true,
        search: true,
        searchFields: ['name'],
        searchLabel: 'Search',

        cells: [
          { id: 'name', label: 'Name', align: 'left' },
          { id: 'score', label: 'Score', align: 'left' },
          { id: 'date_completed', label: 'Completed', align: 'left' }
        ]
      },
      trainingUsers: {
        type: 'trainingMicroTrainingsUsers',
        search: true,
        searchFields: ['name'],
        searchLabel: 'Search',

        cells: [
          { id: 'name', label: 'Name', align: 'left' },
          {
            id: 'view',
            label: 'View',
            align: 'left',
            action: {
              icon: 'Eye',
              label: '',
              tooltip: 'View User Micro Training Report',
              modal: {
                app: 'clients',
                tab: 'trainingUsers'
              }
            }
          }
        ]
      },
      trainingUsersTrainings: {
        type: 'trainingsUsersTrainings',
        deepSource: true,

        cells: [
          { id: 'name', label: 'Name', align: 'left' },
          { id: 'score', label: 'Score', align: 'left' },
          { id: 'created', label: 'Completed', align: 'left' }
        ]
      },
      phishingReports: {
        type: 'phishingReports',
        selecting: true,
        search: true,
        searchFields: ['name'],
        searchLabel: 'Search',
        actions: [
          {
            icon: 'Disable',
            title: 'Stop Campaign',
            method: 'endCampaign'
          },
          {
            icon: 'Delete',
            title: 'Delete',
            method: 'delete'
          }
        ],
        row: {
          click: true,
          action: 'modal',
          modal: {
            app: 'clients',
            tab: 'phishingReportsCampaign'
          }
        },
        orderBy: 'last_run_date',
        order: 'desc',
        cells: [
          { id: 'name', label: 'Campaign Name', align: 'left' },
          { id: 'scenario_name', label: 'Scenario Name', align: 'left' },
          {
            id: 'autophish',
            label: 'Auto Phish',
            align: 'left',
            renderOnlyActiveIcon: true // If is autophish
          },
          {
            id: 'is_test_campaign',
            label: 'Test',
            align: 'left',
            renderOnlyActiveIcon: true // If is test
          },
          { id: 'last_run_date', label: 'Run Date', align: 'left' },
          { id: 'status', label: 'Status', align: 'left' },
          { id: 'phishing_fail_rate', label: 'PFR %', align: 'left', title: 'Phishing Fail Rate' },
          { id: 'filename', label: 'Report', align: 'left', printerCampaignReport: true }
        ]
      },
      phishingReportsUT: {
        type: 'phishingReports',
        selecting: true,
        search: true,
        searchFields: ['name'],
        searchLabel: 'Search',
        actions: [
          {
            icon: 'Disable',
            title: 'Stop Campaign',
            method: 'endCampaign'
          },
          {
            icon: 'Delete',
            title: 'Delete',
            method: 'delete'
          }
        ],
        row: {
          click: true,
          action: 'modal',
          modal: {
            app: 'clients',
            tab: 'phishingReportsCampaign'
          }
        },
        orderBy: 'last_run_date',
        order: 'desc',
        cells: [
          { id: 'name', label: 'Campaign Name', align: 'left' },
          { id: 'scenario_name', label: 'Scenario Name', align: 'left' },
          {
            id: 'is_test_campaign',
            label: 'Test',
            align: 'left',
            renderOnlyActiveIcon: true // If is test
          },
          { id: 'last_run_date', label: 'Run Date', align: 'left' },
          { id: 'status', label: 'Status', align: 'left' },
          { id: 'phishing_fail_rate', label: 'PFR %', align: 'left' },
          { id: 'filename', label: 'Report', align: 'left', printerCampaignReport: true }
        ]
      },
      phishingReportsRecipients: {
        type: 'phishingReportsRecipients',
        selecting: true,

        actions: [
          {
            icon: 'Replay',
            iconColor: 'error',
            title: 'Reset',
            method: 'resetPhishingItem'
          }
        ],

        cells: [
          { id: 'name', label: 'Name', align: 'left', slice: 15 },
          { id: 'email', label: 'Email', align: 'left' },
          { id: 'role', label: 'Role', align: 'left' },
          { id: 'status', label: 'Status', align: 'left' },
          { id: 'send_date', label: 'Email Sent', align: 'left', type: 'date' },
          { id: 'modified_date', label: 'Last Action', align: 'left', type: 'date' }
        ]
      },
      phishingCampaignScenariosList: {
        type: 'phishingCampaignScenariosList',
        selecting: true,
        selectingRadio: true,
        search: true,
        searchFields: ['name', 'description'],
        searchLabel: 'Search',
        row: {
          click: true,
          action: 'modal',
          modal: {
            app: 'clients',
            tab: 'scenarioPreview'
          }
        },

        cells: [
          { id: 'name', label: 'Name' },
          { id: 'difficulty', label: 'Difficulty' },
          { id: 'country', label: 'Country' },
          { id: 'description', label: 'Description', removeHtml: true }
        ]
      },
      phishingCampaignRecipientsList: {
        type: 'phishingCampaignRecipientsList',
        selecting: true,
        search: true,
        searchFields: ['name', 'email'],
        actions: [],

        cells: [
          { id: 'name', label: 'Recipient Name' },
          { id: 'email', label: 'Email' },
          { id: 'group_role', label: 'Role' },
          { id: 'tag', label: 'Tag' },
          { id: 'ess', label: 'Ess' }
        ]
      },
      autoPhish: {
        type: 'autoPhish',
        selecting: true,

        cells: [
          { id: 'recipientName', label: 'Recipient Name', align: 'left' },
          { id: 'email', label: 'Email', align: 'left' },
          { id: 'position', label: 'Position', align: 'left' },
          { id: 'tag', label: 'Tag', align: 'left' },
          { id: 'ess', label: 'ESS', align: 'left' }
        ]
      },
      autoPhishCampaigns: {
        type: 'autoPhish',
        selecting: true,
        actions: [
          {
            icon: 'Delete',
            title: 'Delete',
            method: 'delete'
          }
        ],
        row: {
          click: true,
          action: 'modal',
          modal: {
            app: 'clients',
            tab: 'autoPhishCampaign',
            fullScreen: true,
            external: true
          }
        },
        orderBy: 'date_start',
        order: 'desc',
        cells: [
          { id: 'name', label: 'Campaign Name', align: 'left' },
          { id: 'frequency', label: 'Frequency', align: 'left'  },
          { id: 'date_start', label: 'Start Date', align: 'left' },
          { id: 'date_end', label: 'End Date', align: 'left' },
          { 
            id: 'reuse_campaign', 
            label: 'Reuse Campaign', 
            labelAlign: 'center', 
            align: 'center', 
            renderOnlyActiveIcon: true
          }
        ]
      },
      tags: {
        type: 'tags',
        selecting: true,
        selectAll: true,
        actions: [
          {
            icon: 'Delete',
            title: 'Delete',
            method: 'delete'
          }
        ],
        cells: [
          {
            id: 'name',
            label: 'Name',
            align: 'left',
            updating: {
              app: 'clients',
              tab: 'tags',
              field: 'name',
              id: true
            }
          },
          { 
            id: 'date_created',
            label: 'Created',
            align: 'left'
          },
          { 
            id: 'date_modified',
            label: 'Modified',
            align: 'left'
          }
          // { id: 'id', label: 'Action', align: 'left', renderEditTag: true, hideOrderBy: true }
        ]
      }
    },
    partnerProfile: {
      ...tables.partnerProfile
    }
  }
};
