// @flow
import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import { Layout as AppLayout, Footer, LoadingStyled } from 'components';
import { Box } from '@material-ui/core';

import type { ComponentType } from 'react';
import cognito from '../../helpers/apis/cognito';

const Layout: ComponentType<*> = styled(AppLayout)`
  margin: 0 auto;
`;

const P: ComponentType<*> = styled.p`
  font-size: 18px;
  text-align: center;
`;

const VideoIframeContainer = styled.div`
  ${props => props.theme.breakpoints.down("sm")} {
    overflow: hidden;
    height: 0;
    padding-top: 56.25%;
    position: relative;
    margin: 0 16px;
    width: calc(100% - 32px);

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`

const MicroTrainingPublicView = ({ location }) => {
  const partnerLogosBaseURL = process.env.PARTNER_LOGOS_BASE_CF_URL;
  const logoPath = new URLSearchParams(location.search).get('logo_path');
  const [videoNumber, setVideoNumber] = useState(null);
  const loginRedirect = () => {
    window.location.href = `/#/login?microtraining_id=${new URLSearchParams(location.search).get('microtraining_id')}`;
  };
  useEffect(() => {
    sessionStorage.setItem('microtraining_id', new URLSearchParams(location.search).get('microtraining_id'));
    cognito
      .authorize()
      .then(response => {
        console.log('response=', response);
        window.location.href = '';
      })
      .catch(err => {
        console.log('error=', err);
        setVideoNumber(new URLSearchParams(location.search).get('video_number'));
      });
  }, []);

  if (!videoNumber) return <LoadingStyled />;
  return (
    <Layout sidebar={null} background="paper" centered footer={<Footer />}>
      {logoPath && logoPath !== '' && (
        <div style={{ marginBottom: '20px'}}>
          {' '}
          <img
            style={{ maxWidth: '200px', maxHeight: '200px' }}
            alt=""
            src={`${partnerLogosBaseURL}${logoPath}`}
          />{' '}
        </div>
      )}
      <VideoIframeContainer>
        <iframe
          title="Training Video"
          width="890"
          height="500"
          src={`https://player.vimeo.com/video/${videoNumber}`}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </VideoIframeContainer>
      <Box mt={3} mb={3}>
        <Button onClick={loginRedirect} style={{ 'background-color': '#082f49' }} variant="contained" color="primary">
          Sign in
        </Button>
      </Box>
      <P>To complete a quiz based on this training please login to the portal</P>
    </Layout>
  );
};

export default MicroTrainingPublicView;
