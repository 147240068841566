// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Star = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 17.7 17" role="img" aria-hidden="false">
    <path fill="none" d="M0 0h17.7v17H0z" />
    <path d="M17.4,5.9C17.4,5.9,17.4,5.9,17.4,5.9c-0.2-0.2-0.5-0.4-0.8-0.4l-4.5-0.6l-2-4.1C9.7,0.1,8.9-0.2,8.3,0.1  C8,0.3,7.8,0.5,7.7,0.7l-2,4.1L1.2,5.5C0.4,5.6-0.1,6.3,0,7c0,0.3,0.2,0.6,0.4,0.8l3.3,3.2l-0.8,4.5C2.8,16.2,3.3,16.9,4,17  c0.3,0,0.6,0,0.8-0.1l4-2.1l4,2.1c0.7,0.3,1.5,0.1,1.8-0.6c0.1-0.2,0.2-0.4,0.2-0.6c0-0.1,0-0.1,0-0.2L14.1,11l3.3-3.2  C17.9,7.3,17.9,6.4,17.4,5.9z M12.8,10.2c-0.2,0.2-0.3,0.4-0.2,0.7l0.8,4.6l-4.1-2.2c-0.2-0.1-0.5-0.1-0.7,0l-4.1,2.2l0.8-4.6  c0-0.2,0-0.5-0.2-0.7L1.6,6.9l4.6-0.7c0.2,0,0.5-0.2,0.6-0.4l2.1-4.2l2.1,4.2c0.1,0.2,0.3,0.4,0.6,0.4l4.6,0.7L12.8,10.2z" />
  </SvgIconStyled>
);

export default Star;
