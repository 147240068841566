// @flow

import useAccess from './useAccess';

type useTabsAllowedTypes = {
  app: string,
  layout: Object,
  accessMerger?: Object
};

function useTabsAllowed({ app, layout, accessMerger }: useTabsAllowedTypes): Object {
  const access = useAccess({ type: 'tabs', app, merger: accessMerger });
  const allowed = {};
  Object.keys(layout)
    .filter(d => access[d])
    .forEach(a => {
      allowed[a] = layout[a];
    });
  return allowed;
}

export default useTabsAllowed;
