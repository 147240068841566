// @flow

const trackSecurityIncidents = {
  type: 'track',
  selecting: true,
  selectAll: true,
  actions: [
    {
      icon: 'Delete',
      title: 'Delete',
      method: 'delete'
    }
  ],
  row: {
    click: true,
    action: 'newModal',
    modal: {
      app: 'clients',
      tab: 'trackSecurityIncidentsInfo'
    }
  },

  cells: [
    { id: 'date', label: 'Incident Date', align: 'left' },
    { id: 'discovery_date', label: 'Discovery Date', align: 'left' },
    { id: 'description', label: 'Description', align: 'left' },
    { id: 'created', label: 'Created', align: 'left' },
    { id: 'modified', label: 'Modified', align: 'left' },
  ]
};

export default trackSecurityIncidents;
