// @flow
import Values from 'values.js';
import { getStorage } from './storage';

const idMap = {
  myDashboard: {
    default: 'userId'
  },
  myCompany: {
    default: 'clientId'
  },
  phishing: {
    default: 'clientId'
  },
  clients: {
    list: 'partnerId',
    default: 'clientId'
  },
  partnerProfile: {
    default: 'partnerId'
  },
  user: {
    default: '',
    profile: 'userId',
    contactUs: 'userId'
  }
};

const percentsPalleteDefault = {
  tint: [5, 15, 95],
  shade: [30, 50, 85]
};

// Get type of id.
export function getId(key: 'partnerId' | 'userId' | 'clientId'): string {
  return getStorage(key, true);
}

// Get type of id based on the resource.
export function getIdByResource(resource: string, tab?: string | false = false): string {
  const m = idMap[resource.split('/')[0]];
  const isTab = Object.prototype.hasOwnProperty.call(m, tab);
  return getId(m[isTab ? tab : 'default']);
}

// Make sure the value is array.
export function toArray(item: any): Array<*> {
  if (Array.isArray(item)) return item.length === 0 ? [] : item;
  return new Array(item);
}

export function validateVariable(variable: any, object?: Object | boolean = false): boolean {
  let validation =
    typeof variable === 'undefined' ||
    variable === null ||
    variable === 0 ||
    variable === '' ||
    (Array.isArray(variable) && variable.length > 0);
  if (object !== false && typeof object === 'object' && object !== null) {
    validation = !Object.prototype.hasOwnProperty.call(object, variable);
  }

  return !validation;
}

export function capitalizeFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

// Transform camel case string to Capitalised string.
export function camelCaseToString(camelCase: string): string {
  return camelCase
    .replace(/([A-Z])/g, match => ` ${match}`)
    .replace(/^./, match => match.toUpperCase())
    .trim();
}

export function snakeCaseToString(snakeCase: string): string {
  return snakeCase
    .replace('_', ' ')
    .replace(' bg', ' background')
    .replace(/^./, match => match.toUpperCase())
    .trim();
}

export function snakeToCamel(str: string): string {
  return str.replace(/([-_][a-z])/g, group =>
    group
      .toUpperCase()
      .replace('-', '')
      .replace('_', '')
  );
}

export function snakeToCamelTitle(str: string): string {
  return str
    .replace(/([A-Z])/g, match => ` ${match}`)
    .replace(/^./, match => match.toUpperCase())
    .replace(/([-_][a-z])/g, group =>
      group
        .toUpperCase()
        .replace('-', ' ')
        .replace('_', ' ')
    );
}

export function getRecord(state: Object, { record, id }: { record: Object | null, id: string | number }): Object {
  if (record === null || typeof record === 'undefined') return null;
  return id !== 0 && Object.hasOwnProperty.call(record, id) ? record[id] : record;
}

export function getActiveTab(tab: Object): string {
  return Object.keys(tab).filter(t => tab[t].active)[0];
}

export function itemExist(object: Object | null, item: string): boolean {
  return object && Object.hasOwnProperty.call(object, item);
}

export function strClean(string: string) {
  return (
    string &&
    string
      .replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, '')
      .replace(/(?:&nbsp;)/g, '')
      .replace(/(?:&#39;)/g, "'")
      .replace(/(?:&rsquo;)/g, "'")
      .replace(/(?:&amp;)/g, '&')
  );
}

export function getMonthFromString(month: string){
  const months = ['January','February', 'March', 'April', 'May', 'June','July', 'August', 'September', 'October', 'November', 'December'];
  return months.indexOf(month) + 1;
}

export function isBase64(string: string) {
  const exclude = ['list', 'clients', 'dashboard', 'company', 'phishing', 'training'];
  if (string === '' || string.trim() === '' || exclude.includes(string)) return false;
  const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
  return base64regex.test(string);
}

export function ChartColorPercent(number: number, invert: boolean, colorType1: boolean) {
  if (colorType1) {
    if (number === 0) return `none`;
    if (number > 0 && number <= 50) return `Danger`;
    if (number >= 51 && number <= 75) return `Warning`;
    return `Success`;
  }

  if (invert) {
    if (number === 0) return `none`;
    if (number > 0 && number <= 5) return `Success`;
    if (number > 5.1 && number <= 10) return `Warning`;
    return `Danger`;
  }

  if (number === 0) return `none`;
  if (number > 0 && number <= 62.375) return `Danger`;
  if (number > 62.376 && number <= 78.625) return `Warning`;
  return `Success`;
}

export function hasKey(obj: Object, key: string) {
  return typeof obj === `object` ? Object.prototype.hasOwnProperty.call(obj, key) : null;
}

export function isObjectEmpty(obj: Object) {
  return typeof obj === 'object' && Object.keys(obj).length === 0;
}

const getColors = (color: Array<*>, type: string, percents: Object) => {
  return color
    .filter(row => row.type === type)
    .filter(
      row => row.weight === percents[type][0] || row.weight === percents[type][1] || row.weight === percents[type][2]
    )
    .map(row => row.hex);
};

export function palleteColors(color: string, weight?: number = 5, percents?: Object = percentsPalleteDefault) {
  if (/^#([0-9a-f]{6}|[0-9a-f]{3})$/i.test(color)) {
    const pallete = new Values(color).all(weight);
    const shades = getColors(pallete, 'shade', percents);
    const tints = getColors(pallete, 'tint', percents);
    const colors = [...shades, ...tints.reverse()];

    return colors;
  }
  return [];
}

export function isHexColor(color: string): boolean {
  if (/^#([0-9A-F]{3}){1,2}$/i.test(color)) {
    return true;
  }
  return false;
}

export function validURL(input: string): boolean {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  );
  return !!pattern.test(input);
}

export function isValidEmail(email: string): boolean {
  if(email.length > 199) {
    return false
  }
  const re = /^\w+([!#$%'*+\-/=?^_`{|*&()\\-`.+,/\"]?\w)*@\w+([\.-]?\w)*(\.\w{2,})+$/;
  return re.test(email);
}

export function isNumber(value: string): boolean {
  if (!isNaN(parseFloat(value)) && isFinite(value)) {
    return true;
  }
  return false;
}

export function isHtml(str: string): boolean {
  return /<[a-z/][\s\S]*>/i.test(str);
}

export function getPreContent(str: string): string {
  return isHtml(str) ? str.replace(/\s+/g, ' ') : str;
}

export function isValidScreenName(screenName) {
  const re = /^[A-Za-z0-9\-]+$/
  return re.test(screenName)
}

export function dateTimeZone(date: string): string {
  if (/^\d{4}-\d{2}-\d{2}$/i.test(date)) {
    const newDate = new Date(new Date(date).getTime() + new Date(date).getTimezoneOffset() * 60000);
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    const formatDate = Intl.DateTimeFormat('en-US', {
      timeZone,
      month: '2-digit',
      day: '2-digit',
      year: 'numeric'
      // hour: 'numeric',
      // minute: 'numeric'
    }).format(newDate);

    return formatDate;
  }

  return '';
}

export const hasInternetConnection = (notify, callBack) => {
  if (!navigator.onLine) {
    notify('No Internet Connection', 'warning');
  } else {
    return callBack();
  }
};

export const inputPatterns = {
  email:
    "^[_A-Za-z0-9-!#$%&'*+/=?^_`{|}~]+(\\.[_A-Za-z0-9-!#$%&'*+/=?^_`{|}~]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$"
};

export const isPublicUrl = path => {
  const publicPages = [
    'signup',
    'logout',
    'federated',
    'setPassword',
    'forgotPassword',
    'newUser',
    'MicroTrainingPublicView'
  ];
  if (publicPages.indexOf(path) === -1) return false;
  else return true;
};

export const decodeId = id => {
  return atob(atob(atob(id)));
};

export const encodeId = id => {
  return btoa(btoa(btoa(id)));
};

export const getCurrentTimeStamp = () => {
  return new Date().getTime();
};

export const getUrlsInsideText = str => {
  let expression = /(https?:\/\/(?:www\.|(?!www))[^\s\.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,})/gi;
  return str.match(expression);
};

export const toUTCfromLocal = dateTime => {
  var d = new Date(dateTime.replace("Z",""));
  return new Date(d.toUTCString()).toISOString()
};

export const fromUTCtoLocal = dateTime => {
  var date = new Date(dateTime.replace(" ","T") + "Z");
  return new Date(date).toLocaleString("sv-SE").replace(" ","T") + "Z";
};


export const getDate = (date) => {
  const completeDate = date ? new Date(date.replace(/\s/, "T")) : "";

  const completeDateDay = completeDate ? completeDate.getDate() < 10 ? "0" + completeDate.getDate() : completeDate.getDate() : "";
  const completeDateMoth = completeDate ? completeDate.getMonth() + 1 < 10 ? "0" + (completeDate.getMonth() + 1) : (completeDate.getMonth() + 1) : "";
  const completeDateYear = completeDate ? completeDate.getFullYear() : "";

  return {
    completeDate,
    completeDateDay,
    completeDateMoth,
    completeDateYear
  }
}
export const setVariable = (name, value) => document.documentElement?.style.setProperty(`--${name}`, value);

export const setVariables = colors =>
  Object.keys(colors).map(s =>
    Object.keys(colors[s]).map(c =>
      setVariable(`color${capitalizeFirstLetter(s)}${capitalizeFirstLetter(c)}`, colors[s][c])
    )
  );

export const objectsEqual = (obj1, obj2) =>
  Object.keys(obj1).length === Object.keys(obj2).length && Object.keys(obj1).every(p => obj1[p] === obj2[p]);

export const arrayOfObjectsEqual = (arr1, arr2) =>
  arr1.length === arr2.length && arr1.every((obj, idx) => objectsEqual(obj, arr2[idx]));


export const toJson = jsonStr => JSON.parse(jsonStr);


function fallbackCopyTextToClipboard(text) {
  var el = document.createElement('textarea');
  el.value = text;
  el.setAttribute('readonly', '');
  el.style = {position: 'absolute', left: '-9999px'};
  document.body.appendChild(el);

  if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
    // save current contentEditable/readOnly status
    var editable = el.contentEditable;
    var readOnly = el.readOnly;

    // convert to editable with readonly to stop iOS keyboard opening
    el.contentEditable = true;
    el.readOnly = true;

    // create a selectable range
    var range = document.createRange();
    range.selectNodeContents(el);

    // select the range
    var selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    el.setSelectionRange(0, 999999);

    // restore contentEditable/readOnly to original state
    el.contentEditable = editable;
    el.readOnly = readOnly;
  } else {
    el.select(); 
  }

  try {
    document.execCommand('copy');
  } catch (err) {
    console.error('error', err);
  }

  document.body.removeChild(el);
}

export const copyTextToClipboard = (text) => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text);
};