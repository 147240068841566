// @flow

import { type ComponentType } from 'react';
import styled from 'styled-components';
import { List as RAList } from 'react-admin';

export const ListParent: ComponentType<*> = styled.div`
  .data-grid-table {
    background-color: var(--backgroundPaper);
    position: relative;
    border-collapse: separate;
    thead {
      border-collapse: separate;
    }
    tr {
      height: 48px;
      /* &:hover {
        background-color: var(--backgroundDefault);
      } */
    }
    th {
      position: relative;
      z-index: 1;
      border-top: var(--borderSize) solid var(--borderBase);
      border-bottom: var(--borderSize) solid var(--borderBase);
      background-color: transparent;
      svg {
        color: var(--colorDefault) !important;
      }
      &:first-child {
        border-radius: var(--borderRadius) 0px 0px var(--borderRadius);
      }
      &:last-child {
        border-radius: 0px var(--borderRadius) var(--borderRadius) 0px;
      }
    }
  }
  .data-grid-table + span p {
    font-size: calc(var(--fontSize) - 3px);
  }
  .data-grid-table + span button {
    font-size: calc(var(--fontSize) - 3px);
  }

  .data-grid-header {
    > span {
      font-size: var(--fontSize);
      font-weight: medium;
      color: var(--colorFontTableHeader);
    }
  }

  .data-grid-body {
    td {
      color: var(--colorDefault);
      padding: 3px 8px;
      border-bottom: var(--borderSize) solid var(--borderTableDefault);
      font-size: var(--fontSize);

      > span {
        font-size: var(--fontSize);
      }
      &[role='expand-content'] {
        padding: 0;
      }
      /* svg {
        color: var(--colorDefault);
      } */
    }
  }

  .data-grid-cell {
    &:first-child {
      padding-left: 17px !important;
    }

    &__odd {
      background-color: var(--colorBaseLight3);
    }
  }
`;

export const ListStyled: ComponentType<*> = styled(RAList)`
  /* RaList-main */
  & > div {
    &:first-child {
      display: flex;
      align-items: center;
      background-color: var(--backgroundPaper);
      border: var(--borderSize) solid var(--borderBase);
      border-bottom: none;
      border-radius: var(--borderRadius) var(--borderRadius) 0 0;
      padding: calc(var(--spacing) * 1.5);

      & > form {
        display: flex;
        align-items: center;
        min-height: 0;
        margin: 0;
      }
    }

    &:last-child {
      position: relative;
      z-index: 999;
      margin-top: calc(var(--spacing) / -1.4);
      border-right: 1px solid var(--borderBase);
      border-left: 1px solid var(--borderBase);
      border-radius: var(--borderRadius);

      & > div {
        overflow: auto;
        overflow-x: auto;
      }
    }
  }

  tr[id$='expand'] td {
    /* padding: 0; */
  }
  display: block !important;
`;
