// @flow
import React, { type Element, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Tabs } from 'components';
import { useLocation, useId } from 'hooks';
import { hasKey, dataProvider } from 'helpers';
import useHasInternet from '../../hooks/useHasInternet';
import { BSN_SET_CLIENT_ACCESS } from 'conf';

const ClientsEdit = (): Element<*> => {
  const { item } = useLocation();
  const partnerId = useId({ key: 'partnerId' });
  const updatedAccess = useSelector(state => state.bsn.clients.clientUpdatedAccess);
  const adminAccess = useSelector(({ admin }) => {
    const { data } = admin.resources.clients;
    return hasKey(data, String(item)) ? data[item].access : null;
  });
  const [access, setAccess] = useState(adminAccess);
  const hasInternet = useHasInternet();
  const dispatchRx = useDispatch();

  useEffect(() => {
    if (access === null) {
      dataProvider.getOne('clients', `list/${partnerId}`, { _filter: `id:${String(item)}` }).then(d => {
        hasInternet(() => {
          if (d && d.data && d.data.length > 0) {
            setAccess(d.data[0].access);
          }
        })
      });
    } else if (updatedAccess) {
      setAccess(updatedAccess);
    }
  }, [adminAccess, item, partnerId, updatedAccess]);

  useEffect(() => {
    return ()=> {
      dispatchRx({
        type: BSN_SET_CLIENT_ACCESS,
        payload: { access: null }
      });
    }
  }, []);

  return <Tabs id={String(item)} access={access || {}} />;
};

export default ClientsEdit;
