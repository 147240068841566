// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const File = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 24 23" role="img" aria-hidden="false">
    <path d="M19.6727 5.7611L17.8572 4.02123L15.5508 1.81088L13.6982 0.0355075L13.6611 0V0.0355075H11.8086H11.5029H6.77885C5.24122 0.0355075 4 1.22501 4 2.69857V20.3369C4 21.8105 5.24122 23 6.77885 23H16.9309C18.4685 23 19.7098 21.8105 19.7098 20.3369V8.30876V7.53647V5.7966V5.7611H19.6727ZM17.0513 5.7611H14.7078C14.1335 5.7611 13.6611 5.31725 13.6611 4.75801V2.51216L17.0513 5.7611ZM16.9309 21.2246H6.77885C6.26939 21.2246 5.85257 20.834 5.85257 20.3369V2.69857C5.85257 2.21034 6.26939 1.81088 6.77885 1.81088H11.8086V4.75801C11.8086 6.29371 13.1146 7.53647 14.7078 7.53647H17.8572V20.3369C17.8572 20.834 17.4404 21.2246 16.9309 21.2246Z" />
  </SvgIconStyled>
);

export default File;
