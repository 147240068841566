// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const DeleteIcon = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 16 16" role="img" aria-hidden="false">
    <path d="M14.1391 2.09126H9.94746C9.95365 2.06651 9.96599 2.04177 9.96599 2.01702C9.96599 0.903332 9.08192 0 8 0C6.91808 0 6.03401 0.903332 6.03401 2.01702C6.03401 2.04177 6.03401 2.06651 6.03401 2.09126H1.85471C0.834621 2.09126 0 2.92653 0 3.94741V4.20109C0 5.22197 0.834621 6.05724 1.85471 6.05724H2.37405C2.31223 6.23666 2.27511 6.42846 2.27511 6.62645V14.1439C2.27511 15.1647 3.10973 16 4.12982 16H11.8702C12.8903 16 13.7249 15.1647 13.7249 14.1439V6.62645C13.7249 6.42846 13.6878 6.23666 13.626 6.05724H14.1453C15.1654 6.05724 16 5.22197 16 4.20109V3.94741C15.9938 2.92653 15.1654 2.09126 14.1391 2.09126ZM7.2643 2.01702C7.2643 1.58392 7.59196 1.23743 7.99381 1.23743C8.39567 1.23743 8.72333 1.59011 8.72333 2.01702C8.72333 2.04177 8.73571 2.06651 8.74189 2.09126H7.25192C7.25192 2.06651 7.2643 2.04177 7.2643 2.01702ZM12.4822 6.62645V14.1439C12.4822 14.4841 12.204 14.7626 11.864 14.7626H4.12364C3.78361 14.7626 3.5054 14.4841 3.5054 14.1439V6.62645C3.5054 6.36659 3.65999 6.15004 3.88874 6.05724H5.52086V11.4896C5.52086 11.8299 5.79907 12.1083 6.1391 12.1083C6.47913 12.1083 6.75734 11.8299 6.75734 11.4896V6.05724H9.23029V11.4462C9.23029 11.7865 9.50849 12.065 9.84853 12.065C10.1886 12.065 10.4668 11.7865 10.4668 11.4462V6.05724H12.0989C12.3277 6.15004 12.4822 6.37278 12.4822 6.62645ZM14.7573 4.20109C14.7573 4.54138 14.4791 4.8198 14.1391 4.8198H12.2659C12.136 4.78887 12 4.77031 11.8578 4.77031H4.11749C3.97529 4.77031 3.84544 4.78887 3.70943 4.8198H1.83619C1.49616 4.8198 1.21795 4.54138 1.21795 4.20109V3.94741C1.21795 3.60712 1.49616 3.3287 1.83619 3.3287H14.1329C14.4729 3.3287 14.7511 3.60712 14.7511 3.94741V4.20109H14.7573Z" />
  </SvgIconStyled>
);

export default DeleteIcon;
