// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Delete = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 16.3 18" role="img" aria-hidden="false">
    <path fill="none" d="M0 0h16.3v18H0z" />
    <g>
      <path d="M5.2,14c0.4,0,0.7-0.3,0.7-0.7V7.6c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7v5.8C4.5,13.7,4.8,14,5.2,14z   " />
      <path d="M8.1,14c0.4,0,0.7-0.3,0.7-0.7V7.6c0-0.4-0.3-0.7-0.7-0.7S7.4,7.2,7.4,7.6v5.8C7.4,13.7,7.7,14,8.1,14z" />
      <path d="M11.1,14c0.4,0,0.7-0.3,0.7-0.7V7.6c0-0.4-0.3-0.7-0.7-0.7s-0.7,0.3-0.7,0.7v5.8C10.4,13.7,10.7,14,11.1,14z" />
      <path d="M15.6,3h-4l-0.5-1.7C10.9,0.5,10.2,0,9.4,0H6.9C6.1,0,5.4,0.5,5.1,1.3L4.6,3h-4C0.3,3,0,3.3,0,3.6   C0,4,0.3,4.3,0.6,4.3h0.7v12c0,0.9,0.8,1.7,1.7,1.7h10.1c1,0,1.7-0.8,1.7-1.7v-12h0.7c0.3,0,0.6-0.3,0.6-0.6C16.3,3.3,16,3,15.6,3z    M6.3,1.7c0.1-0.2,0.3-0.4,0.5-0.4h2.5c0.3,0,0.5,0.2,0.5,0.4L10.3,3H5.9L6.3,1.7z M13.7,16.4L13.7,16.4c-0.1,0.2-0.3,0.3-0.5,0.3   H3.1c-0.3,0-0.5-0.2-0.5-0.5v-12h11.1V16.4z" />
    </g>
  </SvgIconStyled>
);

export default Delete;
