// @flow
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  Button,
  Checkbox,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  InputAdornment,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  makeStyles
} from '@material-ui/core';

import { getStorage } from 'helpers';
import pushNotifications from 'helpers/apis/pushNotifications';
import { Container, Typography, LoadingStyled, RichTextEditor } from 'components';
import { Search as SearchIcon } from '@material-ui/icons';
import { useNotify } from 'react-admin';
const useStyles = makeStyles({
  root: {
    width: '100%'
  },
  container: {
    maxHeight: 440
  },
  padded: {
    margin: '0 10px'
  }
});

const TableCellContainer: ComponentType<*> = styled(TableCell)`
  && {
    &:hover {
      cursor: pointer;
    }
  }
`;

const PushNotificationsListSelectionModal = props => {
  const classes = useStyles();
  const [state, setState] = useState({ options: props.list, selected_options: props.selectedList });
  const [tableList, setTableList] = useState(props.list);
  const [group, setGroup] = useState([]);
  const search = e => {
    const arr = [...state.options].filter(function(el) {
      return el.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1;
    });
    setTableList(arr);
  };
  const filter = e => {
    setGroup(e.target.value);
    const arr = [...state.options].filter(function(el) {
      return e.target.value.indexOf(el[e.target.name]) !== -1;
    });
    setTableList(arr);
  };
  const selectOption = idx => {
      setState({
        ...state,
        selected_options: [...state.selected_options, tableList[idx]]
      });
  };
  const revertSelectOption = idx => {
    const arr = [...state.selected_options];
    arr.splice(idx, 1);
    setState({ ...state, selected_options: arr });
  };
  console.log(state);
  console.log(tableList);
  return (
    <>
      <Container.Grid p={2} container>
        <Container.Grid item xs={12}>
          <TextField
            className={classes.padded}
            id="input-with-icon-textfield"
            label={`Enter the ${props.type === 'includedUser' ? 'user' : 'client'} name`}
            variant="outlined"
            fullWidth
            onChange={e => search(e)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
          {props.type === 'includedUser' && (
            <FormControl className={classes.padded} fullWidth variant="outlined">
              <InputLabel id="demo-simple-select-label">Group role</InputLabel>
              <Select
                name="group_name"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={group}
                variant="outlined"
                label="Group Role"
                onChange={e => filter(e)}
                multiple
              >
                {/* <MenuItem value={'E'}>Employee</MenuItem> */}
                <MenuItem value={'M'}>Manager</MenuItem>
                <MenuItem value={'MA'}>Manager Admin</MenuItem>
                <MenuItem value={'PA'}>Partner Administrator</MenuItem>
              </Select>
            </FormControl>
          )}
        </Container.Grid>
      </Container.Grid>
      <Container.Grid flexWrap="wrap" p={2} container>
        {state.selected_options.map((row, idx) => (
          <Box p={1}>
            <Chip label={row.name} onDelete={() => revertSelectOption(idx)} />
          </Box>
        ))}
      </Container.Grid>
      <Container.Grid p={2} container>
        <Container.Grid item xs={12}>
          <TableContainer className={classes.container} component={Paper}>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="right">{props.type === 'includedUser' && 'Group Role'}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableList.map((row, idx) => {
                  if (state.selected_options.filter(e => e.id === row.id).length === 0)
                    return (
                      <TableRow onClick={e => selectOption(idx)} key={row.id}>
                        <TableCellContainer component="th" scope="row">
                          {row.name}
                        </TableCellContainer>
                        <TableCell align="right">{props.type === 'includedUser' && row.group_name}</TableCell>
                      </TableRow>
                    );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Container.Grid>
      </Container.Grid>
      <Container.Grid p={2} container>
        <Container.Grid justify="flex-end" item xs={12}>
          <Box mr={1}>
            <Button onClick={props.close}>Cancel</Button>
          </Box>
          <Box mr={1}>
            <Button disabled={!state.selected_options.length} variant="contained" color="primary" onClick={e => props.save(state.selected_options)}>
              Save
            </Button>
          </Box>
        </Container.Grid>
      </Container.Grid>
    </>
  );
};

export default PushNotificationsListSelectionModal;
