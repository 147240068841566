// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Document = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 15 21.275" role="img" aria-hidden="false">
    <path fill="none" d="M0 0h15v21.275H0z" />
    <path
      d="M45.887,25.544l-4.338-4.375c-.533-.495-.69-.569-1.2-.569H32.837A1.818,1.818,0,0,0,31.1,22.382V40.055a1.844,1.844,0,0,0,1.819,1.819h11.4A1.8,1.8,0,0,0,46.1,40.241V26.058A.95.95,0,0,0,45.887,25.544Zm-5.059,2.11h3.787v12.4a.323.323,0,0,1-.334.334H32.919a.323.323,0,0,1-.334-.334V22.382a.3.3,0,0,1,.3-.3h6.163v3.787A1.784,1.784,0,0,0,40.828,27.654Zm-.3-1.782V22.385l3.785,3.785H40.828A.3.3,0,0,1,40.531,25.872Z"
      transform="translate(-31.1 -20.6)"
      fill="#535353"
    />
  </SvgIconStyled>
);

export default Document;
