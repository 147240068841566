import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';
import {
  Avatar,
  Button,
  Typography,
  Link,
  TextareaAutosize,
  CircularProgress,
  Box
} from '@material-ui/core';
import PostComment from './PostComment';
import Thumbnail from '../common/Thumbnail';
import newsfeed from 'helpers/apis/newsfeed';
import { getStorage, decodeId, getUrlsInsideText } from 'helpers';
import { LoadingStyled } from 'components';
import { setComments, setFeeds, clearComments, addComment, updateCommentId, deleteComment } from 'helpers/action';

const PostCommentingSection = ({ commentsCount, postId, updateCommentsCount }) => {
  const comments = useSelector(state => state.newsfeed.comments);
  const feeds = useSelector(state => state.newsfeed.feeds);
  const { profile, theme } = useSelector(state => state.bsn?.user);
  const [postingLoading, setPostingLoading] = useState(false);
  const [commentsLoading, setCommentsLoading] = useState(false);
  const [newComments, setNewComments] = useState([]);
  const hasMore = commentsCount && !commentsLoading && commentsCount !== comments[postId]?.length;
  const dispatch = useDispatch();
  const user = {
    name: getStorage('name'),
    id: decodeId(getStorage('userId'))
  };
  const [deletedThumbnails, setDeletedThumbnails] = useState([]);

  const [commentData, setCommentData] = useState({
    user_id: user.id,
    post_id: postId,
    comment_text: '',
    thumbnail_metadata: null
  });

  const updateComment = (index, newData) => {
    dispatch(
      setComments(postId, [
        ...comments[postId].slice(0, index),
        ...[
          {
            ...comments[postId][index],
            ...newData
          }
        ],
        ...comments[postId].slice(index + 1)
      ])
    );
  };

  const onChangeCommentText = event => {
    let text = event.target.value;

    let links = getUrlsInsideText(event.target.value);
    let deletedList = links && deletedThumbnails.filter(el => links.includes(el));

    if (deletedList) setDeletedThumbnails([...deletedList]);
    else if (!links) setDeletedThumbnails([]);

    const linkToPreview = links && links.filter(el => !deletedList.includes(el))[0];

    if (
      links &&
      links.length !== 0 &&
      (!commentData.thumbnail_metadata || commentData.thumbnail_metadata.url !== linkToPreview)
    ) {
      setCommentData({ ...commentData, ['comment_text']: text });
      const uniqueLinks = [...new Set(links)];

      if (uniqueLinks.length !== deletedList.length) {
        setPostingLoading(true);
        newsfeed
          .getLinkPreview(linkToPreview)
          .then(res => {
            setCommentData({
              ...commentData,
              ['thumbnail_metadata']: res.data.thumbnail_metadata,
              ['comment_text']: text
            });
            setPostingLoading(false);
          })
          .catch(err => {
            console.clear();
            console.log(err);
          });
      }
    } else if (!links || links.length === 0)
      setCommentData({ ...commentData, ['thumbnail_metadata']: null, ['comment_text']: text });
    else setCommentData({ ...commentData, ['comment_text']: text });
  };

  const postComment = () => {
    const newCommentTempId = Date.now();
    const newCommentTs = new Date(new Date().getTime() + new Date().getTimezoneOffset() * 60000);
    const newComment = {
      comment_text: commentData.comment_text,
      id: newCommentTempId,
      liked: false,
      likes_count: 0,
      thumbnail_metadata: commentData.thumbnail_metadata,
      timestamp: format(newCommentTs, 'yyyy-MM-dd HH:mm:ss'),
      disableActions: true,
      user: {
        client_name: profile?.client_name,
        email: profile?.email,
        first_name: profile?.first_name,
        id: +commentData.user_id,
        last_name: profile?.last_name,
        partner_base_color: theme?.base?.base
      }
    };
    dispatch(addComment(postId, newComment));

    let idx = feeds.findIndex(x => x.id === postId);
    dispatch(
      setFeeds([
        ...feeds.slice(0, idx),
        ...[
          {
            ...feeds[idx],
            comments_count: feeds[idx].comments_count + 1
          }
        ],
        ...feeds.slice(idx + 1)
      ])
    );

    setCommentData({
      user_id: user.id,
      post_id: postId,
      comment_text: '',
      thumbnail_metadata: null
    });

    newsfeed
      .addComment(commentData)
      .then(res => {
        dispatch(updateCommentId(postId, newCommentTempId, res?.data?.comment_id));
        setNewComments([...newComments, res?.data?.comment_id]);
      })
      .catch(err => {
        console.clear();
        console.log(err);
        dispatch(deleteComment(postId, newCommentTempId));
      });
  };

  const updateCommentData = (idx, comment) => {
    const newData = {
      comment_text: comment.comment_text,
      thumbnail_metadata: comment.thumbnail_metadata
    };
    updateComment(idx, newData);
  };

  const deleteCommentfromComments = idx => {
    dispatch(setComments(postId, [...comments[postId].slice(0, idx), ...comments[postId].slice(idx + 1)]));
  };

  const loadMoreComments = () => {
    setCommentsLoading(true);
    let offset = comments[postId] ? comments[postId].length - newComments.length : 0;
    newsfeed
      .getComments(postId, offset, user.id)
      .then(res => {
        if (res.data.length !== 0) {
          let oldComments = comments[postId] || [];
          let commentsArray = [...oldComments, ...res.data];
          dispatch(setComments(postId, commentsArray));
        }
      })
      .catch(err => {
        console.clear();
        console.log(err);
      })
      .finally(() => setCommentsLoading(false));
  };

  const likeComment = idx => {
    comments[postId][idx].liked
      ? newsfeed.unLikeComment(comments[postId][idx].id, user.id, postId)
      : newsfeed.likeComment(comments[postId][idx].id, user.id, postId);

    const newData = {
      likes_count: comments[postId][idx].liked
        ? comments[postId][idx].likes_count - 1
        : comments[postId][idx].likes_count + 1,
      liked: !comments[postId][idx].liked
    };
    updateComment(idx, newData);
  };

  const onDeleteComment = (id) => {
    const index = newComments.indexOf(id);
    if (index > -1) {
      let newList = [...newComments];
      newList.splice(index, 1);
      setNewComments(newList);
    }
  };

  useEffect(() => {
    return () => dispatch(clearComments(postId));
  }, [dispatch]);

  useEffect(() => {
    if (commentsCount !== 0) {
      setCommentsLoading(true);
      newsfeed
        .getComments(postId, 0, user.id)
        .then(res => {
          dispatch(setComments(postId, res.data));
        })
        .catch(err => {
          console.clear();
          console.log(err);
        })
        .finally(() => setCommentsLoading(false));
    }
  }, []);

  const deleteThumbnail = () => {
    setDeletedThumbnails([...new Set([ ...deletedThumbnails, commentData.thumbnail_metadata.url ])]);
    setCommentData({ ...commentData, ['thumbnail_metadata']: null });
  };

  return (
    <Box py={1.3} px={{ xs: 1.9, md: 0 }}>
      <Box display="flex">
        <Box>
          <Avatar style={{ backgroundColor: 'var(--colorBaseBase)' }}>
            {user.name
              .split(' ')
              .map((n, i) => i < 2 ? n[0] : null)
              .join('')}
          </Avatar>
        </Box>
        <Box pl={{ xs: 1.2, md: 2 }} flexGrow={1}>
          <TextareaAutosize
            onChange={onChangeCommentText}
            value={commentData.comment_text}
            maxLength="1000"
            aria-label="Write a comment"
            placeholder="Write a comment"
            style={{ width: '100%', minHeight: '41px', boxSizing: 'border-box', fontSize: '16px', fontFamily: 'Roboto', padding: '10px', resize: 'vertical' }}
          />
          <Typography
            variant="caption"
            style={{ color: commentData.comment_text.length >= 990 ? 'red' : 'rgb(84, 87, 97)' }}
          >
            ({commentData.comment_text.length}/1000)
          </Typography>
        </Box>
      </Box>

      {commentData.thumbnail_metadata && (
        <Box my={1.2}>
          <Thumbnail data={commentData.thumbnail_metadata} deleteThumbnail={deleteThumbnail} allowDelete />
        </Box>
      )}

      <Box pb={{ xs: 1, md: 2 }} textAlign="right">
        <Button
          onClick={postComment}
          disabled={commentData.comment_text.trim() === '' || postingLoading}
          variant="contained"
          color="primary"
        >
          {postingLoading && <CircularProgress color="inherit" size={20} />} Post
        </Button>
      </Box>

      {commentsCount !== 0 && comments[postId] && (
        comments[postId].map((comment, index) => (
          <PostComment
            updateComments={(idx, commentData) => updateCommentData(idx, commentData)}
            deleteFromComments={idx => deleteCommentfromComments(idx)}
            isOwnComment={user.id == comment.user.id}
            key={comment.id}
            comment={comment}
            idx={index}
            postId={postId}
            toggleLike={idx => likeComment(idx)}
            onDeleteComment={onDeleteComment}
          />
        ))
      )}
      
      {commentsCount !== 0 && commentsLoading && <LoadingStyled />}

      {hasMore ? (
        <Box mt={2.5}>
          <Link
            component="button"
            variant="body2"
            onClick={() => {
              loadMoreComments();
            }}
          >
            See More
          </Link>
        </Box>
      ) : null}
    </Box>
  );
};

export default PostCommentingSection;
