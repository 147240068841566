// @flow
import React, { type Element, useState, useEffect } from 'react';
import { useLocation, useId, useConfig, useDataGet } from 'hooks';
import { Button, Modal3 } from 'components';
import { LazyIcon } from 'components/icons';
import { SelectField } from '../forms';
import TableListModal from '../tablelist/TableListModal';

function transform(data) {
  return data.map(({ label, value }) => {
    return {
      label,
      value
    };
  });
}

const PagePolicies = (): Element<*> => {
  const app = 'clients';
  const { app: appLoc, item } = useLocation();
  const clientId = useId({ key: 'clientId' });
  const setId = item || clientId;

  const { data: policyType } = useDataGet({ app: appLoc, tab: 'policyType', item, transform });

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(null);
  const settings = useConfig('tables', app, String(value));

  useEffect(() => {
    if (value === null && policyType) setValue(policyType?.[0]?.value);
  }, [value, policyType]);

  const onClick = e => {
    setOpen(true);
  };

  const Toolbar = () => (
    <>
      {policyType ? (
        <>
          <SelectField
            value={value}
            name="policies"
            onChange={e => setValue(e.target.value)}
            emptyValue="Policy Type"
            choices={policyType}
            mr={2}
            variant
          />
          {value === 'policies' && <Button onClick={e => onClick(e)}>+ New Policy</Button>}
          {value === 'otherPolicies' && <Button onClick={e => onClick(e)}>+ New Other Policy</Button>}
        </>
      ) : (
        <LazyIcon component="Loading" mr={1} color="colorDefault" size={3} />
      )}
    </>
  );

  const tableOptions = {
    refetchOnWindowFocus: false
  };

  const Table = ({ val, id, config }: { val: any, id: string, config: Object }) => {
    return (
      <>
        {val ? (
          <>
            <TableListModal
              tab={val}
              id={id}
              app={app}
              tableOptions={tableOptions}
              tableSettings={config}
              toolbar={<Toolbar />}
              resetTable={val}
            />
            {open && (
              <Modal3
                open={open}
                setOpen={setOpen}
                app={app}
                type={value === 'policies' ? 'policy' : 'other_policy'}
                tab={value}
                createNew
              />
            )}
          </>
        ) : (
          <LazyIcon component="Loading" mr={1} color="colorDefault" size={3} />
        )}
      </>
    );
  };

  return <Table val={value} id={setId} config={settings} />;
};

export default PagePolicies;
