// @flow

import React, { type ComponentType, type Element, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ViewList as ViewListIcon } from '@material-ui/icons';
import { MenuItemLink as MuiMenuItemLink, Responsive } from 'react-admin';
import styled from 'styled-components';
import { useLocation } from 'hooks';
import { useApp, getActiveTab, hasKey } from 'helpers';
import { transitions } from 'conf';
import Logo from '../../common/Logo';
import { LazyIcon } from '../../icons';
import { layoutMenuKit, layoutToolbarBottomData } from '../helper';
import MenuProfileInfo from './MenuProfileInfo';
import { useTabsAllowed } from 'hooks';
import { Accordion, AccordionDetails, AccordionSummary, Hidden } from '@material-ui/core';
import Arrow from '../../icons/Arrow';

export const MainMenu: ComponentType<*> = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 20px;
  width: 100%;
  background: var(--colorBaseBase);
  overflow-y: auto;
  /* TODO: Change CSS Variables */
  transition: all all ${transitions.duration.leavingScreen}ms ${transitions.easing.sharp};
  /* position: relative; */

  ${props => props.theme.breakpoints.down("sm")} {
    padding: 0 36px;
    border-radius: 0 5px 5px 0;
  }
`;

export const MainMenuLogoContainer: ComponentType<*> = styled.div`
  margin-top: calc(var(--spacing) * 2);
  margin-bottom: calc(var(--spacing) * 2);
  text-align: center;

  ${props => props.theme.breakpoints.down("sm")} {
    border-radius: 5px;
    overflow: hidden;
    display: flex;
  }

`;

const MenuItemLinkSingleStyled = styled.div`
  display: flex;
  align-items: center;

  a {
    width: 100%;
    min-height: 48px;
    font-weight: ${({ active }) => (active ? 700 : 500)};
    padding: 0 16px !important;
    background-color: ${({ active }) => (active ? 'var(--colorBaseDark1)' : 'var(--colorBaseBase)')} !important;

    &.has-notification {
      position: relative;
      padding-right: 30px !important;

      &:after {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        display: inline-block;
        background: #FF5A61;
        border-radius: 50%;
        top: 50%;
        margin-top: -5px;
        right: 17px;
      }
    }

    & > div {
      margin-right: 3px;
    }
  }

  .menu-item-icon {
    color: #fff;
  }
`;

export const MainMenuItem: ComponentType<*> = styled.div``;

export const MenuItemLink: ComponentType<*> = styled(MuiMenuItemLink)`
  &.has-notification {
    position: relative;
    padding-right: 30px;

    &:after {
      ${({ notifnumber }) => notifnumber && `content: '${notifnumber > 9 ? '9+' : notifnumber}';`}
      position: absolute;
      width: 18px;
      height: 18px;
      display: inline-block;
      background: #FF5A61;
      border-radius: 50%;
      top: 50%;
      margin-top: -9px;
      right: 10px;
      color: #fff;
      font-size: 10px;
      line-height: 18px;
      font-weight: 700;
      text-align: center;
      letter-spacing: 1px;
    }
  }

  && {
    ${({ pt }) => pt && `padding-top: ${pt}px;`}
    ${({ pb }) => pb && `padding-bottom: ${pb}px;`}
    ${({ pl }) => pl && `padding-left: ${pl}px;`}
    ${({ pr }) => pr && `padding-right: ${pr}px;`}
    ${({ mt }) => mt && `margin-top: ${mt}px;`}
    ${({ mb }) => mb && `margin-bottom: ${mb}px;`}
    ${({ ml }) => ml && `margin-left: ${ml}px;`}
    ${({ mr }) => mr && `margin-right: ${mr}px;`}
    ${({ bt }) => bt && `border-top: ${bt}px solid #ffffff1a;`};

    > div { min-width: inherit; }

    svg {
      font-size: 19px;
      margin-right: 13px;
      color: var(--colorCommonWhite);
    }
    background-color: ${({active}) => !active ? "transparent" : "var(--colorBaseDark2)" };
    ${props => props.theme.breakpoints.down("sm")} {
      border-radius: 5px;
      letter-spacing: 0;
      font-size: 14px;

      &.tabs-items {
        font-weight: ${({ active }) => (active ? 700 : 400)};
        padding-left: 35px;
        color: ${({active}) => active ? "#fff" : "var(--colorGreyLight3)" };
      }
    }
  }
`;

const AccordionContent = styled(Accordion)`
  background-color: transparent  !important;
  box-shadow: none !important;

  &.Mui-expanded {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  &:before {
    content: none !important;
  }
`

const AccordionDetailsContent = styled(AccordionDetails)`
  flex-direction: column;
`


const MenuArrowIcon = styled(Arrow)`
  width: 12px !important;
  height: 12px !important;
`

const AccordionSummaryContainer = styled(AccordionSummary)`

  background-color: ${({active}) => active ? "var(--colorBaseDark1)" : "var(--colorBaseBase)" }  !important;
  border-radius: 5px !important;

  &.accordion-summary.Mui-expanded {
    min-height: 48px;
  }

  &.accordion-summary > div:first-child {
    margin: 0 !important;
  }

  &.accordion-summary > div:last-child {
    transform: rotate(180deg);

    &.Mui-expanded {
      transform: rotate(0deg);
    }
  }

`

const AccordionSummaryContent = styled.div`
  display: flex;
  align-items: center;

  .menu-item-icon {
    color: #fff;
  }

  h3 {
    color: #ffffff;
    margin-left: 16px;
    font-size: 14px;
    font-weight: 500;
  }
`

export const MenuItemLinkKit: ComponentType<*> = styled(MuiMenuItemLink);

export type MenuResource = {
  hasCreate: boolean,
  hasEdit: boolean,
  hasList: boolean,
  hasShow: boolean,
  icon: Function,
  name: string,
  options: {
    label?: string
  }
};

export type MenuProps = {
  dense: boolean,
  hasDashboard: boolean,
  logout: Element<*>,
  onMenuClick?: Function,
  open: boolean,
  resources: Array<MenuResource>,
  width: string
};

const getLabel = (resource: MenuResource) =>
  resource.options && resource.options.label ? resource.options.label : resource.name;

const Menu = ({ dense, hasDashboard, onMenuClick, logout, width, setOpenModalSideBar, ...rest }: MenuProps): Element<*> => {
  const { dispatch } = useApp();
  const { app, tab: currentTab } = useLocation();
  const hasNotification = useSelector(({ bsn }) => bsn?.user?.profile?.newsfeed_unopened_notifications_count);

  const resources = useSelector(rxState => {
    const resource = [];
    if (hasKey(rxState.bsn.user, 'access') && rxState.bsn.user.access) {
      for (const re in rxState.admin.resources) {
        if (hasKey(rxState.bsn.user.access.apps, re)) resource.push(rxState.admin.resources[re].props);
      }
    }
    return resource;
  });

  const {
    tabs,
    user: { profile, access },
    system: { tabCurrent, item, location: locationCurrent }
  } = useSelector(rxState => rxState.bsn);

  const hasLogo = profile?.logo_partner || false;
  const onClick = (e, location, tab) => {
    console.log("getActiveTab(tabs[location])", getActiveTab(tabs[location]))
    dispatch.changeApp(location, locationCurrent, getActiveTab(tabs[location]), tabCurrent, item);
    typeof onMenuClick === 'function' && onMenuClick(e);
  };
  const onKeyDown = e => { 
    if (e.keyCode === 13 || e.keyCode === 32) e.preventDefault()
  }

  const desktopMenuItems = resources
    .filter(r => r.hasList)
    .map(resource => {
      const { name } = resource;
      const hasDot = resource.options.hasDot;
      if (name === 'user') return null;
      const tab = getActiveTab(tabs[name]);
      const to = name === 'clients' ? `/${name}` : `/${name}/${tab || ""}`;
      const active = app === name;
      return (
        <MenuItemLink
          key={resource.name}
          to={to}
          primaryText={getLabel(resource)}
          leftIcon={resource.icon ? <resource.icon /> : <ViewListIcon />}
          onClick={e => onClick(e, name)}
          onKeyDown={onKeyDown}
          dense={dense}
          active={active}
          className={hasDot && hasNotification ? 'has-notification' : ''}
          notifnumber={hasNotification}
          pt={10}
          pb={10}
          mt={5}
          mb={5}
        />
      );
    })

  const MobileMenuItemsTabs = ({currentApp, hasDot}) => {
    const allowedTabs = useTabsAllowed({ layout: tabs[currentApp], app: currentApp });
    const blockedTabs = ["securityTraining", "newsletters", "securityPolicies"];

    return Object.keys(allowedTabs).filter((tab) => !blockedTabs.includes(tab) ).map((tab, index) => {
      return <MenuItemLink
        className={"tabs-items"}
        key={index}
        to={({ pathname }) => {
          const [, , , item] = pathname.split('/');
          return item ? `/${currentApp}/${tab}/${item}` : `/${currentApp}/${tab}`;
        }}
        primaryText={tabs[currentApp][tab].label}
        onClick={() => setOpenModalSideBar(false)}
        dense={dense}
        active={currentTab && currentApp ? (currentTab.toLowerCase() === tab.toLowerCase()) && (currentApp.toLowerCase() === app.toLowerCase()) : false}
        className={hasDot && hasNotification ? 'has-notification' : ''}
        notifnumber={hasNotification}
        pt={10}
        pb={10}
        mt={5}
        mb={5}
      />
    })
  }

  const MenuItemLinkSingle = ({ resource }) => {
    const currentApp = resource.name;
    const isActive =
      currentTab && currentApp
        ? currentTab.toLowerCase() === 'dashboard' && currentApp.toLowerCase() === app.toLowerCase()
        : false;
    return (
      <MenuItemLinkSingleStyled active={isActive}>
        <MenuItemLink
          to={({ pathname }) => {
            const [, , , item] = pathname.split('/');
            return (item && currentApp !== 'newsfeed') ? `/${currentApp}/dashboard/${item}` : `/${currentApp}/dashboard`;
          }}
          primaryText={resource.options.label}
          leftIcon={<resource.icon className={'menu-item-icon'} />}
          onClick={() => setOpenModalSideBar(false)}
          dense={dense}
          active={isActive}
          className={resource.options.hasDot && hasNotification ? 'has-notification' : ''}
        />
      </MenuItemLinkSingleStyled>
    );
  };

  const MobileMenuItems = () => {
    const resourcesList = resources.filter(r => r.hasList);
    const blockedPages = ["myCompany", "clients", "partnerProfile", "user"]

    return resourcesList.map(resource => {
      const { name, options } = resource;

      if (blockedPages.includes(name)) return null;
      if (options.hasOwnProperty('hasMobileSublist') && !options.hasMobileSublist)
        return <MenuItemLinkSingle resource={resource} />;

      return (
        <AccordionContent >
          <AccordionSummaryContainer
            expandIcon={<MenuArrowIcon />}
            aria-controls={`${name}-content`}
            id={`${name}-header`}
            className={"accordion-summary"}
            active={app === name}
          >
            <AccordionSummaryContent>
              <resource.icon className={"menu-item-icon"} />
              <h3>{options.label}</h3>
            </AccordionSummaryContent>
          </AccordionSummaryContainer>
          <AccordionDetailsContent>
            <MobileMenuItemsTabs currentApp={name} hasDot={options.hasDot} />
          </AccordionDetailsContent>
        </AccordionContent>
      );
    })
  }

  return (
    <MainMenu {...rest} style={{minHeight: "-webkit-fill-available", height: "-webkit-fill-available" }}>
      <MainMenuItem>
        {hasLogo && (
          <MainMenuLogoContainer>
            <Logo />
          </MainMenuLogoContainer>
        )}
        <MenuProfileInfo />
        <Hidden smDown>
          {desktopMenuItems}
        </Hidden>
        <Hidden mdUp>
          {tabs && app && app !== "login" && <MobileMenuItems/>}
        </Hidden>
        {/* <Responsive xsmall={logout} medium={null} /> */}
      </MainMenuItem>
      <MainMenuItem>
        <Hidden mdUp>

            <MenuItemLink
              to={layoutToolbarBottomData[1].link}
              primaryText={layoutToolbarBottomData[1].label}
              leftIcon={<LazyIcon component={layoutToolbarBottomData[1].icon} />}
              dense={dense}
              onClick={() => dispatch.changeTab(app, null, null, item)}
              pt={20}
              pb={20}
              pl={16}
              pr={16}
              mb={10}
              bt={1}
            />
        </Hidden>
        <Hidden smDown>
          {
            access &&
            access.features.marketing_material &&
            layoutMenuKit.map(({ icon, label, id, link }) => (
              <MenuItemLink
                to={window.location.hash.substr(1)}
                primaryText={label}
                leftIcon={<LazyIcon component={icon} />}
                key={id}
                dense={dense}
                onClick={() => window.open(link, '_blank')}
                pt={20}
                pb={20}
                pl={5}
                pr={5}
                mb={35}
                bt={1}
              />
            ))
          }
        </Hidden>
      </MainMenuItem>
    </MainMenu>
  );
};

Menu.defaultProps = {
  onMenuClick: () => null
};

export default Menu;
