// @flow
import React, { useEffect } from 'react';
import type { Element } from 'react';
import cognito from '../../helpers/apis/cognito';
import { getStorage } from '../../helpers';
import { toJson } from '../../helpers/utils';
import { useWindowSize } from '../../hooks';
import { isMobile } from "react-device-detect"

type LogoutType = Object;

const Logout: Function = (props: LogoutType): Element<*> => {
  const userpoolClientID = getStorage('userpoolClientID');
  const userpoolUrl = getStorage('userpoolUrl');
  const userIsFederated = toJson(getStorage('federated'));
  const cognitoLogoutURL = `${userpoolUrl}logout?response_type=code&client_id=${userpoolClientID}&redirect_uri=https://${window.location.hostname}/redirector.html&state=STATE&scope=openid+profile+aws.cognito.signin.user.admin`;
  console.log('cognito logout url=', cognitoLogoutURL);

  const window_width = window.innerWidth;
  const logout_window_width = 500;
  const logout_window_height = 400;
  const logout_window_top = 100;
  const logout_window_left = window_width ? window_width / 2 - logout_window_width / 2 : 0;
  const logout_window_options = `toolbar=no,status=no,menubar=no,scrollbars=yes,resizable=no,left=${logout_window_left},top=${logout_window_top},width=${logout_window_width},height=${logout_window_height}`;

  const logout_window_html = `
    <script type="text/javascript">
        document.write("<title>Log out</title><h3 style='text-align:center;margin-top:40px;'>You have been securely logged off the portal.</h3>");
        setTimeout(function(){
          window.location.href = "${cognitoLogoutURL}";
        }, 1000);
    </script>`;

  const [width] = useWindowSize();

  useEffect(() => {
    if (width !== 0)
      cognito
        .logout()
        .then(response => {
          console.log(response);
        })
        .catch(error => {})
        .then(() => {
          sessionStorage.clear();
          localStorage.clear();
          if (!isMobile && userIsFederated) {
            const logout_window = window.open('', '_blank', logout_window_options);
            if (logout_window) {
              logout_window.document.write(logout_window_html);

              setTimeout(() => {
                logout_window.close();
                window.location.href = '/logout/index.html';
              }, 4000);
            }
          } else {
            window.location.href = '/logout/index.html';
          }
        });
  }, [width]);

  return (
    <>
      <iframe src="https://login.windows.net/common/oauth2/logout" style={{ display: 'none' }} />
      <img src="https://accounts.google.com/logout" style={{ display: 'none' }} />
    </>
  );
};

export default Logout;
