// @flow
import React, { type Element, useState } from 'react';
import styled from 'styled-components';
import { useNotify } from 'react-admin';
import { CircularProgress, Hidden } from '@material-ui/core';
import { useLocation, useAccess } from 'hooks';
import { useDashboard } from './DashboardContext';
import reporting from '../../helpers/apis/reporting';
import { LazyIcon } from '../icons';
import DashboardToDoList from './DashboardToDoList';
import DashboardChartBarGroupWidget from './DashboardChartBarGroupWidget';
import DashboardBoxTitle from './DashboardBoxTitle';
import ChartCircle from '../charts/ChartCircle';
import ChartHalfCircle from '../charts/ChartHalfCircle';
import ChartEqualizer from '../charts/ChartEqualizer';
import ChartRadar from '../charts/ChartRadar';
import Typography from '../types/Typography';
import Button from '../forms/Button';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {Pagination} from "swiper/core"
import 'swiper/swiper.min.css';
import "swiper/components/pagination/pagination.min.css"
import HeadTitle from '../mobile/HeadTitle';

SwiperCore.use([Pagination])

const { h6: H6 } = Typography;

type DashboardTypes = {
  chartLine?: {
    title?: string | null,
    description?: string | null,
    data: Array<Object>
  } | null,
  chartRadar?: {
    data: Object
  } | null,
  externalDataBreaches?: {
    title?: string | null,
    description?: string | null,
    chart: {
      charData: Array<Object>
    }
  } | null,
  pieChartGrid?: Array<Object> | null,
  toDoList?: Object | null,
  essChart?:
    | {
        title: string | null,
        description?: string | null,
        ess: number
      }
    | {
        description?: string | null,
        ess: number
      },
  essDescription?: string | null,
  rankTable?: {
    title: string,
    component: Element<*>
  } | null,
  hasData: boolean,
  resource: string,
  hidden?: Object
};

type String = string & { replaceAll: Function };

const Report = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--colorCommonWhite);
  margin: calc(var(--spacing) * 1.2);
  padding: calc(var(--spacing) * 0.5) calc(var(--spacing) * 1.2);
  ${({ opacity }) => opacity && `opacity: 0.1`};
`;

const Frame = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-flow: row wrap;
  min-height: 480px;

  ${props => props.theme.breakpoints.down("sm")} {
    flex-direction: column;
  }
`;

const Row = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-flow: row wrap;
`;

const Row1 = styled(Row)`
  flex: 1 66.6666666666%;

  ${props => props.theme.breakpoints.down("sm")} {
    flex-flow: column;
    width: 100%;
  }
`;

const Row2 = styled(Row)`
  flex: 1 33.33333333%;
  /* @media only screen and (min-width: 1235px) { */
  flex-flow: column;
  flex-direction: column;

  ${props => props.theme.breakpoints.down("sm")} {
    width: 100%;
  }


  /* } */
`;

const Box = styled.div`
  display: flex;
  box-sizing: border-box;
  min-width: 100px;
  flex: 1 ${({ shrink }) => shrink}%;
  padding: 10px;

  ${props => props.theme.breakpoints.down("sm")} {
    max-width: 576px;
    width: 100%;
    margin: 0 auto;
    &.dashboard-table {
      padding: 0 10px 10px;
    }
  }

  @media only screen and (min-width: 1235px) {
    flex: 1 ${({ shrinkLg }) => shrinkLg}%;
  }
  ${({ opacity }) => opacity && `opacity: 0.1`};
`;

const SliderContainer = styled.div`
  background: #fff;
  margin: 0 auto;
  padding-bottom: 20px;
  max-width: 556px;
  width: calc(100% - 20px);

  .swiper-pagination {
    bottom: 0 !important;
  }
`

const Content = styled.div`
  flex: 1 100%;
  background-color: #fff;
  padding: 20px;

  ${props => props.theme.breakpoints.down("sm")} {
    width: 100%;
    box-sizing: border-box;

    &.group-widget {
      padding: 20px 0;
    }
  }
`;

const DashboardTitle = styled.h1`
  font-size: 18px;
  font-weight: 700;
  color: #535353;
  margin: 22px 0 13px 10px;
`

const DesktopCircleCharts = ({
   essChart,
   isDashboard,
   showBox,
   chartLine,
   externalDataBreaches,
   box,
   open
}) => {
  return (
    <Hidden smDown>
      {essChart?.ess !== 0 && isDashboard.ess && (
        <Box shrink="40" opacity={open && showBox(open, essChart.title, box)}>
          <Content>
            {/* TODO: Change essChart API for a Object */}
            <ChartHalfCircle
              // title="Employee Secure Score (ESS)"
              title={essChart.title}
              description={essChart.description}
              chart={{
                chartData: [{ name: essChart.ess, value: (essChart.ess / 800) * 100 }]
              }}
              labels
            />
          </Content>
        </Box>
      )}
      {chartLine && chartLine.data && chartLine.data.length > 0 && isDashboard.ess_trend && (
        <Box shrink="60" opacity={open}>
          <Content>
            <ChartEqualizer title={chartLine.title} chart={chartLine} />
          </Content>
        </Box>
      )}

      {externalDataBreaches && isDashboard.external_data_breaches && (
        <Box shrink="40" opacity={open && showBox(open, externalDataBreaches.title, box)}>
          <Content>
            <ChartCircle {...externalDataBreaches} fontSize={13} icon labels />
          </Content>
        </Box>
      )}
    </Hidden>
  )
}

const MobileCircleCharts = ({
  essChart,
  isDashboard,
  showBox,
  chartLine,
  externalDataBreaches,
  box,
  open
}) => {

  return (
    <Hidden mdUp>
      <SliderContainer>
      <Swiper
        spaceBetween={50}
        pagination={true}
      >
        {essChart?.ess !== 0 && isDashboard.ess && (
          <SwiperSlide>
            <Box shrink="40" opacity={open && showBox(open, essChart.title, box)}>
              <Content>
                {/* TODO: Change essChart API for a Object */}
                <ChartHalfCircle
                  // title="Employee Secure Score (ESS)"
                  title={essChart.title}
                  description={essChart.description}
                  chart={{
                    chartData: [{ name: essChart.ess, value: (essChart.ess / 800) * 100 }]
                  }}
                  labels
                />
              </Content>
            </Box>
          </SwiperSlide>
        )}


        {chartLine && chartLine.data && chartLine.data.length > 0 && isDashboard.ess_trend && (
          <SwiperSlide>
            <Box shrink="60" opacity={open}>
              <Content>
                <ChartEqualizer title={chartLine.title} chart={chartLine} />
              </Content>
            </Box>
          </SwiperSlide>
        )}


        {externalDataBreaches && isDashboard.external_data_breaches && (
          <SwiperSlide>
            <Box shrink="40" opacity={open && showBox(open, externalDataBreaches.title, box)}>
              <Content>
                <ChartCircle {...externalDataBreaches} fontSize={13} icon labels />
              </Content>
            </Box>
          </SwiperSlide>
        )}

      </Swiper>
    </SliderContainer>
    </Hidden>
  )
}


const CircleCharts = (props) => {
  return (
    <>
      <DesktopCircleCharts {...props} />
      <MobileCircleCharts {...props} />
    </>
  )
};



const Dashboard = ({
  chartLine,
  chartRadar,
  externalDataBreaches,
  pieChartGrid,
  toDoList,
  essChart,
  rankTable,
  hasData,
  resource,
  hidden
}: DashboardTypes): Element<*> => {
  const { dispatch } = useDashboard();
  const { open, box } = dispatch.getBox();
  const notify = useNotify();
  const { item } = useLocation('clientId');
  const [loading, setLoading] = useState(false);
  const { overall, leaderboard } = useAccess({ type: 'feature' });
  // TODO: remove When the other Dashboards are returning the hidden key
  const isDashboard = {
    avg_micro_quiz: !hidden?.avg_micro_quiz,
    ess: !hidden?.ess,
    ess_trend: !hidden?.ess_trend,
    external_data_breaches: !hidden?.external_data_breaches,
    improve_your_ESS: !hidden?.improve_your_ESS,
    leaderboard: !hidden?.leaderboard,
    micro_quiz_taken: !hidden?.micro_quiz_taken,
    overall: !hidden?.overall,
    phishing_attack_fail_rate: !hidden?.phishing_attack_fail_rate,
    security_training: !hidden?.security_training
  };



  const showBox = (checkOpen: boolean, label: String, compareLabel: String) =>
    !(checkOpen && replaceAll(label) === replaceAll(compareLabel));

  const onClick = () => {
    setLoading(true);
    reporting
      .getReport({
        report_key: 'OverallEss',
        recipe: 'chrome-pdf',
        parameters: {
          client_id: item
        },
        delivery: 'url'
      })
      .then(res => {
        setLoading(false);
        setTimeout(() => {
          window.open(res.data.url);
        }, 1000);
      })
      .catch(() => {
        notify('Error 400', 'error');
        setLoading(false);
      });
  };

  return (
    <>
      <Hidden mdUp>
        <HeadTitle title={"Dashboard"}/>
      </Hidden>
      {(resource === 'clients' || resource === 'myCompany') && (
        <Report opacity={open}>
          <H6 mt={2.5} mb={2.5} pl={1.5}>
            Reports
          </H6>
          <Button height={36} pr={3} pl={3} onClick={onClick}>
            {loading ? (
              <>
                <CircularProgress size={18} thickness={2} />
                &nbsp;&nbsp;Loading...
              </>
            ) : (
              <>
                <LazyIcon color="colorCommonWhite" component="Download" mr={1} />
                Download ESS Overall Report
                {/* {resource === 'clients' ? `Download Manage Clients Dashboard` : `Download Dashboard`} */}
              </>
            )}
          </Button>
        </Report>
      )}
      <Frame>
        {isDashboard.ess && isDashboard.ess_trend && isDashboard.external_data_breaches && (
          <Row1>
            <CircleCharts
              essChart={essChart}
              isDashboard={isDashboard}
              showBox={showBox}
              chartLine={chartLine}
              externalDataBreaches={externalDataBreaches}
              box={box}
              open={open}
            />
            {isDashboard.external_data_breaches && (
              <Box shrink="60" opacity={open}>
                <Content className={"group-widget"}>
                  {pieChartGrid && <DashboardChartBarGroupWidget chart={pieChartGrid} />}
                  {chartRadar && resource !== 'clients' && <ChartRadar {...chartRadar.data} />}
                </Content>
              </Box>
            )}
          </Row1>
        )}
        {(toDoList || chartRadar || overall || leaderboard) && (
          <Row2>
            {resource === 'myDashboard' && isDashboard.improve_your_ESS && (
              <Hidden smDown>
                <Box shrink="40" shrinkLg="50" opacity={open}>
                  <Content>{toDoList && <DashboardToDoList record={toDoList} />}</Content>
                </Box>
              </Hidden>
            )}

            {resource === 'clients' && (
              <Box shrink="40" shrinkLg="50" opacity={open}>
                <Content>{chartRadar && <ChartRadar {...chartRadar.data} />}</Content>
              </Box>
            )}
            {rankTable && (isDashboard.leaderboard || isDashboard.overall) && (
              <Box shrink="60" shrinkLg="50" opacity={open} className={"dashboard-table"}>
                <Content>
                  <DashboardBoxTitle label={rankTable.title} fontSize={13} />
                  {rankTable.component}
                </Content>
              </Box>
            )}
          </Row2>
        )}
      </Frame>
    </>
  );
};

function replaceAll(str: String) {
  if (str.replaceAll) {
    return str.replaceAll(' ', '_').toLowerCase();
  }

  return str.replace(/\s/g, '-').toLowerCase();

}

Dashboard.defaultProps = {
  chartLine: null,
  chartRadar: null,
  pieChartGrid: null,
  toDoList: null,
  essChart: null,
  essDescription: null,
  rankTable: null,
  externalDataBreaches: null,
  hidden: null
};

export default Dashboard;
