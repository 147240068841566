// @flow
import { createGlobalStyle, css } from 'styled-components';
import { variables, transitions } from 'conf';
import store from 'helpers/store';

const { hasDarkMode } = store('bsn').getState().system;

const {
  font,
  color,
  transitions: { easing, duration },
  shadows
} = variables;

export const theme = {
  colors: {
    ...color
  }
};

const lightTheme = css`
  --colorBaseBase: ${theme.colors.primary.base};
  --colorBaseDark1: ${theme.colors.primary.dark1};
  --colorBaseDark2: ${theme.colors.primary.dark2};
  --colorBaseDark3: ${theme.colors.primary.dark3};
  --colorBaseLight1: ${theme.colors.primary.light1};
  --colorBaseLight2: ${theme.colors.primary.light2};
  --colorBaseLight3: ${theme.colors.primary.light3};
  --backgroundDefault: var(--colorBaseLight3);
  --colorDefault: var(--colorGreyLight2);
  --hoverDefault: var(--colorGreyDark2);
  --borderDefault: var(--colorGreyLight3);
  --borderBase: #ccc;
  --borderTableDefault: var(--colorBaseLight3);

  --colorFontTableHeader: var(--colorBaseDark3);

  --buttonPrimaryBackground: var(--colorBaseDark2);
  --buttonPrimaryHover: #39596d;

  --buttonSecondaryBackground: var(--colorCommonWhite);
  --buttonSecondaryBorderFont: var(--colorDefault);
  --buttonSecondaryHover: var(--colorBaseBase);
  --buttonSecondaryFontHover: var(--colorCommonWhite);

  --backgroundPaper: var(--colorCommonWhite);
  --filterImage: none;

  --colorBox: var(--colorSystemInfo);

  --blackAndWhite: var(--colorCommonBlack);

  --loadingNormal: var(--colorBaseLight2);
  --loadingMedium: var(--colorBaseBase);
  --loadingDark: var(--colorBaseDark3);
`;
// TODO: --hoverButtonDefault: #39596d; the hover will be calculated when the color palette is finished
const darkTheme = css`
  --colorBaseBase: ${theme.colors.dark.base};
  --colorBaseDark1: ${theme.colors.dark.dark1};
  --colorBaseDark2: ${theme.colors.dark.dark2};
  --colorBaseDark3: ${theme.colors.dark.dark3};
  --colorBaseLight1: ${theme.colors.dark.light1};
  --colorBaseLight2: ${theme.colors.dark.light2};
  --colorBaseLight3: ${theme.colors.dark.light3};

  --backgroundDefault: var(--colorBaseDark3);
  --colorDefault: var(--colorGreyLight3);
  --hoverDefault: var(--colorGreyLight2);
  --borderDefault: var(--colorGreyLight1);
  --borderBase: var(--colorBaseLight2);
  --borderTableDefault: var(--colorBaseDark3);
  --iconDefaultcolor: #adadad;

  --colorFontTableHeader: var(--colorBaseLight3);

  --buttonPrimaryBackground: ${theme.colors.primary.dark2};
  --buttonPrimaryHover: #39596d;

  --buttonSecondaryBackground: var(--colorBaseBase);
  --buttonSecondaryBorderFont: var(--colorDefault);
  --buttonSecondaryHover: var(--colorBaseLight3);
  --buttonSecondaryFontHover: var(--colorBaseBase);

  --backgroundPaper: var(--colorBaseDark2);
  --filterImage: brightness(0) invert(1);

  --colorBox: var(--colorGreyLight3);

  --blackAndWhite: var(--colorCommonWhite);
`;
// TODO: --hoverButtonDefault: #39596d; the hover will be calculated when the color palette is finished
export const GlobalStyle = createGlobalStyle`
  html {
    font-size: 62.5%;

    /* Spacing */
    --spacing: 8px;
    --marginBoby: 0;
    --paddingBoby: 0;
    --outlineBoby: 0;
    --boxSizingBoby: border-box;

    /* Border */
    --borderSize: 1px;
    --borderRadius: 4px;

    /* Typography */
    --fontFamily: ${font.family};
    --fontSize: ${font.size};
    --fontSizeHtml: ${font.size};
    --fontWeightRegular: ${font.regular};
    --fontWeightMedium: ${font.medium};
    --fontWeightBold: ${font.bold};
    --lineHeight: 1.3;

    /* Color Common */
    --colorCommonWhite: ${color.common.white};
    --colorCommonBlack: ${color.common.black};

    /* Color Grey */
     --colorGreyBase: ${color.grey.base};
    --colorGreyDark1: ${color.grey.dark1};
    --colorGreyDark2: ${color.grey.dark2};
    --colorGreyDark3: ${color.grey.dark3};
    --colorGreyLight1: ${color.grey.light1};
    --colorGreyLight2: ${color.grey.light2};
    --colorGreyLight3: ${color.grey.light3};

    /* Color Primary */
    ${lightTheme}

    /* Color Dark Mode */
    [data-theme="dark"] {
      ${hasDarkMode ? darkTheme : lightTheme}
    }

    /* Color System */
    /* --colorSystemDanger: ${color.system.danger};
    --colorSystemInfo: ${color.system.info};
    --colorSystemSuccess: ${color.system.success};
    --colorSystemWarning: ${color.system.warning}; */

    /* Color System Hover */
    --colorSystemInfoHover: #3387ff;
    --colorSystemSuccessHover: #3dd889;
    --colorSystemDangerHover: #ff7881;
    --colorSystemSuccessDisable: #b6f0d3;

    /* Buttons */
    --buttonDefault: var(--colorBaseBase);
    --buttonLight: var(--colorBaseLight3);
    --buttonDark: var(--colorBaseDark3);
    --buttonDanger: var(--colorSystemDanger);
    --buttonInfo: var(--colorSystemInfo);
    --buttonSuccess: var(--colorSystemSuccess);
    --buttonWarning: var(--colorSystemWarning);
    --buttonRadius: 4px;

    /* Layout */
    --layoutSidebarWidth: 292px;
    --layoutHeaderHeight: 75px;

    /* Transitions */
    --easeInOut: ${easing.easeInOut};
    --easeOut: ${easing.easeOut};
    --easeIn: ${easing.easeIn};
    --sharp: ${easing.sharp};

    --shortest: ${duration.shortest};
    --shorter: ${duration.shorter};
    --short: ${duration.short};
    --standard: ${duration.standard};
    --complex: ${duration.complex};
    --enteringScreen: ${duration.enteringScreen};
    --leavingScreen: ${duration.leavingScreen};

    /* Shadows */
    --shadowDefault: ${shadows.default};

  }
  
  :root {
    --toastify-toast-width: 370px;
  }

  body {
    font-family: var(--fontFamily);
    font-size: var(--fontSize);
    font-weight: var(--fontWeightRegular);
    line-height: var(--lineHeight);
    background: var(--backgroundDefault);
    color: var(--colorDefault);
    text-align: left;
    margin: var(--marginBoby);
    padding: var(--paddingBody);
    outline: var(--outlineBody);
    box-sizing: var(--boxSizingBoby);
    /* TODO: Change CSS Variables */
    transition: all ${transitions.duration.leavingScreen}ms ${transitions.easing.sharp};

    a {
      /* TODO: Change CSS Variables */
      transition: all ${transitions.duration.leavingScreen}ms ${transitions.easing.sharp};
    }
  }
`;

export const GlobalStyleBase = createGlobalStyle`
  html {
    --colorBaseBase: ${theme.colors.primary.base};
    --colorBaseDark1: ${theme.colors.primary.dark1};
    --colorBaseDark2: ${theme.colors.primary.dark2};
    --colorBaseDark3: ${theme.colors.primary.dark3};
    --colorBaseLight1: ${theme.colors.primary.light1};
    --colorBaseLight2: ${theme.colors.primary.light2};
    --colorBaseLight3: ${theme.colors.primary.light3};
  }
`;
