// @flow
import React, { cloneElement, memo, type Element } from 'react';
import { useSelector } from 'react-redux';
import { Dialog } from '@material-ui/core';
import { Datagrid } from 'react-admin';
import { Route } from 'react-router';
import { getActiveTab } from 'helpers';
import { ListParent, ListStyled } from './ComponentType';

type ListProps = {
  componentPush: Function,
  create?: Element<*> | null,
  edit: Element<*>,
  actions: Element<*>,
  children: any,
  basePath: string,
  resource: string,
  ...
};

const List = ({ componentPush, create, edit, actions, children, ...props }: ListProps): Element<*> => {
  const { bsn } = useSelector(rxState => rxState);
  const handleClose: Function = (redirect: string): void => componentPush(redirect);

  return (
    <>
      <ListParent>
        <ListStyled {...props} perPage={25} actions={actions}>
          <Datagrid
            rowClick={(id, basePath) => `${basePath}/${getActiveTab(bsn.tabs[basePath.split('/')[1]])}/${id}`}
            classes={{
              table: 'data-grid-table',
              headerCell: 'data-grid-header',
              tbody: 'data-grid-body',
              rowCell: 'data-grid-cell',
              rowEven: 'data-grid-cell__even',
              rowOdd: 'data-grid-cell__odd'
            }}
          >
            {children}
          </Datagrid>
        </ListStyled>
      </ListParent>
      {create && (
        <Route path={`${props.basePath}/create`}>
          {({ match }) => (
            <Dialog
              open={!!match}
              fullWidth
              maxWidth="md"
              anchor="right"
              onClose={handleClose}
              aria-labelledby={`${props.resource}-create`}
            >
              {cloneElement(create, {
                handleCancel: (redirect?: string): void => handleClose(redirect || props.basePath),
                ...(props: $Rest<ListProps, any>)
              })}
            </Dialog>
          )}
        </Route>
      )}
    </>
  );
};

List.defaultProps = {
  create: null
};

export default memo<ListProps>(List);
