// @flow
import React, { memo, type Element, useState, useEffect } from 'react';
import { TextFieldStyled } from './ComponentTypes';
import { isNumber } from 'helpers';

type Props = {
  classes?: Object,
  value?: boolean | Function | any,
  onChange?: Function | false,
  autoFocus?: boolean,
  variant?: boolean,
  helperText?: any,
  error?: boolean | null,
};

const TextField = ({ classes, value, variant, onChange, enableEnterKey=false, integerOnly = false, autoFocus, helperText, ...props }: Props): Element<*> => {
  const [newValue, setValue] = useState(value);
  const [newError, setNewError] = useState(props.error);
  const [errorMessage, setErrorMessage] = useState('');
  const isOnchange = typeof onChange === 'function';
  const variantValue = variant ? 'standard' : 'outlined';

  useEffect(() => {
    setNewError(props.error);
  }, [props.error]);

  const handleValid = e => {
    if (e.target.value) {
      setNewError(false);
      setErrorMessage('');
    }
  };

  const handleInvalid = e => {
    e.preventDefault();
    setNewError(!e.target.validity.valid);
    setErrorMessage(e.target.validationMessage);
  };

  const textFieldOnKeyPress = e => {
    if ( (integerOnly && !isNumber(e.key)) || (!enableEnterKey && e.key === 'Enter') ) {
      e.preventDefault();
    }
  };

  return (
    <TextFieldStyled
      {...props}
      value={isOnchange ? value : newValue}
      onChange={isOnchange ? onChange : e => setValue(e.target.value)}
      onInput={e => handleValid(e)}
      onKeyPress={ textFieldOnKeyPress }
      classes={{ root: `variant-${variantValue}` }}
      margin="none"
      width={1}
      InputLabelProps={{ classes: { focused: 'field-focused' }, autoFocus }}
      variant={variantValue}
      onInvalid={handleInvalid}
      error={props.error || newError}
      helperText={errorMessage || helperText}
    />
  );
};

TextField.defaultProps = {
  classes: {},
  autoFocus: false,
  value: '',
  onChange: false,
  variant: false,
  helperText: '',
  error: false,
};

export default memo<Props>(TextField);
