import {
  SET_FEEDS,
  CLEAR_FEEDS,
  ADD_FEED,
  UPDATE_FEED_ID,
  DELETE_FEED,
  SET_COMMENTS,
  CLEAR_COMMENTS,
  ADD_COMMENT,
  UPDATE_COMMENT_ID,
  DELETE_COMMENT,
  SET_NOTIFICATIONS,
  MARK_OPENED,
  ADD_NOTIFICATION,
  UPDATE_NOTIFICATIONS,
  NEWSFEED_LICENSE,
  NEWSFEED_LICENSE_AGREED
} from 'conf/constants';

// general
export const setNewsfeedLicense = value => {
  return { type: NEWSFEED_LICENSE, payload: value };
};

export const setNewsfeedLicenseAgreed = () => {
  return { type: NEWSFEED_LICENSE_AGREED };
};

//feeds

export const setFeeds = feeds => {
  return { type: SET_FEEDS, payload: feeds };
};

export const clearFeeds = () => {
  return { type: CLEAR_FEEDS };
};

export const addFeed = feed => {
  return { type: ADD_FEED, payload: feed };
};

export const updateFeedId = (oldId, newId) => {
  return { type: UPDATE_FEED_ID, oldId, newId };
};

export const deleteFeed = feedId => {
  return { type: DELETE_FEED, payload: feedId };
};

//comments

export const setComments = (postId, comments) => {
  return { type: SET_COMMENTS, postId, postComments: comments };
};

//if action is called without "postId" param, all the comments are being cleared, otherwise only a specific post's comments
export const clearComments = postId => {
  return { type: CLEAR_COMMENTS, payload: postId };
};

export const addComment = (postId, newComment) => {
  return { type: ADD_COMMENT, postId, newComment };
};

export const updateCommentId = (postId, oldId, newId) => {
  return { type: UPDATE_COMMENT_ID, postId, oldId, newId };
};

export const deleteComment = (postId, commentId) => {
  return { type: DELETE_COMMENT, postId, commentId };
};

//notifications

export const setNotifications = (notifications) => {
  return { type: SET_NOTIFICATIONS, payload: notifications };
};

export const markOpened = (notificationId) => {
  return { type: MARK_OPENED, notificationId };
};

export const addNotification = notification => {
  return { type: ADD_NOTIFICATION, payload: notification };
};

export const updateNotifications = value => {
  return { type: UPDATE_NOTIFICATIONS, payload: value };
};