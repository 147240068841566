import apiClient from '../apiClient/apiClient';
import { getStorage } from '../storage';




const baseURL = process.env.REPORTING_BASE_URL;

const reporting = {};


reporting.getReport = (payload) => {
    const idToken = getStorage('idToken', true);
    const requestHeaders = {
        "Content-Type": "application/json",
        "Authorization": idToken
    }
    const requestOptions = {
        url: baseURL,
        data: payload,
        headers: requestHeaders
    }
    return apiClient.post(requestOptions);
}


export default reporting;
