// @flow
import React, { type ComponentType } from 'react';
import styled from 'styled-components';
import { Typography } from 'components';

const SVGContainer: ComponentType<*> = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: ${({ isSmall }) => `calc(var(--spacing) * ${isSmall ? 1 : 10})`};
`;
const SVGLoading: ComponentType<*> = styled.svg`
  width: ${({ isSmall }) => `calc(var(--spacing) * ${isSmall ? 6 : 10})`};
  height: ${({ isSmall }) => `calc(var(--spacing) * ${isSmall ? 6 : 10})`};
`;
const SVGRect: ComponentType<*> = styled.rect`
  width: calc(var(--spacing) * 1);
  height: calc(var(--spacing) * 5);
  fill: ${({ color }) => `var(--${color})`};
`;

const LoadingStyled = ({ size }) => {
  const isSmall = size && size === 'small';
  return (
    <SVGContainer isSmall={isSmall}>
      <SVGLoading isSmall={isSmall} viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <SVGRect x="17.5" y="30" rx="4" color="loadingNormal">
          <animate
            attributeName="y"
            repeatCount="indefinite"
            dur="1s"
            calcMode="spline"
            keyTimes="0;0.5;1"
            values="18;30;30"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
            begin="-0.2s"
          />
          <animate
            attributeName="height"
            repeatCount="indefinite"
            dur="1s"
            calcMode="spline"
            keyTimes="0;0.5;1"
            values="64;40;40"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
            begin="-0.2s"
          />
        </SVGRect>
        <SVGRect x="42.5" y="30" rx="4" color="loadingMedium">
          <animate
            attributeName="y"
            repeatCount="indefinite"
            dur="1s"
            calcMode="spline"
            keyTimes="0;0.5;1"
            values="20.999999999999996;30;30"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
            begin="-0.1s"
          />
          <animate
            attributeName="height"
            repeatCount="indefinite"
            dur="1s"
            calcMode="spline"
            keyTimes="0;0.5;1"
            values="58.00000000000001;40;40"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
            begin="-0.1s"
          />
        </SVGRect>
        <SVGRect x="67.5" y="30" rx="4" color="loadingDark">
          <animate
            attributeName="y"
            repeatCount="indefinite"
            dur="1s"
            calcMode="spline"
            keyTimes="0;0.5;1"
            values="20.999999999999996;30;30"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
          />
          <animate
            attributeName="height"
            repeatCount="indefinite"
            dur="1s"
            calcMode="spline"
            keyTimes="0;0.5;1"
            values="58.00000000000001;40;40"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
          />
        </SVGRect>
      </SVGLoading>
      <Typography.p m={0.1} fontSize={12}>
        One moment, the page is loading
      </Typography.p>
    </SVGContainer>
  );
};

export default LoadingStyled;
