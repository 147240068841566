// @flow
import React, { memo, type Element, useState } from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { TimePicker } from './ComponentTypes';

type Props = Object;

const TimeField = ({ value, onChange, ...props }: Props): Element<*> => {
  const isOnchange = typeof onChange === 'function';
  const newVal = `${value}:00.000Z`;
  const newDate = new Date();
  const [, time] = newDate.toISOString().split('T');
  const date = `2000-01-01T${value === '' ? time : newVal}`;
  const [newValue, setValue] = useState(date);

  const onDateChange = e => {
    let dt = e.toISOString();
    const [HH, MM] = dt
      .split('T')[1]
      .split('.')[0]
      .split(':');
    dt = `${HH}:${MM}`;
    return isOnchange ? onChange(dt) : setValue(dt);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <TimePicker
        {...props}
        autoOk
        inputVariant="outlined"
        margin="none"
        value={isOnchange ? date : newValue}
        onChange={onDateChange}
        mask="__:__ _M"
        placeholder="08:00 AM"
        InputLabelProps={{ classes: { focused: 'field-focused' } }}
        KeyboardButtonProps={{
          'aria-label': 'change time'
        }}
        ampm={false}
      />
    </MuiPickersUtilsProvider>
  );
};

export default memo<Props>(TimeField);
