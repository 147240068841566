// @flow
import React, { useEffect, useState, type Element, useRef, useMemo, memo } from 'react';
import { useSelector } from 'react-redux';
import { LazyApp } from 'apps';
import { useNotify } from 'react-admin';
import { Dialog, DialogContentText, Slide } from '@material-ui/core';
import { useApp, hasKey, dataProvider, isObjectEmpty, getPreContent } from 'helpers';
import { useLocation, useConfig, useFormData, useId } from 'hooks';
import { LazyIcon } from '../icons';
import Typography from '../types/Typography';
import LoadingStyled from '../types/Loading';
import {
  ButtonCancel,
  ButtonSubmit,
  ButtonDownload,
  DialogTitleStyled,
  DialogContentStyled,
  DialogActionsStyled,
  AlignRight,
  AlignLeft,
  IconButton
} from './ComponentTypes';
import useHasInternet from '../../hooks/useHasInternet';

const { h3: H3 } = Typography;

const preStyles = {
  border: 'none',
  background: 'none',
  padding: 0,
  margin: 0,
  whiteSpace: 'pre-line',
  wordBreak: 'break-word',
  fontFamily: 'var(--fontFamily)',
  fontSize: '16px',
  lineHeight: '1.1876em',
  color: 'var(--colorDefault)'
};

type restType = any;

type ModalTypes = {
  open: boolean,
  setOpen: Function,
  app: string,
  tab: string,
  parentTab?: string | null,
  rowId?: string,
  parentId?: string,
  rowIndex?: number,
  paramId?: string,
  createNew?: boolean,
  data?: Object | Array<*> | null,
  type?: string | null,
  apiApp?: string | null,
  apiTab?: string | null,
  row?: Object,
  modalTitle?: string | null,
  paramIdVal?: string | null,
  ...restType
};

type sendDataType = {
  deferred_sending: number | boolean,
  first_text: string,
  second_text: string,
  hours: number | string,
  message_type: string,
  add_notification_email: string,
  delete_draft?: number | string
};

const Modal3 = ({
  open = false,
  setOpen,
  app,
  tab,
  parentTab = null,
  rowId = '',
  parentId = '',
  rowIndex,
  createNew = false,
  paramId = '',
  data,
  type = null,
  apiApp = null,
  apiTab = null,
  row,
  modalTitle,
  paramIdVal,
  maxWidth = "md",
  ...rest
}: ModalTypes): Element<*> => {
  const form = useRef(null);
  const saveButton = useRef(null);
  const { dispatch: dispatchApp } = useApp();
  const { item } = useLocation();
  const notify = useNotify();
  const config: Object = useConfig('modals', app, tab);
  const [state, setStateFunction]: [any, Function] = useState(null);
  const [url, setUrl] = useState(null);
  const [refresh, setRefresh] = useState(null);
  const [initialFields, setInitialFields] = useState({});
  const [loading, setLoading] = useState(false);
  const clientId = useId({ key: 'clientId' });
  const partnerId = useId({ key: 'partnerId' });
  const setState = s => {
    setTimeout(() => setStateFunction(s), 300);
  };
  const email = useSelector(({ bsn }) => bsn.user.profile.email);
  const [id, setId] = useState(rowId || item || '');
  const [disabled, setDisabled] = useState(false);
  const [layout, setLayout] = useState({
    footer: {
      right: null,
      left: null
    }
  });
  const hasInternet = useHasInternet();
  const { setFormData } = useFormData();
  let formData = null;
  let changedFile = null;
  const {
    buttons,
    title,
    requireRowId,
    idRequired,
    paramKey,
    otherId,
    userIdrequired,
    initialState,
    noState,
    noQuery,
    newTabSource,
    newItemSource,
    requireRowData,
    invalidFormMessage,
    ...settings
  } = config;
  const [record, setRecord] = useState(
    useSelector(({ bsn }): Object | null => {
      if (noState || !hasKey(bsn[app], tab)) return null;
      const init = initialState;
      // If modal is for new item, return with initial state.
      if (createNew) return Object.keys(initialFields).length ? setState(initialFields) : setState(init);
      const rxData = bsn[app][tab];

      if (requireRowData) {
        return { ...initialState, data: row };
      }

      if (rxData === null) return null;

      // if call require `item`, add it to the request, otherwise get regular rxData.
      if (idRequired) {
        if (Object.keys(initialFields).length) return '';
        const newRow = rxData[item] && rxData[item].filter(d => d.id === rowId)[0];
        setState(newRow);
        return newRow;
      }
      if (requireRowId) {
        if (hasKey(rxData, rowId)) {
          if (Object.keys(initialFields).length) return '';
          setState(rxData[rowId]);
          return rxData[rowId];
        }
        if (typeof rxData === 'string') return rxData;
        return setState(rxData);
      }
      if (rxData && !state) {
        setState(rxData);
        return rxData;
      }
      // if no initial rxData or regular rxData, return null.
      if (rxData === null && init === null) return null;
      // return rxData, overwriting initial state.
      return rxData;
    })
  );
  // This useEffect should clear the state on unmount of the modal component - It is not cleaning
  useEffect(
    () => {
      if (requireRowData) {
        setStateFunction({ ...initialState, data: row });
        setRecord({ ...initialState, data: row });
      }
      return () => {
        setStateFunction(null);
        dispatchApp.set(apiApp || app, apiTab || tab, null);
        dispatchApp.set('system', 'tableRowModal', false);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  useEffect(() => {
    if (state === null && parentId && paramKey) {
      dispatchApp.getOne(apiApp, apiTab, parentId, { [paramKey]: paramId }).then(res => setStateFunction(res.data));
    } else if (otherId && !noQuery && !paramKey) {
      dispatchApp.getOne(app, tab, otherId ? rowId : null);
    } else if (!state && !noQuery) {
      dispatchApp.getOne(app, tab, requireRowId ? rowId : null, { [paramKey]: paramIdVal || paramId || rowId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramKey, noQuery]);

  useEffect(() => {
    if (!state && (state !== null || typeof state !== 'number') && record) setStateFunction(record);
  }, [state, record]);

  useEffect(() => {
    if (!state && data) setStateFunction(data);
    if (!state && createNew) setStateFunction(config.initialState);
  }, [state, data, createNew, config.initialState]);

  const dispatch = {};

  dispatch.onClose = (closeType: string): void => {
    setClose();
  };

  dispatch.onClick = (
    clickType: 'download' | 'certificate' | 'refresh' | 'resetPassword' | 'resetMFAToken' | 'customMessageDraft',
    link?: string | null,
    ev?: Object
  ) => {
    switch (clickType) {
      case 'download': {
        if (link !== null) {
          window.open(link);
        } else {
          notify('There is no file attached', 'error');
        }
        break;
      }
      case 'certificate': {
        if (url !== null) window.open(url.replace('chrome-image', 'chrome-pdf'));
        break;
      }
      case 'refresh': {
        setRefresh(true);
        setTimeout(() => setRefresh(false), 3000);
        break;
      }
      case 'resetPassword': {
        dataProvider.update('clients', `resetPassword/${rowId}`, '');
        break;
      }
      case 'resetMFAToken': {
        dataProvider.update('clients', `resetMFA/${rowId}`, '');
        break;
      }
      case 'customMessageDraft': {
        const fData = setFormData(document.getElementById('modalForm'));
        customMessage(fData, paramId, id, apiTab);

        break;
      }
      default: {
        break;
      }
    }
  };

  const customMessage = (d: Object, messType: number | string, clntId: string, messTab: string) => {
    setStateFunction(null);
    const messageApp = apiApp || app;
    const messageTab = apiTab || messTab;
    const messageItem = messageApp === 'clients' ? item || clientId : partnerId;
    const objectName: string = messageApp === 'clients' ? 'client_messages' : 'partner_messages';

    const { welMessHowMany, welMessageValue, first_text, second_text, deferWelcome } = d;

    const hours = welMessageValue === 'days' ? Number(welMessHowMany) * 24 : welMessHowMany;

    const sendData: sendDataType = {
      deferred_sending: messType === 1 || messType === 1001 ? deferWelcome : false,
      first_text,
      second_text,
      hours,
      message_type: messType,
      add_notification_email: email
    };
    if (messType === 1 || messType === 2) {
      sendData.delete_draft = messType;
    }
    dataProvider
      .post(messageApp, `${messageTab}/${String(messageItem)}`, null, sendData)
      .then(response => {
        setStateFunction({ [`${objectName}`]: { ...state[`${objectName}`], ...sendData } });
        notify(`${response.data.description}`);
      })
      .catch(err => {
        if (err.response?.status && err.response.status === 500) {
          notify('Something went wrong', 'error');
        } else if (err.response && err.response.data) {
          notify(` ${err.response.data.description}`, 'error');
        } else {
          setClose();
          notify(`${err.response}`, 'error');
        }
      });
  };

  dispatch.onStart = () => {
    if (isObjectEmpty(initialFields)) {
      setRecord(initialState);
      setInitialFields(initialState);
    }
  };

  dispatch.onChange = (key, value) => {
    setRecord({ ...record, [key]: value });
  };

  dispatch.onCheckValidity = () => {
    const refForm = form.current;
    return refForm.checkValidity();
  };

  dispatch.getRecord = () => record;

  dispatch.onUpdate = () => {
    if (createNew) dataProvider.post(app, tab, null, record);
    else dispatchApp.update(app, tab, null, record);
  };

  dispatch.setId = newId => setId(newId);

  dispatch.setModalState = newState => {
    setStateFunction(newState);
  };

  dispatch.setDownloadUrl = newUrl => {
    setUrl(newUrl);
  };

  dispatch.presignedUpload = file => {
    setLoading(true);
    const formDataType = new FormData();
    dataProvider
      .post(app, `presignedUpload/${rowId}`, null, {
        filename: file.name,
        type: buttons.submit.uploadType
      })
      .then(res => {
        formDataType.append('AWSAccessKeyId', res.data.fields.AWSAccessKeyId);
        formDataType.append('key', res.data.fields.key);
        formDataType.append('policy', res.data.fields.policy);
        formDataType.append('signature', res.data.fields.signature);
        formDataType.append('x-amz-security-token', res.data.fields['x-amz-security-token']);
        formDataType.append('file', file);
        dataProvider.postUrl(res.data.url, formDataType, 'multipart/form-data')
          .then(resPostUrl => console.log({ resPostUrl }));
      })
      .catch(err => console.log(err))
  };

  dispatch.fileChanged = file => {
    changedFile = file;
  };

  function setClose() {
    changedFile = null;
    setOpen(false);
  }

  function updateAttachedFile(res) {
    const file = changedFile;
    const formDataType = new FormData();
    formDataType.append('AWSAccessKeyId', res.data.fields.AWSAccessKeyId);
    formDataType.append('key', res.data.fields.key);
    formDataType.append('policy', res.data.fields.policy);
    formDataType.append('signature', res.data.fields.signature);
    formDataType.append('x-amz-security-token', res.data.fields['x-amz-security-token']);
    formDataType.append('file', file);

    dataProvider.postUrl(res.data.url, formDataType, 'multipart/form-data')
      .then(resPostUrl => {
        dataProvider.update(app, `${tab}/${rowId}`, formData)
          .then(res => {
            notify(
              res.data?.status || res.data?.description || res.description || 'The information was successfully updated'
            );
          })
          .catch(err => {
            if (err.response.data) {
              notify(` ${err.response.data.description}`);
            } else {
              notify(`${err.response.status}`);
            }
          })
          .finally(() => {
            setClose();
            setLoading(false);
          });
      });
  }

  const onSubmit = e => {
    e.preventDefault();
    const refForm = form.current;
    const elem = refForm.elements;
    const elemFormData = {};
    elem.forEach(el => {
      if (hasKey(el, 'value')) {
        elemFormData[el.name] = el.value;
      }
    });

    if (refForm && refForm.reportValidity()) {
      const param = parseInt(paramId, 10);
      formData = setFormData(refForm);
      if (param === 1001 || param === 1002) {
        const mType = param === 1001 ? 1 : 2;
        customMessage(formData, mType, id, apiTab);
        setClose();
        return;
      }
      setLoading(true);

      if (createNew) {
        dataProvider.post(app, tab, id, formData)
          .then(d => {
            if (tab === newTabSource) {
              dataProvider
                .getList(app, `${newItemSource}/${id}`, {
                  filter: { id: d.data.data.id }
                })
                .then(res => hasInternet(() => dispatchApp.set(app, tab, res.data[0], null, true)));
            }

            if (settings?.successfullyCreated) {
              notify(settings.successfullyCreated, 'info');
            } else {
              notify('Successfully Created!', 'info');
            }
          })
          .catch(err => {
            if (err.response.data?.description) {
              notify(` ${err.response.data.description}`, 'error');
            } else if (err.response.data?.status) {
              notify(` ${err.response.data.status}`, 'error');
            } else if (err.response.status) {
              if (err.response.status === 500) {
                notify('Something went wrong', 'error');
              } else {
                notify(`${err.response.status}`, 'error');
              }
            }
          })
          .finally(() => {
            setClose();
            setLoading(false);
          });
      } else {
        if (changedFile) {
          const file = changedFile;

          setLoading(true);
          const formDataType = new FormData();
          return dataProvider
            .post(app, `presignedUpload/${rowId}`, null, {
              filename: file.name,
              type: buttons.submit.uploadType
            })
            .then(res => {
              updateAttachedFile(res);
            })
            .catch(err => console.log(err));
        }

        // NO changedFile
        dataProvider.update(app, `${tab}/${rowId}`, formData)
          .then(res => {
            notify(
              res.data?.status || res.data?.description || res.description || 'The information was successfully updated'
            );
          })
          .catch(err => {
            if (err.response.data) {
              notify(` ${err.response.data.description}`);
            } else {
              notify(`${err.response.status}`);
            }
          })
          .finally(() => {
            setClose();
            setLoading(false);
          });
        }
    }

    if (refForm && !refForm.reportValidity() && invalidFormMessage) {
      if (!elemFormData.name) {
        notify(invalidFormMessage.name, 'error');
      }

      if (!elemFormData.base64) {
        notify(invalidFormMessage.file, 'error');
      }
    }
  };

  const isLoading = ((state === null || typeof state === 'number') && !createNew && !noQuery) || loading;

  const lazyApp = useMemo(() => {
    return <LazyApp
      component={settings.component}
      app={app}
      tab={tab}
      record={state}
      id={rowId || open}
      dispatch={dispatch}
      setLayout={setLayout}
      layout={layout}
      paramId={paramId}
      type={type}
      apiTab={apiTab}
      apiApp={apiApp}
      refresh={refresh}
      setDisabled={setDisabled}
      setClose={setClose}
      saveButton={saveButton}
      {...rest}
    />
  }, [state, refresh]);

  const hasFooter = Object.keys(buttons).length > 0 || layout?.footer?.right || layout?.footer.left;

  return (
    <Dialog
      {...rest}
      open={open}
      onClose={() => setClose()}
      maxWidth={maxWidth}
      scroll="paper"
      fullWidth
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      disableBackdropClick
      disableEscapeKeyDown
      TransitionComponent={Transition}
    >
      <form id="modalForm" ref={form}>
        {title && (
          <DialogTitleStyled id="dialog-title">
            <H3>{modalTitle || title}</H3>
            <IconButton onClick={() => dispatch.onClose('clear')}>
              <LazyIcon component="Close" />
            </IconButton>
          </DialogTitleStyled>
        )}
        <DialogContentStyled dividers>
          {isLoading ? (
            <LoadingStyled />
          ) : (
            <>
              {hasKey(settings, 'component') ? (
                <DialogContentText id="dialog-component">
                  {lazyApp}
                </DialogContentText>
              ) : (
                <>
                  {!noQuery && (
                    <>
                      {!state ? (
                        <LoadingStyled />
                      ) : (
                        <>
                          {state.title && (
                            <>
                              <H3>Name</H3>
                              <Typography.p>{state.title}</Typography.p>
                            </>
                          )}
                          {state.description && (
                            <>
                              <H3>Description</H3>
                              <Typography.p>
                                {/* eslint-disable-next-line react/no-danger */}
                                <pre
                                  dangerouslySetInnerHTML={{ __html: getPreContent(state.description) }}
                                  style={preStyles}
                                />
                              </Typography.p>
                            </>
                          )}
                          {state.content && (
                            <DialogContentText id="dialog-content">
                              <H3>Details</H3>
                              <div style={{ margin: 20 }}>
                                {/* eslint-disable-next-line react/no-danger */}
                                <pre
                                  dangerouslySetInnerHTML={{ __html: getPreContent(state.content) }}
                                  style={preStyles}
                                />
                              </div>
                            </DialogContentText>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </DialogContentStyled>
        {!isLoading && hasFooter && (
          <DialogActionsStyled>
            {layout.footer.left || (
              <AlignLeft>
                {buttons?.other &&
                  buttons.other.map(button => {
                    if ((button.key && row[button.key]) || !button.key) {
                      return (
                        <ButtonDownload
                          color="default"
                          onClick={e => dispatch.onClick(button.type, state ? state[button.key] : null, e)}
                        >
                          {hasKey(button, 'icon') && (
                            <>
                              <LazyIcon
                                style={{ color: `var(--commonWhite)`, fontSize: `var(--fontSize)` }}
                                component={button.icon}
                              />
                              &nbsp;&nbsp;
                            </>
                          )}
                          {button.label}
                        </ButtonDownload>
                      );
                    }
                  })}
              </AlignLeft>
            )}
            {layout.footer.right || (
              <AlignRight>
                {buttons?.cancel && (
                  <ButtonCancel
                    color="default"
                    onClick={() => (buttons.type ? dispatch.onClose(`${buttons.type}`) : dispatch.onClose('clear'))}
                  >
                    {buttons.cancel.label}
                  </ButtonCancel>
                )}
                {buttons?.submit && (
                  <ButtonSubmit type="submit" onClick={onSubmit} disabled={disabled || isLoading} ref={saveButton}>
                    <>
                      {hasKey(buttons.submit, 'icon') && (
                        <>
                          <LazyIcon
                            style={{ color: `var(--commonWhite)`, fontSize: `var(--fontSize)` }}
                            component={buttons.submit.icon}
                          />
                          &nbsp;&nbsp;
                        </>
                      )}
                      {buttons.submit.label}
                    </>
                  </ButtonSubmit>
                )}
              </AlignRight>
            )}
          </DialogActionsStyled>
        )}
      </form>
    </Dialog>
  );
};

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

Modal3.defaultProps = {
  data: null,
  createNew: false,
  paramId: '',
  rowId: '',
  parentId: '',
  rowIndex: 0,
  parentTab: null,
  type: null,
  apiApp: null,
  apiTab: null,
  row: {},
  modalTitle: '',
  paramIdVal: ''
};

export default Modal3;
