// @flow

const otherPolicies = {
  type: 'otherPolicies',
  noQuery: true,
  row: {
    click: true,
    action: 'modal',
    modal: {
      app: 'myDashboard',
      tab: 'otherPolicy'
    }
  },

  cells: [
    { id: 'name', label: 'Name', align: 'left' },
    {
      id: 'description',
      label: 'Description',
      align: 'left',
      removeHtml: true,
      hideOrderBy: true
    },
    {
      id: 'attachment_link',
      label: 'Download',
      labelAlign: 'center',
      align: 'center',
      button: true,
      val: 'attachment_link',
      renderDowloadIcon: true,
      hideOrderBy: true
    },
    {
      id: 'acknowledgement',
      label: 'Ackno',
      labelAlign: 'center',
      align: 'center',
      renderCheckIcon: true,
      hideOrderBy: true
    }
  ]
};

export default otherPolicies;
