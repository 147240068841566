// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Image = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 24 24" role="img" aria-hidden="false">
    <path fillRule="evenodd" clipRule="evenodd" d="M2.76923 0H21.2308C22.7538 0 24 1.24615 24 2.76923V21.2308C24 22.7538 22.7538 24 21.2308 24H2.76923C1.24615 24 0 22.7538 0 21.2308V2.76923C0 1.24615 1.24615 0 2.76923 0ZM21.2308 22.1538C21.7385 22.1538 22.1538 21.7385 22.1538 21.2308V2.76923C22.1538 2.26154 21.7385 1.84615 21.2308 1.84615H2.76923C2.26154 1.84615 1.84615 2.26154 1.84615 2.76923V21.2308C1.84615 21.7385 2.26154 22.1538 2.76923 22.1538H21.2308ZM6.11992 3.46155H17.8799C19.4307 3.46155 20.6768 4.69847 20.6768 6.21232V17.7877C20.6768 18.4615 20.4368 19.0615 20.0215 19.5508C19.523 20.1508 18.7476 20.5385 17.8799 20.5385H6.11992C4.81838 20.5385 3.71992 19.6615 3.4153 18.4708C3.35069 18.2585 3.323 18.0277 3.323 17.7877V6.21232C3.323 4.69847 4.57838 3.46155 6.11992 3.46155ZM15.1845 11.5292L18.8122 15.6092V6.21232C18.8122 5.70462 18.4061 5.28924 17.8799 5.28924H6.11992C5.603 5.28924 5.18761 5.70462 5.18761 6.21232V13.7077L5.89838 12.9046C6.25838 12.5169 6.83992 12.2862 7.43069 12.2954C8.03069 12.2954 8.5753 12.5262 8.91684 12.9139L9.93223 14.0585L12.1845 11.5292C12.5353 11.1508 13.0707 10.92 13.6891 10.92C14.2891 10.92 14.843 11.1508 15.1845 11.5292Z" />
  </SvgIconStyled>
);

export default Image;
