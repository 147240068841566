// @flow
import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import Linkify from 'react-linkify';

const useStyles = makeStyles({
  pre: {
    border: 'none',
    background: 'none',
    padding: 0,
    margin: 0,
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    fontFamily: 'var(--fontFamily)',
    fontSize: '1.6rem',
    lineHeight: '1.5',
    color: 'inherit'
  }
});

const PostBodyText = ({ text }) => {
  const classes = useStyles();
  
  return (
    <Typography>
      <Linkify
        componentDecorator={(decoratedHref, decoratedText, key) => (
          <a target="blank" style={{ wordBreak: 'break-all' }} href={decoratedHref} key={key}>
            {decoratedText}
          </a>
        )}
      >
        <pre className={classes.pre}>{text}</pre>
      </Linkify>
    </Typography>
  );
};

export default PostBodyText;
