/* eslint-disable no-nested-ternary */
// @flow
import React, { type ComponentType } from 'react';
import styled from 'styled-components';
import Base from './Base';

type TypographyTypes = {
  Base: ComponentType<*>,
  h3: ComponentType<*>,
  h4: ComponentType<*>,
  h5: ComponentType<*>,
  h6: ComponentType<*>,
  p: ComponentType<*>,
  errorSpan: ComponentType<*>,
  successSpan: ComponentType<*>
};

const config = {
  fontSize: {
    h3: 1.8,
    h4: 1.7,
    h5: 1.5,
    h6: 1.4,
    p: 1.6,
    errorSpan: 1,
    successSpan: 1
  }
};

const Typography: TypographyTypes = {};

Typography.Base = styled(
  ({
    flex,
    fontSize,
    title,
    color,
    bold,
    p,
    pt,
    pr,
    pb,
    pl,
    m,
    mt,
    mr,
    mb,
    ml,
    b,
    bt,
    br,
    bb,
    bl,
    justify,
    ...props
  }) => <Base {...props} />
).attrs(props => ({
  block: !props.block
}))`
  font-size: ${({ fontSize, component }) => fontSize / 10 || config.fontSize[component]}rem;
  color: ${({ title, color }) => (title ? `var(--colorBox)` : color ? `var(--${color})` : `var(--colorDefault)`)};
  font-weight: ${({ bold }) => (bold ? `600` : `400`)};
  ${({ p }) => p && `padding: calc(${p} * var(--spacing))`};
  ${({ pt }) => pt && `padding-top: calc(${pt} * var(--spacing))`};
  ${({ pr }) => pr && `padding-right: calc(${pr} * var(--spacing))`};
  ${({ pb }) => pb && `padding-bottom: calc(${pb} * var(--spacing))`};
  ${({ pl }) => pl && `padding-left: calc(${pl} * var(--spacing))`};
  text-align: ${({ justify }) => (justify ? `justify` : `left`)};

  ${({ m }) => m && `margin: calc(${m} * var(--spacing))`};
  ${({ mt }) => mt && `margin-top: calc(${mt} * var(--spacing))`};
  ${({ mr }) => mr && `margin-right: calc(${mr} * var(--spacing))`};
  ${({ mb }) => mb && `margin-bottom: calc(${mb} * var(--spacing))`};
  ${({ ml }) => ml && `margin-left: calc(${ml} * var(--spacing))`};

  ${({ b }) => b && `border: ${b}px solid var(--colorGreyLight3)`};
  ${({ bt }) => bt && `border-top: ${bt}px solid var(--colorGreyLight3)`};
  ${({ br }) => br && `border-right: ${br}px solid var(--colorGreyLight3)`};
  ${({ bb }) => bb && `border-bottom: ${bb}px solid var(--colorGreyLight3)`};
  ${({ bl }) => bl && `border-left: ${bl}px solid var(--colorGreyLight3)`};
`;

Typography.h3 = styled(Typography.Base).attrs({ component: 'h3' })`
  /* font-family: 'Lato', sans-serif; */
  font-weight: 600;
  line-height: 1.04;
`;
Typography.h4 = styled(Typography.Base).attrs({ component: 'h4' })`
  /* font-family: 'Lato', sans-serif; */
  font-weight: 600;
  line-height: 1.04;
`;
Typography.h5 = styled(Typography.Base).attrs({ component: 'h5' })`
  /* font-family: 'Lato', sans-serif; */
  font-weight: 600;
  line-height: 1.04;
`;
Typography.h6 = styled(Typography.Base).attrs({ component: 'h6' })`
  /* font-family: 'Lato', sans-serif; */
  font-weight: 600;
  line-height: 1.04;
`;

Typography.p = styled(Typography.Base).attrs({ component: 'p' })`
  line-height: 1.5;
`;
Typography.errorSpan = styled(Typography.Base).attrs({ component: 'span' })`
  line-height: 1.5;
  color: red;
  display: inline-block !important;
`;
Typography.successSpan = styled(Typography.Base).attrs({ component: 'span' })`
  line-height: 1.5;
  color: #0cce6b;
  display: inline-block !important;
`;

export default Typography;
