// @flow
import React, { type Element, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useConfig, useTableRowSelected } from 'hooks';
import { useMediaQuery } from '@material-ui/core';
import { hasKey } from 'helpers';
import { TableHead, TableToolbar } from '.';
import {
  PaperContent,
  TableResponsive,
  PaginationTable,
  TableContent,
  TableBodyContent,
  TableRowContent,
  TableCellContent
} from './ComponentTypes';
import { useTableList } from './TableListContext';
import Modal3 from '../modal/Modal3';
import { TableListNoResults, TableListSelecting, TableListCell } from './containers';
import CustomTablePagination from '../table/pagination/CustomTablePagination';

const stableSort = (array, orderBy, order) => {
  if (!Array.isArray(array)) return [];
  array.sort(sortingUtil(orderBy, order === 'asc'));
  return array;
};
function sortingUtil(orderBy, ascending) {
  return (a, b) => {
    let first = a[orderBy];
    let second = b[orderBy];
    if (typeof first === 'string') {
      first = first.toLowerCase();
    }
    if (typeof second === 'string') {
      second = second.toLowerCase();
    }
    if (first && typeof first === 'object' && hasKey(first, 'label')) {
      first = first.label;
    }
    if (second && typeof second === 'object' && hasKey(second, 'label')) {
      second = second.label;
    }
    // equal items sort equally
    if (first === second) {
      return 0;
    }
    // nulls sort after anything else
    if (first === null) {
      return 1;
    }
    if (second === null) {
      return -1;
    }
    // otherwise, if we're ascending, lowest sorts first
    if (ascending) {
      return first < second ? -1 : 1;
    }
    // if descending, highest sorts first
    return first < second ? 1 : -1;
  };
}

type TableListTableType = {
  tableSettings?: Object,
  data?: Object,
  source?: string,
  id?: string,
  onSearch?: Function,
  cache?: boolean
};

const TableListTable = ({
  tableSettings,
  data,
  source,
  id,
  onSearch,
  cache = true
}: TableListTableType): Element<*> => {
  const {
    tab,
    settings,
    record,
    order,
    orderBy,
    selected,
    page,
    emptyRows,
    open,
    setOpenModal, // setOpen from useModalConfig.js
    openModal,
    dispatch: {
      handleRequestSort,
      handleSelectAllClick,
      handleChangeRowsPerPage,
      setNewPage,
      setOpen,
      setPassword,
      isSelected
    },
    toolbar,
    tableActions,
    total,
    status,
    isFetching,
    modalTitle,
    rowsPerPage
  } = useTableList();

  const dispatchRx = useDispatch();

  useEffect(() => {
    if (settings?.row?.action === 'newModal' && open && !openModal) setOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  const hiddenCell = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const { pagination } = useConfig('system', 'table');
  const system = useSelector(rxState => rxState.bsn.system);
  const { tableRowSelected } = useTableRowSelected();
  if (!settings) return <h1>API does not exist</h1>;
  const { total: sTotal } = settings;
  const tableList = stableSort(record, orderBy, order);

  return (
    <PaperContent>
      <TableToolbar setToolbar={toolbar} tableActions={tableActions} />
      <TableResponsive>
        <TableContent aria-labelledby="tableTitle" size="medium">
          <TableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={record.length}
            tableSettings={settings}
            onSearch={onSearch}
            isFilter={false}
            showPassword={setPassword}
            record={tableList[0]}
          />
          <TableBodyContent isFetching={isFetching}>
            <TableListNoResults hasRecords={tableList.length === 0} fetched={status === 'success'} />
            {tableList.map((row, index) => {
              const rowId = String(row.id);
              let isItemSelected = isSelected(row.id) || tableRowSelected.filter(t => String(t) === rowId)[0] === rowId;
              if (tableRowSelected[0] === 'all') {
                isItemSelected = !system.tableRowUnselected.includes(rowId);
              }
              const isOpen = open === row.id;

              if (isOpen && settings.row.action === 'newModal') {
                const app = settings?.row?.modal?.app;
                const payload = { [tab]: { ...GetConfig(app, tab) } };

                dispatchRx({
                  type: 'BSN_SET_CONFIG_MODAL',
                  resource: 'modals',
                  tab: app,
                  payload
                });

                if (open && openModal) {
                  setOpen(false);
                } else {
                  setOpenModal(true, row.id);
                }
              }

              return (
                <>
                  <TableRowContent
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                  >
                    {!hiddenCell && <TableCellContent />}
                    <TableListSelecting row={row} index={index} isItemSelected={isItemSelected} />
                    <TableListCell isOpen={isOpen} index={index} row={row} modalData={settings?.row?.modal} />
                    {!hiddenCell && <TableCellContent />}
                  </TableRowContent>
                </>
              );
            })}
            {emptyRows > 0 && (
              <TableRowContent>
                <TableCellContent colSpan={10} />
              </TableRowContent>
            )}
          </TableBodyContent>
        </TableContent>
      </TableResponsive>
      {/* {modal && open && (
        <Modal3 {...modal} open={open === row.id} setOpen={setOpen} rowId={rowId} handleModal={handleModal} />
      )} */}

      {tableList.length !== 0 && (
        <CustomTablePagination
          {...pagination}
          totalCount={total || sTotal}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={setNewPage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          isFetching={isFetching}
        />
      )}
    </PaperContent>
  );
};

TableListTable.defaultProps = {
  data: null,
  source: '',
  id: '',
  tableSettings: null,
  onSearch: e => e,
  cache: true
};

function GetConfig(app, tab) {
  return useConfig('modals', app, tab);
}

export default TableListTable;
