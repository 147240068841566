/* eslint-disable no-nested-ternary */
// @flow
import React, { memo, useState, type ComponentType } from 'react';
import styled from 'styled-components';
import { useNotify } from 'react-admin';
import { Typography, Tooltip, MenuItem, MenuList, Popper, Paper, Grow, ClickAwayListener } from '@material-ui/core';
import { SearchField, Button, Modal3, Dialog, CustomTooltip } from 'components';
import { dataProvider, getStorage } from 'helpers';
import { LazyIcon } from '../icons';
import { ActionButton, ToolbarContent, TableCellAlign, TableCellAlignRight } from './ComponentTypes';
import { useTableList } from './TableListContext';

const fontSize = 14;

type TableToolbarTypes = Object;

const ContainerGrow: ComponentType<*> = styled(Popper)`
  && {
    z-index: 1;
    top: inherit !important;
    left: inherit !important;
    right: 0px !important;
    transform: inherit !important;
  }
`;

const TableToolbar = ({ setToolbar, tableActions }: TableToolbarTypes) => {
  const notify = useNotify();
  const {
    app,
    tab,
    item,
    open,
    openDialog,
    settings: { selecting, search, searchFields, searchLabel, actions, button },
    selected,
    dispatch: { setOpen, setOpenDialog },
    dispatch: dispatchTable,
    disableTableAction,
    additionalRefetch,
    disabeldActions
  } = useTableList();
  const numSelected = selected.length;
  const [anchorEl, setAnchorEl] = useState(null);
  let newActions = actions;

  if (tableActions) {
    const actionsKeys = Object.keys(tableActions);
    const newKeys = actionsKeys.filter(row => tableActions[row] === true);
    newActions = actions.filter(row => {
      const filteredAction = newKeys.filter(key => key === row.name);

      return row.name === filteredAction[0];
    });
  }

  if (!search && !setToolbar && !button && !newActions) return null;

  const isActions = newActions && Array.isArray(newActions) && newActions.length > 0;

  const isCurrentUser = getStorage("userId")

  const onClick = method => {
    setOpenDialog(false);
    if (app === `clients` && tab === `usersList` && method === `deleteUsers`) {
      dataProvider
        .delete(app, `usersListActions/${String(item)}`, null, { ids: selected })
        .then(res => {
          dispatchTable.setSelected([]);
          notify(`${res.data?.description}`, 'warning');
        })
        .catch(error => notify(`${error.response.status}`, 'warning'))
        .finally(() => dispatchTable.setOpen(false));
    } else if (app === `clients` && tab === `usersList` && method !== `deleteUsers`) {
      dataProvider
        .post(app, `usersListActions/${String(item)}`, null, { type: method, ids: selected })
        .then(res => {
          if (method === 'delete') dispatchTable.setSelected([]);
          notify(`${res.data?.description}`);
        })
        .catch(error => {
          if (error?.response?.status) notify(`${error.response.status}`, 'warning');
        })
        .finally(() => dispatchTable.setOpen(false));
    } else if (app === `clients` && tab === 'tags' && method === 'delete') {
      dataProvider
        .delete(app, `${String(tab)}/${String(item)}`, null, { id: selected })
        .then(res => {
          notify(`${res.data}`, 'warning');
          dispatchTable.setSelected([]);
        })
        .catch(error =>
          notify(
            error.response?.data?.message ? `${error.response.data.message}` : `${error.response.data.description}`,
            'warning'
          )
        )
        .finally(() => dispatchTable.setOpen(false));
    } else if (app === 'clients' && tab === 'phishingReportsRecipients' && method === 'resetPhishingItem') {
      dispatchTable[method](selected);
      if (additionalRefetch) additionalRefetch();
    } else {
      dispatchTable[method]();
    }
  };

  return (
    <>
      <ToolbarContent variant="dense">
        <TableCellAlign>
          {search && (
            <SearchField label={searchLabel} fullWidth location={app} tab={tab} id={item} fields={searchFields} />
          )}
          {setToolbar}

          {button && button.show && (
            <>
              <Button ml={2} onClick={() => dispatchTable[button.onClick](true)}>
                {button.label}
              </Button>
              <Modal3 open={open} setOpen={setOpen} app="clients" tab={tab} createNew />
            </>
          )}
        </TableCellAlign>
        <TableCellAlignRight>
          {selecting && numSelected > 0 ? (
            <Typography color="inherit" variant="subtitle1">
              <small>{numSelected} selected&nbsp;&nbsp;</small>
            </Typography>
          ) : (
            ''
          )}
          {isActions ? (
            newActions.length > 3 ? (
              <div>
                <Button
                  aria-owns={anchorEl ? 'simple-menu-action' : undefined}
                  aria-haspopup="true"
                  onClick={e => setAnchorEl(e.currentTarget)}
                >
                  <LazyIcon component="Plus" color="colorCommonWhite" mr={1} size={0.7} />
                  Actions
                </Button>
                <ContainerGrow open={Boolean(anchorEl)} anchorEl={anchorEl} role={undefined} transition disablePortal>
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                          <MenuList autoFocusItem={Boolean(anchorEl)} id="simple-menu-action">
                            {newActions.map(({ activateOnSelect = true, ...a }) => (
                              <MenuItem
                                disabled={
                                  (activateOnSelect && selected.length === 0)
                                  || disabeldActions.includes(a.name)
                                  || ((selected.length === 1 && selected.includes(isCurrentUser)) && (a.name === "inactive" || a.name === "delete" )) }
                                onClick={(e) => {
                                  if (!e.key || (e.key && e.key !== 'Enter')) {
                                    if (a.method === 'delete') {
                                      setOpenDialog(true);
                                    } else {
                                      dispatchTable.setSelected([]);
                                      onClick(a.method);
                                    }
                                    setAnchorEl(null);
                                  }
                                }}
                              >
                                <LazyIcon
                                  component={a.icon}
                                  color="colorDefault"
                                  strokeColor={a.icon === 'Enable' && 'colorDefault'}
                                  ml={a.icon !== 'WelcomeMessage' && 0.6}
                                  mr={1}
                                  size={a.icon === 'WelcomeMessage' ? 1.5 : 1}
                                />
                                {a.title}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </ContainerGrow>
              </div>
            ) : (
              newActions.map(({ activateOnSelect = true, ...a }) => {
                return (
                  <>
                    <CustomTooltip title={a.title}>
                      <ActionButton
                        aria-label={a.title}
                        disabled={(activateOnSelect && selected.length === 0) || disableTableAction}
                        onClick={() => {
                          if (a.method === 'delete') {
                            setOpenDialog(true);
                          } else {
                            dispatchTable.setSelected([]);
                            onClick(a.method);
                          }
                        }}
                      >
                        <LazyIcon
                          component={a.icon}
                          fontSize="small"
                          style={{ color: 'var(--iconDefaultcolor)', fontSize }}
                        />
                      </ActionButton>
                    </CustomTooltip>
                  </>
                );
              })
            )
          ) : (
            ''
          )}
          {/* {selecting && numSelected > 0 ? (
            <div>
              {filter && (
                <Tooltip title="Filter">
                  <IconButton aria-label="filter" onClick={onFilter(!filter)}>
                    <FilterListIcon fontSize="medium" style={{ fontSize }} />
                  </IconButton>
                </Tooltip>
              )}
              {reset ? (
                <Tooltip title="Reset">
                  <IconButton aria-label="reset">
                    <ReplayIcon fontSize="small" style={{ fontSize }} />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Delete">
                  <IconButton aria-label="delete">
                    <DeleteIcon fontSize="small" style={{ fontSize }} />
                  </IconButton>
                </Tooltip>
              )}
              {type === 'clients' && (
                <>
                  <Tooltip title="Active">
                    <IconButton aria-label="active">
                      <DoneIcon fontSize="small" style={{ fontSize }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="lock">
                    <IconButton aria-label="lock">
                      <LockIcon fontSize="small" style={{ fontSize }} />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </div>
          ) : (
            <div>
              {filter && (
                <Tooltip title="Filter">
                  <IconButton aria-label="filter" onClick={onFilter(!filter)}>
                    <FilterListIcon fontSize="small" style={{ fontSize }} />
                  </IconButton>
                </Tooltip>
              )}
              {reset ? (
                <Tooltip title="Reset">
                  <IconButton aria-label="reset">
                    <ReplayIcon fontSize="small" style={{ color: '#ff00007a', fontSize }} />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Delete">
                  <IconButton aria-label="delete">
                    <DeleteIcon fontSize="small" style={{ fontSize }} />
                  </IconButton>
                </Tooltip>
              )}
              {type === 'clients' && (
                <>
                  <IconButton aria-label="active" disabled>
                    <DoneIcon fontSize="small" style={{ fontSize }} />
                  </IconButton>
                  <IconButton aria-label="lock" disabled>
                    <LockIcon fontSize="small" style={{ fontSize }} />
                  </IconButton>
                </>
              )}
            </div>
          )} */}
        </TableCellAlignRight>
      </ToolbarContent>
      <Dialog
        title="Delete"
        message="This information will be deleted. Please confirm if you would like to delete?"
        buttonSubmit="Yes, I want to delete"
        open={openDialog || false}
        setOpen={setOpenDialog}
        maxWidth="xs"
        onSubmit={() => {
          onClick('delete');
        }}
      />
    </>
  );
};

export default memo<TableToolbarTypes>(TableToolbar);
