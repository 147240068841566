// @flow

import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useTabDefault from './useTabDefault';
import useTabsAllowed from './useTabsAllowed';
import useLocation from './useLocation';

type useTabsLayoutTypes = {
  app: string,
  tab?: string | false,
  override?: Object,
  accessMerger?: Object
};

function useTabsLayout({ app, tab: tabProps, override, accessMerger = {} }: useTabsLayoutTypes) {
  const { tab: tabLoc, item } = useLocation();
  const { push } = useHistory();
  const tab = tabProps === tabLoc ? tabProps : tabLoc;
  const previous = useSelector(rxState => rxState.bsn.system.tabPrevious);
  const Layout = () => useSelector(state => state.bsn.tabs[app]);
  const layout = override || Layout();
  const allowed = useTabsAllowed({ layout, app, accessMerger });
  const allowedArray = Object.keys(allowed);
  const tabDefault = useTabDefault({ tab: String(tab), layout, layoutOverride: override, accessMerger });
  const active: string = allowedArray.includes(tabDefault) ? tabDefault || tabLoc : allowedArray[0] || tabLoc;
  const settings = Object.keys(layout).includes(active) ? layout[active] : layout[tab];

  useEffect(() => {
    if (sessionStorage.getItem('microtraining_id'))
      push(`/myDashboard/microTrainings`);
    else if (item && tabLoc !== active) {
      push(`/${app}/${active}/${item}`);
    } else if (!item) {
      push(`/${app}/${active}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, tabLoc, item]);

  return { layout: allowed, active, previous, settings };
}

export default useTabsLayout;
