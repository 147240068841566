/* eslint-disable no-console */
// @flow
import React from 'react';
import Loadable from 'react-loadable';
import { LoadingStyled, PageError } from 'components';

export { default as clients } from './clients';
export { default as newsfeed } from './newsfeed';
export { default as dashboard } from './dashboard';
export { default as company } from './company';
export { default as partner } from './partner';
export { default as phishing } from './phishing';
export { default as user } from './user';
export * from './user';
export * from './newsfeed';

export const LazyApp = ({ component, ...rest }: Object) => {
  const Component = component
    .split('/')
    .map((x, i) => (i !== 0 || x === '..' ? x : `./${x.replace('my', '').toLowerCase()}`))
    .join('/');

  const LoadableTab = Loadable<any>({
    loader: () => import(`${Component}`),
    loading: (props: { error: any }) => {
      if (props.error) {
        console.group(`%cEROR: LAZY APP`, 'font-size: medium; color: red');
        console.log(props.error);
        console.groupEnd();
        return (
          <PageError
            title='Oops!'
            subtitile='There was a problem with the page?'
            description='Try and refresh the page, if the problem still exists then Contact Us'
            linkText='Contact Us'
            linkTo='/user/contactUs'
          />
        );
      }
      return <LoadingStyled />;
    },
    timeout: 10000
  });

  return <LoadableTab {...rest} />;
};
