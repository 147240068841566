// @flow
import React, { memo, useEffect } from 'react';
import { TableBody, TableCell, Checkbox, useMediaQuery } from '@material-ui/core';
import { ScrollLeft as ScrollLeftIcon, ScrollRight as ScrollRightIcon } from 'components/icons';
import { LazyIcon, Typography } from 'components';
import { hasKey } from 'helpers';
import { useTableRowSelected, useTableFilter } from 'hooks';
import FilterTable from './FilterTable';
import ShowingHeadCell from './ShowingHeadCell';
import { useTableList2 } from './TableList2Context';
import {
  TableHeadContent,
  TableRowContent,
  TableSort,
  TableHeadIconMobile,
  TableHeadContentSubLabel
} from './ComponentTypes';


type TableHeadTypes = {
  onRequestSort: Function,
  order: 'asc' | 'desc',
  orderBy: string,
  tableSettings: Object,
  isFilter: boolean,
  onSearch: Function,
  showPassword: Function
};

const TableHead = ({
  order,
  orderBy,
  onRequestSort,
  tableSettings,
  isFilter,
  onSearch,
  showPassword,
  handleSelectAllRows
}: TableHeadTypes) => {
  const { app, tab, record } = useTableList2();
  const { tableRowSelected, isSelected, setSelectAll, clearSelect } = useTableRowSelected();
  const { hasFilters } = useTableFilter();
  const checked = isSelected('all') || tableRowSelected.length === record.length;
  const indeterminated = tableRowSelected.length > 0 && tableRowSelected.length !== record.length && !isSelected('all');
  const { cells, selecting, folding } = tableSettings;
  const hiddenCell = useMediaQuery(theme => theme.breakpoints.up('sm'));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => clearSelect(), []);

  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  const onChange = ({ target }) => {
    if (!target.checked) return clearSelect();
    // let selects = ['all'];
    // if (app === 'clients' && tab === 'usersList') selects = record.map(t => String(t.id));
    // if (hasFilters && tableRowSelected[0] !== 'all') selects = record.map(t => String(t.id));
    // return setSelectAll(selects);
    if (handleSelectAllRows) return handleSelectAllRows();
    return setSelectAll(record.map(t => String(t.id)));
  };

  const isRadio = hasKey(tableSettings, 'selectingRadio') && tableSettings.selectingRadio;

  return (
    <>
      <TableHeadContent>
        <TableRowContent>
          {!hiddenCell && (
            <TableHeadIconMobile padding="checkbox">
              <ScrollLeftIcon size={1.7} ml={2} mr={2} />
            </TableHeadIconMobile>
          )}
          {selecting && (
            <TableCell align="left" padding="checkbox" id="fistElement">
              {!isRadio && (
                <Checkbox
                  indeterminate={indeterminated}
                  checked={checked}
                  onChange={onChange}
                  inputProps={{ 'aria-label': 'select all' }}
                />
              )}
            </TableCell>
          )}
          {folding && <TableCell align="left" padding="checkbox" />}
          {cells.map(headCell => (
            <TableCell
              align={headCell.labelAlign || 'left'}
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSort
                active={orderBy === headCell.id}
                direction={order}
                onClick={createSortHandler(headCell.ghostId || headCell.id)}
                disabled={headCell.hideOrderBy}
                title={headCell?.title}
                labelPaddingLeft={headCell.labelPaddingLeft}
              >
                {headCell.renderIconLabel ? (
                  <LazyIcon component={headCell.renderIconLabel} size={1.5} ml={1.5} color="colorDefault" />
                ) : (
                  headCell.label
                )}
                {orderBy === headCell.id ? <span>{order === 'desc' ? '' : ''}</span> : null}
              </TableSort>
              <br />
              {headCell.subLabel && (
                <TableHeadContentSubLabel>
                  {headCell.subLabel.map(label => (
                    <Typography.p fontSize={12} mt={0.1} mb={0.1}>
                      {label}
                    </Typography.p>
                  ))}
                </TableHeadContentSubLabel>
              )}
              {headCell.showing && (
                <ShowingHeadCell
                  optionDefault={headCell.defaultShowingValue}
                  options={headCell.showingValue}
                  showPassword={showPassword}
                />
              )}
            </TableCell>
          ))}
          {!hiddenCell && (
            <TableHeadIconMobile padding="checkbox">
              <ScrollRightIcon size={1.7} ml={2} mr={2} />
            </TableHeadIconMobile>
          )}

        </TableRowContent>
      </TableHeadContent>
      {isFilter && (
        <TableBody>
          <TableRowContent>
            {selecting && <TableCell align="left" />}
            {folding && <TableCell align="left" />}
            {cells.map(cell => (
              <TableCell align="left" id="lastElement">
                <FilterTable dataFilter={cell.filter} onFilter={onSearch} onSearch={onSearch} />
              </TableCell>
            ))}
          </TableRowContent>
        </TableBody>
      )}
    </>
  );
};

export default memo<TableHeadTypes>(TableHead);
