// @flow
import React, { type ComponentType, useState } from 'react';
import styled, { css } from 'styled-components';
import { useNotify } from 'react-admin';
import {
  VisibilityOutlined as VisibilityOutlinedIcon,
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  Edit as EditIcon,
} from '@material-ui/icons';
import { LazyIcon } from 'components/icons';
import { Chip, Link, Tooltip } from '@material-ui/core';
import { dateTimeZone, hasKey, strClean, dataProvider } from 'helpers';
import reporting from '../../helpers/apis/reporting';
import TableListAction from './TableListAction';
import EditingCell from './EditingCell';
import UpdatingCell from './UpdatingCell';
import { BadgeIcon, ButtonTag, ActionButton } from './ComponentTypes';
import { ButtonLink } from '../../apps/dashboard/ComponentTypes';
import { DateTime } from '../text';
import useHasInternet from '../../hooks/useHasInternet';
import {Container} from "components"
import CustomPopover from '../modal/CustomPopover';

const ContainerPolices: ComponentType<*> = styled.div`
  width: 65%;
  display: flex;
  justify-content: space-between;

  & button {
    padding: 0;
    min-width: inherit;

    & > span {
      width: inherit;
    }
  }
`;

const Email: ComponentType<*> = styled.p`
  word-break: break-word;
  min-width: 170px;
  max-width: 300px;
  margin: 0;
`;

const ChipStatus = styled(Chip)`
  && {
    height: auto;
    border-color: ${({color}) => `var(${color})` };
    background-color: ${({variant, color}) => variant === "filled" ? `var(${color})` : "#fff" };
    span {
      padding: 2px 13px 2px 13px;
      font-size: 14px;
      ${({color, variant}) => color && css`
        color: ${variant === "outlined" ? `var(${color})` : "#fff"}
      `}
    }
  }
`

const typeCell = (value, statePass, { button = false, buttonLabel = '', id }) => {
  if (typeof value === 'object' && value !== null) {
    if (typeof value.$$typeof === 'symbol') return value;
    return value[statePass];
  }

  if (value === 'yellow' || value === 'red' || value === 'green') return <BadgeIcon color={value} />;

  if (button) {
    return (
      <ButtonLink size="small" href={value}>
        <VisibilityOutlinedIcon />
        &nbsp;&nbsp; {buttonLabel}
      </ButtonLink>
    );
  }

  if(id && id === 'email') return <Email>{value}</Email>;

  return value;
};

type TypesCellTypes = {
  cell: any,
  rowId: string,
  row: Object,
  paramIdForModal: string,
  hideIcon: boolean,
  statePass: any,
  config: {
    button?: boolean,
    buttonLabel?: string,
    editing: boolean,
    filter: boolean,
    id: string,
    label?: string,
    iconLabel?: string,
    renderCheckIcon?: boolean,
    renderViewIcon?: boolean,
    renderActiveIcon?: boolean,
    renderPrintIcon?: boolean,
    renderEmailIcon?: boolean,
    renderEditTag?: boolean,
    isDate?: boolean,
    updating?: {
      app?: string,
      tab?: string,
      field?: string
    },
    slice?: number,
    accessLevel?: boolean,
    tag?: boolean,
    policesTooltip?: string,
    action?: {
      icon?: string | null,
      label?: string | null
    }
  }
};

const TypesCell = ({ cell, paramIdForModal, row, hideIcon, statePass, config, rowId }: TypesCellTypes) => {
  const notify = useNotify();
  const hasInternet = useHasInternet();

  if (config?.type === 'date') {
    return <DateTime isUTC={config?.isUTC} date={cell[0]} />;
  }

  if (hasKey(config, 'isDate')) return dateTimeZone(cell[0]);

  if (hasKey(config, 'action'))
    return <TableListAction hideIcon={hideIcon} paramId={paramIdForModal} {...config.action} rowId={rowId} />;

  const [val] = cell;
  if (hasKey(config, 'renderCheckIcon')) return val ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />;
  if (hasKey(config, 'renderViewIcon') && hasKey(config, 'renderEditIcon')) {
    return (
      <>
        <Link href={val} target="_blank" rel="noreferrer">
          <VisibilityOutlinedIcon />
        </Link>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Link href={val} target="_blank" rel="noreferrer">
          <EditIcon />
        </Link>
      </>
    );
  }
  if (hasKey(config, 'removeHtml')) return cell[0] && strClean(cell[0]);
  if (hasKey(config, 'renderViewIcon')) {
    return (
      <Link href={val} target="_blank" rel="noreferrer">
        <VisibilityOutlinedIcon />
        &nbsp;&nbsp; {config.iconLabel}
      </Link>
    );
  }
  if (hasKey(config, 'renderEditIcon')) {
    return (
      <Link href={val} target="_blank" rel="noreferrer">
        <EditIcon />
        &nbsp;&nbsp; {config.iconLabel}
      </Link>
    );
  }
  if (hasKey(config, 'renderDowloadIcon')) {
    if (val) {
      return (
        <Link href={val} target="_blank" rel="noreferrer" onClick={e => e.stopPropagation()}>
          <LazyIcon color="buttonPrimaryBackground" size="fontSize" component="Download" />
          {config.iconLabel && <>&nbsp;&nbsp;{config.iconLabel}</>}
        </Link>
      );
    }
    return <div />;
  }
  if (hasKey(config, 'printWorkPlanReport')) {
    const callWorkPlanReport = () =>
      reporting
        .getReport({
          report_key: 'WorkPlan',
          recipe: 'chrome-pdf',
          parameters: { workplan_id: row.id },
          delivery: 'url'
        })
        .then(res => window.open(res.data.url))
        .catch(error => notify(`${error.response}`, error));
    return (
      <ActionButton
        onClick={e => {
          e.stopPropagation();
          callWorkPlanReport();
        }}
      >
        <LazyIcon color="buttonPrimaryBackground" size="fontSize" component="Print" />
        {config.iconLabel && <>&nbsp;&nbsp;{config.iconLabel}</>}
      </ActionButton>
    );
  }
  if (hasKey(config, 'printerCampaignReport')) {
    const handleCampaignReport = e => {
      e.stopPropagation();
      dataProvider.getOne('clients', `phishingCampaignReport/${rowId}`, {}).then(res => {
        hasInternet(() => {
          const a = document.createElement('a');
          a.style.display = 'none';
          document.body.appendChild(a);
          a.href = res.data.url;
          a.setAttribute('download', 'CampaignReport.pdf');
          a.click();
          window.URL.revokeObjectURL(a.href);
          document.body.removeChild(a);
        })
      });
    };

    return (
      <>
        {val !== null && (
          <ActionButton onClick={e => config.id === 'filename' && handleCampaignReport(e)}>
            <LazyIcon color="ActionButtonPrimaryBackground" size="fontSize" color="colorBaseDark2" component="Print" />
            {config.iconLabel && <>&nbsp;&nbsp;{config.iconLabel}</>}
          </ActionButton>
        )}
      </>
    );
  }

  if (hasKey(config, 'slice')) {
    if (cell[0] !== null && cell[0].length > config.slice) return `${cell[0].slice(0, config.slice)}...`;
    return cell[0];
  }

  if (hasKey(config, 'tag') && cell[0]) {
    if (typeof cell[0] === 'object') {
      return (
        <Tooltip title={cell[0].tolltip} arrow>
          <ButtonLink>{cell[0].label}</ButtonLink>
        </Tooltip>
      );
    }
    return (
      <Tooltip title={cell[0]} arrow>
        <ButtonTag>{cell[0].substr(0, 10)}</ButtonTag>
      </Tooltip>
    );
  }

  if (hasKey(config, 'policesTooltip')) {
    return (
      <ContainerPolices>
        <div>
          {cell[0].acknowledged_policies_at && (
            <Tooltip title="Policies" arrow>
              <ButtonLink>
                <LazyIcon component="Insurance" color="colorDefault" />
              </ButtonLink>
            </Tooltip>
          )}
        </div>
        <div>
          {cell[0].acknowledged_other_policies_at && (
            <Tooltip title="Other Policies" arrow>
              <ButtonLink>
                <LazyIcon component="Insurance" color="colorDefault" />
              </ButtonLink>
            </Tooltip>
          )}
        </div>
      </ContainerPolices>
    );
  }

  if (hasKey(config, 'accessLevel')) {
    const c = cell[0];
    const accessOrder: {
      order1: boolean,
      order2: boolean,
      order3: boolean,
      order4: boolean,
      order5: boolean
    } = {
      order1: c.marketing_material,
      order2: c.purchasing,
      order3: c.billing,
      order4: c.tax,
      order5: c.dir_sync, 
    };

    return Object.entries(accessOrder).map(icon => {
      if (typeof icon[1] === 'boolean') {
        if (icon[1])
          return (
            <LazyIcon
              component="Enable"
              color="colorSystemSuccess"
              strokeColor="colorSystemSuccess"
              ml={1.1}
              mr={1.1}
            />
          );
        return <LazyIcon component="Disable" color="colorDefault" ml={1.1} mr={1.1} />;
      }
    });
  }

  if (hasKey(config, 'renderActiveIcon')) {
    if (cell[0])
      return (
        <LazyIcon component="Enable" color="colorSystemSuccess" strokeColor="colorSystemSuccess" ml={1.5} mr={1.5} />
      );
    return <LazyIcon component="Disable" color="colorDefault" ml={1.5} mr={1.5} />;
  }

  if(hasKey(config, "renderStatusIcon")) {
    if (cell[0].name === "COMPLETE") {
      return (
        <ChipStatus label={"Valid"} variant="outlined" color="--colorSystemSuccess" size="small" />
      );
    } else if (cell[0].name === "INVALID") {
      return (
        <ChipStatus label={"Invalid"} variant="outlined" color="--colorSystemDanger" size="small" />
      )
    } else if (cell[0].name === "EXPIRED"){
      return (
        <ChipStatus label={"Expired"} variant="filled" color="--colorSystemDanger" size="small" />
      );
    } else {
      return (
        <ChipStatus label={"Pending"} variant="outlined" color="--colorSystemWarning" size="small" />
      )
    }
  }

  if (hasKey(config, 'renderEmailIcon')) {
    if (cell[0]) return <LazyIcon color="colorSystemDanger" component="WelcomeMessage" size={2} ml={2.5} />;
  }

  if (hasKey(config, 'updating')) {
    return <UpdatingCell cellId={rowId} cellValue={cell[0]} cellConfig={config.updating} />;
  }

  return (
    <>
      {cell[1] ? (
        <EditingCell cellValue={cell[0]} cellEditing={cell[2][0]} cellEditType={cell[1][1]} />
      ) : (
        typeCell(cell[0] ?? '', statePass, config)
      )}
    </>
  );
};

export default TypesCell;
