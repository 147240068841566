// @flow
import React, { type Element, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useNotify } from 'react-admin';
import MuiPhoneNumber from 'material-ui-phone-number';
import { Container, TextField, Button, LoadingStyled } from 'components';
import { useApp, dataProvider } from 'helpers';
import { useDataGet, useId } from 'hooks';
import { ButtonCancel } from './ComponentTypes';


const UserContactUs: Function = (): Element<*> => {
  const notify = useNotify();
  const { push } = useHistory();
  const { dispatch } = useApp();
  const item = useId({ key: 'userId' });
  const clientId = useId({ key: 'clientId' });
  const { data: profile, isSuccess: isSuccessProfile } = useDataGet({ app: 'user', tab: 'profile', item });
  const { data: company, isSuccess: isSuccessCompany } = useDataGet({ app: 'clients', tab: 'company', clientId });
  const [data, setData] = useState({
    id: item,
    first_name: undefined,
    last_name: undefined,
    email: undefined,
    phone: undefined,
    company: undefined,
    subject: '',
    feedback: ''
  });

  useEffect(() => {
    if (isSuccessProfile && !data?.email) {
      setData({
        ...data,
        first_name: profile.firstName,
        last_name: profile.lastName,
        email: profile.email,
        phone: profile.phoneNumber
      });
    }
  }, [isSuccessProfile, profile, data]);

  useEffect(() => {
    if (isSuccessCompany && !data?.company) {
      setData({
        ...data,
        company: company.name
      });
    }
  }, [isSuccessCompany, company, data]);

  const handleChangePhones = (value, name) => {
    onChange(name, value);
  };

  const onChange = (name, value) => {
    setData({ ...data, [name]: value });
  };

  const handleTextAreaOnChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    setData({...data, [name]: value});
  }
  const onUpdate = e => {
    e.preventDefault();

    dataProvider.post('user', 'contactUs', data.id, data).then(res => {
      notify(res.data.message, 'info');
      dispatch.changeTab('myDashboard', 'dashboard', 'contactUs', null, false);
      push(`/`);
    });
  };
 


  const isValidForm = data?.first_name?.trim().length > 0  && data?.last_name?.trim().length > 0 
                        && data?.subject?.trim().length > 0 && data?.feedback?.trim().length > 0;
  
  
  return (
    
    <form onSubmit={onUpdate}>
      <Container.Paper mt={2} p={2} radius={1}>
        <Container.Grid spacing={4}>
          {!data?.email || !data?.company ? (
            <LoadingStyled />
          ) : (
            <>
              <Container.Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
                <TextField
                  value={data.first_name}
                  name="first_name"
                  label="First name"
                  inputProps={{ onChange: ({ target: { name, value } }) => onChange(name, value), maxLength: 50 }}
                  required
                  fullWidth
                />
              </Container.Grid>
              <Container.Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
                <TextField
                  value={data.last_name}
                  name="last_name"
                  label="Last name"
                  inputProps={{ onChange: ({ target: { name, value } }) => onChange(name, value), maxLength: 50 }}
                  required
                  fullWidth
                />
              </Container.Grid>
              <Container.Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
                <TextField
                  value={data.company}
                  name="company"
                  label="Company"
                  inputProps={{ onChange: ({ target: { name, value } }) => onChange(name, value), maxLength: 50 }}
                  fullWidth
                  disabled
                />
              </Container.Grid>
              <Container.Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
                <TextField
                  value={data.email}
                  name="email"
                  label="Email"
                  inputProps={{ onChange: ({ target: { name, value } }) => onChange(name, value), maxLength: 199 }}
                  // required
                  fullWidth
                  disabled
                />
              </Container.Grid>
              <Container.Grid item mt={0.9} xs={12} sm={12} md={12} lg={5} xl={5}>
                <MuiPhoneNumber
                  variant="outlined"
                  value={data.phone.includes('+') ? data.phone : `+1 ${data.phone}`}
                  name="phone"
                  label="Phone number"
                  onChange={e => handleChangePhones(e, 'phone')}
                  defaultCountry="us"
                  countryCodeEditable={false}
                  fullWidth
                />
              </Container.Grid>
              <Container.Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
                <TextField
                  value={data.subject}
                  name="subject"
                  label="Subject"
                  inputProps={{ onChange: ({ target: { name, value } }) => onChange(name, value), maxLength: 50 }}
                  required
                  fullWidth
                />
              </Container.Grid>
              <Container.Grid item xs={12} sm={12} md={12} lg={10} xl={10}>
                
                <textarea 
                style={{width:"100%", fontSize: "large", resize:"none"}}
                id="feedback"
                name="feedback" 
                rows="4" 
                cols="50" 
                placeholder="Feedback*"
                value={data.feedback}
                onChange={handleTextAreaOnChange}
                required
                >
                </textarea>
              </Container.Grid>
              <Container.Grid justify="flex-end" bt={1} pt={2} pb={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                <ButtonCancel
                  to="/"
                  primaryText="Cancel"
                  onClick={() => dispatch.changeTab('myDashboard', 'dashboard', 'contactUs', null, false)}
                />
                <Button
                  mr={3}
                  backgroundColor="colorSystemSuccess"
                  backgroundColorHover="colorSystemSuccessHover"
                  type="submit"
                  disabled={!isValidForm}
                >
                  {/* <LazyIcon style={{ color: `var(--commonWhite)`, fontSize: `var(--fontSize)` }} component="Done" /> */}
                  Submit
                </Button>
              </Container.Grid>
            </>
          )}
        </Container.Grid>
      </Container.Paper>
    </form>
  );
};

export default UserContactUs;
