// @flow
import React, { useState, useEffect } from 'react';
import { Checkbox, FormControlLabel, CircularProgress, Box, Dialog, Snackbar, Chip } from '@material-ui/core';
import { LazyIcon } from 'components/icons';
import { DialogTitleStyled, IconButton } from '../../modal/ComponentTypes';

import { Alert } from '@material-ui/lab';
import { getStorage } from 'helpers';
import pushNotifications from 'helpers/apis/pushNotifications';
import { Button, Container, Typography, LoadingStyled, RichTextEditor, TextField, DateField } from 'components';

import PushNotificationsListSelectionModal from './PushNotificationsListSelectionModal';

const PartnerPushNotificationsConfigurationModal = props => {
  const [state, setState] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [saveDisable, setSaveDisable] = useState(true);
  const [testLoading, setTestLoading] = useState(false);
  const [modalState, setModalState] = useState({ opened: false, type: '' });
  const [alertStatus, setAlertStatus] = useState({ open: false, message: '' });
  const close = props.close;
  const save = () => {
    setSaveLoading(true);
    let savedData = {...state,
      subscription: { ...state.subscription, expiration_date: state.subscription.expiration_date + ' 00:00:00' }}
    pushNotifications.setSubscription(props.id, savedData).then(res => {
      console.log(res);
      setSaveLoading(false);
      setAlertStatus({ open: true, message: 'Push Notification saved successfully' });
    });
  };
  const testMessage = () => {
    setTestLoading(true);
    pushNotifications
      .testPushNotificationsMessage(props.id, {
        partner_id: getStorage('partnerId', true),
        user_id: getStorage('userId', true),
        client_id: getStorage('clientId', true),
        user_email: getStorage('email', true),
        use_custom_message: state.subscription.use_custom_message,
        custom_message_subject: state.subscription.custom_message_subject,
        custom_message_body: state.subscription.custom_message_body,
        subscription_id: props.id
      })
      .then(res => {
        console.log(res);
        setTestLoading(false);
        setAlertStatus({ open: true, message: 'The test message has been sent' });
      });
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertStatus({ open: false, message: '' });
  };

  const validate = (value, type, constraints) => {
    let errorObj = {};
    switch (type) {
      case 'number':
        errorObj = {
          error:
            value === null ||
            value === '' ||
            (constraints.minimum !== null && value < constraints.minimum) ||
            (constraints.maximum !== null && value > constraints.maximum) ||
            (constraints.integer && !Number.isInteger(Number(value)))
        };
        errorObj.error_text = errorObj.error
          ? (constraints.integer ? 'Number must be integer.' : '') +
            (constraints.minimum !== null ? ` Number must be with a minimum value= ${constraints.minimum}.` : '') +
            (constraints.maximum !== null ? ` Number must be with a maximum value= ${constraints.maximum}.` : '')
          : '';

        break;
      default:
        errorObj = {
          error: value.trim() === '',
          error_text: value.trim() !== '' ? '' : "this field shouldn't be empty"
        };
    }
    return errorObj;
  };
  const validateForm = () => {
    setState(state => {
      setSaveDisable(
          state.subscription_validation.frequency_days_error ||
          state.parameters.map(e => e.error).includes(true) ||
          (state.subscription.use_custom_message &&
            (!state.subscription.custom_message_body ||
              !state.subscription.custom_message_subject ||
              state.subscription.custom_message_body === '' ||
              state.subscription.custom_message_subject === ''))
      );
      return state;
    });
  };

  const changeText = (e, type, param) => {
    let error;
    switch (type) {
      case 'parameters':
        const arr = state.parameters;
        arr[param].value = e.target.value;
        arr[param] = {
          ...arr[param],
          ...validate(arr[param].value, arr[param].parameter_type, arr[param].constraints)
        };
        setState({ ...state, parameters: arr });
        break;
      case 'subscription':
        error = validate(e.target.value === '' ? '' : Number(e.target.value), 'number', {
          minimum: param === 'frequency_days' ? 1 : 0,
          integer: true,
          maximum: param === 'frequency_days' ? 30 : 365
        });

        setState({
          ...state,
          subscription: { ...state.subscription, [param]: e.target.value === '' ? '' : Number(e.target.value) },

          subscription_validation: {
            ...state.subscription_validation,
            [`${param}_error`]: error.error,
            [`${param}_error_text`]: error.error_text
          }
        });

        break;

      case 'expiration_date':
        error = validate(e, 'date', null);

        setState({
          ...state,
          subscription: { ...state.subscription, [param]: e },

          subscription_validation: {
            ...state.subscription_validation,
            [`${param}_error`]: error.error,
            [`${param}_error_text`]: error.error_text
          }
        });

        break;
    }

    validateForm();
  };
  const excludeClients = arr => {
    setState({ ...state, excluded_clients: arr });
    validateForm();
    setModalState({ ...modalState, opened: false });
  };
  const includeUsers = arr => {
    setState({ ...state, included_users: arr });
    validateForm();
    setModalState({ ...modalState, opened: false });
  };
  const revertExcludeClient = idx => {
    const arr = state.excluded_clients;
    arr.splice(idx, 1);
    setState({ ...state, excluded_clients: arr });
    validateForm();
  };
  const revertIncludedUser = idx => {
    const arr = state.included_users;
    arr.splice(idx, 1);
    setState({ ...state, included_users: arr });
    validateForm();
  };

  useEffect(() => {
    if (!state)
      pushNotifications
        .getSubscription(props.id)
        .then(res => {
          let resObj = res.data;

          let frequency_days_error = validate(
            resObj.subscription.frequency_days === null ? 1 : resObj.subscription.frequency_days,
            'number',
            { minimum: 1, maximum: 30, integer: true }
          );
          resObj.subscription.frequency_days =
            resObj.subscription.frequency_days === null ? 1 : resObj.subscription.frequency_days;
          resObj.subscription_validation = {};
          resObj.subscription_validation.frequency_days_error = frequency_days_error.error;
          resObj.subscription_validation.frequency_days_error_text = frequency_days_error.error_text;
          resObj.subscription.expiration_date = resObj.subscription.expiration_date.split(" ")[0];
          resObj.parameters.map((item, idx) => {
            resObj.parameters[idx] = { ...item, ...validate(item.value, item.parameter_type, item.constraints) };
          });

          setState(resObj);
        })
        .catch(err => {
          console.log(err);
        });
  }, [state]);
  if (!state || loading) return <LoadingStyled />;
  console.log(state);
  const atValues = state.picklist.custom_message_picklist.map(f => {
    return {
      value: f
        .replace(/_/g, ' ')
        .split(' ')
        .map(word => {
          return word[0].toUpperCase() + word.substring(1);
        })
        .join(' '),
      url: f
    };
  });
  return (
    <>
      <Container.Grid p={2} container>
        <Container.Grid item xs={12}>
          <Typography.p fontWeight="fontWeightMedium">{state.push_notification.header_text}</Typography.p>
        </Container.Grid>
      </Container.Grid>
      {state.parameters.map((param, index) => (
        <Container.Grid p={2} container>
          <Container.Grid item xs={9} pr={1}>
            <Typography.p>{param.parameter_display_name}</Typography.p>
          </Container.Grid>
          <Container.Grid item xs={3}>
            <TextField
              inputProps={{ min: param.constraints.minimum, max: param.constraints.maximum }}
              fullWidth
              error={param.error}
              helperText={param.error_text}
              integerOnly
              type="number"
              onChange={e => changeText(e, 'parameters', index)}
              value={param.value}
            />
          </Container.Grid>
        </Container.Grid>
      ))}

      <Container.Grid p={2} container>
        <Container.Grid item xs={9} pr={1}>
          <Typography.p>Send follow up reminder after how many days</Typography.p>
        </Container.Grid>
        <Container.Grid item xs={3}>
          <TextField
            inputProps={{ min: '1', max: '30' }}
            fullWidth
            error={state.subscription_validation.frequency_days_error}
            helperText={state.subscription_validation.frequency_days_error_text}
            integerOnly
            type="number"
            onChange={e => changeText(e, 'subscription', 'frequency_days')}
            value={state.subscription.frequency_days === null ? 1 : state.subscription.frequency_days}
          />
        </Container.Grid>
      </Container.Grid>
      <Container.Grid p={2} container>
        <Container.Grid item xs={9} pr={1}>
          <Typography.p>Expiration date</Typography.p>
        </Container.Grid>
        <Container.Grid item xs={3}>
          <DateField
            label="Expiration Date"
            name="expiration_date"
            value={state.subscription.expiration_date}
            onChange={e => changeText(e, 'expiration_date', 'expiration_date')}
          />
        </Container.Grid>
      </Container.Grid>
      <Container.Grid pl={2} pr={2} container>
        <Container.Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                label=""
                value={state.subscription.use_custom_message}
                checked={state.subscription.use_custom_message === 1}
                name="custom_message"
                onClick={e => {
                  setState({
                    ...state,
                    subscription: { ...state.subscription, use_custom_message: e.target.checked ? 1 : 0 }
                  });
                  validateForm();
                }}
              />
            }
            label="Use custom message"
          />
        </Container.Grid>
      </Container.Grid>
      {state.subscription.use_custom_message === 1 && (
        <>
          <Container.Grid pl={2} pr={2} container>
            <Typography.p>* use {'{'} to insert dynamic text (e.g. First Name, Course Name)</Typography.p>
          </Container.Grid>
          <Container.Grid pl={2} pr={2} container>
            <Container.Grid item xs={12}>
              <Typography.p>Customize Message subject</Typography.p>
            </Container.Grid>
          </Container.Grid>
          <Container.Grid pl={2} pr={2} container>
            <Container.Grid item xs={12}>
              <TextField
                name="customize_message_subject"
                value={state.subscription.custom_message_subject}
                onChange={e => {
                  setState({
                    ...state,
                    subscription: { ...state.subscription, custom_message_subject: e.target.value }
                  });
                  validateForm();
                }}
                fullWidth
                // variant="outlined"
              />
            </Container.Grid>
          </Container.Grid>
          <Container.Grid p={2} container>
            <Container.Grid item xs={12}>
              <Typography.p>Customize message body</Typography.p>
            </Container.Grid>
          </Container.Grid>
          <Container.Grid p={2} container>
            <Container.Grid item xs={12}>
              <RichTextEditor
                name="push_notification"
                autoComplete={{
                  allowedChars: /^[a-zA-Z0-9_]*$/,
                  mentionDenotationChars: ['{', '#'],
                  showDenotationChar: false,
                  source: function(searchTerm, renderItem, mentionChar) {
                    let values;
                    if (mentionChar === '{') {
                      values = atValues;
                    }
                    if (searchTerm.length === 0) {
                      renderItem(values, searchTerm);
                    } else {
                      const matches = [];
                      for (let i = 0; i < values.length; i++)
                        if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())) matches.push(values[i]);
                      renderItem(matches, searchTerm);
                    }
                  },
                  onSelect: function(item, insertItem) {
                    item.value = `{{${item.value
                      .toLowerCase()
                      .split(' ')
                      .join('_')}}}`;
                    insertItem(item);
                  }
                }}
                onChangeEditorState={e => {
                  setState({ ...state, subscription: { ...state.subscription, custom_message_body: e } });
                  validateForm();
                }}
                currentState={state.subscription.custom_message_body}
              />
            </Container.Grid>
          </Container.Grid>
        </>
      )}
      {state.picklist.clients.length !== 0 && (
        <Container.Grid p={2} container>
          <Container.Grid container xs={4}>
            <Container.Grid item xs={12}>
              <Typography.p fontWeight="bold">Disable push notification for</Typography.p>
            </Container.Grid>
            <Container.Grid item xs={12}>
              your list has {state.excluded_clients.length} customers
            </Container.Grid>
          </Container.Grid>
          <Container.Grid flexWrap="wrap" container xs={5}>
            {state.excluded_clients.map((row, idx) => (
              <Box p={1}>
                <Chip label={row.name} onDelete={() => revertExcludeClient(idx)} />
              </Box>
            ))}
          </Container.Grid>
          <Container.Grid pl={1} item xs={3}>
            <Box ml={1}>
              <Button
                onClick={() => setModalState({ ...modalState, type: 'excludedClient', opened: true })}
                size="small"
                variant="contained"
                color="primary"
              >
                + Add client list
              </Button>
            </Box>
          </Container.Grid>
        </Container.Grid>
      )}
      {state.push_notification.allow_individual_targeting !== 0 && (
        <Container.Grid p={2} container>
          <Container.Grid container xs={4}>
            <Container.Grid item xs={12}>
              <Typography.p fontWeight="bold">Enable push notification for</Typography.p>
            </Container.Grid>
            <Container.Grid item xs={12}>
              your list has {state.included_users.length} users
            </Container.Grid>
          </Container.Grid>
          <Container.Grid flexWrap="wrap" container xs={5}>
            {state.included_users.map((row, idx) => (
              <Box p={1}>
                <Chip label={row.name} onDelete={() => revertIncludedUser(idx)} />
              </Box>
            ))}
          </Container.Grid>
          <Container.Grid pl={1} item xs={3}>
            <Box ml={1}>
              <Button
                onClick={() => setModalState({ ...modalState, type: 'includedUser', opened: true })}
                size="small"
                variant="contained"
                color="primary"
              >
                + Add User list
              </Button>
            </Box>
          </Container.Grid>
        </Container.Grid>
      )}
      <Container.Grid p={2} container>
        <Container.Grid justify="flex-end" item xs={12}>
          <Box mr={1}>
            <Button onClick={close}>Cancel</Button>
          </Box>
          <Box mr={1}>
            <Button disabled={saveLoading || saveDisable} variant="contained" color="primary" onClick={save}>
              {saveLoading ? <CircularProgress size={25} /> : 'Save'}
            </Button>
          </Box>
          <Box mr={1}>
            <Button
              variant="contained"
              disabled={
                testLoading ||
                (state.subscription.use_custom_message &&
                  (!state.subscription.custom_message_body ||
                    !state.subscription.custom_message_subject ||
                    state.subscription.custom_message_body.trim() === '' ||
                    state.subscription.custom_message_subject.trim() === ''))
              }
              color="primary"
              onClick={testMessage}
            >
              {testLoading ? <CircularProgress size={25} /> : 'Test Message'}
            </Button>
          </Box>
        </Container.Grid>
        <Dialog
          open={modalState.opened}
          onClose={() => setModalState({ ...modalState, opened: false })}
          disableBackdropClick
          disableEscapeKeyDown
          fullWidth
          maxWidth="sm"
        >
          <DialogTitleStyled id="dialog-title">
            <Typography.h3>{modalState.type === 'includedUser' ? 'Select users' : 'Select clients'}</Typography.h3>
            <IconButton onClick={() => setModalState({ ...modalState, opened: false })}>
              <LazyIcon component="Close" />
            </IconButton>
          </DialogTitleStyled>
          <PushNotificationsListSelectionModal
            save={e => (modalState.type === 'includedUser' ? includeUsers(e) : excludeClients(e))}
            close={() => setModalState({ ...modalState, opened: false })}
            selectedList={modalState.type === 'includedUser' ? state.included_users : state.excluded_clients}
            list={modalState.type === 'includedUser' ? state.picklist.users : state.picklist.clients}
            type={modalState.type}
          />
        </Dialog>
      </Container.Grid>
      <Snackbar open={alertStatus.open} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          {alertStatus.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default PartnerPushNotificationsConfigurationModal;
