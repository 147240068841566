import React from 'react';
import { useDispatch } from 'react-redux';
import { Box, makeStyles } from '@material-ui/core';
import ReactTimeAgo from 'react-time-ago';
import { encodeId } from 'helpers';
import { NotificationAvatar } from '../common';
import newsfeed from 'helpers/apis/newsfeed';
import { BSN_SET_USER_ALL_NOTIFICATIONS_SEEN } from 'conf';

const useStyles = makeStyles(theme => ({
  content: {
    marginLeft: '40px',
    fontFamily: 'Roboto',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '20px'
    },
  },
  title: {
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '19px',
    margin: '0'
  },
  description: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
    margin: '5px 0'
  },
  timeAgo: {
    display: 'inline-block',
    fontSize: '10px',
    color: '#94959B',
    '&::first-letter': {
      textTransform: 'uppercase'
    }
  }
}));

const NotificationToast = ({ notification }) => {
  const { notification_title, notification_body, notification_id, type, timestamp, data } = notification;
  const postId = encodeId(data.post.id);
  const id = encodeId(notification_id);
  const classes = useStyles();
  const dispatch = useDispatch();

  const openSinglePost = () => {
    let lastToastNotificationId = sessionStorage.getItem('lastToastNotificationId');
    if (lastToastNotificationId && lastToastNotificationId === notification_id) {
      newsfeed
        .markAllSeen()
        .then(res => {
          dispatch({
            type: BSN_SET_USER_ALL_NOTIFICATIONS_SEEN,
            payload: 1
          });
          sessionStorage.removeItem('lastToastNotificationId');
        });
    }

    window.location.href= `/#/newsfeed/dashboard/${postId}?notification_type=${type}&notification_id=${id}`;
  };

  return (
    <Box display="flex" alignItems="center" onClick={openSinglePost}>
      <NotificationAvatar user={data.user} type={type} size='md' />
      <div className={classes.content}>
        <p className={classes.title}>{notification_title}</p>
        <p className={classes.description}>{notification_body}</p>
        <ReactTimeAgo
          className={classes.timeAgo}
          timeStyle="round-minute"
          date={timestamp.replace(' ', 'T') + 'Z'}
          locale="en-US"
        />
      </div>
    </Box>
  );
};

export default NotificationToast;
