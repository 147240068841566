// @flow
import { format } from 'date-fns';
import { fromUTCtoLocal } from '../../helpers/utils';

const DateTime = ({ date = null, isUTC = false }: { date?: string | null }) => {
  if (isUTC)
    return fromUTCtoLocal(date)
      .replace('T', ' ')
      .replace('Z', '');
  const dateString = date && date !== '' ? new Date(date) : new Date();
  const formattedDate = format(dateString, 'yyyy-MM-dd, h:mm a');
  return formattedDate;
};

export default DateTime;
