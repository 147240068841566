// @flow

import React, { useState, useEffect } from 'react';
import { getStorage } from 'helpers';
import { useLocation } from 'hooks';
import { Container, LoadingStyled, Button, Typography } from 'components';

import {
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Box
} from '@material-ui/core';
import { LazyIcon } from 'components/icons';
import { DialogTitleStyled, IconButton } from '../../modal/ComponentTypes';
import pushNotifications from 'helpers/apis/pushNotifications';

import PartnerPushNotificationsConfigurationModal from './pushNotificationsConfigurationModal'
// import type { clientsInformationTypes } from 'conf';
const decoder = item => {
  return atob(atob(atob(item)));
};


const ClientsEditNotification = ({ clientId }): Element<*> => {
  const [loading, setLoading] = useState(true);
  const client_id = clientId;
  const [update, forceUpdate] = useState(true);
  const [state, setState] = useState(null);
  const partner_id = getStorage('partnerId', true);
  const [modalState, setModalState] = useState({ id: null, opened: false });
  const EnableDisable = (index, first = false) => {
    setLoading(true);
    const arr = state;
    arr[index].push_notification_subscription.enabled =
      state[index].push_notification_subscription.enabled === 0 && !first ? 1 : 0;
    pushNotifications.setPushNotificationsSubscription(partner_id, arr[index], client_id).then(res => {
      arr[index].push_notification_subscription_id = res.data.push_notification_subscription_id;
      setState(arr);
      forceUpdate(!update);
      setLoading(false);
      if (first)
        setModalState({ ...modalState, id: res.data.push_notification_subscription_id, opened: true })
    })
  }

  const configure = (id, index) => {
    if (id)
      setModalState({ ...modalState, id: id, opened: true })
    else
      EnableDisable(index, true)
  }

  useEffect(() => {
    if (!state)
      pushNotifications
        .getPushNotificationsSubscriptions(partner_id, client_id)
        .then(res => {
          console.log(res);
          setState(res.data);
          setLoading(false);
        })
        .catch(err => {
          console.clear();
          console.log(err);
        });
  }, [state]);
  if (loading || !state) return <LoadingStyled />;

  return (
    <Container.Paper p={2} mt={2} fullWidth radiusTop={2} pb={2}>
      <Container.Grid xs={12} sm={12} md={12} lg={12} xl={12}>
        <Container.Grid xs={12} sm={12} md={12} lg={12} xl={12}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Enable</TableCell>
                  <TableCell>Rule Name</TableCell>
                  <TableCell>Configure</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.map((notification, index) => (
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Checkbox
                        value={notification.push_notification_subscription.enabled}
                        checked={notification.push_notification_subscription.enabled === 1}
                        onClick={() => EnableDisable(index)}
                      />
                    </TableCell>
                    <TableCell>{notification.push_notification.name}</TableCell>
                    <TableCell>
                      <Box display="flex">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={e => configure(notification.push_notification_subscription_id, index)}>
                            Configure
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Dialog
            open={modalState.opened}
            onClose={() => setModalState({ ...modalState, opened: false })}
            disableBackdropClick
            disableEscapeKeyDown
            fullWidth
            maxWidth="lg"
          >
            <DialogTitleStyled id="dialog-title">
              <Typography.h3>Configure</Typography.h3>
              <IconButton onClick={() => setModalState({ ...modalState, opened: false })}>
                <LazyIcon component="Close" />
              </IconButton>
            </DialogTitleStyled>
            <PartnerPushNotificationsConfigurationModal close={() => setModalState({ ...modalState, opened: false })} id={modalState.id} />
          </Dialog>
        </Container.Grid>
      </Container.Grid>
    </Container.Paper>
  );
};

export default ClientsEditNotification;
