// @flow
import React from 'react';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';

type AlertTypes = {
  children: any,
  type: string,
  variant?: string
};

const useStyles = makeStyles({
  general: {
    borderLeftWidth: '5px',
    fontSize: '14px'
  },
  warning: {
    backgroundColor: '#fff6ef',
    color: 'var(--colorSystemWarning)',
    borderColor: 'var(--colorSystemWarning)'
  },
  success: {
    backgroundColor: '#dbf1e8',
    color: 'var(--colorSystemSuccess)',
    borderColor: 'var(--colorSystemSuccess)'
  },
  error: {
    backgroundColor: '#f4e5e7',
    color: 'var(--colorSystemDanger)',
    borderColor: 'var(--colorSystemDanger)'
  }
});

const CustomAlert = ({ children, type, ...rest }: AlertTypes) => {
  const classes = useStyles();

  return (
    <Alert className={`${classes.general} ${classes[type]}`} {...rest}>
      {children}
    </Alert>
  );
};

export default CustomAlert;
