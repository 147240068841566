// @flow
import React, { useState, type Element, useEffect } from 'react';
import { Button, Dialog } from '@material-ui/core';
import { Typography } from 'components';
import { LazyIcon } from 'components/icons';
import { DialogTitleStyled, IconButton, ButtonUppercase } from './ComponentTypes';

const { h3: H3, h4: H4 } = Typography;

type ModalTypes = {
  button: {
    variant?: string,
    color?: string,
    icon?: Element<*>,
    label?: string,
    disabled?: boolean
  },
  buttonUppercase: {
    icon?: Element<*>,
    label?: string,
    disabled?: boolean
  },
  typography: { label?: string },
  title?: string,
  showDismiss?: boolean,
  fullWidth: boolean,
  fullScreen: boolean,
  data?: Object,
  component?: Element<*> | null,
  opened?: boolean,
  dismissOutside: boolean,
  onClose?: () => void
};

const Modal = ({
  button,
  buttonUppercase,
  typography,
  dismissOutside,
  title,
  data,
  opened = false,
  showDismiss = true,
  fullWidth = false,
  fullScreen = false,
  onClose,
  ...rest
}: ModalTypes): Element<*> => {
  const [open, setOpen] = useState(opened);

  useEffect(() => {
    setOpen(opened);
  }, [opened]);

  return (
    <div>
      {button && (
        <Button disabled={button.disabled} variant={button.variant} color={button.color} onClick={() => setOpen(true)}>
          {button.icon && <>{button.icon}&nbsp;</>}
          {button.label}
        </Button>
      )}
      {buttonUppercase && (
        <ButtonUppercase disabled={buttonUppercase.disabled} onClick={() => setOpen(true)}>
          {buttonUppercase.icon && <>{buttonUppercase.icon}&nbsp;</>}
          {buttonUppercase.label}
        </ButtonUppercase>
      )}
      {typography && (
        <H4 pt={2} pb={2} mt={0.1} mb={0.1} onClick={() => setOpen(true)}>
          {typography.label}
        </H4>
      )}
      <Dialog
        {...rest}
        open={open}
        onClose={onClose ? () => onClose() : () => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick={dismissOutside}
        disableEscapeKeyDown={dismissOutside}
        fullWidth={fullWidth}
        fullScreen={fullScreen}
        maxWidth="md"
        style={{overflowScrolling: "touch", WebkitOverflowScrolling: "touch"}}
      >
        {title && (
          <DialogTitleStyled id="dialog-title">
            <H3>{title}</H3>
            <IconButton onClick={onClose ? () => onClose() : () => setOpen(false)}>
              <LazyIcon component="Close" />
            </IconButton>
          </DialogTitleStyled>
        )}
        {data}
      </Dialog>
    </div>
  );
};

Modal.defaultProps = {
  opened: false,
  title: '',
  showDismiss: false,
  data: null,
  component: null,
  onClose: null
};

export default Modal;
