// @flow

const disasterRecovery = {
  type: 'disasterRecovery',
  selecting: true,
  selectAll: true,
  actions: [
    {
      icon: 'Delete',
      title: 'Delete',
      method: 'delete'
    }
  ],
  row: {
    click: true,
    action: 'modal',
    modal: {
      app: 'clients',
      tab: 'disasterRecoveryInfo'
    }
  },

  cells: [
    { id: 'name', label: 'Name', align: 'left' },
    { id: 'description', label: 'Description', align: 'left' },
    { id: 'created_date', label: 'Created', align: 'left' },
    { id: 'modified_date', label: 'Modified', align: 'left' },
    {
      id: 'attachment_link',
      label: 'Download',
      labelAlign: 'center',
      align: 'center',
      button: true,
      renderDowloadIcon: true,
      hideOrderBy: true
    }
  ]
};

export default disasterRecovery;
