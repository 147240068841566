// @flow
import React, { type ComponentType, type Element, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Link, IconButton, Hidden } from '@material-ui/core';
import { dataProvider, hasKey } from 'helpers';
import { useLocation, useRecord } from 'hooks';
import MenuIcon from '../Menu/MenuIcon';
import DarkModeIcon from '../../icons/DarkMode';
import useHasInternet from '../../../hooks/useHasInternet';
import { NotificationAlert } from 'components';
import { NotificationsMenu } from 'apps/newsfeed/components';
import newsfeed from 'helpers/apis/newsfeed';
import { BSN_SET_USER_ALL_NOTIFICATIONS_SEEN } from 'conf';

export const Header: ComponentType<any> = styled.header`
  position: ${({ centered }) => (centered ? 'relative' : 'sticky')};
  width: 100%;
  /* max-width: 1016px; */
  display: flex;
  z-index: 1100;
  box-sizing: border-box;
  flex-shrink: 0;
  color: var(--colorDefault);
  flex-grow: 1;
  flex-direction: row;
  justify-content: flex-end;
  align-items: stretch;
  padding: calc(var(--spacing) * 2);
  padding-bottom: 0;
  top: 0;
`;

export const MenuIconContainer: ComponentType<any> = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const HeaderTitle: ComponentType<any> = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;

  h1 {
    font-size: 2.5rem;
    text-align: left;
    letter-spacing: 0px;
    color: var(--colorBaseBase);
    margin: 0;
  }
`;

const ProductLogo: ComponentType<*> = styled.div`
  display: flex;
  align-items: center;
`;

export const DarkModeLink: ComponentType<any> = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  && {
    color: var(--colorDefault);
    margin: 0 25px;
  }
  &:hover {
    color: var(--hoverDefault);
  }
`;

export const ImageFilter: ComponentType<*> = styled.img`
  filter: var(--filterImage);
  height: calc(var(--spacing) * 5);
`;

const NotificationDot: ComponentType<*> = styled.span`
  position: absolute;
  width: 10px;
  height: 10px;
  display: inline-block;
  background: #FF5A61;
  border-radius: 50%;
  top: 10px;
  right: 10px;
`;

type AppBarProps = {
  width: string,
  centered?: boolean,
  hasDarkMode: boolean
};

const darkModeQuery = window.matchMedia('(prefers-color-scheme: dark)');
const darkModeSaved = localStorage.getItem('darkMode');

const AppBar = ({ centered, hasDarkMode }: AppBarProps): Element<*> => {
  const dispatch = useDispatch();
  const { app, item } = useLocation();
  const information = useRecord('clients', 'information');
  const logo = useSelector(({ bsn: { user: { profile } } }) => (profile ? profile.logo_product : null));
  const [darkMode, setDarkMode] = useState(darkModeSaved || (darkModeQuery.matches ? 'dark' : 'light'));
  const [companyName, setCompanyName] = useState(null);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const hasInternet = useHasInternet();
  const clientName = useSelector(({ bsn }) => bsn?.user?.profile?.client_name);
  const newsfeedAccess = useSelector(({ bsn }) => bsn?.user?.access?.apps?.newsfeed);
  const allNewsfeedNotificationsSeen = useSelector(({ bsn }) => bsn?.user?.profile?.all_newsfeed_notifications_seen);
  const newsfeedLicenseAgreed = useSelector(state => state.newsfeed.license?.newsfeed_license_agreed);
  const showNotificationIcon = newsfeedAccess && app === 'newsfeed' && newsfeedLicenseAgreed;

  useEffect(() => {
    darkModeQuery.addListener(event => {
      setDarkMode(event.matches ? 'dark' : 'light');
    });
  }, []);

  useEffect(() => {
    if (document.body) document.body.setAttribute('data-theme', darkMode);
  }, [darkMode]);

  useEffect(() => {
    if (item && companyName === null && app !== 'newsfeed') {
      dataProvider.getOne('clients', `company/${item}`).then(resp => {
        hasInternet(() => {
          if (typeof resp === 'object' && hasKey(resp, 'data')) setCompanyName(resp.data.name);
        })
      });
    }

    if (!item && companyName) setCompanyName(null);
  }, [companyName, item]);

  const handleClick = e => {
    e.preventDefault();
    const darkModeActived = darkMode === 'light' ? 'dark' : 'light';
    setDarkMode(darkModeActived);
    localStorage.setItem('darkMode', darkModeActived);
  };

  const handleClickIcon = () => {
    setIsNotificationsOpen(true);
    if (!allNewsfeedNotificationsSeen) {
      newsfeed
        .markAllSeen()
        .then(res => {
          dispatch({
            type: BSN_SET_USER_ALL_NOTIFICATIONS_SEEN,
            payload: 1
          });
        });
    }
  };

  return (
    <Hidden smDown>
      <Header centered={centered}>
        {!centered && (
          <Hidden mdUp>
            <MenuIconContainer>
              <MenuIcon />
            </MenuIconContainer>
          </Hidden>
        )}
        {!centered && (
          <>
            {companyName ? (
              <HeaderTitle>
                <h1>{information !== null && hasKey(information, 'name') ? information.name : companyName}</h1>
              </HeaderTitle>
            ) : (
              <HeaderTitle>
                <h1>{clientName}</h1>
              </HeaderTitle>
            )}
            {showNotificationIcon ? (
              <>
                <IconButton style={{ padding: 8, marginRight: 24 }} onClick={handleClickIcon}>
                  <NotificationAlert size={1.715} color="colorGreyLight1" />
                  {allNewsfeedNotificationsSeen ? null : <NotificationDot />}
                </IconButton>
                <NotificationsMenu isOpen={isNotificationsOpen} onClose={() => setIsNotificationsOpen(false)} />
              </>
            ) : null}
            {hasDarkMode && (
              <DarkModeLink href="#" onClick={handleClick}>
                <DarkModeIcon size={1} />
              </DarkModeLink>
            )}
            {logo && (
              <ProductLogo mt={0.5} mb={0.5} p={1} radius={0.5}>
                <ImageFilter src={logo} alt="Logo Partner" />
              </ProductLogo>
            )}
          </>
        )}
      </Header>
    </Hidden>
  );
};

AppBar.defaultProps = {
  centered: false
};

export default AppBar;
