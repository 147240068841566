// @flow
import React, { type Element } from 'react';
import ClientsEditNotification from './pushNotifications/pushNotifications';

type PagePushNotificationsTypes = {};

const PagePushNotifications = ({clientId}): Element<*> => {
  return <ClientsEditNotification clientId={clientId}/>;
};

export default PagePushNotifications;
