// @flow
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { TableList } from 'components';
import { useLocation, useId } from 'hooks';
import WorkPlanToolbar from './WorkPlanToolbar';
import TableListModal from '../../tablelist/TableListModal';

type WorkPlanTypes = {
  app: string
};

const WorkPlan = ({ app }: WorkPlanTypes) => {
  const activeTabType = useSelector(rxState => rxState.bsn.system.activeTabType);
  const [value, setValue] = useState(activeTabType || 'sraDocuments');
  const { item } = useLocation();
  const clientId = useId({ key: 'clientId' });

  const setId = item || clientId;

  const tableOptions = {
    refetchOnWindowFocus: false
  };

  return (
    <TableListModal
      tab={value}
      id={setId}
      app={app}
      tableOptions={tableOptions}
      toolbar={<WorkPlanToolbar id={setId}  dispatchSetValue={setValue} value={value} />}
      resetTable={value}
    />
  );
};

export default WorkPlan;
