// @flow
import React, { type ComponentType } from 'react';
import styled from 'styled-components';
import { Container } from 'components';
import { Link } from 'react-router-dom';
import womanImg from './woman.svg';

const ImgWrap: ComponentType<*> = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  @media(max-width: 959px) {
    justify-content: center;
    margin-bottom: 20px;
  }
`;

const Img: ComponentType<*> = styled.img`
  width: 100%;
  max-width: 530px;
  height: auto;
  margin-right: 30px;
  @media(max-width: 1280px) {
    margin-right: 0;
  }
  @media(max-width: 959px) {
    max-width: 400px;
    width: 80%;
  }
`;

const TextContent: ComponentType<*> = styled.div`
  max-width: 450px;
  @media(max-width: 959px) {
    margin: 0 auto 15px;
    text-align: center;
  }
`;

const Title: ComponentType<*> = styled.p`
  font-size: 156px;
  line-height: 1.1;
  font-weight: bold;
  margin: 100px 0 0;
  color: var(--colorBaseLight1);
  margin-bottom: 16px;
  @media(max-width: 959px) {
    margin: 0 0 10px;
    font-size: 90px;
  }
`;

const SubTitle: ComponentType<*> = styled.p`
  font-size: 36px;
  font-weight: bold;
  margin: 0;
  color: var(--colorBaseLight1);
  @media(max-width: 959px) {
    font-size: 24px;
  }
`;

const Paragraph: ComponentType<*> = styled.p`
  font-size: 24px;
  font-weight: 600;
  margin: 10px 0;
  color: var(--colorBaseLight1);
  opacity: .5;
  @media(max-width: 959px) {
    font-size: 16px;
  }
`;

const LinkStyled: ComponentType<*> = styled(Link)`
  text-decoration: underline;
  color: var(--colorBox);
  font-size: 18px;
  @media(max-width: 959px) {
    padding: 5px;
    font-size: 16px;
  }
`;

function PageExpiredSession() {
  return (
    <Container.Paper p={2} radius={1} style={{minHeight: '100vh '}}>
      <Container.Grid container>
        <Container.Grid item md={6} xs={12}>
          <ImgWrap>
            <Img src={womanImg} alt="Woman" />
          </ImgWrap>
        </Container.Grid>
        <Container.Grid item md={6} xs={12} style={{margin: 'auto'}}>
          <TextContent>
            <Title>Oops!</Title>
            <SubTitle>Your Session has expired</SubTitle>
            <Paragraph>You must log back in to the system to continue.</Paragraph>
            <LinkStyled to="/login/">Login Page</LinkStyled>
          </TextContent>
        </Container.Grid>
      </Container.Grid>
    </Container.Paper>
  )
}

export default PageExpiredSession;
