/* eslint-disable no-console */
// @flow
import React, { type Element, type Context, createContext, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useUserProfile, useWindowSize } from 'hooks';
import {
  BSN_GET_LIST,
  BSN_GET_ONE,
  BSN_SET,
  BSN_SET_ITEM,
  BSN_SET_USER,
  BSN_SYSTEM_CHANGE_TAB,
  BSN_SYSTEM_CHANGE_APP,
  BSN_SET_USER_NOTIFICATION_COUNT,
  BSN_SET_USER_ALL_NOTIFICATIONS_SEEN,
  initialState,
  type initialStateType,
} from 'conf';
import Fuse from 'fuse.js';
import axios from 'axios';
import { differenceInMinutes } from 'date-fns';
import dataProvider from './dataProvider';
import authProvider from './authProvider';
import { getStorage, setStorage, clearStorage } from './storage';
import { checkSession, isSessionExpired } from './session';
import { getActiveTab, itemExist, validateVariable, isPublicUrl } from './utils';
import { getMessaging, onMessage, isSupported } from 'firebase/messaging';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastCloseButton, NotificationToast } from 'apps/newsfeed/components/notifications';
import { decodeId, addNotification } from 'helpers';
import newsfeed from 'helpers/apis/newsfeed';
import useBreakpoint from '../hooks/useBreakpoint';

let messaging = null;
let onRequestPermissionListener = null;
isSupported().then(res => {
  if (res) {
    import('firebaseInit').then(module => {
      messaging = getMessaging();
      onRequestPermissionListener = module.onRequestPermissionListener;
    });
  }
});

const AppContext: Context<{
  dispatch: Function,
  state: initialStateType,
  app: string,
  tab: string,
  item: number | string | null | typeof undefined,
  tempState: any
}> = createContext({
  dispatch: e => e,
  state: initialState,
  app: '',
  tab: '',
  item: 0,
  tempState: []
});

type AppProviderTypes = {
  children: any
};

export const AppProvider = ({ children }: AppProviderTypes): Element<*> => {
  useUserProfile();
  const state = useSelector(rxState => rxState.bsn);
  const user = useSelector(rxState => rxState.bsn.user.profile);
  const location = useSelector(rxState => rxState.bsn.system.location);
  const newsfeedAccess = useSelector(({ bsn }) => bsn?.user?.access?.apps?.newsfeed);
  const { app, tab: tabApp, item: itemApp } = useLocation();
  const isApp = validateVariable(app, state);
  const tab: string = isApp ? tabApp || 'dashboard' : '';
  const isTab = isApp && validateVariable(tab, state[app]);
  const [loading, setLoading] = useState(false);
  const [fcmPermissionRequested, setFcmPermissionRequested] = useState(false);
  const isItem = isApp && validateVariable(itemApp);
  const item = isApp && isItem ? itemApp || state.system.item : null;
  const tabSettings = tab !== 'undefined' ? (isTab ? state.tabs[app][tab] : null) : null;
  const isList = itemExist(tabSettings, 'isList') && tabSettings && tabSettings.isList;
  const theme = state.partnerProfile.colorScheme;
  const dispatchRx = useDispatch();
  const [update, force] = useState(false);
  const dispatch = {};
  const userId = user?.id && decodeId(user.id);
  const mobileView = useBreakpoint("sm")

  useEffect(() => {
    if (newsfeedAccess && !fcmPermissionRequested && onRequestPermissionListener) {
      setFcmPermissionRequested(true);
      onMessage(messaging, payload => {
        console.log('Received foreground message ', payload);
        const notification = {
          ...payload.data,
          data: JSON.parse(payload.data.data)
        };
        dispatchRx(addNotification(notification));

        let allNewsfeedNotificationsSeen = sessionStorage.getItem('allNewsfeedNotificationsSeen');
        if (allNewsfeedNotificationsSeen === '1') sessionStorage.setItem('lastToastNotificationId', notification.notification_id);
        else sessionStorage.removeItem('lastToastNotificationId');

        dispatchRx({
          type: BSN_SET_USER_NOTIFICATION_COUNT,
          payload: {
            replace: false,
            value: 1
          }
        });
        dispatchRx({
          type: BSN_SET_USER_ALL_NOTIFICATIONS_SEEN,
          payload: 0
        });
        toast(<NotificationToast notification={notification} />, {
          draggable: false,
          autoClose: 5000,
          style: { borderRadius: 6, boxShadow: 'none' }
        });
      });

      onRequestPermissionListener()
        .then(firebaseToken => {
          // eslint-disable-next-line no-console
          console.log('firebaseToken', firebaseToken);

          newsfeed
            .SetDevicesToken(userId, firebaseToken)
            .then(res => {
              console.log(res);
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(err => {
          console.log(err);
          return err;
        });
    }
  }, [newsfeedAccess]);

  const onFocusChange = () => {
    if (!document.hidden) {
      authProvider.userAuthorize().then(data => {
        dispatchRx({
          type: BSN_SET_USER_NOTIFICATION_COUNT,
          payload: {
            replace: true,
            value: data.user.newsfeed_unopened_notifications_count
          }
        });
        dispatchRx({
          type: BSN_SET_USER_ALL_NOTIFICATIONS_SEEN,
          payload: data.user.all_newsfeed_notifications_seen
        });
      });
    }
  };

  useEffect(() => {
    if (newsfeedAccess) {
      window.addEventListener('visibilitychange', onFocusChange);
      return () => {
        window.removeEventListener('visibilitychange', onFocusChange);
      };
    }
  }, [newsfeedAccess]);

  useEffect(() => {
    axios.interceptors.response.use(
      response => {
        const url = response.config.url;
        if ((!url.includes('authorize') && !isSessionExpired()) || !getStorage('lastRequestDate', true)) {
          const access_token = getStorage('accessToken', true);
          if (access_token) setStorage('lastRequestDate', Date.now(), true);
        }
        const token_updated = getStorage('tokenUpdated', true);
        const expire_time = getStorage('expireTime', true);
        if (expire_time) {
          const now = new Date();
          const expire_date = new Date(+expire_time);
          const diff = differenceInMinutes(expire_date, now);
          if (diff >= 0 && diff <= 15 && token_updated && token_updated === 'true') {
            setStorage('tokenUpdated', 'false', true);
            checkSession();
          }
        }
        return response;
      },
      error => {
        const status = error?.response?.status;
        const url = error?.response?.config?.url;
        if (status && (status === 401 || status === 403)) {
          if (url.includes('authorize')) {
            console.log(!isPublicUrl(window.location.hash.split('/')[1].split('?')[0]));
            if (
              !window.location.hash.includes('expiredSession') &&
              !window.location.hash.includes('login') &&
              !isPublicUrl(window.location.hash.split('/')[1].split('?')[0])
            ) {
              clearStorage();
              window.location.href = '/#/expiredSession';
            }
          } else {
            checkSession();
          }
        }
        return Promise.reject(error);
      }
    );
  }, []);

  useEffect(() => {
    if (!state.user.profile && !isPublicUrl(window.location.hash.split('/')[1].split('?')[0])) authProvider.checkAuth();
  }, [state.user.profile]);

  useEffect(() => {
    if ((user === 'null' || user === null) && location === 'myDashboard' && !isPublicUrl(window.location.hash.split('/')[1].split('?')[0])) {
      const id_token = getStorage('idToken', true);
      const refresh_token = getStorage('refreshToken', true);
      const access_token = getStorage('accessToken', true);
      console.log(`%cCHECK AUTH`, 'font-size: medium; color: #FF0000');
      // eslint-disable-next-line prefer-promise-reject-errors
      dataProvider
        .post('user', 'authorize', null, { id_token, refresh_token, access_token })
        .then(({ status, statusText, data }) => {
          if (status < 200 || status >= 300) throw new Error(statusText);
          return data;
        })
        .then(data => {
          dispatchRx({
            type: BSN_SET_USER,
            payload: {
              profile: data.user,
              access: data.access,
              theme: data.colors
            }
          });
          return data;
        })
        .then(data => {
          force(!update);
        })
        .catch(error => {
          const status = error?.response?.status;
          if (status === 500) {
            window.location.href = '/#/login';
            clearStorage();
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, location]);

  dispatch.getData = (
    setLocation?: string,
    setTab?: string,
    setItem?: string,
    setIslist?: boolean,
    params?: Object = {}
  ) => {
    const resource = setLocation || app;
    const currentTab = setTab || tab;
    const currentItem = setItem || item;
    const newIsList = typeof setIslist === 'undefined' ? isList : setIslist;
    const setIsItem = validateVariable(currentItem);

    const dispatchSettings: {
      type: string,
      resource: string,
      tab: string,
      item?: string | number | false | null,
      payload: Object
    } = {
      type: newIsList ? BSN_GET_LIST : BSN_GET_ONE,
      resource,
      tab: currentTab,
      payload: null
    };
    // eslint-disable-next-line consistent-return
    if (!tab) return dispatchRx(dispatchSettings);
    return dataProvider[newIsList ? 'getList' : 'getOne'](
      resource,
      setIsItem ? `${currentTab}/${String(currentItem)}` : currentTab,
      params
    )
      .then(response => {
        if (!validateVariable(response) || !validateVariable('data', response)) return;
        const { data: resData } = response;
        // if (setIsItem) dispatchSettings.item = currentItem;
        dispatchSettings.payload = resData;
        dispatchRx(dispatchSettings);
        if (newIsList) {
          dispatchRx({
            type: BSN_SET_ITEM,
            resource: 'tables',
            tab: resource,
            item: currentTab,
            key: 'total',
            payload: Object.prototype.hasOwnProperty.call(response, 'total') ? response.total : null
          });
        }

        // eslint-disable-next-line consistent-return
        return response;
      })
      .catch(error => {
        console.log(error);
      });
  };

  dispatch.postData = (setLocation?: string, setTab?: string, setItem?: string, setIslist) => {};

  dispatch.get = e => console.log(e);

  dispatch.set = (setApp: string, setTab: string, payload: Object) => {
    dispatchRx({
      type: BSN_SET,
      resource: setApp,
      tab: setTab,
      payload
    });
  };

  dispatch.setItem = (
    setApp: string,
    setTab: string,
    key: string,
    payload: Object,
    setItem?: number | string | null = null,
    atBeginning = false
  ) => {
    dispatchRx({
      type: BSN_SET_ITEM,
      resource: setApp,
      tab: setTab,
      key,
      item: setItem,
      atBeginning,
      payload
    });
  };

  dispatch.getOne = (setApp?: string, setTab?: string, setItem?: string, setParam?: Object = {}) => {
    console.log(`%cDISPATCH GET ONE`, 'font-size: medium; color: NavajoWhite');
    return dispatch.getData(setApp, setTab, setItem, false, setParam);
  };

  dispatch.getList = (setApp?: string, setTab?: string, setItem?: string, setParam?: Object = {}) => {
    console.log(`%cDISPATCH GET LIST`, 'font-size: medium; color: LightSeaGreen');
    return dispatch.getData(setApp, setTab, setItem, true, setParam);
  };

  dispatch.changeApp = (
    setApp: string,
    locationPrevious: string,
    setTab: string,
    tabPrevious: string,
    setItem?: number | string | false | null = null
  ) => {
    console.log(`%cDISPATCH CHANGE APP`, 'font-size: medium; color: fuchsia');
    dispatchRx({
      type: BSN_SYSTEM_CHANGE_APP,
      resource: 'system',
      payload: { ...state.system, location: setApp, locationPrevious }
    });
    dispatch.changeTab(setApp, setTab, tabPrevious, setItem);
  };

  dispatch.changeTab = (
    setApp: string,
    setTab: string,
    tabPrevious: string,
    setItem?: number | string | false | null = null,
    mutation?: boolean = false,
    activeTabType?: string = ''
  ) => {
    dispatch.set('system', 'tableFilter', {});
    dispatch.set('system', 'tableRowSelected', []);
    dispatch.set('system', 'tableRowSelectedList', []);
    dispatch.set('system', 'tableRowUnselected', []);
    dispatch.set('system', 'tableRowSelectedCount', 0);
    dispatch.set('system', 'activeTabType', activeTabType);
    console.log(`%cDISPATCH CHANGE TAB`, 'font-size: medium; color: lime');
    const previousTab = tabPrevious || state.system.tabCurrent || 'dashboard';
    const current = setTab || getActiveTab(tab);

    if (typeof current !== 'undefined') {
      dispatchRx({
        type: BSN_SYSTEM_CHANGE_TAB,
        resource: 'system',
        payload: { ...state.system, tabCurrent: '', tabPrevious: previousTab, setItem }
      });
    }
  };

  dispatch.update = (setApp?: string, setTab?: string, setItem?: string, setData: any): Promise<any> => {
    const updateTab = setTab || tab;
    if (state[app][updateTab] === null) return Promise.reject();
    const updateApp = setApp || app;
    const updateItem = setItem || item;
    const hasItem = validateVariable(updateItem);
    const newTab = hasItem && updateItem !== null ? `${updateTab}/${updateItem}` : updateTab;
    const newState = hasItem ? state[updateApp][updateTab][updateItem] : state[updateApp][updateTab];

    return dataProvider.update(app, newTab, setData || newState);
  };

  dispatch.search = (
    query: string,
    fields: Array<string>,
    setApp?: string,
    setTab?: string,
    setItem?: string,
    setKey?: string | boolean = false
  ) => {
    const a = setApp || app;
    const t = setTab || tab;
    const i = setItem || item;
    let s = state[a][t];
    if (s === null) return;
    s = validateVariable(i) ? s[i] : s;
    let results = null;
    const fuse = new Fuse(s, {
      keys: fields,
      includeScore: true
    });
    results = fuse
      .search(query)
      .filter(r => r.score < 0.5 || r.score > 1)
      .map(r => r.item);
    dispatch.setItem(a, t, 'search', results);
  };

  dispatch.setLoading = (type, value) => {
    dispatch.set('system', type, value);
  };

  dispatch.resetPhishingItem = (id, items) => {
    const thisApp = 'clients';
    dataProvider.post(thisApp, 'phishingResetCampaign', id, {
      ids: items
    });

    dispatch.setItem(
      thisApp,
      'phishingReportsRecipients',
      id,
      state.clients.phishingReportsRecipients[id].map(v => (items.includes(v.id) ? { ...v, status: 'Email Sent' } : v))
    );
  };

  return (
    <>
      <ToastContainer
        position="bottom-left"
        newestOnTop={false}
        hideProgressBar
        closeButton={<ToastCloseButton />}
        style={{
          left: mobileView ? 0 : 75,
          bottom: 6,
          maxHeight: '80vh',
          overflow: 'hidden',
          overflowY: 'auto'
        }}
      />
      <AppContext.Provider
        value={{
          dispatch,
          state,
          tempState: { location: app, tab, item, theme },
          app,
          tab,
          item,
          loading,
          setLoading,
          theme
        }}
      >
        {children}
      </AppContext.Provider>
    </>
  );
};

export const useApp = () => useContext(AppContext);
