// @flow
import React, { type Element } from 'react';

import { TabsProvider, type TabsProviderTypes } from './TabsContext';
import TabsTabs from './TabsTabs';

const Tabs = (props: TabsProviderTypes): Element<*> => {
  return (
    <TabsProvider {...props}>
      <TabsTabs />
    </TabsProvider>
  );
};

export default Tabs;
