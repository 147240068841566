// @flow
import { type ComponentType } from 'react';
import styled from 'styled-components';
import { Grid, Typography, Paper, Button } from '@material-ui/core';

export const GridContainer: ComponentType<*> = styled(Grid).attrs({
  container: true,
  spacing: 24,
  alignItems: 'stretch'
})`
  padding: 0;
  margin-top: 0;
`;

export const GridItem: ComponentType<*> = styled(Grid).attrs({
  item: true
})`
  margin-bottom: 0;
  display: ${({ flex }) => (flex ? 'flex' : 'block')};
`;

export const BoxDashboard: ComponentType<*> = styled(Paper).attrs({
  elevation: 1
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ padding }) => (padding ? '12px' : '0')};
  background-clip: padding-box;
  background-color: rgba(255, 255, 255, 0.7);
`;

export const BoxTitleDashboard: ComponentType<*> = styled(Typography)`
  padding: 10px 0;
  margin: 0 12px 12px;
  font-size: 1.6rem;
`;

export const BoxWrapperLegendDashboard: ComponentType<*> = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0 0;
`;

export const BoxLegendDashboard: ComponentType<*> = styled(Typography).attrs({
  variant: 'p',
  component: 'p'
})`
  padding: 10px;
  text-align: center;
  background: ${props => props.background};
  font-size: 1.2rem;
  color: #000;
  font-weight: 600;
`;

export const ButtonSecondary: ComponentType<*> = styled(Button)`
  && {
    padding: calc(var(--spacing) / 4);
    text-transform: capitalize;
    background: var(--buttonSecondaryBackground);
    border: 1px solid var(--buttonSecondaryBorderFont);
    color: var(--buttonSecondaryBorderFont);
    box-shadow: none;
  }
  &:hover {
    && {
      background-color: var(--buttonSecondaryHover);
      border: 1px solid var(--buttonSecondaryBorderFont);
      color: var(--buttonSecondaryFontHover);
      box-shadow: none;
    }
  }
  &:disabled {
    border-color: rgba(0, 0, 0, 0.26);
  }
`;

export const ContainerHeaderBox: ComponentType<*> = styled.div`
  display: flex;
  justify-content: ${({ justify }) => justify};
  align-items: center;
  margin-bottom: ${({ mbbox }) => (mbbox ? '' : 'calc(var(--spacing) / 0.5)')};
  width: 100%;
  height: calc(var(--spacing) * 2.5);
  ${({ pt }) => pt && `padding-top: calc(${pt} * var(--spacing))`};
  ${({ pl }) => pl && `padding-left: calc(${pl} * var(--spacing))`};
  ${({ pr }) => pr && `padding-right: calc(${pr} * var(--spacing))`};

  > h3 {
    margin: calc(var(--spacing) / var(--spacing));
  }
`;
