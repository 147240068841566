// @flow
import React, { memo, type Element, useState, type ComponentType } from 'react';
import styled from 'styled-components';
import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import {
  StarBorder as StarBorderIcon,
  ArrowDropUp as ArrowDropUpIcon,
  ArrowDropDown as ArrowDropDownIcon
} from '@material-ui/icons';
import { useLocation } from 'hooks';
import { useApp } from 'helpers';
import { LazyIcon } from 'components/icons';
import Table from '../types/Table';
import ChartHalfCircle from '../charts/ChartHalfCircle';
import Star from '../icons/Star';

export const TableResponsive: ComponentType<*> = styled.div`
  width: 100%;
  max-height: ${({ height }) => height - 6}vh;
  overflow-y: auto;
  @media screen and (min-width: 1280px) and (max-width: 1430px) {
    overflow-x: auto;
  }
`;

const TableHeadCell: ComponentType<*> = styled(TableCell)`
  && {
    border-bottom: none;
    font-weight: var(--fontWeightRegular);
    font-size: var(--fontSize);
    color: var(--colorGreyLight2);
  }
  &:nth-child(1) {
    border-left: var(--borderSize) solid var(--borderDefault);
    border-radius: var(--borderRadius) 0px 0px var(--borderRadius);
    display: flex;
    justify-content: center;
  }
  &:nth-last-child(1) {
    border-right: var(--borderSize) solid var(--borderDefault);
    border-radius: 0px var(--borderRadius) var(--borderRadius) 0px;
  }

  @media screen and (min-width: 1616px) {
    &:nth-child(2) {
      border-left: var(--borderSize) solid var(--borderDefault);
      border-radius: var(--borderRadius) 0px 0px var(--borderRadius);
    }
    &:nth-last-child(2) {
      border-right: var(--borderSize) solid var(--borderDefault);
      border-radius: 0px var(--borderRadius) var(--borderRadius) 0px;
    }
  }

  background-color: var(--colorGreyLight3);

  ${props => props.theme.breakpoints.down("sm")} {
    padding: 8px 5px !important;


    &:nth-of-type(2) {
      padding-left: 15px !important;
      border-radius: var(--borderRadius) 0px 0px var(--borderRadius);
    }

    &:nth-of-type(4) {
      border-radius:  0px var(--borderRadius) var(--borderRadius) 0px ;
    }
  }
`;

const TableBodyCell: ComponentType<*> = styled(TableCell)`
  && {
    font-size: var(--fontSize);
    color: ${({ active }) => (active ? `var(--colorSystemSuccess)` : `var(--colorDefault)`)};
    ${({ active }) => active && `color: var(--colorSystemSuccess)`};
    border-bottom: none;
    padding: calc(var(--spacing) / 1.6) calc(var(--spacing) * 2);

    &:nth-child(1) {
      vertical-align: bottom;
      padding-left: 0;
    }
    &:nth-last-child(1) {
      padding-right: 0;
    }

    .chart-root {
      width: calc(var(--spacing) * 6.5);
      height: calc(var(--spacing) * 6);

      path {
        stroke-width: calc(var(--spacing) * 1.3);
      }
    }

    ${props => props.theme.breakpoints.down("sm")} {
      font-size: 12px;
      padding: 10px 5px;

      &.ranktable-rank {
        width: 50px;
        text-align: left;
        padding-left: ${({isStar}) => !isStar ? "20px" : "15px" };
      }

      &.ranktable-name {
        width: 150px;
      }

      &.ranktable-score {
        width: 50px;
      }
    }

  }

  > svg {
    font-size: calc(var(--fontSize) * 1.5);
  }
`;

const TableBodyRow: ComponentType<*> = styled(TableRow)`
  && {
    > td {
      ${({ active }) => active === '1' && `color: var(--colorSystemSuccess);`}
    }
  }
`;

const SortButton: ComponentType<*> = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    & > div {
      opacity: 1;
    }
  }
`;

const SortButtonContainer: ComponentType<*> = styled.div`
  display: inherit;
  opacity: ${({ show }) => (show ? 1 : 0)};

  & svg {
    font-size: calc(var(--fontSize) * 1.3);
  }
`;

const UserIconContainer: ComponentType<*> = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  & svg {
    font-size: calc(var(--fontSize) * 1);
  }
  small {
    color: var(--colorGreyLight2);
  }
`;


const StarContainer = styled.div`
  && {
    display: flex;
    align-items: center;
    justify-content: center;

    ${props => props.theme.breakpoints.down("sm")} {
      justify-content: left;
    }
  }

`

type DashboardRankListTypes = {
  data: Array<Object>,
  height?: number | null,
  settings: Object,
  source: string
};

const DashboardRankList = ({ data, height, settings, source }: DashboardRankListTypes): Element<*> => {
  const { app } = useLocation();
  const { dispatch } = useApp();
  const [order, setOrder] = useState(settings.sort.order !== 'ASC');

  const onClick = name => {
    setOrder(!order);

    const sort = {
      field: name,
      order: order ? 'ASC' : 'DESC'
    };

    const newSettings = {
      ...settings,
      sort
    };

    dispatch.setItem('tables', app, source, newSettings);
  };

  const DashboardTableCellIconContainer = styled.div`
    && {
      ${props => props.theme.breakpoints.down("sm")} {
        .dashboard-icon {
          margin-left: 10px;
        }
      }
    }

  `

  return (
    <TableResponsive height={height}>
      <Table.Container size="small" radius={2}>
        <TableHead>
          <TableRow>
            {settings.cells.map(cell => (
              <TableHeadCell>
                <SortButton onClick={() => onClick(cell.id)}>
                  {cell.label}
                  <SortButtonContainer show={cell.id === settings.sort.field}>
                    {order ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                  </SortButtonContainer>
                </SortButton>
              </TableHeadCell>
            ))}
            {app === 'clients' && <TableHeadCell>Risk</TableHeadCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(row => (
            <TableBodyRow key={row.id} active={row.rank}>
              <TableBodyCell className={"ranktable-rank"} align="center" isStar={row.rank === '1'}>{row.rank === '1' ? <StarContainer><Star /></StarContainer> : row.rank}</TableBodyCell>
              <TableBodyCell className={"ranktable-name"}>
                <UserIconContainer>
                  {row.username}
                  {row.you && (
                    <DashboardTableCellIconContainer>
                      <LazyIcon color="colorGreyLight2" ml={3} mr={1} component="UserOutline" className={"dashboard-icon"} />
                      <small>You</small>
                    </DashboardTableCellIconContainer>
                  )}
                </UserIconContainer>
              </TableBodyCell>
              <TableBodyCell className={"ranktable-score"}>{row.ess}</TableBodyCell>
              {app === 'clients' && (
                <TableBodyCell>
                  <ChartHalfCircle
                    title="Risk Level"
                    chart={{
                      chartData: [
                        {
                          name: row.risk,
                          // eslint-disable-next-line no-nested-ternary
                          value: ((row.risk === 'Low' ? 800 : row.risk === 'Med' ? 500 : 400) * 100) / 800
                        }
                      ]
                    }}
                    labels
                    showOf={false}
                    showLegends={false}
                    showTitle={false}
                    className="chart-root"
                  />
                </TableBodyCell>
              )}
            </TableBodyRow>
          ))}
        </TableBody>
      </Table.Container>
    </TableResponsive>
  );
};

DashboardRankList.defaultProps = {
  height: 27
};

export default memo<DashboardRankListTypes>(DashboardRankList);
