/* eslint-disable no-nested-ternary */
// @flow
import React, { createElement, createContext, type ComponentType, type Element, useEffect, useRef, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import { Error, Notification, Sidebar } from 'ra-ui-materialui';
import { useLocation } from 'hooks';
import { theme, BSN_SYSTEM } from 'conf';
import styled, { css } from 'styled-components';
import { AppBar } from './AppBar';
import { Menu } from './Menu';
import { Toolbar } from './Toolbar';
import { Footer } from '../common';
import { GlobalStyleBase } from './GlobalStyle';
import PaymentAlert from './PaymentAlert';
import MobileHeader from './MobileHeader';
import PageUnavailable from '../mobile/PageUnavailable';

import {isMobile as isMobileDevice} from "react-device-detect"
import { Hidden } from '@material-ui/core';

const sideMenuWidth = '300px';

export const MainContext = createContext(null);


export const Root: ComponentType<*> = styled.div`
  width: 100%;
  min-height: 100vh;
  text-align: ${({ centered }) => (centered ? 'center' : 'left')};
  ${({ minWidth }) => (minWidth ? `min-width: ${minWidth}px` : '')};
  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth}px` : '')};
`;

export const Frame: ComponentType<*> = styled.div`
  display: ${({ centered }) => (centered ? 'flex' : 'block')};
  min-height: 100vh;
  transform: translateX(${({ centered }) => (centered ? 0 : sideMenuWidth)});
  width: ${({ centered }) => (centered ? '100%' : `calc(100% - ${sideMenuWidth})`)};
  ${({ centered }) => (centered ? 'flex-direction: column' : '')};

  ${props => props.theme.breakpoints.down("sm")} {
    transform: none;
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: initial;
    height: var(--vh)px;
  }

  > header,
  > footer {
    ${({ centered }) => (centered ? 'flex: none;' : '')};
  }
`;

export const Main: ComponentType<*> = styled.main`
  display: ${({ centered }) => (centered ? 'flex' : 'block')};
  ${({ centered, isHaveAlert }) =>
    centered
      ? `
      flex-direction: column;
      flex: 1 0 auto;
    `
      :  `
      height: calc(100vh - ${isHaveAlert ? "159" : "109"}px);


  `};

  ${props => props.theme.breakpoints.down("sm")} {
    height: auto;
    flex-grow: 1;
    overflow-y: auto;
  }

  position: relative;
  /* padding-top: ${({ centered }) => (centered ? 0 : theme.layout.header.height)}; */
`;

export const SideBar: ComponentType<*> = styled.div`
  display: flex;
  position: fixed;
  height: calc(100vh);
  z-index: ${theme.zIndex.sidebar};
  box-sizing: border-box;
  width: ${sideMenuWidth};
  border-radius: 0 15px 15px 0;
  overflow: hidden;

  ${props => props.theme.breakpoints.down("sm")} {
    border-radius: 0 5px 5px 0;
    width: 285px;
    left: ${({open}) => open ? 0 : -285 }px;
    transition: .4s ease-in-out;
  }

  a {
    color: var(--colorCommonWhite);
    font-size: 1.2rem;
    letter-spacing: 2px;
    align-items: center;
    &:hover {
      background-color: var(--colorBaseDark2);
    }
  }

  > div > div {
    margin-top: 0;
  }
`;

export const Content: ComponentType<*> = styled.div`
  height: 100%;
  box-sizing: border-box;
  padding: ${({ hasSidebar }) => (hasSidebar ? '0 12' : '0 16')}px;
  overflow: auto;

  ${props => props.theme.breakpoints.down("sm")} {
    overflow: unset;
    padding: 0;
    ${({access}) => !access &&  css`
      min-height: 100%;
      height: unset;
    ` }
  }
`;

const sanitizeRestProps: Object = ({ staticContext, history, location, match, ...props }) => props;

type LayoutTypes = {
  appBar?: ComponentType<any>,
  children?: Function | Node | Element<*>,
  customRoutes?: Array<*>,
  dashboard?: Element<*>,
  error?: ComponentType<*>,
  history: Object,
  logout: Node | Function | string,
  menu?: ComponentType<*>,
  minWidth?: string | null,
  maxWidth?: string | null,
  notification?: ComponentType<*>,
  open?: boolean,
  headerTop?: number,
  sidebar?: ComponentType<*>,
  footer?: Element<*> | null,
  title: Node,
  centered?: boolean,
  contentRef: ?HTMLDivElement,
  userProfile: Object,
  dispatch: Function
};

const Layout = (props: LayoutTypes) => {
  const system = useSelector(state => state.bsn.system);
  const {access} = useSelector(({bsn}) => bsn.user);

  const { app, tab } = useLocation();
  const contentRef = useRef();
  const [openModalSideBar, setOpenModalSideBar] = useState(false);

  const vh = window.innerHeight * 0.01

  const {
    appBar,
    children,
    error,
    dashboard,
    logout,
    menu,
    minWidth,
    maxWidth,
    notification,
    sidebar,
    footer,
    title,
    centered,
    dispatch
  } = props;
  const isApp = !centered;

  const mobilePagesWhiteList = {
    myDashboard: ["dashboard", "securityTraining",  "microTrainings", "darkWeb"],
    newsfeed: ["dashboard"]
  }

  const isPageAvailable = (app, tab) => {
    if ((!app || tab === false || tab === "false") && app !== "clients") return true;

    if (mobilePagesWhiteList[app]) {
      return mobilePagesWhiteList[app].includes(tab)
    } else {
      return false
    }
  }

  useEffect(() => {
    if (!isApp) return;
    const newSysten = system;
    newSysten.sidebar = sidebar !== null;
    newSysten.app = isApp;
    newSysten.location = app;
    if (system.locationPrevious === null) newSysten.locationPrevious = newSysten.location;
    props.history.listen(() => {
      if (system.hasError) newSysten.hasError = false;
    });
    if (JSON.stringify(system) !== JSON.stringify(newSysten)) {
      dispatch({ type: BSN_SYSTEM, source: 'system', payload: newSysten });
    }
  }, [app, centered, dispatch, isApp, props, sidebar, system]);

  const { hasError, errorMessage, errorInfo, hasSidebar, hasDarkMode } = system;
  const mainRef = useRef();

  return (
    <>
      {// TODO: Temporary solution to have color scheme on login page
      (app === 'login' || app === 'logout' || app === 'signup') && <GlobalStyleBase />}
      <Root {...sanitizeRestProps(props)} minWidth={minWidth} maxWidth={maxWidth} centered={centered} style={{minHeight: "-webkit-fill-available", height: "-webkit-fill-available" }}>
        {sidebar && (
          <SideBar open={openModalSideBar} style={{minHeight: "-webkit-fill-available", height: "-webkit-fill-available" }}>
            <Toolbar />
            {menu
              ? createElement(menu, {
                  logout,
                  setOpenModalSideBar,
                  hasDashboard: !!dashboard
                })
              : null}
          </SideBar>
        )}
        <Frame centered={centered} style={{height: `${(vh * 100)}px`}}>
          {
            isMobileDevice && !isPageAvailable(app, tab) && access ? (
                <Hidden mdUp>
                  <PageUnavailable/>
                </Hidden>
            ) : (
              <>
                <Hidden mdUp>
                  <MobileHeader open={openModalSideBar} setOpen={setOpenModalSideBar} access={access}/>
                </Hidden>
                <PaymentAlert access={access}/>
                {appBar &&
                createElement(appBar, {
                  title,
                  logout,
                  centered,
                  hasDarkMode
                })}
                <Main centered={centered} isHaveAlert={access?.alerts} ref={mainRef}>
                  <Content onScroll={e => e} ref={contentRef} hasSidebar={hasSidebar} centered={centered} access={access}>
                    <MainContext.Provider value={{mainRef}}>
                      {hasError && error
                        ? createElement(error, {
                          error: errorMessage,
                          errorInfo,
                          title
                        })
                        : children}
                    </MainContext.Provider>
                  </Content>
                </Main>
                {footer || <Footer />}
              </>
            )
          }
        </Frame>
        {notification && createElement(notification)}
      </Root>
    </>
  );
};

Layout.defaultProps = {
  children: null,
  customRoutes: [],
  dashboard: null,
  open: false,
  appBar: AppBar,
  error: Error,
  menu: Menu,
  minWidth: null,
  maxWidth: null,
  notification: Notification,
  sidebar: Sidebar,
  footer: null,
  headerTop: 0,
  centered: false
};

const EnhancedLayout = compose(
  connect(() => {}),
  withRouter
)(Layout);

type LayoutWithThemeProps = {
  theme?: Object
};

const LayoutWithTheme = ({ theme: LTheme, ...props }: LayoutWithThemeProps) => {
  return (
    <MuiThemeProvider theme={createMuiTheme(LTheme)}>
      <EnhancedLayout {...props} />
    </MuiThemeProvider>
  );
};

LayoutWithTheme.defaultProps = {
  theme
};

export default LayoutWithTheme;
