// @flow
import { createMuiTheme } from '@material-ui/core/styles';
import palette from './palette';
import shadows from './shadows';
import typography from './typography';
import breakpoints from './breakpoints';
import shape from './shape';
import spacing from './spacing';
import transitions from './transitions';
import zIndex from './zIndex';
import layout from './layout';

const theme = {
  palette,
  shadows,
  typography,
  breakpoints,
  shape,
  spacing,
  transitions,
  zIndex,
  nprogress: { color: palette.common.black },
  overrides: {
    MuiOutlinedInput: {
      notchedOutline: {
        transition: 'border-color 0.3s'
      }
    }
  },
  themeName: 'bsn2019',
  layout
};

export default createMuiTheme(theme);
