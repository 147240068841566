// @flow
import React, { type Element } from 'react';
import SRA from './SRA/SRA';

type PageSRATypes = {};

const PageSRA = (): Element<*> => {
  return <SRA />;
};

export default PageSRA;
