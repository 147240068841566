// @flow

const documentsServiceProviderInfo = {
  title: 'Service Provider Contract Information',
  component: 'clients/ClientsEditDocumentsServiceProviderInfoModal',
  paramKey: 'client_id',
  requireRowId: true,
  buttons: {
    cancel: {
      label: 'Cancel'
    },
    submit: {
      icon: 'Done',
      label: 'Save',
      uploadType: 'documents' // to UploadPresignedURL API
    },
    other: [
      {
        icon: 'Download',
        label: 'Download',
        key: 'attachment_link',
        type: 'download'
      }
    ]
  },
  initialState: null
};

export default documentsServiceProviderInfo;
