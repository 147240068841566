import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import newsfeed from 'helpers/apis/newsfeed';
import { setNotifications, updateNotifications } from 'helpers/action';
import { getStorage, decodeId, encodeId, authProvider } from 'helpers';
import { makeStyles } from '@material-ui/core/styles';
import { LoadingStyled } from 'components';
import { Box, Drawer, Divider } from '@material-ui/core';
import { NotificationAvatar } from '../common';
import ToastCloseButton from './ToastCloseButton';
import NoNotifications from './NoNotifications';
import ReactTimeAgo from 'react-time-ago';
import InfiniteScroll from 'react-infinite-scroll-component';
import { BSN_SET_USER_NOTIFICATION_COUNT, BSN_SET_USER_ALL_NOTIFICATIONS_SEEN } from 'conf';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 460,
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
      maxWidth: 460
    },
  },
  timeAgo: {
    color: '#847e7e',
    fontSize: '10px',
    display: 'inline-block',
    '&::first-letter': {
      textTransform: 'uppercase'
    }
  }
}));

export default function TemporaryDrawer({ isOpen, onClose }) {
  const dispatch = useDispatch();
  const notifications = useSelector(state => state.newsfeed.notifications);
  const reloadNotifications = useSelector(state => state.newsfeed.reloadNotifications);
  const classes = useStyles();
  const [hasMore, setHasMore] = useState(true);
  const user = {
    id: decodeId(getStorage('userId'))
  };

  const markNotificationSeen = (type, id, postId,opened) => {
    window.location.href = `/#/newsfeed/dashboard/${postId}?notification_id=${id}&notification_type=${type}&previouslyOpened=${opened}`;
  };

  const fetchData = () => {
    newsfeed
      .getNotifications(user.id, notifications.length)
      .then(res => {
        if (res.data.length !== 0) {
          let notificationsArray = [...notifications, ...res.data];
          dispatch(setNotifications(notificationsArray));
        } else setHasMore(false);
      })
      .catch(err => {
        console.clear();
        console.log(err);
      });
  };

  const initialFetch = () => {
    newsfeed
      .getNotifications(user.id, 0)
      .then(res => {
        dispatch(setNotifications(res.data));
        if (res.data.length % 5 !== 0 || res.data.length === 0) setHasMore(false);
      })
      .catch(err => {
        console.log(err);
      });
  }

  useEffect(() => {
    initialFetch();
  }, []);

  useEffect(() => {
    if (reloadNotifications) {
      authProvider
        .userAuthorize()
        .then(data => {
          dispatch({
            type: BSN_SET_USER_NOTIFICATION_COUNT,
            payload: {
              replace: true,
              value: data.user.newsfeed_unopened_notifications_count
            }
          });
          dispatch({
            type: BSN_SET_USER_ALL_NOTIFICATIONS_SEEN,
            payload: data.user.all_newsfeed_notifications_seen
          });
        });

      initialFetch();
      dispatch(updateNotifications(false));
    }
  }, [reloadNotifications]);

  const onFocusChange = () => {
    if (!document.hidden){
      initialFetch();
    }
  };

  useEffect(() => {
    window.addEventListener('visibilitychange', onFocusChange);
    return () => {
      window.removeEventListener('visibilitychange', onFocusChange);
    };
  }, []);

  return (
    <Drawer anchor={'right'} open={isOpen} onClose={onClose}>
      <div role="presentation" onClick={onClose} className={classes.drawer}>
        <div style={{ fontSize: '18px', fontWeight: '700', padding: '15px 20px', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
          Notifications
          <span onClick={onClose} style={{ float: 'right', cursor: 'pointer' }}>
            <ToastCloseButton />
          </span>
        </div>

        {!notifications ? (
          <LoadingStyled />
        ) : !notifications.length ? (
          <NoNotifications />
        ) : (
          <div id="scrollableDivNotifications" style={{ height: '90vh', overflowY: 'scroll' }}>
            <InfiniteScroll
              dataLength={notifications.length} //This is important field to render the next data
              next={fetchData}
              hasMore={hasMore}
              loader={<LoadingStyled />}
              endMessage={
                <p style={{ textAlign: 'center' }}>
                  <b>Yay! You have seen it all</b>
                </p>
              }
              scrollableTarget="scrollableDivNotifications"
            >
              {notifications.map(notification => (
                <>
                  <Box
                    px={1}
                    py={1.5}
                    display="flex"
                    alignItems="center"
                    style={{ backgroundColor: notification.opened === 1 ? 'inhirit' : '#EDF1FA', cursor: 'pointer' }}
                    onClick={() =>
                      markNotificationSeen(
                        notification.type,
                        encodeId(notification.notification_id),
                        encodeId(notification.data.post.id),
                        notification.opened
                      )
                    }
                  >
                    <Box p={1}>
                      <NotificationAvatar user={notification.data.user} type={notification.type} size='sm' />
                    </Box>
                    <Box>
                      <Box pl={1} pr={1} sx={{ display: 'block' }}>
                        {
                          {
                            comment_like: (
                              <>{`${notification.data.user.first_name} ${notification.data.user.last_name} liked your comment`}</>
                            ),
                            post_like: (
                              <>{`${notification.data.user.first_name} ${notification.data.user.last_name} liked your post`}</>
                            ),
                            post_comment: (
                              <>
                                {`${notification.data.user.first_name} ${notification.data.user.last_name} commented on your post`}
                              </>
                            )
                          }[notification.type]
                        }
                      </Box>
                      <Box pl={1} pr={1} sx={{ display: 'block' }}>
                        <ReactTimeAgo
                          className={classes.timeAgo}
                          timeStyle="round-minute"
                          date={notification.timestamp.replace(' ', 'T') + 'Z'}
                          locale="en-US"
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Divider />
                </>
              ))}
            </InfiniteScroll>
          </div>
        )}
      </div>
    </Drawer>
  );
}
