// @flow
import React, { type Element, useState, useEffect, useReducer } from 'react';
import { useNotify } from 'react-admin';
import { CircularProgress } from '@material-ui/core';
import { SelectField, LoadingStyled, TableList, Button, LazyIcon, PageError } from 'components';
import { dataProvider } from 'helpers';
import { useLocation, useId, useDataList } from 'hooks';
import TrainingMicrotrainings from './TrainingMicrotrainings';
import { useTrainings } from './TrainingContext';
import reporting from '../../../helpers/apis/reporting';

type TrainingStateTypes = {
  filter: boolean | string | number,
  reportType: 'annual' | 'micro-training',
  id: string,
  trainingFilters: {
    category: Array<{ value: boolean | string | number, label: string }>,
    type: {
      annual: Array<{
        current_course: boolean,
        id: string,
        label: string,
        training_id: number | string,
        value: string
      }>,
      'micro-training': Array<{ value: string, label: string }>
    }
  }
};

type TrainingDispatchTypes = {
  type: string,
  payload: any
};

const initialState: TrainingStateTypes = {
  reportType: 'annual',
  filter: '',
  id: '',
  trainingFilters: {
    category: [{ label: '', value: '' }],
    type: {
      annual: [
        {
          current_course: false,
          id: '',
          label: '',
          training_id: 0,
          value: ''
        }
      ],
      'micro-training': [{ label: '', value: '' }]
    }
  }
};

const Training = (): Element<*> => {
  const { dispatch } = useTrainings();
  const app = 'clients';
  const tab = 'trainingFilter';
  const userId = useId({ key: 'clientId' });
  const { item: itemLoc } = useLocation();
  const item = itemLoc || userId;
  const { data, isSuccess } = useDataList({ app, tab, item });
  const [state, setState] = useReducer<TrainingStateTypes, TrainingDispatchTypes>(reducer, initialState);
  const notify = useNotify();
  const [loadingReport, setLoadingReport] = useState(false);
  const [pageError, setPageError] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      if (Object.keys(data.type).length) setState({ type: 'INITIALIZE', payload: data });
      else setPageError(true);
    }
  }, [item, data, isSuccess, state.trainingFilters]);

  if (state.trainingFilters === null || state.id === null || state.filter === null) return <LoadingStyled />;

  const onChangeValue = ({ target: { value: val } }) => {
    dispatch.setRadio(0);
    setState({ type: 'CHANGETYPE', payload: val });
  };

  const onChangeSubValue = ({ target: { value: val } }) => {
    const index = state.trainingFilters.type[state.reportType].findIndex(x => x.value === val);
    const { id, value: filt } = state.trainingFilters.type.annual[index];

    setState({ type: 'CHANGEFILTER', payload: { id, filt } });
  };

  const downloadAnnualReport = e => {
    setLoadingReport(true);
    reporting
      .getReport({
        report_key: "AnnualTrainingClients",
        recipe: "xlsx",
        delivery: "url",
        parameters: { client_id: item, course_code: state.filter },
      })
      .then(response => window.open(response.data.url))
      .catch(error => notify(`${error}`, error))
      .finally(() => setLoadingReport(false));
  };

  const Toolbar = () => (
    <>
      <SelectField
        name="training_report_type"
        id="training_report_type"
        value={state.reportType}
        choices={state.trainingFilters.category}
        emptyValue="Training Report Type"
        onChange={onChangeValue}
        allowEmpty
        mr={2}
        variant
      />
      {state.reportType === 'annual' && (
        <SelectField
          name="filter"
          id="filter"
          emptyValue="Filter"
          highlightKey="current_course"
          value={state.filter}
          choices={state.trainingFilters.type[state.reportType]}
          onChange={onChangeSubValue}
          variant
        />
      )}
      {state.reportType === 'annual' && (
        <Button onClick={e => downloadAnnualReport(e)} ml={2}>
          {!loadingReport ? (
            <>
              <LazyIcon component="Download" color="colorCommonWhite" mr={1} size={0.9} />
              &nbsp; Download Annual Training Report
            </>
          ):(
            <>
              <CircularProgress style={{color: 'white'}} size={18} thickness={3} />
              &nbsp; Loading Training Report...
            </>
          )}
        </Button>
      )}
    </>
  );

  const Table = ({ id }: { id: string }) => (
    <TableList
      keyCheckToHideIcon="hideCert"
      paramKeyForModal="training_id"
      source="trainingAnnual"
      setApp={app}
      id={id}
      toolbar={<Toolbar state={state} />}
    />
  );

  return pageError ? (
    <PageError
      title='Oops!'
      subtitile='There was a problem with the page?'
      description='Try and refresh the page, if the problem still exists then Contact Us'
      linkText='Contact Us'
      linkTo='/user/contactUs'
    />
  ) : state.reportType === 'annual' ? (
    <Table id={`${item}?_reportId=${state.id}`} />
  ) : (
    <TrainingMicrotrainings toolbar={<Toolbar />} />
  );
};

function reducer(prevState, { type, payload }) {
  switch (type) {
    case 'INITIALIZE': {
      if (payload.length === 0) return prevState;
      const t = payload.type;
      console.log(payload)
      return {
        ...prevState,
        reportType: Object.keys(t)[0],
        filter: type ? t[Object.keys(t)[0]][0].value : '',
        id: t ? t[Object.keys(t)[0]][0][Object.keys(t)[0] === 'annual' ? 'id' : 'label'] : '',
        trainingFilters: payload
      };
    }

    case 'CHANGETYPE': {
      return {
        ...prevState,
        reportType: payload,
        filter: prevState.trainingFilters.type[payload][0].value,
        id: prevState.trainingFilters.type[payload][0].id
      };
    }

    case 'CHANGEFILTER': {
      return {
        ...prevState,
        id: payload.id,
        filter: payload.filt
      };
    }

    default: {
      return { ...prevState, ...payload };
    }
  }
}

export default Training;
