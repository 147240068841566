// @flow

import { useEffect, useReducer } from 'react';

type useDebounceType = {
  value: string,
  delay: number
};

type useDebounceActionType = { type: string, payload: any };

function useDebounce(props: useDebounceType = { value: '', delay: 300 }) {
  const { value, delay } = props;
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useReducer<useDebounceType, useDebounceActionType>(
    (previousState, { type, payload }): useDebounceType => {
      return { ...previousState, value: payload.value, delay: payload.delay };
    },
    props
  );

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue({ type: 'SET', payload: { value, delay } });
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return [debouncedValue, setDebouncedValue];
}

export default useDebounce;
