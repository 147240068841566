import React, { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import styled from "styled-components";
import { IconButton } from '@material-ui/core';
import { useLocation } from 'hooks';
import { Bar, NotificationAlert } from 'components';
import { NotificationsMenu } from 'apps/newsfeed/components';
import newsfeed from 'helpers/apis/newsfeed';
import { BSN_SET_USER_ALL_NOTIFICATIONS_SEEN } from 'conf';

const MobileHeaderContainer = styled.div`
  padding: 0 8px 0 14px;
  min-height: 56px;
  background-color: var(--colorBaseBase);
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const MobileHeaderBlackWindow = styled.div`
  display: ${({open}) => !open ? "none" : "block"};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0,0,0,.3);
  width: 100%;
  height: 100%;
`
const NotificationDot: ComponentType<*> = styled.span`
  position: absolute;
  width: 10px;
  height: 10px;
  display: inline-block;
  background: #FF5A61;
  border-radius: 50%;
  top: 9px;
  right: 9px;
`;

const MobileHeader = ({setOpen, open, access}) => {
  const dispatch = useDispatch();
  const { app } = useLocation();
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const newsfeedAccess = useSelector(({ bsn }) => bsn?.user?.access?.apps?.newsfeed);
  const allNewsfeedNotificationsSeen = useSelector(({ bsn }) => bsn?.user?.profile?.all_newsfeed_notifications_seen);
  const newsfeedLicenseAgreed = useSelector(state => state.newsfeed.license?.newsfeed_license_agreed);
  const showNotificationIcon = newsfeedAccess && app === 'newsfeed' && newsfeedLicenseAgreed;
  
  if (!access) return null;

  const handleOpen = () => {
    setOpen(open => !open)
  };

  const handleClickIcon = () => {
    setIsNotificationsOpen(true);
    if (!allNewsfeedNotificationsSeen) {
      newsfeed
        .markAllSeen()
        .then(res => {
          dispatch({
            type: BSN_SET_USER_ALL_NOTIFICATIONS_SEEN,
            payload: 1
          });
        });
    }
  };

  return (
    <MobileHeaderContainer>
      <MobileHeaderBlackWindow open={open} onClick={handleOpen}/>
      <Bar style={{width: 24, height: 24}} onClick={handleOpen} />
      {showNotificationIcon ? (
        <>
          <IconButton style={{ padding: 8 }} onClick={handleClickIcon}>
            <NotificationAlert size={1.715} color="colorCommonWhite" />
            {allNewsfeedNotificationsSeen ? null : <NotificationDot />}
          </IconButton>
          <NotificationsMenu isOpen={isNotificationsOpen} onClose={() => setIsNotificationsOpen(false)} />
        </>
      ) : null}
    </MobileHeaderContainer>
  )
}

export default MobileHeader;
