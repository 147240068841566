// @flow
import React, { type Element } from 'react';
import { TableListProvider, type TableListProviderTypes } from './TableListContext';
import TableListTable from './TableListTable';

const TableList = (props: TableListProviderTypes): Element<*> => (
  <TableListProvider {...props}>
    <TableListTable />
  </TableListProvider>
);

TableList.defaultProps = {
  data: null,
  source: '',
  id: '',
  tableSettings: null,
  onSearch: e => e,
  cache: true
};

export default TableList;
