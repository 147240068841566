// @flow
import React, { useState } from 'react';
import { useNotify } from 'react-admin';
import { IconButton, Menu, MenuItem, makeStyles } from '@material-ui/core';
import {
  MoreVert as MoreVertIcon,
  Visibility as VisibilityIcon,
  Edit as EditIcon,
  PinDropOutlined as PinDropOutlinedIcon,
  CloudDownloadOutlined as CloudDownloadOutlinedIcon,
  BookmarkBorderOutlined as BookmarkBorderOutlinedIcon,
  FileCopyOutlined as FileCopyOutlinedIcon,
  DeleteOutlined as DeleteOutlinedIcon
} from '@material-ui/icons';
import { CopyLink } from 'components';
import { AddUpdatePostModal, DeletePostModal } from '../modals';
import { getStorage, decodeId, fromUTCtoLocal, encodeId, copyTextToClipboard } from 'helpers';

const useStyles = makeStyles(theme => ({
  menuBtn: {
    padding: '5px',
    '& svg': {
      fontSize: 30,
      color: 'var(--colorGreyLight2)'
    }
  },
  menuLink: {
    fontSize: '16px',
    padding: '10px 40px 10px 20px'
  }
}));

const PostHeaderActions = ({ feed, feedIdx, updateAfterPostModalClose }) => {
  const classes = useStyles();
  const notify = useNotify();
  const user = { id: decodeId(getStorage('userId')) };
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(null);
  const isOwnPost = feed.user.id === Number(user.id);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openAddUpdatePostModal, setOpenAddUpdatePostModal] = useState(false);

  const handleClick = event => {
    setAnchorEl({ [event.currentTarget.id]: event.currentTarget });
    setOpen({ [event.currentTarget.id]: true });
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(null);
  };

  const copyToClipboard = () => {
    const postId = encodeId(feed.id);
    const postLink = `${window.location.origin}/#/newsfeed/dashboard/${postId}`;
    copyTextToClipboard(postLink);
    notify('Link has been copied to your clipboard');
  };

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls={`long-menu${feedIdx}`}
        aria-haspopup="true"
        id={`menu-control${feedIdx}`}
        onClick={handleClick}
        disabled={feed.disableActions}
        style={{ opacity: feed.disableActions ? '0.5' : '1' }}
        className={classes.menuBtn}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={`long-menu${feedIdx}`}
        anchorEl={
          anchorEl && anchorEl.hasOwnProperty(`menu-control${feedIdx}`) ? anchorEl[`menu-control${feedIdx}`] : null
        }
        keepMounted
        open={open && open.hasOwnProperty(`menu-control${feedIdx}`) ? open[`menu-control${feedIdx}`] : false}
        onClose={handleClose}
        PaperProps={{
          style: {}
        }}
      >
        {/* Edit */}
        {isOwnPost && (
          <MenuItem
            className={classes.menuLink}
            onClick={() => {
              setOpenAddUpdatePostModal(true);
              handleClose();
            }}
          >
            <EditIcon /> &nbsp;&nbsp; Edit Post
          </MenuItem>
        )}
        {openAddUpdatePostModal && (
          <AddUpdatePostModal
            open={openAddUpdatePostModal}
            close={() => setOpenAddUpdatePostModal(false)}
            reload={updateAfterPostModalClose}
            title="Edit post"
            post={{
              user_id: user.id,
              postId: feed.id,
              idx: feedIdx,
              expiration_datetime:
                feed.expiration_datetime !== '9999-12-31 23:59:59' ? fromUTCtoLocal(feed.expiration_datetime) : null,
              show_datetime: feed.show_datetime !== '1000-01-01 00:00:00' ? fromUTCtoLocal(feed.show_datetime) : null,
              text: feed.text,
              thumbnail_metadata: feed.content && feed.content.type === 'thumbnail' ? feed.content.data : null,
              filename: feed.content && feed.content.type !== 'thumbnail' ? feed.content.data.filename : null,
              filePreview: feed.content && feed.content.type !== 'thumbnail' ? feed.content : null
            }}
          />
        )}

        {/* Copy Link */}
        <MenuItem
          className={classes.menuLink}
          onClick={() => {
            copyToClipboard();
            handleClose();
          }}
        >
          <CopyLink size={1.1} /> &nbsp;&nbsp; Copy Link
        </MenuItem>

        {/* Delete */}
        {isOwnPost && (
          <MenuItem
            className={classes.menuLink}
            style={{ color: 'red' }}
            onClick={() => {
              setOpenDeleteModal(true);
              handleClose();
            }}
          >
            <DeleteOutlinedIcon /> &nbsp;&nbsp; Delete
          </MenuItem>
        )}
        {openDeleteModal && (
          <DeletePostModal
            close={() => setOpenDeleteModal(false)}
            open={openDeleteModal}
            postId={feed.id}
            postIdx={feedIdx}
          />
        )}
      </Menu>
    </>
  );
};

export default PostHeaderActions;

{/* <MenuItem style={{ fontSize: 16, color: '#94959B', padding: '10px 40px 10px 20px' }} onClick={handleClose}>
    <VisibilityIcon /> &nbsp;&nbsp; Preview
  </MenuItem> */}
{/* <MenuItem style={{ fontSize: 16, color: '#94959B', padding: '10px 40px 10px 20px' }} onClick={handleClose}>
  <BookmarkBorderOutlinedIcon /> &nbsp;&nbsp; Favorite
</MenuItem>
<MenuItem style={{ fontSize: 16, color: '#94959B', padding: '10px 40px 10px 20px' }} onClick={handleClose}>
  <PinDropOutlinedIcon /> &nbsp;&nbsp; Unpin Post
</MenuItem>
<MenuItem style={{ fontSize: 16, color: '#94959B', padding: '10px 40px 10px 20px' }} onClick={handleClose}>
  <CloudDownloadOutlinedIcon /> &nbsp;&nbsp; Download
</MenuItem> */}