// @flow
import React from 'react';
import { Typography, makeStyles, Link, Box, Grid } from '@material-ui/core';
import { CloudDownloadOutlined as CloudDownloadOutlinedIcon } from '@material-ui/icons';
import Thumbnail from '../common/Thumbnail';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '20px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '12px',
    }
  },
  paper: {
    padding: '15px',
    marginBottom: '20px',
    color: theme.palette.text.secondary
  },
  padded: {
    padding: '15px'
  },
  image: {
    maxWidth: '100%',
    display: 'block',
    borderRadius: '5px',
    overflow: 'hidden'
  },
  deleteIcon: {
    backgroundColor: 'rgba(255, 90, 97, 0.2)',
    color: '#FF5A61',
    borderRadius: '50%',
    padding: '10px',
    fontSize: '25px'
  },
  iframeText: {
    margin: '30px auto',
    color: '#8a8a8a',
    fontSize: '20px'
  },
  iframeContainer: {
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    paddingTop: '56.25%'
  },
  iframe: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: '100%',
    height: '100%'
  }
}));

const PostBodyContent = ({ content }) => {
  const classes = useStyles();
  const { data, type } = content;

  return (
    <Grid container item xs={12} className={classes.root}>
      {{
        image: (
          <div>
            <img className={classes.image} src={data.url} />
          </div>
        ),
        video:
          data.status === 'ready' ? (
            <div className={classes.iframeContainer}>
              <iframe
                title="Feed Video"
                src={`https://iframe.videodelivery.net/${data.video_id}`}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className={classes.iframe}
              />
            </div>
          ) : (
            <Typography style={{ margin: '30px auto', color: '#8a8a8a', fontSize: '20px' }}>
              Video is processing...
            </Typography>
          ),
        thumbnail: (
          <Thumbnail data={data} />
        )
      }[type] || (
        <Link href={data.url} target="_blank" style={{ display: 'inline-block' }}>
          <img
            style={{ maxWidth: '50px', display: 'block' }}
            src={`/media/${data.filename.split('.')[data.filename.split('.').length - 1]}.png`}
          />
          <Box display="flex" alignItems="center" mt={1}>
            <Box mr={1} style={{ wordBreak: 'break-word' }}>
              {data.filename.replace(/.*?_/, '')}
            </Box>
            <CloudDownloadOutlinedIcon style={{ fontSize: 20 }} />
          </Box>
        </Link>
      )}
    </Grid>
  );
};

export default PostBodyContent;
