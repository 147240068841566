// @flow
import { 
  BSN_GET_LIST,
  BSN_SET_ITEM,
  BSN_CLEAR,
  BSN_SET_USER,
  BSN_UPDATE_USER_PROFILE,
  BSN_SET_CLIENT_ACCESS,
  BSN_SET_USER_NOTIFICATION_COUNT,
  BSN_SET_USER_ALL_NOTIFICATIONS_SEEN
} from 'conf/constants';
import { initialState, type initialStateType } from 'conf/initialState';
import { toArray } from 'helpers/utils';
import { type bsnActionType } from '../action';

function mergeState(previous, next) {
  if (JSON.stringify(previous) === JSON.stringify(next)) return previous;
  return Array.isArray(previous) ? [...previous, ...next] : next;
}

export default (
  previousState: initialStateType = initialState,
  { type, resource, tab, item, key, payload, atBeginning }: bsnActionType
): Object | bsnActionType => {
  switch (type) {
    case 'set_color_scheme': {
      return {
        ...previousState,
        themeSettings: payload
      };
    }

    case BSN_CLEAR: {
      return { ...previousState, ...payload };
    }

    case BSN_SET_USER: {
      const newState = previousState;
      newState.user.profile = payload.profile;
      newState.user.access = payload.access;
      newState.user.theme = payload.theme;
      newState.user = { ...payload };
      return newState;
    }

    case BSN_SET_USER_NOTIFICATION_COUNT: {
      const newState = previousState;
      if (payload.replace) {
        newState.user.profile.newsfeed_unopened_notifications_count = payload.value;
      } else {
        newState.user.profile.newsfeed_unopened_notifications_count = previousState.user.profile.newsfeed_unopened_notifications_count + payload.value;
      }
      return newState;
    }

    case BSN_SET_USER_ALL_NOTIFICATIONS_SEEN: {
      const newState = previousState;
      newState.user.profile.all_newsfeed_notifications_seen = payload;
      sessionStorage.setItem('allNewsfeedNotificationsSeen', payload);
      return newState;
    }

    case BSN_UPDATE_USER_PROFILE: {
      const newState = { ...previousState };
      newState.user.profile.first_name = payload.firstName;
      newState.user.profile.last_name = payload.lastName;
      return newState;
    }

    case BSN_GET_LIST: {
      const newState = previousState;

      if (item) {
        if (newState[resource][tab] === null) newState[resource][tab] = {};
        newState[resource][tab][item] = mergeState(previousState[resource][tab][item], payload);
      } else newState[resource][tab] = mergeState(previousState[resource][tab], payload);
      return newState;
    }

    case BSN_SET_ITEM: {
      const newState = previousState;
      if (item) newState[resource][tab][item][key] = payload;
      else if (key) newState[resource][tab][key] = payload;
      else newState[resource][tab] = payload;
      return newState;
    }

    case 'BSN_SET_CONFIG_MODAL': {
      const newPayload = previousState;
      if (!(resource in newPayload)) newPayload[resource] = {};
      newPayload[resource][tab] = payload;

      return { ...newPayload };
    }

    case BSN_SET_CLIENT_ACCESS: {
      const newState = previousState;
      const access = payload.access;
      
      if (access) newState.clients.clientUpdatedAccess = access;
      else newState.clients.clientUpdatedAccess = null;

      return { ...newState };
    }

    default: {
      const newPayload = previousState;
      if (resource && tab) {
        const arrayTab = toArray(tab);
        const arrayPayload = [];
        arrayPayload.push(payload);
        const arrayItem = toArray(item);
        // console.log(resource);
        // console.log(tab);
        // console.log(payload);
        // console.log(arrayTab);
        // console.log(arrayPayload);
        arrayTab.forEach((k, i) => {
          // console.log(k);
          // console.log(arrayPayload[i]);
          // console.log(newPayload[resource][k]);
          !arrayItem[i]
            ? (newPayload[resource][k] = arrayPayload[i])
            : (newPayload[resource][k] = {
                ...newPayload[resource][k],
                [arrayItem[i]]: arrayPayload[i]
              });
        });
      } else {
        newPayload[resource] = {
          ...previousState[resource],
          ...payload
        };
      }
      // console.log(newPayload);
      return newPayload;
    }
  }
};
