// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Plus = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 14.695 14.695" role="img" aria-hidden="false">
    <path fill="none" d="M0 0h16v16H0z" />
    <g transform="translate(-1212 -275)">
      <path
        d="M20.361,12.161H14.532V6.334A.334.334,0,0,0,14.2,6H12.5a.334.334,0,0,0-.334.334v5.827H6.334A.334.334,0,0,0,6,12.5v1.7a.334.334,0,0,0,.334.334h5.827v5.829a.334.334,0,0,0,.334.334h1.7a.334.334,0,0,0,.334-.334V14.532h5.829A.334.334,0,0,0,20.7,14.2V12.5A.334.334,0,0,0,20.361,12.161Z"
        transform="translate(1206 269)"
      />
    </g>
  </SvgIconStyled>
);

export default Plus;
