// @flow
import React from 'react';
import { Route } from 'react-router-dom';
import { ThemeProvider as ThemeProviderStyled } from 'styled-components';
import { Resource } from 'react-admin';
import { ThemeProvider } from '@material-ui/core/styles';

import {
  clients,
  company,
  dashboard,
  partner,
  user,
  UserFederated,
  UserSetPassword,
  UserForgotPassword,
  UserNew,
  MicroTrainingPublicView,
  newsfeed,
  UserLogin,
  UserLogout,
  UserSignup
} from 'apps';
import { Layout, Admin, GlobalStyle, theme, PageExpiredSession } from 'components';
import { theme as MuiTheme } from "conf";
import AADAuth from 'apps/shared/directorySync/DirectorySyncAzureActiveDirectoryAuth';
import { authProvider, dataProvider, reducer, trackingUtils } from 'helpers';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

trackingUtils.initialize();

const env = process.env.REACT_APP_BUILD_ENV;
const queryClient = new QueryClient();
const App = () => {
  return (
    <ThemeProviderStyled theme={{...MuiTheme, ...theme}}>
      <QueryClientProvider client={queryClient}>
        {env === 'dev' && <ReactQueryDevtools initialIsOpen={false} />}
        <GlobalStyle />
        <ThemeProvider>
          <Admin
            layout={Layout}
            dataProvider={dataProvider}
            authProvider={authProvider}
            loginPage={UserLogin}
            customReducers={reducer}
            customRoutes={[
              <Route key="signup" exact path="/signup" component={UserSignup} noLayout />,
              <Route key="logout" exact path="/logout" component={UserLogout} noLayout />,
              <Route key="federated" exact path="/federated" component={UserFederated} noLayout />,
              <Route key="setPassword" exact path="/setPassword" component={UserSetPassword} noLayout />,
              <Route key="forgotPassword" exact path="/forgotPassword" component={UserForgotPassword} noLayout />,
              <Route key="newUser" exact path="/newUser" component={UserNew} noLayout />,
              <Route key="MicroTrainingPublicView" exact path="/MicroTrainingPublicView" component={MicroTrainingPublicView} noLayout />,
              <Route key="expiredSession" exact path="/expiredSession" component={PageExpiredSession} noLayout />,
              <Route key="aadAuth" path="/aadAuth" component={AADAuth} noLayout />
            ]}
          >
            <Resource {...dashboard} />
            <Resource {...newsfeed} />
            <Resource {...company} />
            <Resource {...clients} />
            <Resource {...partner} />
            <Resource {...user} />
          </Admin>
        </ThemeProvider>
      </QueryClientProvider>
    </ThemeProviderStyled>
  );
};

export default App;
