/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
// @flow
import axios from 'axios';
import paginationConfig from '../conf/state/system/table/pagination';
import { getIdByResource, getId, isObjectEmpty, hasKey } from './utils';
import { getStorage, clearStorage } from './storage';

const env = process.env.REACT_APP_BUILD_ENV;
const base = process.env.REACT_APP_BASE_URL;
const prefix = {
  myDashboard: {
    default: process.env.REACT_APP_BASE_URL_1,
    microTrainings: process.env.REACT_APP_BASE_URL_2,
    microTrainingsQuiz: process.env.REACT_APP_BASE_URL_2,
    DashboardQuizModal: process.env.REACT_APP_BASE_URL_2,
    DashboardQuizModalAddMark: process.env.REACT_APP_BASE_URL_2,
    DashboardCertificateModal: process.env.REACT_APP_BASE_URL_2,
    trainingCertificate: process.env.REACT_APP_BASE_URL_2,
    get_signed_cookie: process.env.REACT_APP_BASE_URL_2,
    trainingDetails: process.env.REACT_APP_BASE_URL_2
  },
  myCompany: {
    default: process.env.REACT_APP_BASE_URL_1
  },
  clients: {
    default: process.env.REACT_APP_BASE_URL_3,
    clientsTrainingCertificateViewModal: process.env.REACT_APP_BASE_URL_2,
    templateCountryList: process.env.REACT_APP_BASE_URL_15,
    templateDifficultyList: process.env.REACT_APP_BASE_URL_15
  },
  partnerProfile: {
    default: process.env.REACT_APP_BASE_URL_3,
    GetAllTmss: process.env.REACT_APP_BASE_URL_7,
    PickList: process.env.REACT_APP_BASE_URL_7,
    billingStatement: process.env.REACT_APP_BASE_URL_14
  },
  user: {
    authorize: process.env.REACT_APP_BASE_URL_4,
    login: process.env.REACT_APP_BASE_URL_4,
    default: process.env.REACT_APP_BASE_URL_5
  }
};

export const APIUrl: Function = (app?: string = 'login', tab?: string = 'default'): string => {
  const tabName = tab.split('/')[0];
  const t = hasKey(prefix[app], tabName) ? tabName : 'default';
  return `https://${prefix[app][t]}.${String(base)}/${String(env)}`;
};

export const getApiULR = (app: string, tab: string): string | null => {
  const [newTab, item] = tab.split('/');
  const id = item && item !== 'null' ? item : getIdByResource(app, tab);
  if (app !== 'user' && !id) return null;
  let params =
    app === 'user' && tab !== 'contactUs' && tab !== 'profile'
      ? `${app}/${tab}`
      : `${app}/${newTab.replace('-', '/')}/${id.replace('-', '/')}`;
  let url = '';
  switch (app) {
    case 'user':
    case 'myDashboard':
    case 'clients':
    case 'myCompany':
    case 'partnerProfile': {
      url = APIUrl(app, tab);
      break;
    }

    default: {
      params = `${params}.json`;
      break;
    }
  }
  return `${url}/${params}`;
};

const getParams = ({
  pagination,
  sort,
  filter,
  ...params
}: {
  pagination?: {
    page: number,
    perPage: number
  },
  sort?: {
    field: string,
    order: string
  },
  filter?: Object,
  _sort?: string
}) => {
  const p = {};
  p._start = params._start ? params._start : pagination ? (pagination.page - 1) * pagination.perPage : 0;
  p._end = params._end ? params._end : pagination ? pagination.page * pagination.perPage : paginationConfig.rowsPerPage;
  p._sort = sort ? sort.field : 'name';
  p._sort = Object.prototype.hasOwnProperty.call(params, '_sort') ? params._sort : p._sort;
  p._order = sort ? sort.order.toUpperCase() : 'ASC';
  if (pagination && pagination.page !== 1) {
    p._start += 1;
    p._end += 1;
  }
  p.timestamp_cache = new Date().getTime();
  console.log(filter);
  if (filter && !isObjectEmpty(filter)) {
    p._filter = JSON.stringify(filter)
      .replace(/[{}]/g, '')
      .replace(/[""]/g, '');
  }
  return { ...params, ...p };
};

const getOptions = (
  resource: string,
  tab: string,
  method: string,
  params?: Object = {},
  useDefaultParams?: boolean = true
): Object => {
  console.log('App: ', resource);
  console.log('Current Tab: ', tab.replace('-', '/'));
  const url = getApiULR(resource, tab);
  console.log('API URL: ', url);
  if (!url) return {};
  console.log('Params: ', params);
  const newParams = useDefaultParams ? getParams(params) : params;
  console.log('New Params: ', newParams);
  return {
    url,
    method,
    headers: {
      Authorization: getStorage('idToken', true),
      'Content-Type': 'application/json'
    },
    params: newParams
  };
};

const getTotal = (headers: Object, data: Array<*>) =>
  Object.prototype.hasOwnProperty.call(headers, 'x-total-count') ? headers['x-total-count'] : data.length;

const dataProvider = {};

dataProvider.getList = (resource: string, tab: string, params?: Object) => {
  const isTab = typeof tab === 'string';
  const newTab = isTab ? tab : 'list';
  const newParams = isTab ? params : tab;
  console.group(`%cAPI REQUEST GETLIST ${resource}/${newTab.replace('-', '/')}`, 'font-size: medium; color: yellow');
  const options = getOptions(resource, newTab, 'GET', newParams);
  console.log(tab);
  console.log(options);
  console.groupEnd();
  if (!options) return Promise.resolve({ data: {}, total: 0 });
  return axios(options)
    .then(resp => {
      const { data, headers } = resp;
      let newData = data;
      if (resource === 'myCompany' && tab === 'employees') {
        newData = {
          [getIdByResource('myCompany')]: data
        };
      }
      return {
        data: newData,
        total: getTotal(headers, data)
      };
    })
    .catch(error => {
      console.log(error);
      const status = error?.response?.status;
      if (status === 500 || !navigator.onLine) {
        window.location.href = '/#/login';
        clearStorage();
        return {
          data: [],
          total: 0,
        }
      }
      return error;
    });
};

dataProvider.getOne = (resource: string, tab: string | Object, params?: Object = {}) => {
  console.group(`%cAPI REQUEST GETONE ${resource}/${tab}`, 'font-size: medium; color: yellow');
  const options = getOptions(resource, tab, 'GET', params, false);
  console.groupEnd();
  return axios(options)
    .then(({ data: newData }) => ({ data: newData, total: 1 }))
    .catch(error => {
      console.log(error);
      const status = error?.response?.status;
      if (status === 500) {
        window.location.href = '/#/login';
        clearStorage();
      }
      return error?.response?.data;
    });
};

dataProvider.getMany = (resource: string, params: Object) => Promise;

dataProvider.getManyReference = (resource: string, params: Object) => Promise;

dataProvider.create = (resource: string, params: Object) => {
  console.group(`%cAPI REQUEST CREATE ${resource}`, 'font-size: medium; color: #8458B3');
  const options = getOptions(resource, `add/${getId('partnerId')}`, 'POST');
  const { data } = params;
  console.log('Resource: ', resource);
  console.log('Data: ', data);
  console.log('Options: ', options);
  console.groupEnd();

  return axios({
    ...options,
    data
  });
};

dataProvider.update = (resource: string, tab: string, data: string | Object): Promise<any> => {
  console.group(`%cAPI REQUEST UPDATE ${resource}/${tab}`, 'font-size: medium; color: #DAF7A6');
  const options = getOptions(resource, tab, 'PUT');
  console.log({ ...options, data });
  console.groupEnd();
  return axios({
    ...options,
    data
  })
    .then(({ data: newData }) => ({ data: newData }))
    .catch(error => {
      console.log(error);
      const { status } = error.response;
      if (status === 500) {
        window.location.href = '/#/login';
        clearStorage();
      }
      return error.response.data;
    });
};

dataProvider.updateMany = (resource: string, params: Object) => Promise;

dataProvider.delete = (resource: string, tab: string, id?: string | null, data: Object | Array<*>) => {
  console.group(`%cAPI REQUEST DELETE ${resource}/${tab}`, 'font-size: medium; color: #808000');
  const options: Object = getOptions(resource, id ? `${tab}/${id}` : tab, 'DELETE');
  options.data = data;
  console.log(options);
  console.groupEnd();
  // $FlowFixMe
  return axios(options);
};

dataProvider.deleteMany = (resource: string, params: Object) => Promise;

dataProvider.post = (resource: string, tab: string, id?: string | null, data: Object | Array<*>) => {
  console.group(`%cAPI REQUEST POST ${resource}/${tab}`, 'font-size: medium; color: #808000');
  const options: Object = getOptions(resource, id ? `${tab}/${id}` : tab, 'POST');
  options.data = data;
  console.log(options);
  console.groupEnd();
  // $FlowFixMe
  return axios(options);
};

dataProvider.postUrl = (url: string, data: Object, filetype: string) => {
  console.group(`%cAPI REQUEST POST ${url}`, 'font-size: medium; color: #808000');
  console.log('url data: ', url, data);
  return axios({
    url,
    method: 'POST',
    headers: {
      'Content-Type': filetype
    },
    data
  })
    .then(({ data: newData }) => ({ data: newData }))
    .catch(error => {
      console.log(error);
      const { status } = error.response;
      if (status === 500) {
        window.location.href = '/#/login';
        clearStorage();
      }
    });
};

export default dataProvider;
