// @flow
import { Clients as ClientsIcon } from 'components/icons';
import ClientsList from './ClientsList';
import ClientsEdit from './ClientsEdit';
import ClientsCreate from './ClientsCreate';

export default {
  name: 'clients',
  list: ClientsList,
  edit: ClientsEdit,
  create: ClientsCreate,
  icon: ClientsIcon,
  options: {
    label: 'Manage Clients'
  }
};
