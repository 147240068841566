// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Video = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 24 23" role="img" aria-hidden="false">
    <path fillRule="evenodd" clipRule="evenodd" d="M22.1538 2.65385C22.1538 2.16731 21.7385 1.76923 21.2308 1.76923H2.76923C2.26154 1.76923 1.84615 2.16731 1.84615 2.65385V20.3462C1.84615 20.8327 2.26154 21.2308 2.76923 21.2308H21.2308C21.7385 21.2308 22.1538 20.8327 22.1538 20.3462V2.65385ZM2.76923 0H21.2308C22.7538 0 24 1.19423 24 2.65385V20.3462C24 21.8058 22.7538 23 21.2308 23H2.76923C1.24615 23 0 21.8058 0 20.3462V2.65385C0 1.19423 1.24615 0 2.76923 0ZM8.64932 7.67844C8.54778 7.61652 8.43703 7.58998 8.32627 7.58998C7.98473 7.58998 7.67086 7.84652 7.67086 8.20921V12.1457V16.0823C7.67086 16.445 7.98473 16.7104 8.32627 16.7104C8.4278 16.7104 8.54778 16.6838 8.64932 16.6219L12.2309 14.6581L15.8124 12.6942C16.2463 12.4465 16.2463 11.845 15.8124 11.6061L12.2309 9.64228L8.64932 7.67844ZM5.82471 8.21805C5.82471 6.89998 6.94165 5.82075 8.32627 5.82075C8.76011 5.82075 9.19394 5.9269 9.5724 6.1569L13.1539 8.12075L16.7355 10.0846C17.5109 10.5092 17.9909 11.3054 17.9909 12.1634C17.9909 13.0215 17.5109 13.8177 16.7355 14.2423L13.1539 16.2061L9.5724 18.17C9.19394 18.3823 8.76011 18.4884 8.32627 18.4884C6.95088 18.4884 5.82471 17.4092 5.82471 16.0911V12.1546V8.21805Z" />
  </SvgIconStyled>
);

export default Video;
