// @flow
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLogin, useNotify } from 'react-admin';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button, Layout as AppLayout, TextField, Footer } from 'components';
import type { Element, ComponentType } from 'react';
import { Hidden, Link } from '@material-ui/core';
import cognito from '../../helpers/apis/cognito';
import useHasInternet from '../../hooks/useHasInternet';
import { isValidEmail } from '../../helpers'

const Layout: ComponentType<*> = styled(AppLayout)`
  min-width: 320px;
  max-width: 440px;
  margin: 0 auto;
`;

const Form: ComponentType<*> = styled.form`
  display: flex;
  flex-direction: column;
  background-color: var(--backgroundPaper);
  border-radius: 2px;
  border-top: calc(var(--borderSize) * 5) solid var(--colorBaseBase);
  margin-top: calc(var(--spacing) * 10);
  padding: calc(var(--spacing) * 5);
  && {
    > div {
      margin: 20px 0;
    }
  }
`;

const ButtonLogin: ComponentType<*> = styled(Button)`
  && {
    margin-top: calc(var(--spacing) * 3);
    padding: calc(var(--spacing) * 1.5);
    box-shadow: inherit;
    background-color: var(--colorBaseBase);
    width: 100%;
    &:hover {
      background-color: var(--buttonPrimaryHover);
      &.Mui-disabled {
        background-color: var(--colorBaseBase);
      }
    }

    span {
      text-transform: uppercase;
    }
  }
`;

type renderInputType = {
  meta: {
    touched: boolean,
    error: string
  },
  input: Object
};

type LoginFormTypes = {
  location: {
    search: string
  }
};

const Input: Function = ({
  meta: { touched, error } = { touched: false, error: '' },
  input: { ...inputProps },
  ...props
}: renderInputType) => (
  <TextField error={!!(touched && error)} helperText={touched && error} {...inputProps} {...props} fullWidth />
);

const LoginForm: Function = ({ location }: LoginFormTypes): Element<*> => {
  const access_token = localStorage.getItem('accessToken');
  let history = useHistory();
  
  if (access_token) {
    let currentTab = sessionStorage.getItem('currentTab');
    if (currentTab) history.goBack();
    else history.push('/#/myDashboard/dashboard');
  }

  const hasInternetConnection = useHasInternet();
  const passwordInputRef = useRef();

  const partnerLogosBaseURL = process.env.PARTNER_LOGOS_BASE_URL;
  const handleUserFederation = useCallback(userEmail => {
    cognito
      .checkFederatedLogin(userEmail)
      .then(response => {
        if (response.status === 200 && response.data.url !== null) {
          window.open(response.data.url, '_self');
          setLoading(false);
        } else {
          setShowPassword(true);
          setLoading(false);
          setLoginButtonCaption('SIGN IN');
        }
      })
      .catch(error => {
        hasInternetConnection(() => {
          setLoading(false);
          notify(error.response.data.description, 'warning');
        });
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [email, setEmail] = useState('');
  const [brandKey, setBrandKey] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [loginButtonCaption, setLoginButtonCaption] = useState('NEXT');
  const login = useLogin();
  const notify = useNotify();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: 'BSN_CLEAR',
      payload: {
        myDashboard: {
          dashboard: null,
          leaderboard: null,
          leaderboardOverall: null,
          securityTraining: null,
          microTrainings: null,
          microTrainingsQuiz: null,
          microTrainingsStats: null,
          darkWeb: null,
          darkWebList: null,
          newsletters: null,
          securityPolicies: null,
          securityPolicy: null,
          otherPolicies: null,
          otherPolicy: null
        },
        myCompany: {
          dashboard: null,
          complianceDashboard: null,
          individualEssRanking: null,
          training: null,
          trainingFilter: null,
          microTrainings: null,
          microTrainingUsers: null,
          policies: null,
          otherPolicies: null,
          documents: null,
          documentsInfo: null,
          documentsServiceProvider: null,
          documentsTemplates: null,
          otherDocuments: null,
          sra: { id: 1 }, // TODO: Change to null later
          darkWebMonitoring: null,
          darkWebMonitoringBreaches: null,
          track: { id: 1 }, // TODO: Change to null later
          employees: null
        },
        clients: {
          list: null,
          dashboard: null,
          complianceDashboard: null,
          access: null,
          directorySync: null,
          information: null,
          notification: null,
          products: null,
          user: null,
          users: null,
          usersList: null,
          addUser: null,
          darkWebMonitoring: null,
          trainingReports: null,
          trainingAnnual: null,
          trainingTrainings: null,
          trainingTrainingsUsers: null,
          trainingUsers: null,
          trainingUsersTrainings: null,
          phishingReports: null,
          phishingReportsCampaign: null,
          phishingReportsRecipients: null,
          policies: null,
          policyInfo: null,
          otherPolicies: null,
          otherPolicyInfo: null,
          documents: null,
          documentsInfo: null,
          documentsServiceProvider: null,
          documentsTemplates: null,
          disasterRecovery: null,
          otherDocuments: null,
          track: null,
          trackSecurityIncidents: null,
          trackServerRoomAccess: null,
          phishingCampaignScenariosList: null,
          phishingCampaignRecipientsList: null,
          autophish: null,
          autoPhishCampaigns: null,
          scenarioPreview: ''
        },
        system: {
          systemHasData: false,
          initPosition: 0,
          item: null,
          hasSidebar: false,
          isApp: false,
          loading: false,
          loadingTab: false,
          loadingItem: false,
          location: 'login',
          locationPrevious: null,
          hasError: false,
          errorMessage: '',
          errorInfo: '',
          tabPrevious: null,
          tabCurrent: null,
          hasDarkMode: false,
          tableRowSelected: [],
          tableRowSelectedList: [],
          tableRowUnselected: [],
          tableFilter: {},
          disabledNext: true
        }
      }
    });
    sessionStorage.clear();
    if (new URLSearchParams(location.search).get('microtraining_id'))
      sessionStorage.setItem('microtraining_id', new URLSearchParams(location.search).get('microtraining_id'));
  }, [dispatch]);

  useEffect(() => {
    if (passwordInputRef.current) {
      passwordInputRef.current.focus();
    }
  }, [passwordInputRef.current]);

  const handleForgotPassword = e => {
    e.preventDefault();
    window.location.href = '/#/forgotPassword';
  };
  const handleNewUser = e => {
    e.preventDefault();
    window.location.href = '/#/newUser';
  };

  useEffect(() => {
    let uEmail = new URLSearchParams(location.search).get('email');
    if (uEmail) uEmail = uEmail.replace(/ /g, '+');
    const uBrandKey = new URLSearchParams(location.search).get('brand_key');
    if (uEmail) {
      setEmail(uEmail);
      handleUserFederation(uEmail);
    }
    if (uBrandKey) {
      setBrandKey(uBrandKey);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onKeyDown = e => {
    if (e.keyCode === 13) {
      e.preventDefault();
      submit();
    }
  };

  const submit = e => {
    if (e) e.preventDefault();

    if (email === '') {
      notify(`Please enter an email address`, 'warning');
      return;
    }
    if (!isValidEmail(email)) {
      notify(`Please enter a valid email address`, 'warning');
      return;
    }

    if (showPassword && password === '') {
      notify('Please enter a Password');
      return;
    }

    setLoading(true);
    if (!password) {
      handleUserFederation(email);
    }

    if (showPassword) {
      if (email === '' && password === '') {
        setLoading(false);
        notify('Email and Password are required', 'warning');
      }
      login({ email, password })
        .then(() => {
          setLoading(false);
        })
        .catch(e => {
          setLoading(false);
          notify(e.message, 'warning');
        });
    }
  };

  return (
    <Layout sidebar={null} background="paper" centered footer={<Footer />}>
      {brandKey !== '' && (
        <div>
          {' '}
          <img
            style={{ maxWidth: '200px', maxHeight: '200px' }}
            alt=""
            src={`${partnerLogosBaseURL}${brandKey}`}
          />{' '}
        </div>
      )}
      <Form onSubmit={submit} noValidate>
        <Input
          autoFocus
          id="email"
          name="email"
          value={email}
          label="Email Address"
          disabled={loading}
          onChange={e => setEmail(e.target.value.trim())}
          onKeyDown={onKeyDown}
        />
        {showPassword && (
          <Input
            inputRef={passwordInputRef}
            id="password"
            name="password"
            value={password}
            label="Password"
            type="password"
            disabled={loading}
            onChange={e => setPassword(e.target.value)}
            onKeyDown={onKeyDown}
          />
        )}
        <ButtonLogin type="submit" color="secondary" disabled={loading} fullWidth>
          {loading && <CircularProgress size={18} thickness={2} />}
          &nbsp;&nbsp;{loginButtonCaption}
        </ButtonLogin>
        <Hidden smDown>
            <Link href="#" style={{ color: '#082f49', 'font-weight': 'bold' }} onClick={handleNewUser}>
              New User?
            </Link>
        </Hidden>
        {showPassword && (
          <Link href="#" style={{ color: '#082f49', 'font-weight': 'bold' }} onClick={handleForgotPassword}>
            Forgot your password?
          </Link>
        )}
      </Form>
    </Layout>
  );
};

export default LoginForm;
