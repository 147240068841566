import React, { createContext, useMemo, useState } from 'react';
import Modal3 from '../modal/Modal3';
import TableList from './TableList';

export const TableListModalContext = createContext();

const TableListModal = ({app, tab, modalTitle, toolbar, tableOptions, tableActions, tableSettings, id, tableRefresh, paramIdVal, ...tableProps}) => {
  const [refresh, setRefresh] = useState(false)

  const [open, setOpen] = useState(false)
  const [rowData, setRowData] = useState({
    rowId: 0,
    row: {},
    rowIndex: 0,
    modal: {}
  });

  const handleClose = () => {
    setOpen(false);
    setRefresh(false)
  }

  const modalMemo = useMemo(
    () =>
      open && (
        <Modal3
          {...rowData.modal}
          open={open}
          setOpen={handleClose}
          rowId={rowData.rowId}
          rowIndex={rowData.rowIndex}
          parentTab={tab}
          // fullScreen={false}
          row={rowData.row}
          modalTitle={modalTitle}
          paramIdVal={paramIdVal}
        />
      ),
    [open]
  );

  const toolbarElement = React.cloneElement(toolbar, {setRefresh})

  return (
    <TableListModalContext.Provider value={{setRowData, setOpen, setRefresh}}>
      {modalMemo}
      <TableList
        source={tab}
        id={id}
        setApp={app}
        refresh={tableRefresh === undefined || open ? !refresh : !tableRefresh}
        toolbar={toolbarElement}
        tableOptions={tableOptions}
        tableActions={tableActions}
        tableSettings={tableSettings}
        {...tableProps}
      />
    </TableListModalContext.Provider>
  )
}

export default TableListModal;
