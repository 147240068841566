// @flow
import React, { type Element } from 'react';
import WorkPlan from './workPlan/WorkPlan';

type PageWorkPlanTypes = {
  app: string
};

const PageWorkPlan = ({ app }: PageWorkPlanTypes): Element<*> => <WorkPlan app={app} />;

export default PageWorkPlan;
