// @flow
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import newsfeed from 'helpers/apis/newsfeed';
import { Grid, Typography, makeStyles, Link, Dialog, Button } from '@material-ui/core';
import { DeleteOutlined as DeleteOutlinedIcon } from '@material-ui/icons';
import { setFeeds, updateNotifications } from 'helpers/action';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: '15px',
    marginBottom: '20px',
    color: theme.palette.text.secondary
  },
  padded: {
    padding: '15px'
  },
  image: {
    maxWidth: '100%'
  },
  deleteIcon: {
    backgroundColor: 'rgba(255, 90, 97, 0.2)',
    color: '#FF5A61',
    borderRadius: '50%',
    padding: '10px',
    fontSize: '25px'
  }
}));

const DeletePostModal = ({ open, close, postIdx, postId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const feeds = useSelector(state => state.newsfeed.feeds);

  const deletePost = () => {
    dispatch(setFeeds([...feeds.slice(0, postIdx), ...feeds.slice(postIdx + 1)]));
    newsfeed
      .deletePost(postId)
      .then(res => {
        if (res?.status && res.status === 200) dispatch(updateNotifications(true));
      });
    close();
  };

  return (
    <>
      <Dialog open={open} onClose={close} style={{ padding: '50px,10px' }}>
        <Grid className={classes.padded} alignItems="center" style={{ textAlign: 'center' }} container>
          <Grid style={{ paddingBottom: '20px' }} item xs={12}>
            <DeleteOutlinedIcon className={classes.deleteIcon} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" display="block" gutterBottom>
              Delete Post?
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography display="block" gutterBottom>
              This post will be deleted and cannot be recovered.
            </Typography>
          </Grid>
          <Grid style={{ paddingBottom: '20px' }} item xs={12}>
            <Typography display="block" gutterBottom>
              Are you sure about that?
            </Typography>
          </Grid>
          <Grid style={{ paddingBottom: '20px' }} item xs={12}>
            <Button variant="contained" style={{ backgroundColor: '#FF5A61', color: 'white' }} onClick={deletePost}>
              Yes, I Want Delete
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Link
              style={{ color: '#535353', textDecoration: 'underline' }}
              href="#"
              onClick={e => {
                e.preventDefault();
                close();
              }}
              color="inherit"
            >
              Cancel action
            </Link>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default DeletePostModal;
