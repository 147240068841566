// @flow
import React, { memo, type Element, useState } from 'react';
import { InputAdornment } from '@material-ui/core';
import { SearchIcon } from './ComponentTypes';
import { LazyIcon } from 'components'
import TextField from './TextField';
import { useTableList } from '../tablelist/TableListContext';

type SearchFieldTyoes = {
  label: string,
  id: string | number | boolean,
  fields: Array<string>,
  location: string,
  tab: string,
  item?: string | false,
  fullWidth?: boolean
};

const SearchField = ({
  label,
  id,
  fields,
  fullWidth = false,
  location,
  tab,
  item = false
}: SearchFieldTyoes): Element<*> => {
  const {
    dispatch: { search }
  } = useTableList();
  const [value, setValue] = useState('');
  const onSearch = (val: string = '') => {
    setValue(val);
    if (val === '') search('');
  };

  const onKeyUp = ({ keyCode }) => {
    switch (keyCode) {
      case 13: // If hit enter
        search(value);
        break;
      
      case 27: // If hit esc
        onSearch();
        break;
      
      default:
        return;
    }
  };

  return (
    <TextField
      placeholder={label || 'Search'}
      variant
      value={value}
      inputBase
      InputProps={{
        endAdornment: (
          <InputAdornment
            position="start"
            onClick={() => search(value)}
            style={{ cursor: !value || value.length === 0 ? 'default' : 'pointer' }}
          >
            <SearchIcon />
          </InputAdornment>
        ),
        startAdornment: value && (
          <InputAdornment onClick={() => onSearch()} style={{ cursor: 'pointer'}} >
            <LazyIcon component="Close" color="grey" size={1} mr={1} />
          </InputAdornment>
        )
      }}
      onChange={({ target: { value: val } }) => onSearch(val)}
      onKeyUp={onKeyUp}
      style = {{ minWidth: '160px'}}
    />
  );
};

SearchField.defaultProps = {
  item: false,
  fullWidth: false
};

export default memo<SearchFieldTyoes>(SearchField);
