import apiClient from '../apiClient/apiClient';

import { getStorage } from '../storage';

const env = process.env.REACT_APP_BUILD_ENV;
const base = process.env.REACT_APP_BASE_URL;
const apiGWID = process.env.REACT_APP_BASE_URL_12;
const baseURL = `https://${apiGWID}.${base}/${env}/`;

const pushNotifications = {};

pushNotifications.getRequestHeaders = () => {
  return {
    'Content-Type': 'application/json',
    Authorization: getStorage('idToken', true)
  };
};

pushNotifications.getPushNotificationsSubscriptions = (partnerID, clientID = null) => {
  const requestOptions = {
    url: `${baseURL}PushNotificationsSubscriptions/${partnerID}`,
    headers: pushNotifications.getRequestHeaders(),
    ...(clientID !== null ? { params: { client_id: clientID } } : {})
  };
  return apiClient.get(requestOptions);
};

pushNotifications.setPushNotificationsSubscription = (partnerID, partnerPushNotificationSubscription,clientID=null) => {
  const requestOptions = {
    url: `${baseURL}PushNotificationsSubscriptions/${partnerID}`,
    data: partnerPushNotificationSubscription,
    headers: pushNotifications.getRequestHeaders(),
    ...(clientID !== null ? { params: { client_id: clientID } } : {})
  };
  return apiClient.post(requestOptions);
};

pushNotifications.getSubscription = subscriptionID => {
  const requestOptions = {
    url: `${baseURL}Subscriptions/${subscriptionID}`,
    headers: pushNotifications.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

pushNotifications.setSubscription = (subscriptionID, subscription) => {
  const requestOptions = {
    url: `${baseURL}Subscriptions/${subscriptionID}`,
    data: subscription,
    headers: pushNotifications.getRequestHeaders()
  };
  return apiClient.put(requestOptions);
};

pushNotifications.testPushNotificationsMessage = (subscriptionID,customMessage) => {
  const requestOptions = {
    url: `${baseURL}Subscriptions/${subscriptionID}/test_push_notification_message`,
    data: customMessage,
    headers: pushNotifications.getRequestHeaders(),
  };
  return apiClient.post(requestOptions);
};


export default pushNotifications;
