// @flow
import React, { type Element, type Context, createContext, useContext, useState, useCallback } from 'react';

const settings = [
  {
    name: 'trainingTrainings',
    label: 'Micro Training',
    folding: 'trainingTrainingsUsers'
  },
  {
    name: 'trainingUsers',
    label: 'Users',
    folding: 'trainingUsersTrainings'
  }
];

const TrainingsContext: Context<{
  dispatch: Function,
  radio: number,
  settings: Array<{
    name: string,
    label: string,
    folding: string
  }>
}> = createContext({
  dispatch: e => e,
  radio: 0,
  settings,
  trainingReportId: ''
});

type TrainingProviderTypes = {
  children: any
};

export const TrainingProvider = ({ children }: TrainingProviderTypes): Element<*> => {
  const [radio, setRadio] = useState(0);
  const dispatch = {};

  dispatch.setRadio = useCallback(i => {
    setRadio(Number(i));
  }, []);

  return <TrainingsContext.Provider value={{ dispatch, radio, settings }}>{children}</TrainingsContext.Provider>;
};

export const useTrainings = () => useContext(TrainingsContext);
