// @flow
import React, { type ComponentType, useEffect, useReducer, useState, useCallback } from 'react';
import { QueryClient, useQueryClient } from 'react-query';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useNotify } from 'react-admin';
import { InputAdornment, IconButton, FormControl, OutlinedInput, InputLabel, FormHelperText } from '@material-ui/core';
import MuiPhoneNumber from 'material-ui-phone-number';
import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from '@material-ui/icons';
import { Container, TextField, SelectField, Typography, Button, LoadingStyled, Switch, ButtonPulseAnimate, Dialog } from 'components';
import { useApp, hasKey, dataFetch, dataQueryName, dataProvider } from 'helpers';
import { useId, useDataGet } from 'hooks';
import { BSN_UPDATE_USER_PROFILE } from 'conf';
import { LazyIcon } from 'components/icons';
import type { Element } from 'react';
import { ButtonCancel } from './ComponentTypes';
import { isValidScreenName } from '../../helpers';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      refetchOnWindowFocus: false
    }
  }
});

const { h3: H3 } = Typography;

type Props = {
  record: Object,
  open: boolean,
  setOpen: Function,
  ...
};

const ConfirmedPassword: ComponentType<*> = styled(OutlinedInput)`
  & .Mui-disabled {
    cursor: not-allowed;
  }
`;

const FormControlAlign: ComponentType<*> = styled(FormControl)`
  && {
    margin-top: calc(var(--spacing) * 0.9);
  }
`;

const LinkStyled: ComponentType<*> = styled.a`
  text-decoration: none;
  color: var(--colorDefault);
  font-weight: var(--fontWeightBold);
`;

type initialStateType = {
  jobFunctionList: Object | null,
  data: Object | null,
  updateData: Object,
  showPassword: boolean,
  verifyPassword?: string,
  errorMessage: string,
  newError: boolean,
  checked: boolean,
  emailText: string,
  initialScreenName: string
};

const initialState: initialStateType = {
  jobFunctionList: null,
  data: null,
  updateData: {},
  showPassword: false,
  verifyPassword: undefined,
  errorMessage: '',
  newError: false,
  checked: false,
  emailText: '',
  initialScreenName: ''
};

type UserProfileReducerType = any;

function reducer(prevState: initialStateType, payload: UserProfileReducerType) {
  return { ...prevState, ...payload };
}

function transform(data) {
  return data.map(({ id, name }) => {
    return {
      value: id,
      label: name
    };
  });
}

const UserProfile: Function = ({ record, open, setOpen, ...props }: Props): Element<*> => {
  const { dispatch } = useApp();
  const dispatchRx = useDispatch();
  const uQueryClient = useQueryClient();
  const productName = useSelector(state => state.bsn.user.profile?.product_name);
  const isHidden = productName === 'Unlimited Cybersecurity Training' || productName === 'HIPAA Compliance';
  const userId = useId({ key: 'userId' });
  const clientId = useId({ key: 'clientId' });
  const notify = useNotify();
  const [screenNameIsTaken, setScreenNameIsTaken] = useState({ is_taken: false, message: '' });
  const [time, setTime] = useState(Date.now());
  const [state, setState] = useReducer<initialStateType, UserProfileReducerType>(reducer, initialState);
  const [openDisableWeeklyNewsletter, setOpenDisableWeeklyNewsletter] = useState(false);

  const { data: getProfile } = useDataGet({ app: 'user', tab: 'profile', item: userId, params: { _callId: time } });
  const { data: getJobFunctionList } = useDataGet({ app: 'user', tab: 'jobFunctionList', item: clientId, transform });

  const {
    jobFunctionList,
    data,
    updateData,
    verifyPassword,
    showPassword,
    errorMessage,
    newError,
    checked,
    emailText,
    initialScreenName
  } = state;

  const MAILTO = `mailto:?body=${emailText}&subject=Positive Opt-in`;
  const isCurrentScreenName = data?.screenName.toLowerCase() === initialScreenName.toLowerCase();

  useEffect(() => {
    if (data?.sample_email) {
      setState({
        emailText: data.sample_email
      });
    }
  }, [data?.sample_email]);

  useEffect(() => {
    if (data === null && getProfile !== null && userId) {
      setState({
        data: getProfile,
        checked: getProfile.weeklyNewsletter,
        initialScreenName: getProfile.screenName
      });
    }
  }, [data, userId, getProfile]);

  useEffect(() => {
    if (!isHidden && jobFunctionList === null && getJobFunctionList !== null && clientId) {
      setState({ jobFunctionList: getJobFunctionList });
    }
  }, [clientId, isHidden, jobFunctionList, getJobFunctionList]);

  const onChange = (name: string, value: string) => {
    if (name === 'screenName') setScreenNameIsTaken( { is_taken: null, message: '' } );
    setState({
      data: { ...data, [name]: value },
      updateData: { ...updateData, [name]: value }
    });
  };

  const handleChangePhones = (value, name) => {
    onChange(name, value);
  };

  const onCheckAvailability = (ev, screenName) => {
    ev.preventDefault();
    if (isValidScreenName(screenName)) {
      if (!isCurrentScreenName){
        return fetch(screenName, screenNameIsTaken, userId, setScreenNameIsTaken);
      } else {
        setScreenNameIsTaken({ is_taken: false, message: 'This is your current screen name' });
      }
    } else {
      setScreenNameIsTaken({ is_taken: true, message: 'Do not use special characters' });
    }


  }

  const isValidForm = data?.firstName?.trim().length > 0  && data?.lastName?.trim().length > 0 && data?.screenName?.trim().length > 0;

  const onUpdate = e => {
    e.preventDefault();
    // if (hasKey(updateData, 'screenName') && screenNameIsTaken) {
    //   notify(`Your screen name is not valid.`, 'warning');
    //   return;
    // }

    if (!hasKey(updateData, 'password')) {
      if (data) data.password = '';
      setState({ verifyPassword: '' });
    }

    dispatch.setItem('user', 'profile', userId, data);
    dispatchRx({ type: BSN_UPDATE_USER_PROFILE, payload: data });
    dataProvider.update('user', `profile/${userId}`, data);
    notify(`Successfully Updated!`, 'info');
    uQueryClient.clear();

    setTimeout(() => {
      setTime(Date.now());
    }, 900);
  };

  const handleValid = e => {
    if (e.target.value) {
      setState({ errorMessage: '', newError: false });
    }
  };

  const handleInvalid = e => {
    e.preventDefault();
    setState({
      errorMessage: e.target.validationMessage,
      newError: !e.target.validity.valid
    });
  };

  const handleChangeWeeklyNewsletter = (name, value) => {
    setState({ checked: value });
    onChange(name, value);
  }

  if (data === null) return <LoadingStyled />;

  return (
    <form onSubmit={onUpdate}>
      <Container.Paper mt={2} radius={1}>
        <Container.Grid mb={2}>
          <Container.Grid pl={3} bb={1} sm={12} xs={12} xl={12} md={12}>
            <H3 fontSize={16}>1. Basic information</H3>
          </Container.Grid>
          <Container.Grid mt={2} sm={12} xs={12} xl={12} md={12}>
            <Container.Grid item justify="flex-start" ml={3} sm={6} xs={6} xl={6} md={6}>
              <Container.Grid mr={3} sm={6} xs={6} xl={6} md={6}>
                <TextField
                  value={data.firstName}
                  name="firstName"
                  label="First name"
                  inputProps={{ onChange: ({ target: { name, value } }) => onChange(name, value), maxLength: 50 }}
                  required
                  fullWidth
                />
              </Container.Grid>
              <Container.Grid sm={6} xs={6} xl={6} md={6}>
                <TextField
                  value={data.lastName}
                  name="lastName"
                  label="Last name"
                  inputProps={{ onChange: ({ target: { name, value } }) => onChange(name, value), maxLength: 50 }}
                  required
                  fullWidth
                />
              </Container.Grid>
            </Container.Grid>
            <Container.Grid mt={1} sm={12} xs={12} xl={12} md={12}>
              <Container.Grid item justify="flex-start" ml={3} sm={6} xs={6} xl={6} md={6}>
                <Container.Grid pt={0.9} mr={3} sm={6} xs={6} xl={6} md={6}>
                  <MuiPhoneNumber
                    variant="outlined"
                    value={data.phoneNumber.includes('+') ? data.phoneNumber : `+1 ${data.phoneNumber}`}
                    name="phoneNumber"
                    label="Phone number"
                    onChange={e => handleChangePhones(e, 'phoneNumber')}
                    defaultCountry="us"
                    countryCodeEditable={false}
                    fullWidth
                  />
                </Container.Grid>
                <Container.Grid pt={0.9} sm={6} xs={6} xl={6} md={6}>
                  <MuiPhoneNumber
                    variant="outlined"
                    value={data.mobileNumber.includes('+') ? data.mobileNumber : `+1 ${data.mobileNumber}`}
                    name="mobileNumber"
                    label="Mobile Number"
                    onChange={e => handleChangePhones(e, 'mobileNumber')}
                    defaultCountry="us"
                    countryCodeEditable={false}
                    fullWidth
                  />
                </Container.Grid>
              </Container.Grid>
              <Container.Grid item ml={3} sm={5} xs={5} xl={5} md={5}>
                <TextField
                  value={data.email}
                  name="email"
                  label="Ex: mail@mail.com"
                  disabled
                  inputProps={{ onChange: ({ target: { name, value } }) => onChange(name, value) }}
                  required
                  fullWidth
                />
              </Container.Grid>
            </Container.Grid>
          </Container.Grid>
        </Container.Grid>
      </Container.Paper>
      {!isHidden && (
        <Container.Paper mt={2} radius={1}>
          <Container.Grid mb={2} sm={12} xs={12} xl={12} md={12}>
            <Container.Grid pl={3} bb={1} sm={12} xs={12} xl={12} md={12}>
              <H3 fontSize={16}>2. Function</H3>
            </Container.Grid>
            <Container.Grid mt={3} sm={12} xs={12} xl={12} md={12}>
              <Container.Grid item direction="column" ml={3} sm={6} xs={6} xl={6} md={6}>
                <Container.Grid sm={12} xs={12} xl={12} md={12}>
                  {jobFunctionList && (
                    <SelectField
                      allowEmpty
                      fullWidth
                      id="jobFunctionId"
                      label="Select Function"
                      choices={jobFunctionList}
                      name="jobFunctionId"
                      value={data.jobFunctionId}
                      inputProps={{ onChange: ({ target: { name, value } }) => onChange(name, value) }}
                    />
                  )}
                </Container.Grid>
                <Container.Grid mt={1} sm={12} xs={12} xl={12} md={12}>
                  <Switch
                    name="weeklyNewsletter"
                    value={data.weeklyNewsletter}
                    checked={checked}
                    onChange={e => {
                      if (e.target.checked){
                        return handleChangeWeeklyNewsletter(e.target.name, e.target.checked);
                      }
                      setOpenDisableWeeklyNewsletter(true);
                    }}
                    label="Receive weekly training and monthly newsletter emails"
                  />
                </Container.Grid>
                <Container.Grid mt={1} sm={12} xs={12} xl={12} md={12}>
                  {hasKey(data, 'positive_optin') && (
                    <Switch
                      name="positive_optin"
                      value={data.positive_optin}
                      checked={data.positive_optin}
                      onChange={e => {
                        setState({
                          data: {
                            positive_optin: e.target.checked
                          }
                        });
                        onChange(e.target.name, e.target.checked);
                      }}
                      label="Company positive opt-in for Micro-Training/Monthly Newsletter"
                    />
                  )}
                  {data.positive_optin && (
                    <Typography.p>
                      We suggest you inform your organization that training emails have been opted-in on behalf of the
                      company. Click this link for a&nbsp;
                      <LinkStyled href={MAILTO}>Sample Email</LinkStyled>.
                    </Typography.p>
                  )}
                </Container.Grid>
              </Container.Grid>
              <Container.Grid mt={1} sm={12} xs={12} xl={12} md={12}>
                <Container.Grid pl={3} mt={4} sm={12} xs={12} xl={12} md={12}>
                  <H3 title fontSize={14}>
                    Screen name
                  </H3>
                </Container.Grid>
                <Container.Grid pl={3} sm={12} xs={12} xl={12} md={12}>
                  <H3 style={{ marginBottom: 4 }} fontSize={14}>
                    Note:
                  </H3>
                </Container.Grid>
                <Container.Grid direction="column" pl={3} sm={12} xs={12} xl={12} md={12}>
                  <Typography.p style={{ margin: 0 }} fontSize={14} component="p">
                    For your screen name, do not use your actual name or company name or special characters. This is
                    what other users of the systems will see.
                  </Typography.p>
                  <Typography.p style={{ margin: 0, marginBottom: 10 }} fontSize={14} component="p">
                    Keep it interesting, light and fun. Here are some examples to help:
                  </Typography.p>
                  <Typography.p style={{ margin: 0 }} fontSize={14} component="p">
                    SecuritylsForMe
                  </Typography.p>
                  <Typography.p style={{ margin: 0 }} fontSize={14} component="p">
                    JessiesGirl
                  </Typography.p>
                  <Typography.p style={{ margin: 0 }} fontSize={14} component="p">
                    WorkinForALiving
                  </Typography.p>
                </Container.Grid>
                <Container.Grid item direction="column" justify="flex-start" ml={3} mt={3} sm={6} xs={6} xl={6} md={6}>
                  <Container.Grid mb={1} sm={12} xs={12} xl={12} md={12}>
                    <TextField
                      value={data.screenName}
                      name="screenName"
                      label="Screen Name"
                      inputProps={{ onChange: ({ target: { name, value } }) => onChange(name, value), maxLength: 60 }}
                      required
                      fullWidth
                      error={screenNameIsTaken.is_taken}
                      helperText={screenNameIsTaken.message}
                    />
                  </Container.Grid>
                  <Container.Grid item justify="flex-end" sm={12} xs={12} xl={12} md={12}>
                    <ButtonPulseAnimate
                      height={36}
                      pr={5}
                      pl={5}
                      animation={hasKey(updateData, 'screenName') && screenNameIsTaken.is_taken === null}
                      onClick={e => onCheckAvailability(e, data.screenName)}
                    >
                      Check Availability
                    </ButtonPulseAnimate>
                  </Container.Grid>
                </Container.Grid>
              </Container.Grid>
            </Container.Grid>
          </Container.Grid>
          <Dialog
            title="Disable weekly training and monthly newsletter emails"
            message="You are opting out of a service that had been provided to you by your employer.  Opting out will effect your ESS score"
            buttonSubmit="OK"
            open={openDisableWeeklyNewsletter}
            setOpen={() => {
              setOpenDisableWeeklyNewsletter(false);
            }}
            maxWidth="sm"
            onSubmit={() => {
              setOpenDisableWeeklyNewsletter(false);
              handleChangeWeeklyNewsletter('weeklyNewsletter', false);
            }}
          />
        </Container.Paper>
      )}

      <Container.Paper mt={2} radius={1}>
        <Container.Grid mb={2} sm={12} xs={12} xl={12} md={12}>
          {data && data.show_password && (
            <>
              <Container.Grid pl={3} bb={1} sm={12} xs={12} xl={12} md={12}>
                <H3 fontSize={16}>{!isHidden ? '3. ' : '2. '}Access</H3>
              </Container.Grid>
              <Container.Grid mt={2} pb={2} bb={1} sm={12} xs={12} xl={12} md={12}>
                <Container.Grid item justify="flex-start" ml={3} sm={6} xs={6} xl={6} md={6}>
                  <Container.Grid mr={3} sm={6} xs={6} xl={6} md={6}>
                    <>
                      <input
                        type="text"
                        name="checks"
                        required={data.password !== verifyPassword}
                        style={{ display: 'none' }}
                      />
                      <TextField
                        source={data.password}
                        value={data.password}
                        name="password"
                        label="Password"
                        onChange={({ target: { name, value } }) => onChange(name, value)}
                        type={showPassword ? 'text' : 'password'}
                        fullWidth
                        autoComplete="new-password"
                        inputProps={{
                          // onChange: ({ target: { name, value } }) => onChange(name, value),
                          // onBlur: e => passLength(e),
                          minLength: 6,
                          maxLength: 20
                        }}
                      />
                    </>
                  </Container.Grid>
                  <Container.Grid sm={6} xs={6} xl={6} md={6}>
                    <FormControlAlign
                      variant="outlined"
                      fullWidth
                      required={data.password && true}
                      error={data.password !== verifyPassword || newError}
                    >
                      <InputLabel error={data.password !== verifyPassword || newError}>Verify password</InputLabel>
                      <ConfirmedPassword
                        type={showPassword ? 'text' : 'password'}
                        value={verifyPassword}
                        name="check_password"
                        onChange={({ target: { value } }) => setState({ verifyPassword: value })}
                        labelWidth={130}
                        autoComplete="new-password"
                        // error={data.password !== verifyPassword}
                        onInvalid={handleInvalid}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton onClick={() => setState({ showPassword: !showPassword })}>
                              {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            </IconButton>
                          </InputAdornment>
                        }
                        onInput={e => handleValid(e)}
                        inputProps={{
                          minLength: 6,
                          maxLength: 20
                        }}
                      />
                      <FormHelperText error={data.password !== verifyPassword || newError}>
                        {data.password !== verifyPassword &&
                          !newError &&
                          `Password and Verify Password does not match.`}
                        {newError && errorMessage}
                      </FormHelperText>
                    </FormControlAlign>
                  </Container.Grid>
                </Container.Grid>
              </Container.Grid>
            </>
          )}
          <Container.Grid justify="flex-end" mt={2} mr={3} sm={12} xs={12} xl={12} md={12}>
            <ButtonCancel to="/" primaryText="Cancel" />
            <Button
              mr={3}
              backgroundColor="colorSystemSuccess"
              backgroundColorHover="colorSystemSuccessHover"
              disabled={hasKey(updateData, 'screenName') && screenNameIsTaken.is_taken !== false || !isValidForm} 
              type="submit"
            >
              <LazyIcon style={{ color: `var(--commonWhite)`, fontSize: `var(--fontSize)` }} component="Done" />
              &nbsp;&nbsp;Update
            </Button>
          </Container.Grid>
        </Container.Grid>
      </Container.Paper>
    </form>
  );
};

async function fetch(item: string, values, user_id, set) {
  const app = 'user';
  const tab = 'checkScreenName';
  try {
    const queryName = dataQueryName({ app, tab, item });
    const query = await queryClient.fetchQuery(queryName, async () => {
      const {
        data: { is_taken, message }
      } = await dataFetch({ app, tab, item, params: { user_id } });
      return { is_taken, message };
    });
    set(query);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

export default UserProfile;
