import React from 'react';
import { useLocation } from 'react-router-dom';
import { Alert } from '@material-ui/lab';
import { isMobile } from "react-device-detect"

const PaymentAlert = ({ access }) => {
  const location = useLocation();

  if (location.pathname === '/login' || !access || !access.alerts || isMobile) {
    return null;
  }

  return (
    <Alert severity="error">
      <span dangerouslySetInnerHTML={{ __html: access.alerts[0] }} />
    </Alert>
  );
};

export default PaymentAlert;
