// @flow
import React, { useEffect, useState } from 'react';
import newsfeed from 'helpers/apis/newsfeed';
import { LoadingStyled } from 'components';
import { makeStyles, Divider, IconButton, Dialog, DialogTitle, Box, Typography } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import UserAvatar from '../common/UserAvatar';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: '15px',
    marginBottom: '20px',
    color: theme.palette.text.secondary
  },
  list: {
    padding: '15px',
    overflowY: 'auto',
    height: '30vh',
    [theme.breakpoints.down('sm')]: {
      height: '60vh'
    },
  },
  image: {
    maxWidth: '100%'
  },
  deleteIcon: {
    backgroundColor: 'rgba(255, 90, 97, 0.2)',
    color: '#FF5A61',
    borderRadius: '50%',
    padding: '10px',
    fontSize: '25px'
  },
  closeBtn: {
    position: 'absolute',
    right: 0,
    top: 0
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
    color: '#535353'
  }
}));

const ReactionsModal = ({ open, close, id, type }) => {
  const classes = useStyles();
  const [reacters, setReacters] = useState(null);
  const [hasMore, setHasMore] = useState(true);

  const fetchMoreDataForPosts = () => {
    newsfeed
      .getPostsLikers(id, reacters.length)
      .then(res => {
        if (res.data.length !== 0) {
          let reactersArray = [...reacters, ...res.data];
          setReacters(reactersArray);
        } else setHasMore(false);
      })
      .catch(err => {
        console.clear();
        console.log(err);
      });
  };
  const fetchMoreDataForComments = () => {
    newsfeed
      .getCommentsLikers(id, reacters.length)
      .then(res => {
        if (res.data.length !== 0) {
          let reactersArray = [...reacters, ...res.data];
          setReacters(reactersArray);
        } else setHasMore(false);
      })
      .catch(err => {
        console.clear();
        console.log(err);
      });
  };

  const fetchInitialDataForPosts = () => {
    newsfeed
      .getPostsLikers(id, 0)
      .then(res => {
        setReacters(res.data);
        if (res.data.length % 10 !== 0) setHasMore(false);
      })
      .catch(err => {
        console.clear();
        console.log(err);
      });
  };

  const fetchInitialDataForComments = () => {
    newsfeed
      .getCommentsLikers(id, 0)
      .then(res => {
        setReacters(res.data);
        if (res.data.length % 10 !== 0) setHasMore(false);
      })
      .catch(err => {
        console.clear();
        console.log(err);
      });
  };

  const fetchData = () => {
    switch (type) {
      case 'comment':
        fetchMoreDataForComments();
        break;
      case 'post':
        fetchMoreDataForPosts();
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    switch (type) {
      case 'comment':
        fetchInitialDataForComments();
        break;
      case 'post':
        fetchInitialDataForPosts();
        break;

      default:
        break;
    }
  }, []);

  return (
    <>
      <Dialog maxWidth="sm" fullWidth open={open} onClose={close}>
        <DialogTitle style={{ position: 'relative' }} id="dialog-title">
          <Typography className={classes.title}>Likes</Typography>
          <IconButton
            className={classes.closeBtn}
            id="ModalHeaderButtonClose"
            onClick={close}
          >
            <CloseIcon style={{ fontSize: 25 }} />
          </IconButton>
        </DialogTitle>
        <Divider />
        {reacters ? (
          <div
            className={classes.list}
            id="scrollableDiv1"
          >
            <InfiniteScroll
              dataLength={reacters.length} //This is important field to render the next data
              next={fetchData}
              hasMore={hasMore}
              scrollableTarget="scrollableDiv1"
              loader={<LoadingStyled />}
            >
              {reacters.map(reacter => (
                <>
                  <Box display="flex" alignItems="center" px={{ xs: 0, md: 1 }} py={1}>
                    <UserAvatar user={reacter.user} timestamp={reacter.timestamp} />
                  </Box>
                  <Divider />
                </>
              ))}
            </InfiniteScroll>
          </div>
        ) : (
          <LoadingStyled />
        )}
      </Dialog>
    </>
  );
};

export default ReactionsModal;
