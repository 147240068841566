/* eslint-disable no-console */
// @flow
import React from 'react';
import { Error } from 'react-admin';
import Loadable from 'react-loadable';
import { LoadingStyled } from '../types';

export { default as Copy } from './Copy';
export { default as CopyLink } from './CopyLink';
export { default as DarkWeb } from './DarkWeb';
export { default as Done } from './Done';
export { default as DragDrop } from './DragDrop';
export { default as Key } from './Key';
export { default as LandingPage } from './LandingPage';
export { default as Lock } from './Lock';
export { default as LogOut } from './LogOut';
export { default as Phishing } from './Phishing';
export { default as User } from './User';
export { default as DarkMode } from './DarkMode';
export { default as Home } from './Home';
export { default as Notification } from './Notification';
export { default as NotificationAlert } from './NotificationAlert';
export { default as MyDashboard } from './MyDashboard';
export { default as MyCompany } from './MyCompany';
export { default as Clients } from './Clients';
export { default as Newsfeed } from './Newsfeed';
export { default as WatchNow } from './WatchNow';
export { default as Shield } from './Shield';
export { default as Chat } from './Chat';
export { default as Star } from './Star';
export { default as Info } from './Info';
export { default as Close } from './Close';
export { default as Search } from './Search';
export { default as ScrollRight } from './ScrollRight';
export { default as ScrollLeft } from './ScrollLeft';
export { default as Print } from './Print';
export { default as Email } from './Email';
export { default as Eye } from './Eye';
export { default as Delete } from './Delete';
export { default as DeleteIcon} from "./DeleteIcon";
export { default as LikeIcon} from "./LikeIcon";
export { default as CommentIcon} from "./CommentIcon";
export { default as Comment} from "./Comment";
export { default as Alert } from './Alert';
export { default as WeHaventInfoYet } from './WeHaventInfoYet';
export { default as UserOutline } from './UserOutline';
export { default as Track } from './Track';
export { default as Document } from './Document';
export { default as SelectedBullet } from './SelectedBullet';
export { default as Bullet } from './Bullet';
export { default as Mail } from './Mail';
export { default as Refresh } from './Refresh';
export { default as Download } from './Download';
export { default as Heart } from './Heart';
export { default as Replay } from './Replay';
export { default as PartnerProfile } from './PartnerProfile';
export { default as PartnerResource } from './PartnerResource';
export { default as PartnerKey } from './PartnerKey';
export { default as PartnerLogin } from './PartnerLogin';
export { default as PartnerSecurity } from './PartnerSecurity';
export { default as PartnerDark } from './PartnerDark';
export { default as PartnerBPP } from './PartnerBPP';
export { default as PartnerPII } from './PartnerPII';
export { default as PartnerPHI } from './PartnerPHI';
export { default as PartnerRemote } from './PartnerRemote';
export { default as ColorPickup } from './ColorPickup';
export { default as Consulting } from './Consulting';
export { default as Insurance } from './Insurance';
export { default as Enable } from './Enable';
export { default as Disable } from './Disable';
export { default as Scenario } from './Scenario';
export { default as Calendar } from './Calendar';
export { default as Plus } from './Plus';
export { default as Filter } from './Filter';
export { default as Play } from './Play';
export { default as Message } from './Message';
export { default as Welcome } from './Welcome';
export { default as Save } from './Save';
export { default as Policy } from './Policy';
export { default as OtherPolicy } from './OtherPolicy';
export { default as WelcomeMessage } from './WelcomeMessage';
export { default as Clear } from './Clear';
export { default as Help } from './Help';
export { default as Edit } from './Edit';
export { default as Loading } from './Loading';
export { default as Success } from './Success';
export { default as SuccessXl } from './SuccessXl';
export { default as Warning } from './Warning';
export { default as WarningXl } from './WarningXl';
export { default as Arrow } from './Arrow';
export { default as EyeClose} from "./EyeClose";
export { default as Complete} from "./Complete";
export { default as NotComplete} from "./NotComplete";
export { default as Alphabetical} from "./Alphabetical";
export { default as Sort} from "./Sort";
export { default as Bar} from "./Bar";
export { default as Renew} from "./Renew";
export { default as Update} from "./Update";
export { default as Settings} from "./Settings";
export { default as Image } from './Image';
export { default as Video } from './Video';
export { default as File } from './File';
// Removed Icons Old
// export { default as Attention } from './Attention';
// export { default as AutoPhish } from './AutoPhish';
// export { default as BackArrow } from './BackArrow';
// export { default as Calendar } from './Calendar';
// export { default as ChevronRight } from './ChevronRight';
// export { default as ChevronDown } from './ChevronDown';
// export { default as CSVUpload } from './CSVUpload';
// export { default as Delete } from './Delete';
// export { default as Documents } from './Documents';
// export { default as Employee } from './Employee';
// export { default as FilterList } from './FilterList';
// export { default as Manage } from './Manage';
// export { default as PoliciesProcedures } from './PoliciesProcedures';
// export { default as PossibleScenario } from './PossibleScenario';
// export { default as PurchaseConfirmation } from './PurchaseConfirmation';
// export { default as ScenarioDifficultyMedium } from './ScenarioDifficultyMedium';
// export { default as Search } from './Search';
// export { default as SingleCompaign } from './SingleCompaign';
// export { default as Score } from './Score';
// export { default as SwitchManage } from './SwitchManage';
// export { default as SwitchUser } from './SwitchUser';
// export { default as TotalRecipients } from './TotalRecipients';
// export { default as TotalSimulation } from './TotalSimulation';
// export { default as Training } from './Training';

type LoadableProps = any;

export const LazyIcon = ({ component, color, ...rest }: { component: string, color?: string }) => {
  const iconColor = color || 'primary';
  const LoadableIcon = Loadable<LoadableProps>({
    loader: () => import(`./${component}`),
    loading: ({ error }: { error: any }) => {
      if (error) {
        console.group(`%cERROR: LAZY ICON`, 'font-size: medium; color: red');
        console.log(error);
        console.groupEnd();
        return <Error />;
      }
      return <LoadingStyled />;
    }
  });

  return <LoadableIcon {...rest} color={iconColor} />;
};

LazyIcon.defaultProps = {
  color: 'primary'
};
