// @flow

import { useApp } from 'helpers';
import { useSelector } from 'react-redux';
import useDataGet from './useDataGet';

type useTabDataType = {
  app: string,
  tab: string | false,
  item?: string | false,
  params?: string,
  enabled?: boolean
};

function useTabData({ app, tab: tabTab, item, params = {}, enabled: tabEnabled = true }: useTabDataType) {
  const { dispatch } = useApp();
  let enabled = tabEnabled;
  const UseAccess = ({ app: tabApp }) =>
    useSelector(state => {
      const isTab = tabTab !== false;
      let accessTab = tabTab;
      if (!isTab) {
        const accessObject = state.bsn.user?.access?.apps[tabApp] || {};
        const accessArray = Object.keys(accessObject).filter(a => accessObject[a]);
        accessTab = accessArray.includes(accessTab) ? accessTab : accessArray[0];
      }
      return accessTab;
    });
  const tab = UseAccess({ app });
  enabled = tab ? enabled : false;
  const options = {
    enabled,
    onSuccess: ({ data }) => {
      dispatch.set(app, tab, data);
    }
  };

  const data = useDataGet({ app, tab, item, params, options });

  return data;
}

export default useTabData;
