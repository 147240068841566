// @flow
import React, { memo, type ComponentType, useCallback, useState } from 'react';
import styled from 'styled-components';
import { TextInput, SaveButton, Toolbar, SimpleForm, useNotify, useCreate, useRedirect} from 'react-admin';
import { Button } from '@material-ui/core';
import { Typography } from 'components';
import type { Element } from 'react';
import { Create } from './ComponentTypes';

type ClientsCreateProps = Object;

const InputCreate: ComponentType<*> = styled(TextInput)`
  && {
    margin: 0;
    & > label {
      background: var(--colorCommonWhite);
      padding: 0 calc(var(--spacing) * 0.8);
      &.Mui-focused {
        /* fixed spacing */
        top: -13px;
      }
    }
    & > div {
      border-radius: var(--borderRadius);
      border: var(--borderSize) solid var(--borderBase);
      background-color: var(--colorCommonWhite);
      padding: calc(var(--spacing) * 0.2) 0;

      & > input {
        /* fixed spacing */
        padding: 15px 20px 15px;
      }

      &:before,
      &:after {
        display: none;
      }
    }
  }
`;

const ToolbarBottom: ComponentType<*> = styled(Toolbar)`
  display: flex;
  flex-direction: row-reverse;
  border-top: var(--borderSize) solid var(--borderDefault);
  && {
    background-color: var(--colorCommonWhite);
  }
`;

const ButtonCancel: ComponentType<*> = styled(Button)`
  && {
    text-transform: capitalize;
    color: var(--colorDefault);
    background: transparent;
    text-decoration: underline;
    box-shadow: none;
    margin-right: 20px;
    font-size: var(--fontSize);
    & span {
      color: var(--colorDefault);
    }
    &:hover {
      color: var(--colorDefault);
      background: transparent;
      text-decoration: underline;
      box-shadow: none;
    }
  }
`;

const ButtonSave: ComponentType<*> = styled(SaveButton)`
  && {
    text-transform: capitalize;
    font-weight: var(--fontWeightRegular);
    color: var(--colorCommonWhite);
    background: var(--colorSystemSuccess);
    box-shadow: none;
    font-size: var(--fontSize);

    &:hover {
      background: var(--colorSystemSuccess);
      box-shadow: none;

      & svg {
        color: var(--colorCommonWhite);
      }
    }
  }
`;

const CreateToolbar: Function = ({ handleCancel, ...props }: ClientsCreateProps): Element<*> => {
  const [loading, setLoading] = useState(false);
  const notify = useNotify();
  const [create] = useCreate('clients');
  const redirectTo = useRedirect();
  const { basePath } = props;
  
  const handleSave = useCallback(
    (values, redirect) => {
      if (!values.name.trim()) return  notify('Please enter a client name', 'warning');
      setLoading(true);
      create(
        { payload: {  data: { ...values }}, },
        {
          onSuccess: () => {
            setLoading(false);
            notify('Client has been created', 'info');
            redirectTo(redirect, basePath);
          },
          onFailure: (err) => {
            setLoading(false);           
            notify(err.response.data.status, 'warning');
          }
        }
      );
    },
    [create, notify, redirectTo, basePath]
  );

  return(
    <ToolbarBottom {...props}>
      <ButtonSave label="Create New Client" redirect="/clients" onSave={handleSave} disabled={loading} />
      <ButtonCancel onClick={() => handleCancel('/clients')}>Cancel</ButtonCancel>
    </ToolbarBottom> 
  );
};

const ClientsCreate: Function = (props: ClientsCreateProps): Element<*> => {
  return (
    <Create {...props}>
      <SimpleForm toolbar={<CreateToolbar handleCancel={props.history.push} />}>
        <Typography.h3 mb={4}>Create New Client</Typography.h3>
        <InputCreate source="name" label="Company Name" fullWidth />
      </SimpleForm>
    </Create>
  );
};

export default memo<ClientsCreateProps>(ClientsCreate);
