// @flow
import React, {useState} from 'react';
import { Tooltip, Fade } from '@material-ui/core';

type TooltipTypes = {
  title: string,
  children: any
};

const CustomTooltip = ({title, children, ...rest}: TooltipTypes) => {
  const [open, setOpen] = useState(false);
  
  const handleTooltipClose = () => {
    setOpen(false);
  }

  const handleTooltipOpen = () => {
    if(!children?.props?.disabled) setOpen(true);
  }

  return(
    <Tooltip 
      title={title} 
      open={open} 
      {...rest}
    >
      <div onMouseEnter={handleTooltipOpen}
        onMouseLeave={handleTooltipClose}
        onClick={handleTooltipClose}
      >
        {children}
      </div>
    </Tooltip>
  );
}

export default CustomTooltip;
