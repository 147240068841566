// @flow
import React, { type ComponentType } from 'react';
import styled from 'styled-components';
import { Table as MuiTable } from '@material-ui/core';

type TableTypes = {
  Container: ComponentType<*>
};

const Table: TableTypes = {};

Table.Container = styled(({ p, pt, pr, pb, pl, m, mt, mr, mb, ml, b, bt, br, bb, bl, radius, ...props }) => (
  <MuiTable {...props} />
))`
  && {
    width: 100%;
    ${({ p }) => p && `padding: calc(${p} * var(--spacing))px`};
    ${({ pt }) => pt && `padding-top: calc(${pt} * var(--spacing))`};
    ${({ pr }) => pr && `padding-right: calc(${pr} * var(--spacing))`};
    ${({ pb }) => pb && `padding-bottom: calc(${pb} * var(--spacing))`};
    ${({ pl }) => pl && `padding-left: calc(${pl} * var(--spacing))`};

    ${({ m }) => m && `margin: calc(${m} * var(--spacing))`};
    ${({ mt }) => mt && `margin-top: calc(${mt} * var(--spacing))`};
    ${({ mr }) => mr && `margin-right: calc(${mr} * var(--spacing))`};
    ${({ mb }) => mb && `margin-bottom: calc(${mb} * var(--spacing))`};
    ${({ ml }) => ml && `margin-left: calc(${ml} * var(--spacing))`};

    ${({ b }) => b && `border: ${b}px solid var(--colorGreyLight3)`};
    ${({ bt }) => bt && `border-top: ${bt}px solid var(--colorGreyLight3)`};
    ${({ br }) => br && `border-right: ${br}px solid var(--colorGreyLight3)`};
    ${({ bb }) => bb && `border-bottom: ${bb}px solid var(--colorGreyLight3)`};
    ${({ bl }) => bl && `border-left: ${bl}px solid var(--colorGreyLight3)`};

    border-collapse: separate;
  }
  ${({ radius }) => radius && `border-radius: calc(${radius} * var(--borderRadius))`};
`;

export default Table;
