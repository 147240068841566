// @flow
import React from 'react';
import { Grid, makeStyles, Box } from '@material-ui/core';
import PostHeaderActions from './PostHeaderActions';
import UserAvatar from '../common/UserAvatar';
import { getStorage, decodeId } from 'helpers';

const useStyles = makeStyles(theme => ({
  image: {
    maxWidth: '100%'
  },
  padded: {
    paddingBottom: '20px',
    [theme.breakpoints.down('sm')]: {
      padding: '15px'
    },
  },
  deleteIcon: {
    backgroundColor: 'rgba(255, 90, 97, 0.2)',
    color: '#FF5A61',
    borderRadius: '50%',
    padding: '10px',
    fontSize: '25px'
  }
}));

const PostHeader = ({ feed, feedIdx, updateAfterPostModalClose }) => {
  const classes = useStyles();
  const user = { id: decodeId(getStorage('userId')) };

  return (
    <Box className={classes.padded} display="flex" alignItems="center">
      <UserAvatar user={feed.user} timestamp={feed.timestamp} />
      <Box>
        <PostHeaderActions feed={feed} feedIdx={feedIdx} updateAfterPostModalClose={updateAfterPostModalClose} />
      </Box>
    </Box>
  );
};

export default PostHeader;
