// @flow
import React, { useState } from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';

const PostCommentActions = ({ comment, commentIdx, showDeleteModal, toggleUpdate }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(null);
  const handleClick = event => {
    setAnchorEl({ [event.currentTarget.id]: event.currentTarget });
    setOpen({ [event.currentTarget.id]: true });
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(null);
  };

  //if (!comments) return <LoadingStyled />;
  //console.log(user);
  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls={`long-menu-comments${commentIdx}`}
        aria-haspopup="true"
        id={`menu-control-comments${commentIdx}`}
        onClick={handleClick}
        disabled={comment.disableActions}
        style={{ marginRight: '-12px', opacity: comment.disableActions ? '0.5' : '1' }}
      >
        <MoreVertIcon style={{ fontSize: 20, color: 'var(--colorGreyLight2)' }} />
      </IconButton>
      <Menu
        id={`long-menu-comments${commentIdx}`}
        anchorEl={
          anchorEl && anchorEl.hasOwnProperty(`menu-control-comments${commentIdx}`)
            ? anchorEl[`menu-control-comments${commentIdx}`]
            : null
        }
        keepMounted
        open={
          open && open.hasOwnProperty(`menu-control-comments${commentIdx}`)
            ? open[`menu-control-comments${commentIdx}`]
            : null
        }
        onClose={handleClose}
        PaperProps={{
          style: {}
        }}
      >
        <MenuItem
          style={{ fontSize: 16, color: '#000000', padding: '10px 40px 10px 20px' }}
          onClick={() => {
            toggleUpdate();
            handleClose();
          }}
        >
          Edit
        </MenuItem>

        <MenuItem
          style={{ fontSize: 16, color: 'red', padding: '10px 40px 10px 20px' }}
          onClick={() => {
            showDeleteModal();
            handleClose();
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </>
  );
};

export default PostCommentActions;
