// @flow
import { type ComponentType } from 'react';
import styled from 'styled-components';
import { Create as RACreate } from 'react-admin';
import {
  Grid,
  Typography,
  FormHelperText,
  Button,
  ExpansionPanel,
  ExpansionPanelSummary,
  DialogContentText as MuiDialogContentText
} from '@material-ui/core';

export const Create: ComponentType<*> = styled(RACreate)`
  width: 100%;
`;

export const GridContainer: ComponentType<*> = styled(Grid)`
  width: inherit;
  margin: inherit;
  padding: inherit;
`;

export const ClientSubTitle: ComponentType<*> = styled(Typography)`
  margin-bottom: 15px;
  font-weight: bold;
`;

export const ClientText: ComponentType<*> = styled(Typography)`
  margin: 10px 0;
`;

export const ClientHelperText: ComponentType<*> = styled(FormHelperText)`
  text-indent: 33px;
  margin-top: -10px;
`;

export const ClientSubTitlePadding: ComponentType<*> = styled(Typography)`
  margin: 30px 0 15px 0;
  font-weight: bold;
`;

export const SmallBlueInfoText: ComponentType<*> = styled(Typography)`
  && {
    font-size: 11px;
  }
  color: var(--colorSystemInfo);
`;
export const InfoText: ComponentType<*> = styled(Typography)`
  && {
    font-size: var(--fontSize);
    color: var(--colorDefault);
  }
  padding: 0;
  margin: 0;
`;
export const TextColorBase: ComponentType<*> = styled(Typography)`
  && {
    font-size: 16px;
    color: var(--colorBaseBase);
  }
  padding: 0;
  margin: 0;
`;

export const TextTitle: ComponentType<*> = styled(Typography)`
  && {
    font-size: 16px;
    color: var(--colorBaseBase);
  }
`;

export const InfoTextTitle: ComponentType<*> = styled(Typography)`
  && {
    font-size: var(--fontSize);
    font-weight: bold;
    padding: 0;
    margin: 0;
  }
`;

export const TabsWrapper: ComponentType<*> = styled.div`
  /* max-width: 1000px; */
`;

export const CapitalizeButton: ComponentType<*> = styled(Button)`
  && {
    text-transform: capitalize;
    background-color: var(--buttonPrimaryBackground);
    color: var(--colorCommonWhite);
    font-weight: 300;
    :hover {
      background-color: var(--buttonPrimaryHover);
    }
  }
`;

export const ContainerDisabledButton: ComponentType<*> = styled.div`
  ${({ disabled }) => disabled && `cursor: not-allowed;`}
`;

export const DivStyled: ComponentType<*> = styled.div`
  && {
    align-self: end;
  }
`;

export const DivSpaceBetween: ComponentType<*> = styled.div`
  && {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`;

export const AccordionSummary: ComponentType<*> = styled(ExpansionPanelSummary)`
  && {
    border-radius: var(--borderRadius);
  }
  > div:first-child {
    margin: 0 0;
    color: grey;
  }
  .Selected {
    color: blue;
  }
`;
// TODO: set width 100% on AccordionSummary, then align on right
// TODO: when selected change the color of AccordionSummary to blue (text and svg);
export const Accordion: ComponentType<*> = styled(ExpansionPanel)`
  margin: 6.77px 0;
  && {
    &:before {
      background: none;
    }
  }
`;

export const AccordionSummaryHeader: ComponentType<*> = styled(ExpansionPanelSummary)`
  && {
    border: 1px solid grey;
    border-radius: var(--borderRadius);
  }
  > div:first-child {
    margin: 0 0;
    color: grey;
    display: flex;
    justify-content: flex-end;
  }
`;

export const DialogContentText = styled(MuiDialogContentText)`
  text-align: justify;
`;

export const DialogActions = styled.div`
  flex: 0 0 auto;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  justify-content: space-between;
`;

export const DialogActionsLeft = styled.div`
  align-items: flex-start;
`;

export const DialogActionsRight = styled.div`
  align-items: flex-end;
`;
