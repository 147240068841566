// @flow
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dialog, Button, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import newsfeed from 'helpers/apis/newsfeed';
import { setComments, setFeeds, updateNotifications } from 'helpers/action';

const DeleteCommentModal = ({ open, close, postId, commentId, commentIdx, onDeleteComment }) => {
  const dispatch = useDispatch();
  const comments = useSelector(state => state.newsfeed.comments);
  const feeds = useSelector(state => state.newsfeed.feeds);

  const deleteComment = () => {
    let postIdx = feeds.findIndex(x => x.id === postId);
    dispatch(
      setFeeds([
        ...feeds.slice(0, postIdx),
        ...[
          {
            ...feeds[postIdx],
            comments_count: feeds[postIdx].comments_count - 1
          }
        ],
        ...feeds.slice(postIdx + 1)
      ])
    );
    dispatch(
      setComments(postId, [...comments[postId].slice(0, commentIdx), ...comments[postId].slice(commentIdx + 1)])
    );
    newsfeed
      .deleteComment(commentId,postId)
      .then(res => {
        if (res?.status && res.status === 200) dispatch(updateNotifications(true));
        onDeleteComment(commentId);
      });
    close();
  };

  return (
    <>
      <Dialog open={open} onClose={close} style={{ padding: '50px,10px' }}>
        <DialogTitle id="responsive-dialog-title">{'Delete Comment'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this comment?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={close} color="primary">
            Cancel
          </Button>
          <Button onClick={deleteComment} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteCommentModal;
