/* eslint-disable no-underscore-dangle */
// @flow

import { useReducer, useEffect } from 'react';
import { useQuery, QueryClient } from 'react-query';
import { dataFetch, dataQueryName } from 'helpers';

type useTableDataTypes = {
  app: string,
  tab: string,
  item?: string,
  data?: any,
  params?: Object,
  options?: Object,
  transform: Function
};

const queryClient = new QueryClient();

const useTableData = ({
  app,
  tab,
  item,
  params: uParams = {},
  options: uOptions = {},
  transform
}: useTableDataTypes) => {
  const initialState = {
    params: uParams,
    options: {}
  };
  const [state, setState] = useReducer(reducer, initialState);
  const { params } = state;
  const options = { ...uOptions, ...state.options };
  const queryname = dataQueryName({ app, tab, item, params });
  const { isSuccess, data: record, refetch: queryRefetch, ...query } = useQuery(
    queryname,
    () => fetch(app, tab, item, params, transform),
    getOptions(options)
  );

  useEffect(() => {
    if (record?.hasMore || params._callId) {
      queryClient.prefetchQuery(queryname, () => fetch(app, tab, item, params, transform), getOptions(options));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record, app, tab, item, params, options, queryname]);

  const dispatch = {};

  dispatch.setPage = (page: number) => {
    setState({ type: 'SETPAGE', payload: page });
  };

  dispatch.setFilter = (filter: number) => {
    setState({ type: 'SETFILTER', payload: filter });
  };

  dispatch.refetch = () => {
    setState({ type: 'SETREFETCH', payload: Date.now() });
  };

  dispatch.disableRefresh = () => {
    setState({ type: 'DISABLEREFRESH', payload: null });
  };

  dispatch.setPrePage = (prePage) => {
    setState({ type: 'SET_PRE_PAGE', payload: prePage });
  }

  return {
    ...query,
    data: record?.data || [],
    page: params.page,
    params,
    dispatch,
    total: record?.total || 0
  };
};

function reducer(prevState, { type, payload }) {
  const newState = {...prevState};
  switch (type) {
    case 'SETPAGE': {
      newState.params.pagination.page = payload;
      return newState;
    }

    case 'SETFILTER': {
      newState.params = payload;
      return newState;
    }

    case 'SETREFETCH': {
      newState.params._callId = payload;
      return newState;
    }

    case 'DISABLEREFRESH': {
      newState.options.refetchOnWindowFocus = false;
      return newState;
    }

    case "SET_PRE_PAGE": {
      newState.params.pagination.perPage = payload
      newState.params.pagination.page = 0
      return newState
    }

    default: {
      return { ...prevState, ...payload };
    }
  }
}

function getOptions(options) {
  return {
    keepPreviousData: true,
    staleTime: 10,
    cacheTime: false,
    ...options
  };
}

async function fetch(app, tab, item, params, transform) {
  const { data, headers } = await dataFetch({ app, tab, item, params });
  return { data: transform(data), total: headers['x-total-count'] };
}

export default useTableData;
