// @flow
import React, { type Element, useRef } from 'react';
import useWindowResize from 'beautiful-react-hooks/dist/useWindowResize';
import { BoxDashboard, BoxLegendDashboard, BoxTitleDashboard, BoxWrapperLegendDashboard } from './ComponentTypes';
import ChartSpeedometer from '../charts/ChartSpeedometer';
import { dashboardChartSettingsESS } from './helper';

type DashboardChartESSTypes = {
  chartData: Object,
  hasData: boolean
};

const DashboardChartESS = ({ chartData, hasData }: DashboardChartESSTypes): Element<*> => {
  const boxRef = useRef();
  const onWindowResize = useWindowResize();

  onWindowResize(() => {
    // eslint-disable-next-line no-console
    console.log(boxRef);
  });

  return (
    <div ref={boxRef}>
      <BoxDashboard>
        <BoxTitleDashboard variant="h3" component="h3">
          Employee Secure Score (ESS)
        </BoxTitleDashboard>
        <ChartSpeedometer chartData={chartData} chartSettings={dashboardChartSettingsESS} hasData={hasData} />
        <BoxWrapperLegendDashboard>
          <BoxLegendDashboard background="#f78383">Poor ESS 0-499</BoxLegendDashboard>
          <BoxLegendDashboard background="#e8d980">Fair ESS 500-629</BoxLegendDashboard>
          <BoxLegendDashboard background="#8bcc8b">Good ESS 630-800</BoxLegendDashboard>
        </BoxWrapperLegendDashboard>
      </BoxDashboard>
    </div>
  );
};

export default DashboardChartESS;
