import styled from "styled-components"
import { Accordion, ListItem } from '@material-ui/core';

export const OrganisationListItem = styled(ListItem)`
  padding-left: 0 !important;
`

export const SystemAccordion = styled(Accordion)`
  .system-accordion-text {
    margin: 0;
  }

  .pt-0 {
    padding-top: 0 !important;
  }
`
