// @flow
import React, { memo, type Element, useState } from 'react';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

type Props = Object;

const DateTimeField = ({ classes, nullValue, value, onChange, ...props }: Props): Element<*> => {
  let date =
    value && value !== ''
      ? new Date(new Date(value).getTime() + new Date(value).getTimezoneOffset() * 60000)
      : nullValue
      ? null
      : new Date();
  const [newValue, setValue] = useState(date);
  const isOnchange = typeof onChange === 'function';

  const onDateChange = e => {
    let dateString = new Date(e.getTime() - e.getTimezoneOffset() * 60000).toISOString();
    if (isOnchange) onChange(dateString);
    setValue(e);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DateTimePicker
        {...props}
        inputVariant="outlined"
        margin="none"
        value={newValue}
        onChange={onDateChange}
        classes={classes || {}}
        InputLabelProps={{ classes: { focused: 'field-focused' } }}
        format="yyyy-MM-dd hh:mm a"
      />
    </MuiPickersUtilsProvider>
  );
};

export default memo<Props>(DateTimeField);
