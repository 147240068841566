// @flow

import disasterRecovery from './disasterRecovery';
import documentsServiceProvider from './documentsServiceProvider';
import documentsTemplates from './documentsTemplates';
import phishingCampaignScenariosList from './phishingCampaignScenariosList';
import phishingCampaignRecipientsList from './phishingCampaignRecipientsList';
import policies from './policies';
import otherDocuments from './otherDocuments';
import otherPolicies from './otherPolicies';
import sraDocuments from '../myCompany/sraDocuments';
import trackSecurityIncidents from './trackSecurityIncidents';
import trackServerRoomAccess from './trackServerRoomAccess';
import usersList from './usersList';
import usersBPPList from './usersBPPList';
import workPlan from '../myCompany/workPlan';
import workPlanFinding from '../myCompany/workPlanFinding';

const clients = {};

clients.disasterRecovery = disasterRecovery;
clients.documentsServiceProvider = documentsServiceProvider;
clients.documentsTemplates = documentsTemplates;
clients.phishingCampaignScenariosList = phishingCampaignScenariosList;
clients.phishingCampaignRecipientsList = phishingCampaignRecipientsList;
clients.policies = policies;
clients.otherDocuments = otherDocuments;
clients.otherPolicies = otherPolicies;
clients.sraDocuments = sraDocuments;
clients.trackSecurityIncidents = trackSecurityIncidents;
clients.trackServerRoomAccess = trackServerRoomAccess;
clients.usersList = usersList;
clients.usersBPPList = usersBPPList;
clients.workPlan = workPlan;
clients.workPlanFinding = workPlanFinding;

export default clients;
