// @flow
import React, { type Element, useCallback, useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import { LoadingStyled, Button, Modal3 } from 'components';
import { useId, useLocation, useDataGet } from 'hooks';
import { useApp } from 'helpers';
import { SelectField } from '../forms';
import TableListModal from '../tablelist/TableListModal';

type PageDocumentsStateTypes = {
  value: string,
  templateValue: string,
  open: boolean,
  presignedUrlKey: string
};

type PageDocumentsDispatchTypes = {
  type: string,
  payload: any
};

const initialState = {
  value: '',
  templateValue: 'Show All',
  open: false,
  presignedUrlKey: ''
};

const PageDocuments = (): Element<*> => {

  const [refresh, setRefresh] = useState(false)

  const [{ value, templateValue, open, presignedUrlKey }, setState] = useReducer<
    PageDocumentsStateTypes,
    PageDocumentsDispatchTypes
  >(reducer, initialState);
  const app = 'clients';
  const settings = useSelector(state => state.bsn.tables[app][value]);
  const { app: appLoc, tab, item: itemLoc } = useLocation();
  const clientId = useId({ key: 'clientId' });
  const { dispatch: dispatchApp } = useApp();
  const item = itemLoc || clientId;
  const { data: record } = useDataGet({ app: appLoc, tab, item });

  useEffect(() => {
    if (!value && record && record.length > 0 ) {
      const indexTemplates = record.findIndex(r => r.value === 'documentsTemplates');
      const index = indexTemplates !== -1 ? indexTemplates : 0;
      setState({ type: 'SETVALUE', payload: record[index].value });
    } 
  }, [record, value]);

  useEffect(() => {
    return () => {
      dispatchApp.set('system', 'tableFilter', []);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // I need document type.. to pass on the modal, and to show on the button.. I will get from the select box.
  useEffect(() => {
    if (value === 'disasterRecovery') setState({ type: 'SETPRESIGNEDURLKEY', payload: 'disaster_recovery' });
    if (value === 'documentsServiceProvider' || value === 'documentsBusinessAssociateAgreement')
      setState({ type: 'SETPRESIGNEDURLKEY', payload: 'documents' });
    if (value === 'otherDocuments') setState({ type: 'SETPRESIGNEDURLKEY', payload: 'other_documents' });
  }, [value]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const Table = useCallback(props => <TableListModal {...props} />, [value]);

  if (!record || !value) return <LoadingStyled />;

  const docTypeChoices =
    record &&
    record.map((v, l) => {
      return { value: v.value, label: v.label };
    });

  const templateChoice = record && record.find(r => r.field_name === 'template');
  const typeChoices = templateChoice?.filters
    ? templateChoice.filters.map((v, l) => {
        return { value: v.id, label: v.label };
      })
    : [];

  const temTypeChoices = [{ value: 'Show All', label: 'Show All' }, ...typeChoices];

  const onClick = e => {
    setRefresh(!open )
    setState({ type: 'SETOPEN', payload: !open });
  };

  const onChange = e => {
    dispatchApp.set('system', 'tableFilter', { category_id: e.target.value === 'Show All' ? '' : e.target.value });
    setState({ type: 'SETTEMPLATE', payload: e.target.value });
  };

  const Toolbar = () => (
    <>
      {record && (
        <SelectField
          name="documentTypes"
          value={value}
          emptyValue="Document Type"
          onChange={e => setState({ type: 'SETVALUE', payload: e.target.value })}
          choices={docTypeChoices}
          mr={2}
          variant
        />
      )}
      {record && value === 'documentsTemplates' && (
        <SelectField
          name="templates"
          value={templateValue}
          onChange={onChange}
          choices={temTypeChoices}
          emptyValue="Templates Category"
          mr={2}
          variant
        />
      )}
      {value === 'disasterRecovery' && <Button onClick={onClick}>+ New Disaster Recovery Plan</Button>}
      {value === 'documentsServiceProvider' && <Button onClick={onClick}>+ New Service Provider Document</Button>}
      {value === 'documentsBusinessAssociateAgreement' && (
        <Button onClick={onClick}>+ New Business Associate Agreement</Button>
      )}
      {value === 'otherDocuments' && <Button onClick={onClick}>+ New Other Document</Button>}
    </>
  );

  const tableOptions = {
    refetchOnWindowFocus: false
  };

  return (
    <>
      <Table
        tab={value === 'documentsBusinessAssociateAgreement' ? 'documentsServiceProvider' : value}
        modalTitle={value === 'documentsBusinessAssociateAgreement' ? 'Business Associate Agreement Information' : ''}
        app={app}
        tableOptions={tableOptions}
        tableSettings={settings}
        toolbar={<Toolbar />}
        id={item}
        paramIdVal={item}
        resetTable={value === 'documentsBusinessAssociateAgreement' ? 'documentsServiceProvider' : value}
        tableRefresh={refresh}
      />
      {open && <Modal3 open={open} setOpen={onClick} app={app} type={presignedUrlKey} tab={value} createNew />}
    </>
  );
};

function reducer(prevState, { type, payload }) {
  switch (type) {
    case 'SETVALUE': {
      return { ...prevState, value: payload };
    }

    case 'SETTEMPLATE': {
      return { ...prevState, templateValue: payload };
    }

    case 'SETOPEN': {
      return { ...prevState, open: payload };
    }
    case 'SETPRESIGNEDURLKEY': {
      return { ...prevState, presignedUrlKey: payload };
    }
    default: {
      return { ...prevState, ...payload };
    }
  }
}

export default PageDocuments;
