import React, { useEffect, useState } from 'react';
import {
  ArrowLeft, ArrowRight,
  CurrentPaginationInput,
  MobilePaginationContainer,
  MobilePaginationItemsCount,
  MobilePaginationWrapper
} from './style';
import { useDebounce } from '../../../hooks';

const MobilePagination = ({
  start,
  end = 0,
  totalCount = 0,
  onChangePage,
  paginationCount,
  isFetching
}) => {

  const [paginationValue, setPaginationValue] = useState(1)


  const handleChangePagination = (e) => {
    if (e.target.value) {
      setPaginationValue(+e.target.value)
    } else {
      setPaginationValue(e.target.value)
    }
  }

  const handleBlur = (e) => {
    const {value} = e.target

    if (paginationValue > paginationCount) return false

    if (value < 1) {
      setPaginationValue(1)
    } else {
      onChangePage("_", paginationValue - 1)
    }
  }

  const handleSubmit = (e) => {
    e.stopPropagation();
    if (paginationValue > paginationCount || paginationValue < 1) return false
    onChangePage("_", paginationValue - 1)
  }

  const handleChangePage = (type) => {
    if (isFetching) {
      return null
    }
    if (type === "next") {
      if (paginationValue < paginationCount) {
        setPaginationValue(prevValue => prevValue + 1)
        onChangePage("_", paginationValue)
      }
    } else {
      if (paginationValue > 1) {
        setPaginationValue(prevValue => prevValue - 1)
        onChangePage("_", paginationValue - 2)
      }
    }
  }

  return (
    <MobilePaginationContainer display={"flex"} py={3} px={3} >
      <MobilePaginationItemsCount>
        {start} - {end} of  {totalCount} items
      </MobilePaginationItemsCount>
      <MobilePaginationWrapper>
        <ArrowLeft
          disabled={paginationValue <= 1 || isFetching}
          onClick={() => handleChangePage("prev")}
        />
        <form onSubmit={handleSubmit}>
          <CurrentPaginationInput
            type={"number"}
            value={paginationValue}
            onChange={handleChangePagination}
            onBlur={handleBlur}
            danger={paginationValue > paginationCount && paginationCount > 0}
            disabled={isFetching}
          />
        </form>
        <MobilePaginationItemsCount>
           of  {paginationCount}
        </MobilePaginationItemsCount>
        <ArrowRight
          disabled={paginationValue >= paginationCount || isFetching}
          onClick={() => handleChangePage("next")}
        />
      </MobilePaginationWrapper>
    </MobilePaginationContainer>
  )
}

export default MobilePagination;
