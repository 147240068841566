import React, { useMemo } from "react";
import { Box, Hidden, MenuItem, Select } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import {Typography} from "components"
import MobilePagination from './MobilePagination';

const CustomTablePagination = ({totalCount = 0, rowsPerPage = 25, page, onChangePage, onChangeRowsPerPage, isFetching, ...pagination}) => {
  const paginationCount = Math.ceil(totalCount / rowsPerPage) ;

  const start = (page * rowsPerPage) + 1;
  const end = Math.min(start + rowsPerPage - 1, totalCount);

  const rowsPerPageMemo = useMemo(
    () =>
      <Select value={rowsPerPage || 25} onChange={onChangeRowsPerPage} disabled={isFetching}>
        <MenuItem value={25}>25</MenuItem>
        <MenuItem value={50}>50</MenuItem>
        <MenuItem value={100}>100</MenuItem>
        <MenuItem value={200}>200</MenuItem>
      </Select>,
    [rowsPerPage, isFetching]
  );

  return (
    <>
      <Hidden mdUp>
        <MobilePagination
          start={start}
          end={end}
          totalCount={totalCount}
          paginationCount={paginationCount}
          onChangePage={onChangePage}
          isFetching={isFetching}
        />
      </Hidden>
      <Hidden smDown>
        <Box display={"flex"} py={3} px={3} justifyContent={"flex-end"} alignItems={"center"}>
          <Typography.p fontSize={12} my={0} mr={5} ml={5}>
            {start} - {end} of {totalCount} Items
          </Typography.p>

          {rowsPerPageMemo}

          <Typography.p fontSize={12} mr={2} ml={2}>
            Items per page
          </Typography.p>

          <Pagination
            {...pagination}
            count={paginationCount}
            page={page + 1}
            onChange={(event, paginationPage) => onChangePage(event, paginationPage - 1)}
            disabled={isFetching}
          />
        </Box>
      </Hidden>
    </>

  )
}

export default CustomTablePagination
