import React from "react"
import Arrow from '../../icons/Arrow';
import { Filter } from '../../icons';
import { HeadContainer, HeadTitleContainer } from './style';


const HeadTitle = ({backButton, filter, onFilter = () => {}, title, onBack = () => {}, customRightComponent = null}) => {
  return (
    <HeadContainer>
      <HeadTitleContainer onClick={onBack}>
        {backButton && <Arrow className={"back-button-arrow"}/>}
        <h3>{title}</h3>
      </HeadTitleContainer>
      {filter && <Filter onClick={onFilter}/>}
      {customRightComponent}
    </HeadContainer>
  )
}

export default HeadTitle
