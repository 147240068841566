// @flow
import React, { type Element, type ComponentType, useState, useCallback } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import { useNotify } from 'react-admin';
import styled from 'styled-components';
import { Container } from '../types';
import { LazyIcon } from '../icons';

const DragZoneStyled: ComponentType<*> = styled(Box)`
  position: relative;
  border: 1px dotted var(--colorBaseBase);
  border-radius: var(--borderRadius);
  width: 100%;
  outline: none;
  text-align: center;
  z-index: 2;
  padding: 40px 16px;
`;

const DragZoneContent: ComponentType<*> = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  left: 4%;
  z-index: 1;

  svg {
    font-size: 24px;
    color: var(--colorBaseBase);
  }
`;

export const SmallBlueInfoText: ComponentType<*> = styled(Typography)`
  && {
    font-size: 11px;
  }
  color: var(--colorSystemInfo);
`;
export const InfoText: ComponentType<*> = styled(Typography)`
  && {
    font-size: var(--fontSize);
    color: var(--colorDefault);
  }
  padding: 0;
  margin: 0;
`;
export const TextColorBase: ComponentType<*> = styled(Typography)`
  && {
    font-size: 16px;
    color: var(--colorBaseBase);
  }
  padding: 0;
  margin: 0;
`;

export const InfoTextTitle: ComponentType<*> = styled(Typography)`
  && {
    color: var(--colorBaseBase);
    font-size: var(--fontSize);
    font-weight: bold;
    padding: 0;
    margin: 0;
  }
`;

type DragZoneTypes = {
  title?: string,
  titleInside?: string,
  info?: string,
  hint?: string | boolean,
  icon?: string | Function,
  fileType: string,
  dispatch?: Function | null,
  size?: number,
  record: Object,
  disabled?: boolean,
  disabledClick?: boolean,
  type?: string,
  isRequired?: boolean
};

const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

const DragZone = ({
  title,
  titleInside,
  info,
  hint = false,
  icon,
  fileType,
  record,
  disabled,
  disabledClick = false,
  dispatch,
  type,
  size,
  isRequired
}: DragZoneTypes): Element<*> => {
  const notify = useNotify();
  const [file, setFile] = useState(null);
  const [base64, setBase64] = useState(null);
  const recordFileName = record?.file && (typeof record.file === 'object' ? record.file.name : record.file);
  const infoText = file ? file.name : recordFileName || info;

  const onDrop = useCallback(
    acceptedFiles => {
      const newFile = acceptedFiles[0];
      if (
        !newFile ||
        !fileType
          .replace(/\s/g, '')
          .split(',')
          .some(t => newFile.name.toLowerCase().includes(t))
      ) {
        return notify(`This type of file is not allowed. Please use a ${fileType.toUpperCase()} file`, 'warning');
      }

      const newFileObject = {};
      const fileObject = Object.assign(newFileObject, {
        lastModified: newFile.lastModified,
        lastModifiedDate: newFile.lastModifiedDate,
        name: newFile.name,
        path: newFile.path,
        size: newFile.size,
        type: newFile.type,
        webkitRelativePath: newFile.webkitRelativePath
      });

      setFile(fileObject);

      switch (type) {
        case 'base64':
          toBase64(newFile).then(res => {
            setBase64(res);
            if (dispatch) {
              dispatch(res, fileObject);
            }
            notify('File attached');
          });
          break;
        case 'file':
          if (dispatch) {
            dispatch(newFile);
          }
          notify('File attached');
          break;
        case 'input':
          break;
        default:
          break;
      }
    },
    [dispatch, notify, type]
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: `${fileType}`, // e.g.: .csv, .png, .docx
    disabled,
    onDrop
  });
  const inputProps = getInputProps();

  return (
    <Container.Grid item direction="column" sm={12} xs={12} xl={12} md={12}>
      <Container.Grid direction="row" alignItems="center" mb={1} sm={12} xs={12} xl={12} md={12}>
        <InfoTextTitle>{title}</InfoTextTitle>
      </Container.Grid>
      <Box position="relative">
        <Container.Grid direction="row" alignItems="center" mb={1} sm={size} xs={size} xl={size} md={size}>
          <DragZoneStyled display="flex" alignItems="center" flexDirection="row" {...getRootProps()}>
            <input type="file" id="file" {...inputProps}  />
            {type === 'base64' && <textarea name="base64" required={isRequired} value={base64 || ''} style={{ display: 'none' }} />}
          </DragZoneStyled>
          <DragZoneContent>
            <Container.Grid alignItems="center" direction="row" ml={1} sm={1} xs={1} xl={1} md={1}>
              <TextColorBase>{icon ? <LazyIcon component={icon} /> : <LazyIcon component="DragDrop" />}</TextColorBase>
            </Container.Grid>
            <Container.Grid direction="column" alignItems="flex-start" ml={2} mt={0.5} sm={11} xs={11} xl={11} md={11}>
              <TextColorBase>{titleInside}</TextColorBase>
              <InfoText>{infoText}</InfoText>
            </Container.Grid>
          </DragZoneContent>
        </Container.Grid>
      </Box>
      <Container.Grid direction="row" alignItems="flex-start" sm={size} xs={size} xl={size} md={size}>
        <SmallBlueInfoText>{`* Only ${fileType} files will be accepted` || hint}</SmallBlueInfoText>
      </Container.Grid>
    </Container.Grid>
  );
};

DragZone.defaultProps = {
  title: 'Upload and files',
  titleInside: 'Attachment',
  info: 'Drag & Drop your files or Browse',
  hint: false,
  icon: null,
  size: 5,
  disabled: false,
  disabledClick: false,
  dispatch: null,
  type: null,
  isRequired: false
};

export default DragZone;
