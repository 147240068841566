// @flow
import { type initialStateType } from 'conf';

export type bsnActionType = {
  type: string,
  resource: string,
  tab?: string | Array<any>,
  item?: string | Array<any>,
  key?: string,
  payload: any,
  atBeginning?: boolean
};

export const bsnAction: Function = (args: bsnActionType): bsnActionType => args;
