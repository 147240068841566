// @flow
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';
import newsfeed from 'helpers/apis/newsfeed';
import { LoadingStyled, CustomTooltip } from 'components';
import Thumbnail from '../common/Thumbnail';
import { Image, Video, File } from 'components/icons';
import {
  Avatar,
  Grid,
  Typography,
  makeStyles,
  Divider,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Chip,
  Popover,
  Box,
  Hidden,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import {
  Close as CloseIcon,
  ExpandMore as ExpandMoreIcon,
  ArrowBackIosRounded as ArrowBackIosRoundedIcon
} from '@material-ui/icons';
import { getCurrentTimeStamp, getUrlsInsideText, toUTCfromLocal, getStorage } from 'helpers';
import Compressor from 'compressorjs';
import { addFeed, updateFeedId, deleteFeed } from 'helpers/action';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: '15px',
    marginBottom: '20px',
    color: theme.palette.text.secondary
  },
  padded: {
    padding: '15px',
    [theme.breakpoints.down('sm')]: {
      padding: 0
    },
  },
  image: {
    maxWidth: '100%'
  },
  deleteIcon: {
    backgroundColor: 'rgba(255, 90, 97, 0.2)',
    color: '#FF5A61',
    borderRadius: '50%',
    padding: '10px',
    fontSize: '25px'
  },
  chip: {
    maxWidth: '100%'
  },
  iconBtn: {
    padding: 8,
    marginRight: 10,
    [theme.breakpoints.down('sm')]: {
      marginRight: 20
    },
  },
  closeBtn: {
    position: 'absolute',
    right: 0,
    top: 0
  },
  backBtn: {
    padding: 8
  },
  closeFileBtn: {
    position: 'absolute',
    right: 0,
    top: 0,
    backgroundColor: '#ffffff80',
    zIndex: '999'
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
    color: '#535353'
  },
  postBtn: {
    marginLeft: 'auto'
  },
  dialogHeader: {
    [theme.breakpoints.down('sm')]: {
      padding: '16px 12px 10px',
      '& h2 .post-btn': {
        marginLeft: 'auto',
        fontSize: '14px',
        lineHeight: 1.5
      }
    },
    '& h2': {
      display: 'flex',
      alignItems: 'center'
    }
  },
  dialogContent: {
    [theme.breakpoints.down('sm')]: {
      padding: '8px 18px'
    }
  },
  dialogActions: {
    [theme.breakpoints.down('sm')]: {
      padding: '8px 18px 20px'
    }
  }
}));

const getFilePreview = file => {
  switch (file.type) {
    case 'image':
      return { preview: file.data.url, type: file.type };
    case 'video':
      return { preview: file.data.video_thumbnail, type: file.type };

    default:
      return { preview: file.data.filename, type: 'data' };
      break;
  }
};

const AddUpdatePostModal = ({ open, close, reload, post, title }): Element<*> => {
  const classes = useStyles();
  const themeMui = useTheme();
  const mobileView = useMediaQuery(themeMui.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const user = { name: getStorage('name') };
  const { profile, theme } = useSelector(state => state.bsn?.user);
  const [postData, setPostData] = useState(post);
  const [postLoading, setPostLoading] = useState(false);
  const [filePreviewData, setFilePreviewData] = useState(
    postData.filePreview ? getFilePreview(postData.filePreview) : null
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [deletedThumbnails, setDeletedThumbnails] = useState([]);

  useEffect(() => {
    let links = getUrlsInsideText(post.text);
    if (!post.thumbnail_metadata && links) setDeletedThumbnails(links);
  }, [post]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const id = openPopover ? 'simple-popover' : undefined;

  const postFeed = (filename = null) => {
    let { postId, filePreview, idx, ...post_data } = { ...postData };
    post_data.filename = filename;
    post_data.expiration_datetime = post_data.expiration_datetime
      ? toUTCfromLocal(post_data.expiration_datetime)
      : '9999-12-31 23:59:59';
    post_data.show_datetime = post_data.show_datetime ? toUTCfromLocal(post_data.show_datetime) : '1000-01-01 00:00:00';
    if (!postData.postId) {
      const newFeedTempId = Date.now();
      const newFeedTs = new Date(new Date().getTime() + new Date().getTimezoneOffset() * 60000);
      const newFeed = {
        comments_count: 0,
        content: null,
        expiration_datetime: post_data.expiration_datetime,
        id: newFeedTempId,
        liked: false,
        likes_count: 0,
        published: 1,
        show_datetime: post_data.show_datetime,
        text: post_data.text,
        timestamp: format(newFeedTs, 'yyyy-MM-dd HH:mm:ss'),
        disableActions: true,
        user: {
          client_name: profile?.client_name,
          email: profile?.email,
          first_name: profile?.first_name,
          id: +post_data.user_id,
          last_name: profile?.last_name,
          partner_base_color: theme?.base?.base
        }
      };

      if (filePreviewData) {
        if (filePreviewData.type !== 'video') {
          newFeed.content = {
            data: {
              filename: filename,
              url: `${process.env.NEWSFEED_ASSETS_BASE_URL}assets/newsfeed/${filename}`
            },
            type: filePreviewData.type
          };
        }
      } else if (post_data.thumbnail_metadata) {
        newFeed.content = {
          data: {
            ...post_data.thumbnail_metadata
          },
          type: 'thumbnail'
        };
      }

      if (!filePreviewData || (filePreviewData && filePreviewData.type !== 'video')) {
        dispatch(addFeed(newFeed));
      }
      setFilePreviewData(null);
      close();

      newsfeed
        .addPost(post_data)
        .then(res => {
          console.log(res);
          dispatch(updateFeedId(newFeedTempId, res?.data?.post_id));
        })
        .catch(err => {
          console.clear();
          console.log(err);
          dispatch(deleteFeed(newFeedTempId));
        });
    } else {
      !postLoading && setPostLoading(true);
      newsfeed
        .updatePost(postData.postId, post_data)
        .then(res => {
          console.log(res);
          setPostLoading(false);
          setFilePreviewData(null);
          reload();
          close();
        })
        .catch(err => {
          console.clear();
          console.log(err);
        });
    }
  };

  const onChangePostText = event => {
    let text = event.target.value;
    if (!filePreviewData) {
      let links = getUrlsInsideText(event.target.value);
      let deletedList = links && deletedThumbnails.filter(el => links.includes(el));

      if (deletedList) setDeletedThumbnails([...deletedList]);
      else if (!links) setDeletedThumbnails([]);

      const linkToPreview = links && links.filter(el => !deletedList.includes(el))[0];

      if (
        links &&
        links.length !== 0 &&
        (!postData.thumbnail_metadata || postData.thumbnail_metadata.url !== linkToPreview)
      ) {
        setPostData({ ...postData, ['text']: text });
        const uniqueLinks = [...new Set(links)];

        if (uniqueLinks.length !== deletedList.length) {
          setPostLoading(true);
          newsfeed
            .getLinkPreview(linkToPreview)
            .then(res => {
              setPostData({
                ...postData,
                ['thumbnail_metadata']: res.data.thumbnail_metadata,
                ['text']: text
              });
              setPostLoading(false);
            })
            .catch(err => {
              console.clear();
              console.log(err);
            });
        }
      } else if (!links || links.length === 0)
        setPostData({ ...postData, ['thumbnail_metadata']: null, ['text']: text });
      else setPostData({ ...postData, ['text']: text });
    } else setPostData({ ...postData, ['text']: text });
  };

  const uploadFile = file => {
    const filename = `${getCurrentTimeStamp()}_` + file.name;
    newsfeed
      .getPresignedPost(filename)
      .then(res => {
        console.log(res);
        const formDataType = new FormData();
        formDataType.append('AWSAccessKeyId', res.data.fields.AWSAccessKeyId);
        formDataType.append('key', res.data.fields.key);
        formDataType.append('policy', res.data.fields.policy);
        formDataType.append('signature', res.data.fields.signature);
        formDataType.append('x-amz-security-token', res.data.fields['x-amz-security-token']);
        formDataType.append('file', file);
        newsfeed
          .uploadFile(res.data.url, formDataType)
          .then(res => {
            console.log(res);
            setPostData({ ...postData, ['filename']: filename, ['thumbnail_metadata']: null });
            postFeed(filename);
          })
          .catch(err => {
            console.clear();
            console.log(err);
          });
      })
      .catch(err => {
        console.clear();
        console.log(err);
      });
  };

  const previewFile = e => {
    setFilePreviewData({
      preview: e.target.name === 'data' ? e.target.files[0].name : URL.createObjectURL(e.target.files[0]),
      type: e.target.name,
      file: e.target.files[0]
    });
    setPostData({ ...postData, thumbnail_metadata: null });
    e.target.value = '';
  };

  const handleUpload = () => {
    setPostLoading(true);
    switch (filePreviewData.type) {
      case 'image':
        new Compressor(filePreviewData.file, {
          quality: 0.6,
          success: res => {
            uploadFile(res);
          }
        });
        break;
      default:
        uploadFile(filePreviewData.file);
        break;
    }
  };

  const deleteFilePreview = () => {
    setFilePreviewData(null);
    setPostData({ ...postData, ['filename']: null });
  };

  const deleteThumbnail = () => {
    setDeletedThumbnails([...new Set([ ...deletedThumbnails, postData.thumbnail_metadata.url ])]);
    setPostData({ ...postData, ['thumbnail_metadata']: null });
  };

  const PostButton = () => (
    <Button
      disabled={postData.text.trim() === ''}
      onClick={() => (filePreviewData && !postData.filename ? handleUpload() : postFeed(postData.filename))}
      color="primary"
      variant="contained"
      disableElevation
      className="post-btn"
    >
      Post
    </Button>
  );

  return (
    <>
      <Dialog
        open={open}
        onClose={close}
        fullWidth
        maxWidth="sm"
        fullScreen={mobileView}
      >
        {postLoading && (
          <div style={{ position: 'absolute', inset: '0px', backgroundColor: 'rgb(255 255 255 / 64%)', zIndex: '999', width: '100%', height: '100%' }}>
            <LoadingStyled />
          </div>
        )}

        <DialogTitle id="dialog-title" className={classes.dialogHeader}>
          <Hidden mdUp>
            <IconButton
              className={classes.backBtn}
              onClick={close}
            >
              <ArrowBackIosRoundedIcon style={{ fontSize: 20 }} />
            </IconButton>
          </Hidden>
          <Typography className={classes.title}>{title}</Typography>
          <Hidden smDown>
            <IconButton
              className={classes.closeBtn}
              id="ModalHeaderButtonClose"
              onClick={close}
            >
              <CloseIcon style={{ fontSize: 25 }} />
            </IconButton>
          </Hidden>
          <Hidden mdUp>
            <PostButton />
          </Hidden>
        </DialogTitle>

        <Hidden smDown><Divider /></Hidden>

        <DialogContent className={classes.dialogContent}>
          <Box py={{ xs: 0, md: 2 }}>
            <Box pb={{ xs: 1.2, md: 2.5 }} display="flex" alignItems="center">
              <Box>
                <Avatar style={{ backgroundColor: 'var(--colorBaseBase)' }}>
                  {user.name
                    .split(' ')
                    .map((n, i) => i < 2 ? n[0] : null)
                    .join('')}
                </Avatar>
              </Box>
              <Box ml={1.5} maxWidth="calc(100% - 52px)">
                <Chip variant="outlined" deleteIcon={<ExpandMoreIcon />} label={user.name} className={classes.chip} />
              </Box>
            </Box>

            <Box mb={{ xs: 1.5, md: 2.5 }}>
              <TextField
                fullWidth
                variant="outlined"
                InputLabelProps={{ shrink: false }}
                placeholder="What do you want to talk about? 💬"
                multiline
                rows={5}
                name="text"
                value={postData.text}
                onChange={onChangePostText}
              />
            </Box>

            {postData.thumbnail_metadata && !filePreviewData && (
              <Box>
                <Thumbnail data={postData.thumbnail_metadata} deleteThumbnail={deleteThumbnail} allowDelete />
              </Box>
            )}

            {filePreviewData && (
              <Box position='relative'>
                <IconButton
                  className={classes.closeFileBtn}
                  onClick={deleteFilePreview}
                >
                  <CloseIcon />
                </IconButton>
                {{
                  image: <img className={classes.image} src={filePreviewData.preview} />,
                  video: (
                    <video className={classes.image} controls poster={filePreviewData.preview}>
                      <source src={filePreviewData.preview} />
                    </video>
                  )
                }[filePreviewData.type] || (
                  <Box py={1.1} pr={4.5} style={{ wordBreak: 'break-word' }}>
                    {filePreviewData.preview}
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </DialogContent>

        <Hidden smDown><Divider /></Hidden>

        <DialogActions className={classes.dialogActions}>
          <Grid container className={classes.padded} alignItems="center">
            <Grid item>
              <input
                accept=".ai,.bmp,.gif,.ico,.jpeg,.jpg,.png,.ps,.psd,.svg,.tif,.tiff"
                style={{ display: 'none' }}
                id="raised-button-image"
                multiple={false}
                type="file"
                name="image"
                onChange={previewFile}
              />
              <CustomTooltip title='Image' placement='top'>
                <label htmlFor="raised-button-image">
                  <IconButton component="span" className={classes.iconBtn}>
                    <Image color='colorGreyLight2' size={mobileView ? 1.3 : 1.6} />
                  </IconButton>
                </label>
              </CustomTooltip>
            </Grid>
            <Grid item>
              <input
                accept=".3g2,.3gp,.avi,.flv,.h264,.m4v,.mkv,.mov,.mp4,.mpg,.mpeg,.rm,.swf,.vob,.wmv"
                style={{ display: 'none' }}
                id="raised-button-video"
                multiple={false}
                type="file"
                name="video"
                onChange={previewFile}
              />
              <CustomTooltip title='Video' placement='top'>
                <label htmlFor="raised-button-video">
                  <IconButton component="span" className={classes.iconBtn}>
                    <Video color='colorGreyLight2' size={mobileView ? 1.3 : 1.6} />
                  </IconButton>
                </label>
              </CustomTooltip>
            </Grid>
            <Grid item>
              <input
                accept=".doc,.docx,.pdf,.ppt,.pptx,.csv,.xls,.xlsx"
                style={{ display: 'none' }}
                id="raised-button-data"
                multiple={false}
                type="file"
                name="data"
                onChange={previewFile}
              />
              <CustomTooltip title='File' placement='top'>
                <label htmlFor="raised-button-data">
                  <IconButton component="span" className={classes.iconBtn}>
                    <File color='colorGreyLight2' size={mobileView ? 1.3 : 1.6} />
                  </IconButton>
                </label>
              </CustomTooltip>
            </Grid>
            {/* <Grid alignItems="center" item>
              <Button aria-describedby={id} onClick={handleClick} variant="raised" component="span">
                <ScheduleOutlinedIcon style={{ fontSize: 20 }} />
              </Button>
              <Popover
                id={id}
                open={openPopover}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
                }}
              >
                <Grid alignItems="center" container>
                  <Grid alignItems="center" className={classes.padded} xs={12} item>
                    <DateTimeField
                      onChange={val => setPostData({ ...postData, expiration_datetime: val })}
                      nullValue
                      value={postData.expiration_datetime}
                      variant="inline"
                      label="Expire Date"
                    />
                  </Grid>
                  <Grid alignItems="center" className={classes.padded} xs={12} item>
                    <DateTimeField
                      onChange={val => setPostData({ ...postData, show_datetime: val })}
                      nullValue
                      value={postData.show_datetime}
                      variant="inline"
                      label="Post Date"
                    />
                  </Grid>
                </Grid>
              </Popover>
            </Grid>
            <Grid item>
             */}
            <Hidden smDown>
              <Grid item className={classes.postBtn}>
                <PostButton />
              </Grid>
            </Hidden>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddUpdatePostModal;
