// @flow
import React, { useState, type Element, type ComponentType } from 'react';
import { useNotify } from 'react-admin';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Layout as AppLayout, TextField, Footer } from 'components';
import cognito from '../../helpers/apis/cognito';

const Layout: ComponentType<*> = styled(AppLayout)`
  min-width: 320px;
  max-width: 440px;
  margin: 0 auto;
`;

const Form: ComponentType<*> = styled.form`
  display: flex;
  flex-direction: column;
  background-color: var(--backgroundPaper);
  border-radius: 2px;
  border-top: 5px solid #082f49;
  margin-top: calc(var(--spacing) * 10);
  padding: calc(var(--spacing) * 5);
  && {
    > div {
      margin: 20px 0;
    }
  }
`;

type InputType = {
  autoFocus?: boolean,
  id: string,
  name: string,
  value: string,
  label: string,
  disabled: boolean,
  type: string,
  onChange: Function,
  meta?: {
    touched: boolean,
    error: string
  },
  input?: Object,
  ...
};

const Input = ({
  meta: { touched, error } = { touched: false, error: '' },
  input: { ...inputProps },
  ...props
}: InputType): Element<*> => (
  <TextField error={!!(touched && error)} helperText={touched && error} {...inputProps} {...props} fullWidth />
);

Input.defaultProps = {
  autoFocus: false,
  meta: { touched: false, error: '' },
  input: {}
};

type UserSetPasswordType = {
  location: {
    search: Object
  }
};

const UserSetPassword = ({ location }: UserSetPasswordType) => {
  const [password, setPassword] = useState('');
  const [passwordVerification, setPasswordVerification] = useState('');
  const [loading, setLoading] = useState(false);
  const notify = useNotify();
  const token = new URLSearchParams(location.search).get('token');
  const formIsValid = () => password !== '' && passwordVerification !== '' && password === passwordVerification;
  const submit = e => {
    e.preventDefault();
    if (!formIsValid()) return notify("Password and set Password shouldn't be empty and should match");
    setLoading(true);
    cognito
      .resetUserPassword(password, token)
      .then(response => {
        setLoading(false);
        notify('Your Password is changed successfully');
        window.location.href = `/#login?email=${response.data.email}`;
      })
      .catch(err => {
        setLoading(false);
        notify(err.response.data.description);
      });
    return null;
  };

  return (
    <Layout sidebar={null} background="paper" centered footer={<Footer />}>
      <Form onSubmit={submit} noValidate>
        <h2 style={{ color: '#082f49' }}> Reset Password </h2>
        <Input
          autoFocus
          id="password"
          name="password"
          value={password}
          label="Password"
          disabled={loading}
          type="password"
          onChange={e => {
            setPassword(e.target.value);
          }}
        />
        <Input
          id="passwordVerification"
          name="passwordVerification"
          value={passwordVerification}
          label="Verify Password"
          type="password"
          disabled={loading}
          onChange={e => {
            setPasswordVerification(e.target.value);
          }}
        />
        <Button
          style={{ 'background-color': '#082f49' }}
          type="submit"
          disabled={loading}
          variant="contained"
          color="primary"
        >
          &nbsp;&nbsp;Submit
          {loading && <CircularProgress size={18} thickness={2} />}
        </Button>
      </Form>
    </Layout>
  );
};

export default UserSetPassword;
