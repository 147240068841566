// @flow
import React from 'react';

import { Paper, makeStyles, Divider } from '@material-ui/core';

import PostHeader from './PostHeader';
import PostInteractions from './PostInteractions';
import PostBody from './PostBody';
import PostCommentingSection from './PostCommentingSection';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: '20px 20px 8px',
    marginBottom: '20px',
    color: theme.palette.text.secondary,
    '&:last-child': {
      marginBottom: 0
    },
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      marginBottom: '10px',
      borderRadius: '10px'
    },
  },
  padded: {
    padding: '15px'
  },
  image: {
    maxWidth: '100%'
  },
  deleteIcon: {
    backgroundColor: 'rgba(255, 90, 97, 0.2)',
    color: '#FF5A61',
    borderRadius: '50%',
    padding: '10px',
    fontSize: '25px'
  }
}));

const Post = ({ feed, feedIdx, updateCommentsCount, updateAfterPostModalClose, openCommentsSection }) => {
  const classes = useStyles();
  const [showCommentsSection, setShowCommentsSection] = React.useState(openCommentsSection);

  return (
    <Paper key={feedIdx} className={classes.paper} elevation={0}>
      <PostHeader
        feed={feed}
        feedIdx={feedIdx}
        updateAfterPostModalClose={updateAfterPostModalClose}
      />
      <Divider />
      <PostBody feed={feed} />
      <Divider />
      <PostInteractions
        idx={feedIdx}
        toggleCommentsSection={() => setShowCommentsSection(!showCommentsSection)}
        feed={feed}
      />
      {showCommentsSection && (
        <PostCommentingSection
          updateCommentsCount={countup => updateCommentsCount(feedIdx, countup)}
          commentsCount={feed.comments_count}
          postId={feed.id}
        />
      )}
    </Paper>
  );
};

export default Post;
