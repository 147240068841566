// @flow

import React, { memo } from 'react';
import { SelectField } from 'components';
import WorkPlanAddButton from './WorkPlanAddButton';

type WorkPlanToolbarTypes = {
  value: string,
  setRefresh: Function,
  dispatchSetValue: Function,
  id: string
};

const choices = [
  { value: 'sraDocuments', label: 'SRA Documents' },
  { value: 'workPlan', label: 'Work Plans' }
];

const WorkPlanToolbar = ({ value, setRefresh, id, dispatchSetValue }: WorkPlanToolbarTypes) => (
  <>
    <SelectField
      value={value}
      name="policies"
      onChange={e => dispatchSetValue(e.target.value)}
      emptyValue="SRA Type"
      choices={choices}
      mr={2}
      variant
    />
    <WorkPlanAddButton
      tab={value}
      label={value === 'workPlan' ? 'New Work Plan' : 'New SRA Document'}
      setRefresh={setRefresh}
      id={id}
    />
  </>
);

export default memo<WorkPlanToolbarTypes>(WorkPlanToolbar);
