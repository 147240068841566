const taxExempt = {
  title: 'Tax Compliance',
  component: 'partner/PartnerAddTaxExemptModal',
  noQuery: true,
  buttons: [],
  initialState: {
    exempt_reasons: '',
    exposure_zones: '',
    file: '',
    "file_type": '',
    status: "PENDING",
    method: "addCertificate"
  }
};

export default taxExempt;
