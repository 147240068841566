// @flow
import React, { type Element, useState } from 'react';
import { TableList, SelectField, Button } from 'components';
import { dataProvider } from 'helpers';
import { useLocation, useId } from 'hooks';
import { useTrainings } from './TrainingContext';

type TrainingMicrotrainingsTypes = {
  toolbar: Element<*>
};

const TrainingMicrotrainings = ({ toolbar }: TrainingMicrotrainingsTypes): Element<*> => {
  const app = 'clients';
  const { settings, dispatch } = useTrainings();
  const [value, setValue] = useState(0);
  const userId = useId();
  const { item } = useLocation();
  const clientId = item || userId;
  const onChange = e => {
    setValue(e.target.value);
    dispatch.setRadio(e.target.value);
  };

  const choices = settings.map((v, i) => {
    return { value: i, label: v.label };
  });

  const downloadMicroReport = e => {
    dataProvider.getOne('clients', `microTrainingReport/${clientId}`).then(response => {
      window.open(response.data.url);
    });
  };

  const Toolbar = () => {
    return (
      <>
        {toolbar}
        <SelectField
          name="viewBy"
          emptyValue="View By"
          value={value}
          choices={choices}
          allowEmpty
          onChange={onChange}
          variant
        />
        <Button onClick={e => downloadMicroReport(e)}>Download Micro Training Report</Button>
      </>
    );
  };

  const Table = ({ val }: { val: number }) => (
    <TableList source={settings[val].name} setApp={app} toolbar={<Toolbar />} />
  );

  return <Table val={value} />;
};

export default TrainingMicrotrainings;
