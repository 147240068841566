// @flow

import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

type useTableRowSelectedDispatch = {
  tab: 'tableRowSelected' | 'tableRowUnselected',
  payload: Array<string>
};

type useTableRowSelectedReturn = {
  tableRowSelected: Array<string>,
  setSelect: Function,
  setSelectAll: Function,
  removeSelect: Function,
  clearSelect: Function,
  isSelected: Function,
  isSelectedAll: Function,
  tableRowUnselected: Array<string>,
  setUnSelect: Function,
  removeUnSelect: Function,
  clearUnSelect: Function,
  getCount: Function,
  tableFilter: Object,
  restoreSelect: Function,
  getFilterFlat: Function,
  tableRowSelectedCount: number
};

function useTableRowSelected(): useTableRowSelectedReturn {
  const app = 'system';
  const tableRowSelected = useSelector(state => state.bsn.system?.tableRowSelected || []);
  const tableRowUnselected = useSelector(state => state.bsn.system?.tableRowUnselected || []);
  const tableRowSelectedCount = useSelector(state => state.bsn.system?.tableRowSelectedCount || 0);
  let tableFilter = useSelector(state => JSON.stringify(state.bsn.system?.tableFilter || {}));
  // Work around for CHrome to see the change on the object
  tableFilter = { ...JSON.parse(tableFilter) };
  const dispatch = useDispatch();

  const setDispatch = useCallback(
    ({ tab, payload }: useTableRowSelectedDispatch) =>
      dispatch({
        type: 'BSN_SET_TABLE_SELECT',
        resource: app,
        tab,
        payload
      }),
    [dispatch]
  );

  function dispatchSelect(payload: Array<string>, isUnselect: boolean = false) {
    return setDispatch({ tab: isUnselect ? 'tableRowUnselected' : 'tableRowSelected', payload });
  }

  function setSelect(value: string | number, isSingle?: boolean = false) {
    const val = String(value);
    if (isSingle || value === 'all') return dispatchSelect([val]);
    if (tableRowSelected[0] === 'all') return setUnSelect(val);
    const selectIndex = tableRowSelected.indexOf(val);
    if (selectIndex > 0 || selectIndex === 0) return dispatchSelect(tableRowSelected.filter(s => s !== val));
    return dispatchSelect([...tableRowSelected, ...Array(val)]);
  }

  function setSelectAll(value: Array<string>) {
    return dispatchSelect(value);
  }

  function removeSelect(value: any) {
    return dispatchSelect(tableRowSelected.filter(item => item !== value));
  }

  function clearSelect() {
    setDispatch({ tab: 'tableRowUnselected', payload: [] });
    return dispatchSelect([]);
  }

  function isSelected(value: string | number): boolean {
    const val = String(value);
    let selected = tableRowSelected.filter(t => t === val)[0] === val;
    if (tableRowSelected[0] === 'all') {
      selected = !tableRowUnselected.includes(val);
    }
    return selected;
  }

  function setUnSelect(value: any) {
    const selectIndex = tableRowUnselected.indexOf(value);
    if (selectIndex > 0 || selectIndex === 0)
      return setDispatch({ tab: 'tableRowUnselected', payload: tableRowUnselected.filter(s => s !== value) });

    return setDispatch({ tab: 'tableRowUnselected', payload: [...tableRowUnselected, value] });
  }

  function removeUnSelect(value: any) {
    const selected = tableRowUnselected.filter(item => item !== value);
    return setDispatch({ tab: 'tableRowUnselected', payload: selected });
  }

  function clearUnSelect() {
    return setDispatch({ tab: 'tableRowUnselected', payload: [] });
  }

  function restoreSelect(payload: {
    tableRowSelected?: Array<string>,
    tableRowUnselected?: Array<string>,
    tableFilter?: Object
  }) {
    dispatch({
      type: 'BSN_RESTORE_TABLE_SELECT',
      resource: app,
      payload
    });
  }

  const isSelectedAll: boolean = tableRowSelected && tableRowSelected[0] === 'all';

  function getCount(count: number): number {
    const countNumber = Number(count);
    let selectedCount = tableRowSelected.length;
    if (isSelectedAll) selectedCount = countNumber - tableRowUnselected.length;
    if (countNumber !== selectedCount || selectedCount !== tableRowSelectedCount) {
      dispatch({
        type: 'BSN_SET_TABLE_COUNT',
        resource: app,
        tab: 'tableRowSelectedCount',
        payload: selectedCount
      });
    }
    return selectedCount;
  }

  function getFilterFlat() {
    return tableFilter
      ? Object.keys(tableFilter)
          .filter(f => tableFilter[f].length > 0)
          .map(f => {
            const val = tableFilter[f];
            if (Array.isArray(val) && val.length > 0) return `${f}:[${tableFilter[f]}]`;
            return `${f}:${tableFilter[f]}`;
          })
          .join(',')
      : '';
  }

  return {
    tableRowSelected,
    setSelect,
    setSelectAll,
    removeSelect,
    clearSelect,
    isSelected,
    isSelectedAll,
    tableRowUnselected,
    setUnSelect,
    removeUnSelect,
    clearUnSelect,
    getCount,
    tableFilter,
    tableRowSelectedCount,
    restoreSelect,
    getFilterFlat
  };
}

export default useTableRowSelected;
