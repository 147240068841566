// @flow
import React, { useState, useRef , useMemo} from 'react';
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css'; // ES6
import "quill-mention";
import "quill-mention/dist/quill.mention.css";


function RichTextEditor({name, autoComplete={}, onChangeEditorState ,currentState}) {
  const [text, setText] = useState(currentState)
  const quillRef = useRef(null);

  const handleChange = (content, delta, source, editor) => {
    setText(content);
    onChangeEditorState(content);
  };

  const imageHandler = () => {
    const quill = quillRef.current.getEditor();
    let range = quill.getSelection();
    let value = prompt('What is the image URL');
    if(value){
        quill.insertEmbed(range.index, 'image', value);
    }
};
  
  const toolbarOptions = [
    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'font': [] }],
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'script': 'sub'}, { 'script': 'super' }],      
    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
  ]
  if (name === 'push_notification'){
    toolbarOptions.push(['link', 'image', 'video'])
  }

  const format = ['size','font','bold','italic','underline','strike',
  'blockquote','code-block','list','script','color','background','link','image','video','mention'];

  const modules = useMemo(() => ({
  toolbar: {
    container: toolbarOptions,
    handlers: {
      image: imageHandler
    }
  },
  mention: autoComplete,
  }), [])

  return (
    <div style = {{width:'100%', marginBottom:'20px'}}>
      <ReactQuill 
      value={text}
      modules= {modules}
      formats= {format}
      theme="snow"
      onChange={handleChange}
      ref={quillRef}
      style={{ width:"100%", height: '300px'}}
      />
      <textarea
      disabled
      name={`${name}`}
      value={text}
      style={{ display: 'none' }}
      />
    </div>
  )
}
export default RichTextEditor; 

/* import React, { useState } from 'react';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './RichTextEditorStyles.css';
import { format } from 'date-fns';

type RichTextEditorTypes = {
  name: string,
  value: string,
  readOnly?: boolean,
  onChange: Function
};

const defaultOptions = ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history'];

function RichTextEditor({ name, value, readOnly, onChange, autoComplete = {}, toolbarOptions }: RichTextEditorTypes) {
  const blocksFromHtml = htmlToDraft(value || '');
  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
  const editorStateVal = EditorState.createWithContent(contentState);
  const [editorState, setEditorState] = useState(editorStateVal);

  const onEditorStateChange = content => {
    setEditorState(content);
    let str = draftToHtml(convertToRaw(content.getCurrentContent()))
      .replace(/\"/g, "\'")
      .replace(/style\=/g, 'style&#61;');
    onChange(str);
  };

  return (
    <div>
      <Editor
        readOnly={readOnly}
        editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName" 
        editorClassName="editorClassName" 
        onEditorStateChange={onEditorStateChange}
        mention={autoComplete}
        editorStyle={{ border: "1px solid #F1F1F1", minHeight: "200px" }}
        toolbar={{options: toolbarOptions || defaultOptions}}
      />
      <textarea
        disabled
        name={`${name}`}
        value={draftToHtml(convertToRaw(editorState.getCurrentContent()))
          .replace(/\"/g, "\'")
          .replace(/style\=/g, 'style&#61;')}
        style={{ display: 'none' }}
      />
    </div>
  );
}

RichTextEditor.defaultProps = {
  readOnly: false
};

export default RichTextEditor; 
*/