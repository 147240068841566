// @flow
import React from 'react';
import { MiniChart } from 'react-mini-chart';
import { theme } from 'conf';

type Props = {
  record?: Object,
  source: string
};

const ChartMini = ({ source, record }: Props) => {
  return (
    <MiniChart
      strokeColor={theme.palette.grey[200]}
      activePointColor={theme.palette.grey.A700}
      dataSet={[record, source]}
    />
  );
};

ChartMini.defaultProps = {
  record: {}
};

export default ChartMini;
