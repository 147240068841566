// @flow
import React, { type Element, memo, type ComponentType } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { TableBody, TableCell, TableRow, Box, Tooltip } from '@material-ui/core';
import { CheckCircleOutline } from '@material-ui/icons';
import { useApp } from 'helpers';
import { useLocation } from 'hooks';
import { ButtonSecondary } from './ComponentTypes';
import Table from '../types/Table';
import Container from '../types/Containers';

type DashboardBestPracticesTypes = {
  record: Object
};

const TableBodyCell: ComponentType<*> = styled(TableCell)`
  && {
    font-size: var(--fontSize);
    border-bottom: none;
    padding: calc(var(--spacing) / 2) calc(var(--spacing) * 2);
    &:nth-child(1) {
      padding-left: 0;
    }
    &:nth-last-child(1) {
      padding-right: 0;
    }
  }
`;

const CheckCircleOutlineIcon: ComponentType<*> = styled(CheckCircleOutline)`
  color: var(--colorSystemSuccess);
  font-size: 24px;
`;

const DashboardBestPractices = ({ record }: DashboardBestPracticesTypes): Element<*> => {
  const { dispatch } = useApp();
  const { app } = useLocation();
  const disableAction = app === 'clients';

  const actionClick = link => {
    const [, app, tab] = link.split('/');
    const activeTabType = tab.split('?')[1] || '';
    dispatch.changeTab(app, tab, 'complianceDashboard', null, false, activeTabType);
  };

  const Button = ({ children, ...props }) => {
    return (
      <ButtonSecondary onClick={() => actionClick(props.href)} {...props}>
        {children}
      </ButtonSecondary>
    );
  };

  return (
    <Container.Paper>
      <Table.Container size="small" mt={1} radius={2}>
        <TableBody>
          {record.map(row => (
            <TableRow key={row.id}>
              <TableBodyCell>{row.action}</TableBodyCell>
              <TableBodyCell align="center" style={{ width: 36 }}>
                {row.completed ? (
                  <Box display="flex" alignItems="center" justifyContent="center" height={34}>
                    <CheckCircleOutlineIcon />
                  </Box>
                ) : disableAction || row.disabled ? (
                  <Tooltip title={disableAction ? '' : 'Feature not Available'} placement="left" arrow>
                    <div>
                      <ButtonSecondary disabled>Go</ButtonSecondary>
                    </div>
                  </Tooltip>
                ) : (
                  <Link color="secondary" variant="contained" to={`/${row.link}`} component={Button}>
                    Go
                  </Link>
                )}
              </TableBodyCell>
            </TableRow>
          ))}
        </TableBody>
      </Table.Container>
    </Container.Paper>
  );
};
export default memo<DashboardBestPracticesTypes>(DashboardBestPractices);
