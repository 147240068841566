// @flow
import React from 'react';
import { Paper, makeStyles, Typography, Link, Box } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  borderBottom: {
    borderBottom: '1px solid #F3F5F6'
  },
  captioned: {
    color: '#94959B',
    fontSize: '12px',
    margin: '4px 0 0'
  },
  link: {
    color: '#535353',
    fontWeight: 500
  },
  img: {
    width: 50,
    minWidth: 50,
    '& img': {
      width: '100%',
      display: 'block',
      borderRadius: '5px',
      overflow: 'hidden'
    }
  }
}));

const suggestedList = [
  {
    title: 'Service Overview & Pricing',
    link: 'https://www.breachsecurenow.com/bsn-overview-pricing/',
    description: 'Review resale product descriptions and partner pricing',
    img: '/media/ServiceOverview&Pricing.png'
  },
  {
    title: 'BSN Boot Camp: Onboarding Webinar Series',
    link: 'https://vimeo.com/showcase/bsn-boot-camp',
    description: '30-minute technical webinars to help you master the BSN services',
    img: '/media/sNoeoiBc.png'
  },
  {
    title: 'Go-to-Market Toolkit: Marketing, Sales, Onboarding',
    link:
      'https://www.breachsecurenow.com/wp-content/uploads/2021/03/Instruction-Document-Onboarding-Marketing-Toolkit.pdf',
    description: 'Resources for rolling out training to your customers',
    img: '/media/raQLQYGT.png'
  }
];

const SuggestedContent = () => {
  const classes = useStyles();

  return (
    <Paper elevation={0}>
      <Box px={2.5} py={2} className={classes.borderBottom}>
        <Typography>Suggested Content</Typography>
      </Box>

      <Box px={2.5} pb={1.5}>
        {suggestedList.map(item => (
          <Box className={classes.borderBottom} display="flex" alignItems="center" py={1.5}>
            <div className={classes.img}>
              <img src={item.img} />
            </div>
            <Box style={{ paddingLeft: '15px' }}>
              <Link target="_blank" href={item.link} className={classes.link}>
                {item.title}
              </Link>
              <Box component='p' className={classes.captioned}>
                {item.description}
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </Paper>
  );
};

export default SuggestedContent;
