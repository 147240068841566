import React from "react"
import { AvailableLinksItem, LogoutButton, PageUnavailableContainer } from './style';
import Unavailable from '../../icons/Unavailable';
import { useSelector } from 'react-redux';


const AvailableLinks = () => {
  const user = useSelector(({bsn}) => bsn.user);
  const newsfeedAccess = user?.access?.apps?.newsfeed;
  const securityTrainingAccess = user?.access?.apps?.myDashboard.securityTraining;

  return (
    <>
      <AvailableLinksItem to={"/myDashboard/dashboard"}>My Dashboard</AvailableLinksItem>
      {securityTrainingAccess && <AvailableLinksItem to={"/myDashboard/securityTraining"}>Security Training</AvailableLinksItem>}
      <AvailableLinksItem to={"/myDashboard/microTrainings"}>Micro Training</AvailableLinksItem>
      <AvailableLinksItem to={"/myDashboard/darkWeb"}>Dark Web</AvailableLinksItem>
      {newsfeedAccess && <AvailableLinksItem to={"/newsfeed/dashboard"}>Newsfeed</AvailableLinksItem>}
    </>
  )
}

const PageUnavailable = () => {

  const user = useSelector(({bsn}) => bsn.user)

  const userProductName = user?.profile?.product_name;

  const isUserHaveNotMobilePages = userProductName === "HIPAA Compliance" || userProductName === "Unlimited Cybersecurity Training"

  return (
    <PageUnavailableContainer>
      <Unavailable className={"page-unavailable"}/>

      <h3>
        Page is unavailable!
      </h3>

      <p>
        {
          isUserHaveNotMobilePages ? (
            "This page cannot be run on your device."
          ) : (
            "Here are a few suggested links below"
          )
        }
      </p>

      {
        isUserHaveNotMobilePages ? (
          <LogoutButton to={"/logout"}>
            Log out
          </LogoutButton>
        ) : (
          <AvailableLinks/>
        )
      }

    </PageUnavailableContainer>
  )
}

export default PageUnavailable
