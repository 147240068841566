import React, { useEffect } from 'react';
import directorySync from 'helpers/apis/directorySync';
import { useHistory } from 'react-router-dom';
import { getStorage } from '../../../helpers';

export default function DirectorySyncAzureActiveDirectoryAuth(props) {
  const history = useHistory();

  const search = props.location.search
  const params = new URLSearchParams(search);

  const id_token = params.get('id_token');
  const state = params.get('state');
  const session_state = params.get('session_state');
  const admin_consent = params.get('admin_consent');
  const aad_sync_field = getStorage('aad_sync_field');

  useEffect(() => {
    if(admin_consent === 'True') {
      directorySync.auth({ client_id: state, id_token, aad_sync_field })
        .then(resp => {
          const message = "You have authorized successfully."
          history.push({
            pathname: `/clients/directorySync/${state}`,
            search: `?success=1&message=${message}`
          });
        })
        .catch(error => {
          const message = error?.response?.data?.description || "Something went wrong, please, try again."
          history.push({
            pathname: `/clients/directorySync/${state}`,
            search: `?success=0&message=${message}`
          });
        });
    } else {
      const message = "You should allow all the permission to continue."
      history.push({
        pathname: `/clients/directorySync/${state}`,
        search: `?success=0&message=${message}`
      });
    }

  }, [admin_consent]);

  // Here we need to do same page, Ahmed can help with this,
  // the user will be redirected to this page when the process is running and to get the parameters that returning from Azure account is allowed.

  return <center> Redirecting...... </center>;
}
