// @flow

import React, { type Element, type ComponentType } from 'react';
import styled from 'styled-components';
import { Button as MuiButton } from '@material-ui/core';

export const ButtonStyled: ComponentType<*> = styled(MuiButton)`
  && {
    padding: 8px 24px;
    background-color: ${({ backgroundColor }) =>
      backgroundColor ? `var(--${backgroundColor})` : 'var(--colorBaseDark2)'};
    ${({ mr }) => mr && `margin-right: calc(${mr} * var(--spacing))`};
    ${({ ml }) => ml && `margin-left: calc(${ml} * var(--spacing))`};
    ${({ mt }) => mt && `margin-top: calc(${mt} * var(--spacing))`};
    ${({ mb }) => mb && `margin-bottom: calc(${mb} * var(--spacing))`};
    ${({ pr }) => pr && `padding-right: calc(${pr} * var(--spacing))`};
    ${({ pl }) => pl && `padding-left: calc(${pl} * var(--spacing))`};
    height: ${({ height }) => height && `${height}`}px;
    width: ${({ fullwidth }) => (fullwidth ? '100%' : 'auto')};
    border-radius: var(--borderRadius);
    :hover {
      background-color: ${({ backgroundColorHover }) =>
        backgroundColorHover ? `var(--${backgroundColorHover})` : 'var(--buttonPrimaryHover)'};
    }
  }

  .bsn-button-label {
    color: var(--colorCommonWhite);
    font-size: var(--fontSize);
    text-transform: capitalize;
    white-space: nowrap;
  }
`;

export const ContainerButtonDisabled: ComponentType<*> = styled.div`
  display: flex;
  justify-content: right;
  ${({ disabled }) => disabled && `cursor: not-allowed;`}
`;

type ButtonType = {
  fullwidth?: boolean,
  disabled?: boolean,
  ...
};

const Button = ({ fullwidth, disabled, ...props }: ButtonType): Element<*> => {
  return (
    <ContainerButtonDisabled disabled={disabled}>
      <ButtonStyled
        classes={{ label: 'bsn-button-label' }}
        fullwidth={fullwidth}
        disabled={disabled}
        {...(props: $Rest<ButtonType, any>)}
      />
    </ContainerButtonDisabled>
  );
};

Button.defaultProps = {
  fullwidth: false
};

export default Button;
