// @flow
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import newsfeed from 'helpers/apis/newsfeed';
import { Grid, makeStyles, Link, Box, IconButton } from '@material-ui/core';
import clsx from 'clsx';
import { CustomTooltip } from 'components';
import { Comment, LikeIcon } from 'components/icons';
import { ReactionsModal } from '../modals';
import { setFeeds } from 'helpers/action';
import { getStorage, decodeId } from 'helpers';

const useStyles = makeStyles(theme => ({
  padded: {
    paddingTop: '8px',
    [theme.breakpoints.down('sm')]: {
      padding: '3px'
    },
  },
  disabled: {
    pointerEvents: 'none',
    opacity: '0.5'
  },
  hoverUnderline: {
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}));

const PostInteractions = ({ feed, idx, toggleCommentsSection }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = { id: decodeId(getStorage('userId')) };
  const feeds = useSelector(state => state.newsfeed.feeds);
  const [open, setOpen] = useState(false);

  const likePost = () => {
    const id = feed.id;
    feed.liked ? newsfeed.unLikePost(id, user.id) : newsfeed.likePost(id, user.id);
    dispatch(
      setFeeds([
        ...feeds.slice(0, idx),
        ...[
          {
            ...feed,
            likes_count: feed.liked ? feed.likes_count - 1 : feed.likes_count + 1,
            liked: !feed.liked
          }
        ],
        ...feeds.slice(idx + 1)
      ])
    );
  };

  return (
    <Grid
      className={clsx(classes.padded, {
        [classes.disabled]: feed.disableActions
      })}
      alignItems="center"
      container
    >
      <Box mr={{ xs: 4, md: 2.5 }} display='flex' alignItems='center'>
        <CustomTooltip title={feed.liked ? 'Unlike' : 'Like'} placement='top'> 
          <IconButton component="span" onClick={likePost}>
            <LikeIcon color={feed.liked ? 'colorSystemSuccess' : 'colorGreyLight2'} size={1.7} />
          </IconButton>
        </CustomTooltip>
        {feed.likes_count !== 0 && (
          <Link
            style={{ color: 'var(--colorGreyLight2)' }}
            href="#"
            onClick={e => {
              e.preventDefault();
              setOpen(true);
            }}
          >
            <Box component="span" px={{ xs: 1, md: 0 }}>
              {feed.likes_count}
            </Box>
          </Link>
        )}
      </Box>
      {open && <ReactionsModal id={feed.id} open={open} close={() => setOpen(false)} type="post" />}

   
      <CustomTooltip title='Comments' placement='top'> 
        <Link
          href="#"
          onClick={e => {
            e.preventDefault();
            toggleCommentsSection();
          }}
          color="inherit"
          underline="none"
        >
          <Box display='flex' alignItems='center'>
            <IconButton component="span">
              <Comment color='var(--colorGreyLight2)' size={1.7} />
            </IconButton>
            <Box component="span" px={{ xs: 1, md: 0 }} className={classes.hoverUnderline}>
              {feed.comments_count !== 0 && `${feed.comments_count}`}
            </Box>
          </Box>
        </Link>
      </CustomTooltip>
    </Grid>
  );
};

export default PostInteractions;
