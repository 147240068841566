// @flow

import { differenceInMinutes } from 'date-fns';
import { setStorage, getStorage, clearStorage } from './storage';
import authProvider from './authProvider';

const getTime: Function = (expiration: number = 0): number => {
  const now: Object = new Date();
  const time: number = now.getTime();
  return time + expiration;
};

export const setExpirationTime: Function = (minutes): void => {
  setStorage('expireTime', getTime(minutes * 1000), true);
};

export const setSession: Function = (data: Object): void => {
  Object.keys(data).forEach(key => {
    setStorage(key, data[key], true);
  });
};

export const isSessionExpired: Function = (): void => {
  const last_request_date = getStorage('lastRequestDate', true);
  if (last_request_date) {
    const now = new Date();
    const request_date = new Date(+last_request_date);
    return differenceInMinutes(now, request_date) >= 58;
  } else {
    return false;
  }
};

export const checkSession: Function = async (): void => {
  const access_token = getStorage('accessToken', true);
  if (access_token) {
    if (isSessionExpired()) {
      clearStorage();
      window.location.href = '/#/expiredSession';
    }
    else {
      await authProvider.checkAuth();
    }
  }
};