// @flow
import styled from 'styled-components';
import { Button, DialogActions as MuiDialogActions } from '@material-ui/core';

export const DialogActions: ComponentType<*> = styled(MuiDialogActions)`
  background: var(--backgroundDefault);
  display: flex;
  && {
    padding: 16px 24px;
    justify-content: space-between;
  }
`;

export const AlignRight: ComponentType<*> = styled.div`
  && {
    justify-content: right;
  }
`;

export const AlignLeft: ComponentType<*> = styled.div`
  && {
    justify-content: left;
  }
`;

export const ButtonCancel: ComponentType<*> = styled(Button)`
  && {
    text-transform: capitalize;
    color: var(--colorDefault);
    background: transparent;
    text-decoration: underline;
    box-shadow: none;
    margin-right: 20px;
    font-size: var(--fontSize);
    &:hover {
      background: transparent;
      text-decoration: underline;
      box-shadow: none;
    }
  }
`;

export const ButtonSubmit: ComponentType<*> = styled(Button)`
  && {
    text-transform: capitalize;
    color: var(--colorCommonWhite);
    background: var(--colorSystemSuccess);
    font-size: var(--fontSize);
    padding-right: 15px;
    padding-left: 15px;
    &:hover {
      background: var(--colorSystemSuccessHover);
    }
    &:disabled {
      opacity: 0.2;
      background: var(--colorSystemSuccess);
      color: var(--colorCommonWhite);
    }
  }
`;

export const ButtonDownload: ComponentType<*> = styled(Button)`
  && {
    text-transform: capitalize;
    color: var(--colorCommonWhite);
    background: var(--buttonInfo);
    align-items: left;
    margin-right: var(--spacing);
    box-shadow: none;
    padding-right: 15px;
    padding-left: 15px;
    font-size: var(--fontSize);
    &:hover {
      background: var(--colorSystemInfoHover);
      box-shadow: none;
    }
  }
`;
