// @flow
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Typography, Avatar, Link, makeStyles, Box, TextareaAutosize, Hidden } from '@material-ui/core';
import { getUrlsInsideText } from 'helpers';
import PostCommentActions from './PostCommentActions';
import Thumbnail from '../common/Thumbnail';
import newsfeed from 'helpers/apis/newsfeed';
import DeleteCommentModal from '../modals/DeleteCommentModal';
import ReactionsModal from '../modals/ReactionsModal';
import ReactTimeAgo from 'react-time-ago';
import Linkify from 'react-linkify';
import { setComments } from 'helpers/action';

const useStyles = makeStyles(theme => ({
  name: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '19px',
    letterSpacing: '0px',
    textAlign: 'left'
  },
  timeAgo: {
    display: 'inline-block',
    '&::first-letter': {
      textTransform: 'uppercase'
    }
  },
  pre: {
    border: 'none',
    background: 'none',
    padding: 0,
    margin: 0,
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    fontFamily: 'var(--fontFamily)',
    fontSize: '1.6rem',
    lineHeight: '1.5',
    color: 'inherit'
  },
  textArea: {
    width: '100%',
    minHeight: '41px',
    boxSizing: 'border-box',
    fontSize: '16px',
    fontFamily: 'var(--fontFamily)',
    padding: '10px',
    resize: 'vertical',
    background: 'none'
  },
  commentInfo: {
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start'
    },
  },
  saveBtn: {
    marginLeft: '20px',
    '&[disabled]': {
      pointerEvents: 'none',
      opacity: 0.5
    }
  }
}));

const PostComment = ({ comment, idx, isOwnComment, postId, toggleLike, onDeleteComment }) => {
  const classes = useStyles();
  const [toggleUpdate, setToggleUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [openLikes, setOpenLikes] = useState(false);
  const [commentUpdate, setCommentUpdate] = useState(comment.comment_text);
  const [thumbnailUpdate, setThumbnailUpdate] = useState(comment.thumbnail_metadata);
  const comments = useSelector(state => state.newsfeed.comments);
  const dispatch = useDispatch();
  const [deletedThumbnails, setDeletedThumbnails] = useState([]);
  let reactionStyles = { cursor: 'pointer', color: comment.liked ? '#0CCE6B' : 'inherit', display: 'inline-block' };
  if (comment.disableActions) {
    reactionStyles = {
      ...reactionStyles,
      pointerEvents: 'none',
      opacity: '0.5'
    };
  }

  useEffect(() => {
    let links = getUrlsInsideText(comment.comment_text);
    if (!comment.thumbnail_metadata && links) setDeletedThumbnails(links);
  }, [comment]);

  const updateComment = commentData => {
    dispatch(
      setComments(postId, [
        ...comments[postId].slice(0, idx),
        ...[
          {
            ...comments[postId][idx],
            comment_text: commentData.comment_text,
            thumbnail_metadata: commentData.thumbnail_metadata
          }
        ],
        ...comments[postId].slice(idx + 1)
      ])
    );
    setToggleUpdate(false);
    setThumbnailUpdate(commentData.thumbnail_metadata);
    newsfeed
      .updateComment(commentData)
      .then(res => {})
      .catch(err => {
        console.clear();
        console.log(err);
      });
  };

  const checkUpdatedComment = () => {
    let links = getUrlsInsideText(commentUpdate);
    let thumbnailDeleted = !thumbnailUpdate && links?.length;
    if (commentUpdate !== comment.comment_text || thumbnailDeleted) {
      let commentData = {
        id: comment.id,
        comment_text: commentUpdate,
        thumbnail_metadata: thumbnailUpdate,
        post_id: postId
      };
      let deletedList = links && deletedThumbnails.filter(el => links.includes(el));

      if (deletedList) setDeletedThumbnails([...deletedList]);
      else if (!links) setDeletedThumbnails([]);

      const linkToPreview = links && links.filter(el => !deletedList.includes(el))[0];

      if (
        links &&
        links.length !== 0 &&
        (!commentData.thumbnail_metadata || commentData.thumbnail_metadata.url !== linkToPreview)
      ) {
        const uniqueLinks = [...new Set(links)];

        if (uniqueLinks.length !== deletedList.length) {
          newsfeed
            .getLinkPreview(linkToPreview)
            .then(res => {
              commentData.thumbnail_metadata = res.data.thumbnail_metadata;
              updateComment(commentData);
            })
            .catch(err => {
              console.clear();
              console.log(err);
            });
        } else if (thumbnailDeleted) {
          updateComment(commentData);
        }
      } else {
        commentData.thumbnail_metadata = null;
        updateComment(commentData);
      }
    } else setToggleUpdate(false);
  };

  const cancelUpdateComment = () => {
    setToggleUpdate(false);
    setCommentUpdate(comment.comment_text);
    setThumbnailUpdate(comment.thumbnail_metadata);
  };

  const deleteThumbnail = () => {
    setDeletedThumbnails([...new Set([ ...deletedThumbnails, comment.thumbnail_metadata.url ])]);
    setThumbnailUpdate(null);
  };

  const timeAgoComponent = (
    <ReactTimeAgo
      className={classes.timeAgo}
      date={comment.timestamp.replace(' ', 'T') + 'Z'}
      locale="en-US"
      timeStyle="round-minute"
    />
  );

  return (
    <Box mt={{ xs: 1, md: 2 }} display="flex">
      <Box>
        <Avatar style={{ backgroundColor: comment.user.partner_base_color }}>
          {`${comment.user.first_name.charAt(0)}${comment.user.last_name.charAt(0)}`}
        </Avatar>
      </Box>
      <Box pl={{ xs: 1.2, md: 2 }} flexGrow={1}>
        <Box style={{ backgroundColor: '#F4F5F7' }} p={2} borderRadius={{ xs: 10, md: 5 }}>
          <Grid className={classes.commentInfo} container>
            <Grid item xs={9} md={6}>
              <Typography variant="h4" gutterBottom className={classes.name}>
                {`${comment.user.first_name} ${comment.user.last_name}`}
              </Typography>
              <Typography variant="caption" display="block" gutterBottom>
                {comment.user.client_name}
                <Hidden mdUp>
                  <>&nbsp;/&nbsp;{timeAgoComponent}</>
                </Hidden>
              </Typography>
            </Grid>
            <Grid item xs={3} md={6}>
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <Hidden smDown>
                  <Typography variant="caption" display="block" gutterBottom>
                    {timeAgoComponent}
                  </Typography>
                </Hidden>
                {isOwnComment && (
                  <PostCommentActions
                    showDeleteModal={() => setOpen(true)}
                    toggleUpdate={() => {
                      setToggleUpdate(true);
                    }}
                    commentIdx={idx}
                    comment={comment}
                  />
                )}
              </Box>
            </Grid>
          </Grid>

          {!toggleUpdate && (
            <Box mt={2.5}>
              <Typography id={`text-${comment.id}`}>
                <Linkify
                  componentDecorator={(decoratedHref, decoratedText, key) => (
                    <a target="blank" style={{ wordBreak: 'break-all' }} href={decoratedHref} key={key}>
                      {decoratedText}
                    </a>
                  )}
                >
                  <pre className={classes.pre}>{comment.comment_text}</pre>
                </Linkify>
              </Typography>
            </Box>
          )}

          {toggleUpdate && (
            <>
              <Box mt={2.5}>
                <TextareaAutosize
                  onChange={e => setCommentUpdate(e.target.value)}
                  value={commentUpdate}
                  aria-label="Write a comment"
                  placeholder="Write a comment"
                  className={classes.textArea}
                />
              </Box>
              <Box mt={2.5}>
                <Link
                  component="button"
                  variant="body2"
                  onClick={cancelUpdateComment}
                >
                  Cancel
                </Link>
                <Link
                  component="button"
                  variant="body2"
                  className={classes.saveBtn}
                  disabled={!commentUpdate?.trim()}
                  onClick={checkUpdatedComment}
                >
                  Save
                </Link>
              </Box>
            </>
          )}

          {thumbnailUpdate && (
            <Box mt={1.2}>
              <Thumbnail data={thumbnailUpdate} deleteThumbnail={deleteThumbnail} allowDelete={toggleUpdate} />
            </Box>
          )}
        </Box>

        <Box mt={2}>
          <Typography
            style={reactionStyles}
            onClick={() => toggleLike(idx)}
          >
            Like{' '}
          </Typography>
          &nbsp;&nbsp;
          {comment.likes_count !== 0 && (
            <Link
              style={{ color: '#94959B' }}
              href="#"
              onClick={e => {
                e.preventDefault();
                setOpenLikes(true);
              }}
              color="inherit"
            >
              <Box component="span" px={{ xs: 1, md: 0 }}>
                {comment.likes_count}
              </Box>
            </Link>
          )}
        </Box>
      </Box>

      <DeleteCommentModal
        close={() => setOpen(false)}
        open={open}
        commentIdx={idx}
        commentId={comment.id}
        postId={postId}
        onDeleteComment={onDeleteComment}
      />
      {openLikes && (
        <ReactionsModal
          close={() => setOpenLikes(false)}
          open={openLikes}
          type="comment"
          id={comment.id}
        />
      )}
    </Box>
  );
};

export default PostComment;
