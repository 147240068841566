// @flow

import sraDocuments from './sraDocuments';
import workPlan from './workPlan';
import workPlanFinding from './workPlanFinding';

const myCompany = {};

myCompany.sraDocuments = sraDocuments;
myCompany.workPlan = workPlan;
myCompany.workPlanFinding = workPlanFinding;

export default myCompany;
