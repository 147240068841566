// @flow

import { useReducer } from 'react';
import { useQuery } from 'react-query';
import { dataFetch, isObjectEmpty, dataQueryName } from 'helpers';
import useId from './useId';

type useDataGetTypes = {
  app: string,
  tab: string | false,
  item?: string | false,
  page?: number,
  params?: Object,
  isList?: boolean,
  options?: Object,
  transform?: Function
};

type useDataGetStateTypes = {
  app: string,
  tab: string | false,
  item: string,
  params: Object
};

type useDataGetDispatchTypes = {
  type: string,
  payload: any
};

function useDataGet({
  app,
  tab,
  item: Item,
  page,
  params = {},
  isList = false,
  options = {},
  transform = e => e
}: useDataGetTypes) {
  const newParams = getParams(isList, params);
  const UseId = () => useId({ app, tab });
  const item = Item || UseId();
  // eslint-disable-next-line no-unused-vars
  const [state, dispatch] = useReducer<useDataGetStateTypes, useDataGetDispatchTypes>(reducer, {
    app,
    tab,
    item,
    params: newParams
  });
  const queryName = dataQueryName({ app, tab, item, params: newParams });

  const { isSuccess, data: record, refetch: queryRefetch, ...query } = useQuery(
    queryName,
    async () => {
      const { data, headers } = await dataFetch({ app, tab, item, params: newParams });
      return { data, total: headers['x-total-count'] };
    },
    {
      ...options,
      keepPreviousData: isList,
      staleTime: options?.staleTime || 1000000,
      notifyOnChangeProps: ['data', 'error']
    }
  );

  const refetch = () => {
    dispatch({
      type: 'SETREFETCH',
      payload: {
        params: newParams,
        queryName: dataQueryName({ app, tab, item, params: newParams })
      }
    });
    queryRefetch();
  };

  return {
    ...query,
    data: isSuccess ? transform(record.data) : null,
    total: isSuccess ? record.total : 0,
    isSuccess,
    dispatch,
    refetch
  };
}

function reducer(previousState, { type, payload }) {
  const prev = { ...previousState };
  switch (type) {
    case 'SETPAGE': {
      prev.params = {
        ...prev.params,
        pagination: {
          page: payload,
          perPage: 25
        }
      };
      return prev;
    }

    case 'SETFILTER': {
      prev.params = payload;
      return prev;
    }

    default: {
      return { ...previousState, ...payload };
    }
  }
}

function getParams(isList, params) {
  if (isList && isObjectEmpty(params)) {
    return {
      pagination: {
        page: 1,
        perPage: 25
      },
      ...params
    };
  }
  return params;
}

export default useDataGet;
