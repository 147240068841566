// @flow
import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import PostBodyText from './PostBodyText';
import PostBodyContent from './PostBodyContent';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: '15px',
    marginBottom: '20px',
    color: theme.palette.text.secondary
  },
  padded: {
    padding: '20px 0',
    [theme.breakpoints.down('sm')]: {
      padding: '15px'
    },
  },
  image: {
    maxWidth: '100%'
  },
  deleteIcon: {
    backgroundColor: 'rgba(255, 90, 97, 0.2)',
    color: '#FF5A61',
    borderRadius: '50%',
    padding: '10px',
    fontSize: '25px'
  }
}));

const PostBody = ({ feed }) => {
  const classes = useStyles();
  const { text, content } = feed;

  return (
    <Grid className={classes.padded} alignItems="center" container>
      <Grid item xs={12}>
        <PostBodyText text={text} />
      </Grid>
      {content && <PostBodyContent content={content} />}
    </Grid>
  );
};

export default PostBody;
