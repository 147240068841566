// @flow

import React, { memo, useState, useEffect } from 'react';
import Slide from '@material-ui/core/Slide';
import { Button, Modal3 } from 'components';
import { CircularProgress } from '@material-ui/core';
import { LazyIcon } from 'components/icons';
import reporting from '../../../helpers/apis/reporting';

type WorkPlanAddButtonType = {
  tab: string,
  label: string,
  setRefresh: Function,
  id: string
};

const Transition = props => {
  return <Slide direction="up" {...props} />;
};

const WorkPlanAddButton = ({ tab, label, setRefresh, id }: WorkPlanAddButtonType) => {
  const [loading, setLoading] = useState(false);
  const app = 'myCompany';
  const [open, dispatchOpen] = useState(false);

  const setOpen = (value: boolean) => {
    setRefresh(value);
    dispatchOpen(value);
  };

  useEffect(() => {
    // the refresh on the table it was not working on creating a new file
    setRefresh(open);
  }, [open]);

  function downloadApendix() {
    setLoading(true);
    reporting
      .getReport({
        report_key: 'SraAppendix',
        recipe: 'chrome-pdf',
        parameters: { client_id: id },
        delivery: 'url'
      })
      .then(response => {
        window.open(response.data.url);
        setLoading(false);
      })
      .catch(err => {
        console.log(err.response);
        setLoading(false);
      });
  }

  return (
    <>
      <Button onClick={() => setOpen(true)} mr={1} pr={2} pl={2}>
        <LazyIcon component="Plus" color="colorCommonWhite" mr={1} size={0.7} />
        {label}
      </Button>
      {tab === 'sraDocuments' && (
        <Button onClick={() => downloadApendix(id)} mr={1} pr={2} pl={2}>
          {loading ? (
            <>
              <CircularProgress size={18} thickness={2} />
              &nbsp;&nbsp;Loading...
            </>
          ) : (
            <>
              <LazyIcon component="Download" color="colorCommonWhite" mr={1} size={1} />
              Download SRA Appendix
            </>
          )}
        </Button>
      )}
      {open && <Modal3 open={open} setOpen={setOpen} app={app} tab={tab} createNew TransitionComponent={Transition} />}
    </>
  );
};

export default memo<WorkPlanAddButtonType>(WorkPlanAddButton);
