import { useNotify } from 'react-admin';
import { hasInternetConnection } from '../helpers';

function useHasInternet () {
  const notify = useNotify();

  return (callback) => hasInternetConnection(notify, callback)
}

export default useHasInternet;
