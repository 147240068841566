

const addIfExisting = (inputObj,outputObj,key)=>{
    if(inputObj.hasOwnProperty(key)){
        outputObj[key] = inputObj[key];
    }
}

const addQueryParams= (options,requestOptions) =>{
    addIfExisting(options,requestOptions,"params");
}

const addHeaders= (options,requestOptions) =>{
    addIfExisting(options,requestOptions,"headers");
}


export function getRequestOptions(options){
    const requestOptions = {}
    addHeaders(options,requestOptions);
    addQueryParams(options,requestOptions);
    return requestOptions;
}
