import apiClient from '../apiClient/apiClient';

import { getStorage } from '../storage';

const env = process.env.REACT_APP_BUILD_ENV;
const base = process.env.REACT_APP_BASE_URL;
const apiGWID = process.env.REACT_APP_BASE_URL_11;
const baseURL = `https://${apiGWID}.${base}/${env}/`;

const sra = {};

sra.getRequestHeaders = () => {
  return {
    'Content-Type': 'application/json',
    Authorization: getStorage('idToken', true)
  };
};

sra.getClientOrganizationProfile = clientID => {
  const requestOptions = {
    url: `${baseURL}Clients/${clientID}/OrganizationProfiles`,
    headers: sra.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};
sra.getClientSraStatus = clientID => {
  const requestOptions = {
    url: `${baseURL}Clients/${clientID}/Sra/Status`,
    headers: sra.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};
sra.setClientOrganizationProfile = (clientID, clientOrganizationalProfile) => {
  const requestOptions = {
    url: `${baseURL}Clients/${clientID}/OrganizationProfiles`,
    data: { organization_profile: clientOrganizationalProfile },
    headers: sra.getRequestHeaders()
  };
  return apiClient.post(requestOptions);
};

sra.setClientSraStatus = (clientID, sraStatus) => {
  const requestOptions = {
    url: `${baseURL}Clients/${clientID}/Sra/Status`,
    headers: sra.getRequestHeaders(),
    data: sraStatus
  };
  return apiClient.post(requestOptions);
};

sra.getClientSraQA = clientID => {
  const requestOptions = {
    url: `${baseURL}Clients/${clientID}/Sra/result`,
    headers: sra.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

sra.getClientInfo = clientID => {
  const requestOptions = {
    url: `${baseURL}Clients/${clientID}/info`,
    headers: sra.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

sra.setClientSraQA = (clientID, clientSraQA) => {
  const requestOptions = {
    url: `${baseURL}Clients/${clientID}/Sra/result`,
    data: { questions: clientSraQA },
    headers: sra.getRequestHeaders()
  };
  return apiClient.post(requestOptions);
};

export default sra;
