// @flow
import React, { type Element, useState, useReducer, useEffect } from 'react';
import { Container, DragZone, Button, TextField, DateField } from 'components';
import { useNotify } from 'react-admin';
import { dataProvider } from 'helpers';
import { useId, useLocation } from 'hooks';
import { Step, StepLabel } from '@material-ui/core';
import { Stepper } from './ComponentTypes';

type PageDocumentsModalTypes = {
  record: {
    date?: string,
    year?: string,
    name: string,
    description: string,
    type: string,
    file: string,
    step: number
  },
  modalType: string,
  app: string,
  tab: string,
  dispatch: {
    onClose: Function
  }
};

type PageDocumentsModalStateTypes = {
  data: {
    description: string,
    date: string,
    file: any,
    name: string,
    id: string
  },
  step: number,
  disabled: boolean,
  loading: boolean
};

type PageDocumentsModalDispatchTypes = {
  type: string,
  payload: any
};

const initialState = {
  data: {
    description: '',
    date: new Date().toISOString(),
    year: new Date().toISOString(),
    file: { name: '' },
    name: '',
    id: ''
  },
  step: 0,
  disabled: true,
  loading: false
};

let statusMessage = null;
let showCreatedMsg = false;

const PageDocumentsModal = ({ record, tab, app, modalType, dispatch }: PageDocumentsModalTypes): Element<*> => {
  const notify = useNotify();
  const [paste, setPaste] = useState(false);
  const [createdData, setCreatedData] = useState(null);
  const { item } = useLocation();
  const clientId = useId({ key: 'clientId' });
  const setId = item || clientId;
  const [state, setState] = useReducer<PageDocumentsModalStateTypes, PageDocumentsModalDispatchTypes>(
    reducer,
    initialState
  );
  const steps = ['Information', 'Attachment'];

  useEffect(() => {
    return () => {
      if (showCreatedMsg) {
        notify(statusMessage);
        showCreatedMsg = false;
        statusMessage = null;
      }
    };
  }, [notify]);

  const onChange = (name, value, type) => {
    if (paste) {
      setState({ type, payload: { [name]: value } });
      setPaste(false);
    }
    setState({ type, payload: { [name]: value } });
  };

  const handleNext = e => {
    e.preventDefault();

    const isEdit =
      createdData &&
      (createdData.name !== state.data.name ||
        createdData.description !== state.data.description ||
        (createdData.date && createdData.date !== state.data.date) ||
        (createdData.year && createdData.year !== state.data.year));

    if (state.data.id === '') {
      setState({ type: 'SETLOADING', payload: true });
      const sendData = {
        name: state.data.name,
        description: state.data.description
      };
      if (record.date) {
        sendData.date = state.data.date.slice(0, 10);
      }
      if (record.year) {
        sendData.year = state.data.year.slice(0, 4);
      }
      dataProvider.post(app, `${tab}/${setId}`, null, { ...sendData })
        .then(res => {
          statusMessage = res.data?.status || `New Document '${res.data?.data?.name}' was successfully created`;
          showCreatedMsg = true;
          setState({ type: 'NEXTSTEP', payload: {id: res.data?.data?.id, disabled: state.data.file.name}  });
          setCreatedData(res.data?.data);
        })
        .catch(error => {
          notify(error?.response?.data?.message, 'error');
        })
        .finally(() => {
          setState({ type: 'SETLOADING', payload: false });
        });
    } else if (isEdit) {
      setState({ type: 'SETLOADING', payload: true });
      const editTab = `${tab}Info`;
      const sendData = {
        name: state.data.name,
        description: state.data.description
      };
      if (record.date) {
        sendData.date = state.data.date.slice(0, 10);
      }
      if (record.year) {
        sendData.year = state.data.year.slice(0, 4);
      }

      dataProvider.update(app, `${editTab}/${state.data.id}`, { ...sendData })
        .then(res => {
          statusMessage = `New Document '${res.data?.data?.name}' was successfully created`;
          showCreatedMsg = true;
          setState({ type: 'NEXTSTEP', payload: {id: res.data?.data?.id, disabled: state.data.file.name}});
          setCreatedData(res.data?.data);
        })
        .catch(error => {
          notify(error?.response?.data?.message, 'error');
        })
        .finally(() => {
          setState({ type: 'SETLOADING', payload: false });
        });
    } else {
      setState({ type: 'NEXTSTEP', payload: {id: state.data.id, disabled: state.data.file.name}  });
    }
  };

  const handleBack = e => {
    e.preventDefault();
    setState({ type: 'PREVSTEP', payload: null });
  };

  const onSave = () => {
    setState({ type: 'SETLOADING', payload: true });
    showCreatedMsg = false;
    dataProvider
      .post(app, `presignedUpload/${state.data.id}`, null, { filename: state.data.file.name, type: modalType })
      .then(res => {
        const formData = new FormData();
        formData.append('AWSAccessKeyId', res.data.fields.AWSAccessKeyId);
        formData.append('key', res.data.fields.key);
        formData.append('policy', res.data.fields.policy);
        formData.append('signature', res.data.fields.signature);
        formData.append('x-amz-security-token', res.data.fields['x-amz-security-token']);
        formData.append('file', state.data.file);
        dataProvider
          .postUrl(res.data.url, formData, 'multipart/form-data')
          .then(resp => {
            // eslint-disable-next-line no-console
            console.log('Res post url: ', resp);
            notify(statusMessage);
            dispatch.onClose();
          })
          .catch(err => {
            // eslint-disable-next-line no-console
            console.log(err);
          })
          .finally(() => {
            setState({ type: 'SETLOADING', payload: false });
          })
      })
      .catch(err => {
        notify(err && err.response && err.response.data.message, 'warning');
      });
  };

  const onFileUpload = file => {
    setState({ type: 'STEP2', payload: file });
  };

  return (
    <Container.Grid direction="column">
      <Stepper activeStep={state.step} alternativeLabel>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Container.Grid item alignItems="center" sm={12} xs={12} xl={12} md={12}>
        {state.step === 0 ? (
          <Container.Grid item direction="column" mb={3} ml={3} mr={3} sm={12} xs={12} xl={12} md={12}>
            <Container.Grid item sm={3} xs={3}>
              {record.year ? (
                <DateField
                  fullWidth
                  id="year"
                  datePicker
                  label="Year"
                  name="year"
                  format="year"
                  value={state.data.year}
                  onChange={value => onChange('year', `${value}T23:59:59.000Z`, 'SETDATA')}
                  // disableFuture
                />
              ) : (
                <DateField
                id="date"
                datePicker
                label="Date"
                name="date"
                value={state.data.date}
                onChange={value => onChange('date', `${value}`, 'SETDATA')}
                fullWidth
              />
              )}
            </Container.Grid>
            <TextField
              fullWidth
              source={state.data.name}
              value={state.data.name}
              id="name"
              label="Name"
              name="name"
              ml={3}
              required
              inputProps={{
                onPaste: e => setPaste(true),
                onChange: e => onChange('name', e.target.value, 'STEP1')
              }}
            />
            <TextField
              fullWidth
              source={state.data.description}
              value={state.data.description}
              id="description"
              label="Description"
              name="description"
              multiline
              rows={4}
              ml={3}
              required
              inputProps={{
                onPaste: e => setPaste(true),
                onChange: e => onChange('description', e.target.value, 'STEP1')
              }}
            />
          </Container.Grid>
        ) : (
          <Container.Grid item direction="column" ml={3} pb={2} sm={12} xs={12} xl={12} md={12}>
            <Container.Grid direction="row" alignItems="center" sm={4} xs={4} xl={4} md={4}>
              <DragZone
                title="Upload a file"
                fileType=".doc, .docx, .pdf, .txt, .dotx, .csv, .xlsx, .xls"
                size={12}
                record={state.data}
                dispatch={onFileUpload}
                type="file"
              />
            </Container.Grid>
          </Container.Grid>
        )}
      </Container.Grid>
      <Container.Grid item justify="flex-end" alignItems="center" mr={3} sm={12} xs={12} xl={12} md={12}>
        {state.step > 0 ? (
          <>
            <Button onClick={e => handleBack(e)}>Back</Button>
            <Button ml={2} disabled={state.loading || state.disabled} onClick={onSave}>
              Save
            </Button>
          </>
        ) : (
          <Button variant="contained" onClick={handleNext} disabled={state.loading || state.disabled}>
            Next
          </Button>
        )}
      </Container.Grid>
    </Container.Grid>
  );
};

function reducer(prevState, { type, payload }) {
  switch (type) {
    case 'STEP1': {
      const newData = { ...prevState.data, ...payload };
      const isDisable = newData.name.trim().length > 0 && newData.description.trim().length > 0;
      return { ...prevState, data: newData, disabled: !isDisable };
    }

    case 'STEP2': {
      return { ...prevState, data: { ...prevState.data, file: payload }, disabled: false };
    }

    case 'SETDATA': {
      return { ...prevState, data: { ...prevState.data, ...payload } };
    }

    case 'NEXTSTEP': {
      return { ...prevState, step: prevState.step + 1, data: { ...prevState.data, id: payload.id }, disabled: !payload.disabled };
    }

    case 'PREVSTEP': {
      const { name, description } = prevState.data;
      const isDisable = name.length > 0 && description.length > 0;
      return { ...prevState, step: prevState.step - 1, disabled: !isDisable, loading: false };
    }

    case 'SETLOADING': {
      return { ...prevState, loading: payload };
    }

    default: {
      return prevState;
    }
  }
}

export default PageDocumentsModal;
