// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const DragDrop = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 30.635 25.308" role="img" aria-hidden="false">
    <g transform="translate(-4 -62.692)">
      <path d="M45.064,53.005A1.006,1.006,0,0,0,45,51.59l-3.663-3.33a.842.842,0,0,0-.676-.26h0a.9.9,0,0,0-.676.26l-3.663,3.33a1.008,1.008,0,0,0-.063,1.415,1.045,1.045,0,0,0,1.415.063l1.988-1.811v9.064a1,1,0,1,0,2,0V51.257l1.988,1.811A1.068,1.068,0,0,0,45.064,53.005Z" transform="translate(-21.344 26.68)" fill="#082f49"/>
      <path d="M28.579,21.386A9.272,9.272,0,0,0,10.2,19.5a7.639,7.639,0,0,0,1.457,15.141h3.663a1,1,0,0,0,.028-2H11.659a5.663,5.663,0,0,1-.52-11.3,1,1,0,0,0,.895-.874A7.33,7.33,0,1,1,26.592,22.2a1,1,0,0,0,.866,1.116.937.937,0,0,0,.122.008h.4a4.662,4.662,0,0,1,0,9.324H23.314a1,1,0,0,0-.028,2h4.69a6.642,6.642,0,0,0,.6-13.257Z" transform="translate(0 50.692)" fill="#082f49"/>
    </g>
  </SvgIconStyled>
);

export default DragDrop;
