// @flow
import React, { type Element, useReducer, useState, useEffect } from 'react';
import { Container, TextField, DragZone, Button, DateField, SelectField } from 'components';
import { useNotify } from 'react-admin';
import { dataProvider, isNumber, isValidEmail} from 'helpers';
import { useId, useLocation } from 'hooks';
import { Step, StepLabel } from '@material-ui/core';
import MuiPhoneNumber from 'material-ui-phone-number';
import { Stepper } from './ComponentTypes';
import { LazyIcon } from 'components/icons';
import useHasInternet from '../../hooks/useHasInternet';

type recordType = {
  id: string,
  business_name: string,
  contact: string,
  email: string,
  address: string,
  address2: string,
  city: string,
  state: string,
  zip_code: string,
  phone: string,
  contract_date: string | null,
  relationship_id: string,
  other_relationship: string
};

type PageDocumentsServiceProviderModalTypes = {
  record: recordType,
  tab: string,
  type: string,
  dispatch: {
    onClose: Function,
    presignedUpload: Function,
    onCheckValidity: Function
  },
  id: string
};

type PageDocumentsServiceProviderStateModal = {
  data: {
    ...recordType,
    file: any
  },
  step: number,
  disabled: boolean,
  loading: boolean
};

type PageDocumentsServiceProviderDispatchModal = {
  type: string,
  payload: any
};

const initialState = {
  data: {
    id: '',
    business_name: '',
    contact: '',
    email: '',
    address: '',
    address2: '',
    city: '',
    state: '',
    zip_code: '',
    phone: '',
    contract_date: new Date().toISOString().slice(0, 10),
    relationship_id: '',
    other_relationship: ''
  },
  step: 0,
  disabled: true,
  loading: false
};

let statusMessage = null;
let showCreatedMsg = false;

const PageDocumentsServiceProviderModal = ({
  record,
  tab,
  type: modalType,
  dispatch,
  id,
  ...props
}: PageDocumentsServiceProviderModalTypes): Element<*> => {
  const app = 'clients';
  const notify = useNotify();
  const { item } = useLocation();
  const clientId = useId({ key: 'clientId' });
  const setId = item || clientId;
  const [state, setState] = useReducer<
    PageDocumentsServiceProviderStateModal,
    PageDocumentsServiceProviderDispatchModal
  >(reducer, initialState);
  const steps = ['Information', 'Attachment'];
  const [relationshipOptions, setRelationshipOptions] = useState(null);
  const [createdData, setCreatedData] = useState(null);
  const hasInternet = useHasInternet();

  useEffect(() => {
    return () => {
      if (showCreatedMsg) {
        notify(statusMessage);
        showCreatedMsg = false;
        statusMessage = null;
      }
    };
  }, [notify]);

  useEffect(() => {
    if (relationshipOptions === null) {
      dataProvider
        .getList('clients', `documentsServiceProviderRelationships/${setId}`, {})
        .then(res => hasInternet(() => setRelationshipOptions(Array.isArray(res.data) ? res.data : [])));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relationshipOptions]);

  const optionsRelationship =
    relationshipOptions &&
    relationshipOptions.map((v, l) => {
      return { value: v.id, label: v.name };
    });

  const handleChangePhone = (value, name) => {
    onChange({ target: { name, value } }, 'STEP1');
  };

  const handleEmailValidation = e => {
    e.target.setCustomValidity('');

    if (!isValidEmail(e.target.value))
      e.target.setCustomValidity('Please enter a valid email address');

    onChange(e, 'STEP1');
  };

  const onChange = ({ target: { name, value } }, type) => {
    setState({ type, payload: { [name]: value } });
  };

  const onDateChange = (name, value) => {
    setState({ type: 'STEP1', payload: { [name]: value } });
  };

  const handleNext = e => {
    e.preventDefault();
    const isValid = dispatch.onCheckValidity();

    if (isValid) {
      setState({ type: 'SETLOADING', payload: true });

      const relationshipItem = createdData && relationshipOptions.find(item => item.name === createdData.relationship);

      const isEdit =
        createdData &&
        (createdData.business_name !== state.data.business_name ||
          createdData.contact !== state.data.contact ||
          createdData.email !== state.data.email ||
          createdData.business_address !== state.data.address ||
          createdData.business_address2 !== state.data.address2 ||
          createdData.city !== state.data.city ||
          createdData.state !== state.data.state ||
          createdData.zip !== state.data.zip_code ||
          createdData.phone !== state.data.phone ||
          createdData.contract_date !== state.data.contract_date ||
          relationshipItem.id !== state.data.relationship_id ||
          createdData.other_relationship !== state.data.other_relationship);

      if (state?.data?.id?.length === 0) {
        dataProvider
          .post(app, `documentsServiceProvider/${setId}`, null, state.data)
          .then(res => {
            statusMessage = res.data?.status;
            showCreatedMsg = true;
            setState({ type: 'NEXTSTEP', payload: res.data?.data?.id });
            setCreatedData(res.data?.data);
          })
          .catch(error => {
            notify(error?.response?.data?.message, 'warning');
          })
          .finally(() => {
            setState({ type: 'SETLOADING', payload: false });
          });
      } else if (isEdit) {
        dataProvider
          .update(app, `documentsServiceProviderInfo/${state?.data?.id}?client_id=${setId}`, state.data)
          .then(res => {
            const docType =
              tab === 'documentsServiceProvider' ? 'Service Provider Document' : 'Business Associate Agreement';
            statusMessage = `New ${docType} '${res.data?.data?.business_name}' was successfully created`;
            showCreatedMsg = true;
            setState({ type: 'NEXTSTEP', payload: res.data?.data?.id });
            setCreatedData(res.data?.data);
          })
          .catch(error => {
            notify(error?.response?.data?.message, 'warning');
          })
          .finally(() => {
            setState({ type: 'SETLOADING', payload: false });
          });
      } else {
        setState({ type: 'NEXTSTEP', payload: state.data.id });
      }
    }
  };

  const handleBack = e => {
    e.preventDefault();
    setState({ type: 'PREVSTEP', payload: null });
  };

  const onSave = () => {
    setState({ type: 'SETLOADING', payload: true });
    showCreatedMsg = false;
    dataProvider
      .post(app, `presignedUpload/${state.data.id}`, null, { filename: state.data.file.name, type: modalType })
      .then(res => {
        const formData = new FormData();
        formData.append('AWSAccessKeyId', res.data.fields.AWSAccessKeyId);
        formData.append('key', res.data.fields.key);
        formData.append('policy', res.data.fields.policy);
        formData.append('signature', res.data.fields.signature);
        formData.append('x-amz-security-token', res.data.fields['x-amz-security-token']);
        formData.append('file', state.data.file);
        dataProvider
          .postUrl(res.data.url, formData, 'multipart/form-data')
          .then(resp => {
            // eslint-disable-next-line no-console
            console.log('Res post url: ', resp);
            notify(statusMessage);
            dispatch.onClose();
          })
          .catch(err => {
            // eslint-disable-next-line no-console
            console.log(err);
          })
          .finally(() => {
            setState({ type: 'SETLOADING', payload: false });
          });
      })
      .catch(err => {
        notify(err && err.response && err.response.data.message, 'warning');
      });
  };

  const onFileUpload = file => {
    setState({ type: 'STEP2', payload: file });
  };

  return (
    <Container.Grid direction="column">
      <Stepper activeStep={state.step} alternativeLabel>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Container.Grid item alignItems="center" sm={24} xs={24} xl={24} md={24}>
        {state.step === 0 ? (
          <Container.Grid direction="column">
            <Container.Grid spacing={4}>
              <input type="hidden" name="id" value={id} />
              <Container.Grid item sm={4} xs={12}>
                <TextField
                  value={state.data.business_name}
                  name="business_name"
                  label={tab === 'documentsServiceProvider' ? 'Service Provider Organization Name' : 'Business Name'}
                  onChange={e => onChange(e, 'STEP1')}
                  multiline
                  fullWidth
                  required
                />
              </Container.Grid>
              <Container.Grid item sm={4} xs={12}>
                <TextField
                  value={state.data.contact}
                  name="contact"
                  label="Contact Name"
                  onChange={e => onChange(e, 'STEP1')}
                  multiline
                  fullWidth
                  required
                />
              </Container.Grid>
              <Container.Grid item sm={4} xs={12}>
                <TextField
                  type="email"
                  value={state.data.email}
                  name="email"
                  label="Email Address"
                  onChange={e => handleEmailValidation(e, 'STEP1')}
                  fullWidth
                  required
                />
              </Container.Grid>
            </Container.Grid>
            <Container.Grid spacing={4}>
              <Container.Grid item sm={4} xs={12}>
                <TextField
                  value={state.data.address}
                  name="address"
                  label="Address"
                  onChange={e => onChange(e, 'STEP1')}
                  multiline
                  fullWidth
                />
              </Container.Grid>
              <Container.Grid item sm={4} xs={12}>
                <TextField
                  value={state.data.address2}
                  name="address2"
                  label="Address 2"
                  onChange={e => onChange(e, 'STEP1')}
                  multiline
                  fullWidth
                />
              </Container.Grid>
              <Container.Grid item sm={4} xs={12}>
                <TextField
                  value={state.data.city}
                  name="city"
                  label="City"
                  onChange={e => onChange(e, 'STEP1')}
                  multiline
                  fullWidth
                />
              </Container.Grid>
            </Container.Grid>
            <Container.Grid spacing={4}>
              <Container.Grid item sm={4} xs={12}>
                <TextField
                  value={state.data.state}
                  name="state"
                  label="State"
                  onChange={e => onChange(e, 'STEP1')}
                  multiline
                  fullWidth
                />
              </Container.Grid>
              <Container.Grid item sm={4} xs={12}>
                <TextField
                  value={state.data.zip_code}
                  name="zip_code"
                  label="Zip Code"
                  onChange={e => onChange(e, 'STEP1')}
                  integerOnly
                  inputProps={{ maxLength: 5}}
                  fullWidth
                />
              </Container.Grid>
              <Container.Grid item sm={4} xs={12} mt={1}>
                <MuiPhoneNumber
                  variant="outlined"
                  value={state.data.phone}
                  name="phone"
                  label="Phone Number"
                  onChange={e => handleChangePhone(e, 'phone')}
                  defaultCountry="us"
                  fullWidth
                />
              </Container.Grid>
            </Container.Grid>
            <Container.Grid spacing={4} mt={0.2}>
              <Container.Grid item sm={4} xs={12}>
                <DateField
                  id="contract_date"
                  datePicker
                  label="Contract Date"
                  name="contract_date"
                  onChange={value => onDateChange('contract_date', value)}
                  value={state.data.contract_date}
                  fullWidth
                  required
                />
              </Container.Grid>
            </Container.Grid>
            <Container.Grid spacing={4} mt={0.2}>
              {optionsRelationship && (
                <>
                  <Container.Grid item mt={2} sm={12} xs={12}>
                    <SelectField
                      name="relationship_id"
                      value={state.data.relationship_id}
                      emptyValue="Select Please"
                      label="Relationship"
                      fullWidth
                      onChange={e => onChange(e, 'STEP1')}
                      choices={optionsRelationship}
                      required
                    />
                  </Container.Grid>
                  {state.data.relationship_id === 'VFZSSlBRPT0=' && (
                    <Container.Grid item sm={12} xs={12}>
                      <TextField
                        value={state.data.other_relationship}
                        name="other_relationship"
                        label="Other Relationship"
                        multiline
                        rows={2}
                        onChange={e => onChange(e, 'STEP1')}
                        fullWidth
                      />
                    </Container.Grid>
                  )}
                </>
              )}
            </Container.Grid>
          </Container.Grid>
        ) : (
          <Container.Grid item direction="column" pb={2} sm={12} xs={12} xl={12} md={12}>
            <Container.Grid direction="row" alignItems="center" sm={5} xs={5} xl={5} md={5}>
              <DragZone
                title="Upload a file"
                fileType=".doc, .docx, .pdf, .txt, .dotx, .csv, .xlsx, .xls"
                size={12}
                record={state.data}
                dispatch={onFileUpload}
                type="file"
              />
            </Container.Grid>
          </Container.Grid>
        )}
      </Container.Grid>
      <Container.Grid item justify="flex-end" alignItems="center" mt={2} sm={12} xs={12} xl={12} md={12}>
        {state.step > 0 ? (
          <>
            <Button onClick={e => handleBack(e)}>Back</Button>
            <Button ml={2} onClick={onSave} disabled={state.loading || state.disabled}>
              Save
            </Button>
          </>
        ) : (
          <Button variant="contained" onClick={handleNext} disabled={state.loading || state.disabled}>
            Next
          </Button>
        )}
      </Container.Grid>
    </Container.Grid>
  );
};

function reducer(
  prevState: PageDocumentsServiceProviderStateModal,
  { type, payload }: PageDocumentsServiceProviderDispatchModal
) {
  switch (type) {
    case 'STEP1': {
      const newState = { ...prevState, data: { ...prevState.data, ...payload } };
      const { business_name, contact, email, contract_date, relationship_id } = newState.data;
      newState.disabled = !business_name.trim() || !contact.trim() || !email || !contract_date || !relationship_id;
      return newState;
    }
    case 'STEP2': {
      return { ...prevState, data: { ...prevState.data, file: payload }, disabled: false };
    }
    case 'SETDATA': {
      return { ...prevState, data: { ...prevState.data, ...payload } };
    }
    case 'NEXTSTEP': {
      return {
        ...prevState,
        step: prevState.step + 1,
        data: { ...prevState.data, id: payload ? payload : prevState.data.id },
        disabled: !prevState.data.file,
        loading: false
      };
    }
    case 'PREVSTEP': {
      const newState = { ...prevState };
      const { business_name, contact, email, contract_date, relationship_id } = newState.data;
      newState.disabled = !business_name.trim() || !contact.trim() || !email || !contract_date || !relationship_id;
      newState.step = prevState.step - 1;
      newState.loading = false;
      return newState;
    }
    case 'SETLOADING': {
      return { ...prevState, loading: payload };
    }
    default: {
      return prevState;
    }
  }
}

export default PageDocumentsServiceProviderModal;
