// @flow

const { REACT_APP_BUILD_ENV, REACT_APP_BASE_URL } = process.env;
const env = String(REACT_APP_BUILD_ENV);
const base = String(REACT_APP_BASE_URL);
const prefix = {
  myDashboard: {
    default: process.env.REACT_APP_BASE_URL_1,
    microTrainings: process.env.REACT_APP_BASE_URL_2,
    microTrainingsQuiz: process.env.REACT_APP_BASE_URL_2,
    DashboardQuizModal: process.env.REACT_APP_BASE_URL_2,
    DashboardQuizModalAddMark: process.env.REACT_APP_BASE_URL_2,
    DashboardCertificateModal: process.env.REACT_APP_BASE_URL_2,
    trainingCertificate: process.env.REACT_APP_BASE_URL_2,
    get_signed_cookie: process.env.REACT_APP_BASE_URL_2,
    trainingDetails: process.env.REACT_APP_BASE_URL_2,
    ssla: process.env.REACT_APP_BASE_URL_6
  },
  myCompany: {
    default: process.env.REACT_APP_BASE_URL_1,
    track: process.env.REACT_APP_BASE_URL_16,
    users: process.env.REACT_APP_BASE_URL_5
  },
  clients: {
    default: process.env.REACT_APP_BASE_URL_3,
    clientsTrainingCertificateViewModal: process.env.REACT_APP_BASE_URL_2,
    templateCountryList: process.env.REACT_APP_BASE_URL_15,
    templateDifficultyList: process.env.REACT_APP_BASE_URL_15
  },
  newsfeed: {
    default: process.env.REACT_APP_BASE_URL_3
  },
  partnerProfile: {
    default: process.env.REACT_APP_BASE_URL_3,
    GetAllTmss: process.env.REACT_APP_BASE_URL_7,
    PickList: process.env.REACT_APP_BASE_URL_7,
    taxExempt: process.env.REACT_APP_BASE_URL_14
  },
  user: {
    authorize: process.env.REACT_APP_BASE_URL_4,
    login: process.env.REACT_APP_BASE_URL_4,
    default: process.env.REACT_APP_BASE_URL_5
  }
};

function dataApiUrl(app?: string = 'login', tab?: string | false = 'default'): { baseUrl: string, apiUrl: string } {
  const tabName = tab && tab.split('/')[0];
  const t = Object.prototype.hasOwnProperty.call(prefix[app], tabName) ? tabName : 'default';
  const baseUrl = `https://${prefix[app][t]}.${base}/${env}`;
  const apiUrl = tab ? `${baseUrl}/${app}/${tab === 'false' ? 'dashboard' : tab}` : `${baseUrl}/${app}`;
  return { baseUrl, apiUrl };
}

export default dataApiUrl;
